// list constants
export const STORAGE_LIST_MARGIN = 20;

// row constants
export const STORAGE_ROW_HEIGHT = 80;

// floating button
export const SHOW_LATE_STORAGE_BUTTON_OFFSET = 60;

// item (inner container row constants)
export const STORAGE_ROW_ITEM_MARGIN = 10;

// scroll
export const STORAGE_ROW_SCROLL_OFFSET = STORAGE_ROW_HEIGHT + STORAGE_ROW_ITEM_MARGIN;
