import { AxiosResponse } from 'axios';
import { call, CallEffect, put, takeLatest } from 'redux-saga/effects';

import { MooseApiRoutes } from 'src/config/api.config';
import { isOffline } from 'src/helpers/cache.helpers';
import { replaceColonPrefixed } from 'src/helpers/message.helpers';
import { fetchRequest, HttpMethod } from 'src/state/sagas/network/network.saga';
import { actionStatusAdd } from 'src/state/store/actionStatus/actionStatus.action';
import { ActionStatusEnum, ActionType } from 'src/state/store/actionStatus/actionStatus.type';
import { ACTION_OUTGOING_HELPDESK_REQUEST } from 'src/state/store/outgoing/outgoing.const';
import { OutgoingHelpdeskRequestActionType } from 'src/state/store/outgoing/outgoing.type';
import { authorize } from 'src/state/sagas/auth/authorization.saga';

export function* outgoingHelpdeskRequestSaga() {
	yield takeLatest(ACTION_OUTGOING_HELPDESK_REQUEST, outgoingHelpdeskRequestWorker);
}

function* outgoingHelpdeskRequestWorker(action: ActionType<OutgoingHelpdeskRequestActionType>) {
	yield put(actionStatusAdd({ type: ACTION_OUTGOING_HELPDESK_REQUEST }, ActionStatusEnum.RUNNING, isOffline()));

	try {
		if (!action.payload) {
			return;
		}

		const { orderUuid, formdata } = action.payload;

		yield call(sendHelpdeskRequest, orderUuid, formdata);

		yield put(actionStatusAdd({ type: ACTION_OUTGOING_HELPDESK_REQUEST }, ActionStatusEnum.SUCCESS));
	} catch (error) {
		yield put(actionStatusAdd({ type: ACTION_OUTGOING_HELPDESK_REQUEST }, ActionStatusEnum.ERROR, isOffline()));
	}
}

function* sendHelpdeskRequest(orderUuid: string, formdata: FormData): Generator<CallEffect<any>, AxiosResponse, any> {
	return yield call(
		fetchRequest,
		{
			method: HttpMethod.Post,
			url: replaceColonPrefixed(MooseApiRoutes.Helpdesk, { orderUuid }),
			headers: {
				'Content-Type': 'multipart/form-data',
			},
			data: formdata,
		},
		[authorize],
	);
}
