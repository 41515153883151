import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';

import { withNavbarHeader } from 'src/hoc/withNavbarHeader/withNavbarHeader.hoc';
import { NotificationSettingsContainer } from 'src/containers/app/notificationSettings/notificationSettings.container';
import { AppPages } from 'src/navigation/app/appRouter.type';

const NotificationSettingsPageComponent: FC = () => {
	const history = useHistory();

	const navToEmail = () => {
		history.push(AppPages.EmailSettings);
	};

	const navToSms = () => {
		history.push(AppPages.SmsSettings);
	};

	return <NotificationSettingsContainer navToEmail={navToEmail} navToSms={navToSms} />;
};

const NotificationSettingsPage = withNavbarHeader(NotificationSettingsPageComponent);

export { NotificationSettingsPage };
