import { add } from 'date-fns';

import { Mapper } from 'src/state/mappers/mapper.type';
import { ApiOrder, Order } from 'src/state/store/order/order.type';

import { customerMapper } from './customer.mapper';
import { orderStateMapper } from './orderState.mapper';

class OrderMapper extends Mapper<ApiOrder, Order> {
	map(data: ApiOrder): Order {
		if (!data.id || !data.clientOrderId || !data.state || !data.datetime) {
			return this.throwInvalid();
		}

		// eslint-disable-next-line no-undef
		const timeslotDuration: Duration = { hours: 1 };

		const orderDate = Date.parse(data.datetime);

		return {
			id: data.id,
			clientOrderId: data.clientOrderId,
			sequenceNumber: data.sequenceNumber,
			alternateClientOrderReference: data.alternateClientOrderReference,
			timeslot: {
				start: orderDate,
				end: add(orderDate, timeslotDuration).getTime(),
			},
			foodxId: data.foodxId,
			customer: data.customer && customerMapper.map(data.customer),
			state: orderStateMapper.map(data.state),
			totes: data.totes?.map((tote) => ({ barcode: tote.lp })) || [],
			storageSlots: data.storage?.storageSlots.map((storageSlot) => storageSlot.label) || [],
			pallies: data.storage?.pallies.map((pally) => pally.label) || [],
			items: data.items || [],
		};
	}
}

export const orderMapper = new OrderMapper();
