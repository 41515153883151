import React from 'react';
import { BottomSheet } from 'react-spring-bottom-sheet';

import { DraggableBottomSheetProps } from './draggableBottomSheet.type';
import './draggableBottomSheet.style.scss';

const DraggableBottomSheet = ({ open, className, children, onDismiss }: DraggableBottomSheetProps) => {
	return (
		<BottomSheet className={className} open={open} onDismiss={onDismiss}>
			{children}
		</BottomSheet>
	);
};

export { DraggableBottomSheet };
