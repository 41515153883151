import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { Camera, CameraResultType } from '@capacitor/camera';
import { useToasts } from 'react-toast-notifications';

import { processFormDataImages } from 'src/helpers/image.helper';
import { actionOutgoingRefundRequest } from 'src/state/store/outgoing/outgoing.action';
import { selectOrderById } from 'src/state/store/order/order.selector';
import { Store } from 'src/state/store.type';
import { Order } from 'src/state/store/order/order.type';
import { Icon } from 'src/components/icon/icon.component';
import { IconNames } from 'src/components/icon/icon.type';
import { Input } from 'src/components/input/input.component';
import { TextArea } from 'src/components/textArea/textArea.component';
import { IconButton } from 'src/components/button/iconButton/iconButton.component';
import { Colors } from 'src/assets';
import { formatDateKeyForStore } from 'src/helpers/date.helpers';
import './orderRefundContainer.style.scss';
import { ACTION_OUTGOING_REFUND_REQUEST } from 'src/state/store/outgoing/outgoing.const';
import { ActionStatus, ActionStatusEnum } from 'src/state/store/actionStatus/actionStatus.type';
import { useActionStatus } from 'src/hooks/useActionStatus.hook';

import { OrderRefundContainerProps } from './orderRefundContainer.type';

const OrderRefundContainer: FC<OrderRefundContainerProps> = ({
	orderId,
	currentDateKey,
	onNavigateBack,
}: OrderRefundContainerProps) => {
	const order: Order | undefined = useSelector((state: Store) =>
		selectOrderById(state, orderId, formatDateKeyForStore(currentDateKey)),
	);

	const { t } = useTranslation();

	const dispatch = useDispatch();

	const { addToast } = useToasts();

	const helpdeskAction: ActionStatus | undefined = useActionStatus({
		type: ACTION_OUTGOING_REFUND_REQUEST,
	});

	const [images, setImages] = useState<string[]>([]);

	const FormSchema = Yup.object().shape({
		amount: Yup.string().required(t('refundOrder.requiredError')),
		message: Yup.string().required(t('refundOrder.requiredError')),
		images: Yup.array().notRequired(),
	});

	const formik = useFormik<{
		orderUuid?: string;
		amount: number;
		message: string;
	}>({
		initialValues: {
			orderUuid: order?.id,
			amount: 0,
			message: '',
		},
		enableReinitialize: true,
		validationSchema: FormSchema,
		onSubmit: (values) => {
			processFormDataImages(images)
				.then((formdata) => {
					formdata.append('orderUuid', values.orderUuid!);
					formdata.append('amount', values.amount.toString());
					formdata.append('message', values.message);
					formdata.append('date', new Date(order!.timeslot.start).toDateString());

					dispatch(actionOutgoingRefundRequest(values.orderUuid!, formdata));

					onNavigateBack();
				})
				.catch((error) => {
					console.log(error);
				});
		},
	});

	useEffect(() => {
		if (!formik.isSubmitting) {
			return;
		}
		if (helpdeskAction && helpdeskAction.isOffline) {
			addToast(t('offlineWarningModal.description'), {
				appearance: 'warning',
			});
			onNavigateBack();
		} else if (helpdeskAction && helpdeskAction.status) {
			switch (helpdeskAction.status) {
				case ActionStatusEnum.SUCCESS:
					addToast(t('outgoingHelpdesk.successMessage', { orderId: order?.clientOrderId }), {
						appearance: 'success',
					});
					onNavigateBack();
					break;
				case ActionStatusEnum.ERROR:
					addToast(t('outgoingHelpdesk.errorMessage', { orderId: order?.clientOrderId }), {
						appearance: 'error',
					});
			}
		}
	}, [helpdeskAction]);

	const takePicture = async () => {
		try {
			if (images.length >= 5) {
				return;
			}

			const image = await Camera.getPhoto({
				quality: 60,
				allowEditing: false,
				saveToGallery: false,
				resultType: CameraResultType.DataUrl,
				width: 600,
				height: 600,
			});

			setImages((oldImages) => {
				if (image.dataUrl) {
					return [...oldImages, image.dataUrl];
				}

				return oldImages;
			});
		} catch (error) {
			console.log(error);
		}
	};

	const deletePicture = (index: number) => {
		if (images.length >= 0) {
			setImages((oldImages) => oldImages.filter((i) => i !== images[index]));
		}
	};

	return (
		<>
			<div className="outgoing-change-status">
				<Icon className="refund__icon" iconName={IconNames.Support} size={70} />

				<h1>{t('refundOrder.title')}</h1>

				<div className="refund__info">
					<h2>{t('orderDetail.title', { orderId: order?.clientOrderId })}</h2>

					<span className="info__name">
						{order?.customer?.lastname} {order?.customer?.firstname}
					</span>

					<span className="info__date">{order && new Date(order.timeslot.start).toDateString()}</span>
				</div>

				<form id="refund-form" className="refund__form" onSubmit={formik.handleSubmit}>
					<Input
						placeholder={t('refundOrder.amount')}
						fullWidth={true}
						id="amount"
						name="amount"
						type="number"
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
						value={formik.values.amount}
						error={formik.errors.amount}
						touched={formik.touched.amount}
					/>
					<TextArea
						placeholder={t('refundOrder.message')}
						id="message"
						name="message"
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
						value={formik.values.message}
						rows={10}
						error={formik.errors.message}
						touched={formik.touched.message}
					/>

					<div className="picture-preview">
						<div className="picture-preview__list">
							{images.length >= 0 &&
								images.map((value) => {
									const index = images.indexOf(value);

									return (
										<div key={index} className="picture-preview__list__item">
											<img
												src={images[index]}
												alt="{t('refundOrder.uploadedImage')}"
												className=" picture-preview__list__item__thumb"
											/>
											<p className="picture-preview__list__item__text">
												{t('refundOrder.picture')} {index + 1}
											</p>
											<IconButton
												id="delete"
												type="button"
												className="picture-preview__list__item__delete"
												onClick={() => {
													deletePicture(index);
												}}
												iconName={IconNames.Trash}
											/>
										</div>
									);
								})}
							{images.length <= 4 && (
								<div className="picture-preview__list__item add" onClick={takePicture}>
									<Icon iconName={IconNames.ImagePlus} size={40} />
									<p className="picture-preview__list__item__text">
										{images.length === 0
											? `${t('refundOrder.pictureAdd')}`
											: `${t('refundOrder.pictureAddMore')}`}
									</p>
								</div>
							)}
						</div>
					</div>

					<IconButton
						form="refund-form"
						type="submit"
						fullWidth={true}
						className="button"
						backgroundColor={Colors.green}
						iconName={IconNames.Check}
						size={15}
						color="white"
						disabled={!formik.isValid}>
						{t('refundOrder.submit')}
					</IconButton>
				</form>
			</div>
		</>
	);
};

export { OrderRefundContainer };
