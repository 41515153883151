import { Pally, StorageItemKey, StorageSlot } from 'src/state/store/storage/storage.type';
import { Order } from 'src/state/store/order/order.type';

export const getStorageItemType = (item: StorageSlot | Pally | Order): StorageItemKey => {
	if ('foodxId' in item) return StorageItemKey.ORDER;

	if ('pallies' in item) return StorageItemKey.STORAGE_SLOT;

	return StorageItemKey.PALLY;
};

export const getStorageItemNestedCount = (
	item: StorageSlot | Pally | Order,
): { totesAmount?: number; palliesAmount?: number; ordersAmount?: number } => {
	switch (getStorageItemType(item)) {
		case StorageItemKey.STORAGE_SLOT:
			return {
				palliesAmount: (item as StorageSlot).pallies.length,
				ordersAmount: (item as StorageSlot).orderIds?.length || (item as StorageSlot).orders?.length || 0,
			};
		case StorageItemKey.PALLY:
			return {
				ordersAmount: (item as Pally).orderIds?.length || (item as Pally).orders?.length || 0,
			};
		case StorageItemKey.ORDER:
			return {
				totesAmount: (item as Order).totes.length,
			};
	}
};

export const hasStorageItemNestedItems = (item: StorageSlot | Pally | Order): boolean => {
	const nestedCount = getStorageItemNestedCount(item);

	switch (getStorageItemType(item)) {
		case StorageItemKey.STORAGE_SLOT:
			return nestedCount.ordersAmount! > 0 || nestedCount.palliesAmount! > 0;
		case StorageItemKey.PALLY:
			return nestedCount.ordersAmount! > 0;
		case StorageItemKey.ORDER:
			return false;
	}
};
