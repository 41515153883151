import { Mapper } from 'src/state/mappers/mapper.type';
import { ApiStorageOverview, StorageOverviewSetActionType } from 'src/state/store/storage/storage.type';

import { storageSlotMapper } from './storageSlot.mapper';
import { pallyMapper } from './pally.mapper';

class StorageMapper extends Mapper<ApiStorageOverview, StorageOverviewSetActionType> {
	map = (data: ApiStorageOverview): StorageOverviewSetActionType | undefined => {
		if (!data) {
			return this.throwInvalid();
		}

		return {
			storageSlots: data.storageSlots?.map(storageSlotMapper.map) || [],
			pallies: data.pallies?.map(pallyMapper.map) || [],
		};
	};
}

export const storageMapper = new StorageMapper();
