import { createReducer } from 'reduxsauce';

import { arrayToMapRecord } from 'src/helpers/record.helpers';
import { LastFetchedSetActionType } from 'src/state/store/cache/cache.type';
import { ActionType } from 'src/state/store/actionStatus/actionStatus.type';
import { ACTION_LOGOUT } from 'src/state/store/general/general.const';

import {
	ACTION_CARREFOUR_STORES_CLEAR,
	ACTION_CARREFOUR_STORES_SET,
	ACTION_LAST_FETCHED_STORES_SET,
	ACTION_SELECTED_CARREFOUR_STORE_REMOVE,
	ACTION_SELECTED_CARREFOUR_STORE_SET,
	ACTION_STORE_SET_NOTIFICATIONS,
	ACTION_STORE_SET_STORAGE,
	CARREFOUR_STORE_INITIAL_STATE,
} from './carrefourStore.const';
import {
	C4StoreStateStore,
	CarrefourStoresSetActionType,
	SelectedStoreSetActionType,
	StoreSetNotificationsActionType,
	StoreSetStorageActionType,
} from './carrefourStore.type';

const storesSet = (state: C4StoreStateStore, action: ActionType<CarrefourStoresSetActionType>): C4StoreStateStore => {
	if (!action.payload) {
		return state;
	}

	const { stores } = action.payload;

	return { ...state, stores: { ...state.stores, ...arrayToMapRecord(stores) } };
};

const selectedStoreSet = (
	state: C4StoreStateStore,
	action: ActionType<SelectedStoreSetActionType>,
): C4StoreStateStore => {
	if (!action.payload) {
		return state;
	}

	const { id } = action.payload;

	return { ...state, selectedStoreId: id };
};

const selectedStoreRemove = (state: C4StoreStateStore): C4StoreStateStore => {
	return { ...state, selectedStoreId: undefined };
};

const storeSetNotifications = (
	state: C4StoreStateStore,
	action: ActionType<StoreSetNotificationsActionType>,
): C4StoreStateStore => {
	if (!action.payload?.id || !state.stores[action.payload.id]) {
		return state;
	}

	const { id, emails, phoneNumbers } = action.payload;

	const store = state.stores[id];

	return {
		...state,
		stores: {
			...state.stores,
			[id]: {
				...store,
				notifications: {
					emails: [...emails],
					phoneNumbers: [...phoneNumbers],
				},
			},
		},
	};
};

const lastFetchedStoresSet = (
	state: C4StoreStateStore,
	action: ActionType<LastFetchedSetActionType>,
): C4StoreStateStore => {
	if (!action.payload) {
		return state;
	}

	const { lastFetched } = action.payload;

	return {
		...state,
		lastFetched,
	};
};

const storeSetStorage = (
	state: C4StoreStateStore,
	action: ActionType<StoreSetStorageActionType>,
): C4StoreStateStore => {
	const store = action.payload?.id && state.stores[action.payload.id];

	if (!action.payload || !store) {
		return state;
	}

	const { id, storageFeatures } = action.payload;

	return {
		...state,
		stores: {
			...state.stores,
			[id]: {
				...store,
				storageFeatures,
			},
		},
	};
};

const clear = (): C4StoreStateStore => {
	return CARREFOUR_STORE_INITIAL_STATE;
};

/* ------------- Hookup Reducers To Types ------------- */

export const storesReducer = createReducer(CARREFOUR_STORE_INITIAL_STATE, {
	[ACTION_CARREFOUR_STORES_SET]: storesSet,
	[ACTION_SELECTED_CARREFOUR_STORE_SET]: selectedStoreSet,
	[ACTION_SELECTED_CARREFOUR_STORE_REMOVE]: selectedStoreRemove,
	[ACTION_STORE_SET_NOTIFICATIONS]: storeSetNotifications,
	[ACTION_STORE_SET_STORAGE]: storeSetStorage,
	[ACTION_LAST_FETCHED_STORES_SET]: lastFetchedStoresSet,
	[ACTION_LOGOUT]: clear,
	[ACTION_CARREFOUR_STORES_CLEAR]: clear,
});
