import { call, CallEffect, put, select, takeLatest } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';

import { MooseApiRoutes } from 'src/config/api.config';
import { replaceColonPrefixed } from 'src/helpers/message.helpers';
import { authorize } from 'src/state/sagas/auth/authorization.saga';
import { fetchRequest, HttpMethod } from 'src/state/sagas/network/network.saga';
import { actionStatusAdd } from 'src/state/store/actionStatus/actionStatus.action';
import { ActionStatusEnum, ActionType } from 'src/state/store/actionStatus/actionStatus.type';
import { selectSelectedStoreId } from 'src/state/store/carrefourStore/carrefourStore.selector';
import { ACTION_STORAGE_CREATE_STORAGE_SLOT } from 'src/state/store/storage/storage.const';
import { StorageCreateStorageSlotActionType } from 'src/state/store/storage/storage.type';
import { createdItemMapper } from 'src/state/mappers/generic/createdItem.mapper';

function* createStorageSlot(
	storeId: string,
	code: number,
	label?: string,
	pallyIds?: string[],
	orderIds?: string[],
): Generator<CallEffect, AxiosResponse, AxiosResponse> {
	return yield call(
		fetchRequest,
		{
			method: HttpMethod.Post,
			url: replaceColonPrefixed(MooseApiRoutes.StorageCreateStorageSlot, {
				storeUuid: storeId,
			}),
			data: {
				code,
				label,
				pallyIds,
				orderIds,
			},
		},
		[authorize],
		createdItemMapper,
	);
}

function* createStorageSlotWorker(action: ActionType<StorageCreateStorageSlotActionType>): any {
	yield put(actionStatusAdd({ type: ACTION_STORAGE_CREATE_STORAGE_SLOT }, ActionStatusEnum.RUNNING));

	try {
		const selectedCarrefourStore: string | undefined = yield select(selectSelectedStoreId);

		if (!action.payload || !selectedCarrefourStore) {
			return;
		}

		const { code, label, pallyIds, orderIds } = action.payload;

		yield call(createStorageSlot, selectedCarrefourStore, code, label, pallyIds, orderIds);

		yield put(actionStatusAdd({ type: ACTION_STORAGE_CREATE_STORAGE_SLOT }, ActionStatusEnum.SUCCESS));
	} catch (error) {
		yield put(actionStatusAdd({ type: ACTION_STORAGE_CREATE_STORAGE_SLOT }, ActionStatusEnum.ERROR));
	}
}

export function* createStorageSlotSaga() {
	yield takeLatest(ACTION_STORAGE_CREATE_STORAGE_SLOT, createStorageSlotWorker);
}
