import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Colors } from 'src/assets';
import { Button } from 'src/components/button/button.component';
import { Icon } from 'src/components/icon/icon.component';
import { IconNames } from 'src/components/icon/icon.type';
import { LinkMethod, StorageItemKey } from 'src/state/store/storage/storage.type';
import { BottomSheet } from 'src/components/bottomSheet/bottomSheet.component';

import { StorageMoveOrExtendBottomSheetProps } from './storageMoveExtend.type';
import './storageMoveExtend.style.scss';

const StorageMoveExtendBottomSheet: FC<StorageMoveOrExtendBottomSheetProps> = ({
	storageItem,
	onSelectMethod,
	onCancel,
}: StorageMoveOrExtendBottomSheetProps) => {
	const { t } = useTranslation();

	const storageItemTranslation = (includeLabel?: boolean) => {
		let value: string;

		switch (storageItem.type) {
			case StorageItemKey.STORAGE_SLOT:
				value = t('storageBottomSheet.storageSlot');
				break;
			case StorageItemKey.PALLY:
				value = t('storageBottomSheet.pally');
				break;
			default:
				value = 'unknown item';
				console.error('unknown storage item');
		}

		if (includeLabel) {
			value = `${value} ${storageItem.data}`;
		}

		return value;
	};

	const iconName = storageItem.type === StorageItemKey.STORAGE_SLOT ? IconNames.Storages : IconNames.Pallies;

	return (
		<BottomSheet>
			<div className="storage-bottom-sheet">
				<Icon className="storage__icon" iconName={iconName} size={70} />
				<h1>{t('storageBottomSheet.bottomSheetTitle')}</h1>

				<div className="storage-bottom-sheet__button-container">
					<Button backgroundColor={Colors.primary} onClick={() => onSelectMethod(LinkMethod.MOVE)}>
						{t('storageBottomSheet.moveButton', { storageType: storageItemTranslation() })}
					</Button>
					<Button backgroundColor={Colors.primary} onClick={() => onSelectMethod(LinkMethod.EXTEND)}>
						{t('storageBottomSheet.extendButton', { storageType: storageItemTranslation() })}
					</Button>
					<Button backgroundColor={Colors.red} onClick={onCancel}>
						{t('storageBottomSheet.cancelButton')}
					</Button>
				</div>
			</div>
		</BottomSheet>
	);
};

export default StorageMoveExtendBottomSheet;
