import React, { FC } from 'react';
import { useHistory } from 'react-router';

import { TotesScannerContainer } from 'src/containers/app/outgoing/totesScanner/totesScanner.container';
import { withNavbarHeader } from 'src/hoc/withNavbarHeader/withNavbarHeader.hoc';
import { AppPages, OrderDetailPageProps } from 'src/navigation/app/appRouter.type';

const TotesScannerPageComponent: FC<OrderDetailPageProps> = ({ orderId, currentDateKey }: OrderDetailPageProps) => {
	const history = useHistory();

	const navigateBack = () => {
		history.goBack();
	};

	const navigateToOutgoingOverview = () => {
		history.push(AppPages.Outgoing);
	};
	console.log('page currentDateKey', currentDateKey);

	return (
		<TotesScannerContainer
			currentDateKey={currentDateKey}
			orderId={orderId}
			onNavigateBack={navigateBack}
			onNavigateToOutgoingOverview={navigateToOutgoingOverview}
		/>
	);
};

const TotesScannerPage = withNavbarHeader<OrderDetailPageProps>(TotesScannerPageComponent);

export { TotesScannerPage };
