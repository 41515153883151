import { CSSProperties } from 'react';

export enum IconNames {
	ArrowUp = 'arrowUp',
	Box = 'box',
	Check = 'check',
	CheckedCircle = 'checkedCircle',
	Chevron = 'chevron',
	ChevronLeft = 'chevronLeft',
	Clock = 'clock',
	Clock2 = 'clock2',
	Cross = 'cross',
	Download = 'download',
	Edit = 'Edit',
	Enter = 'enter',
	EuroCircle = 'euroCircle',
	Exit = 'exit',
	Eye = 'eye',
	Grid = 'Grid',
	Groceries = 'groceries',
	GroceriesCarrefour = 'groceriesCarrefour',
	HRLStore = 'HRLStore',
	HelpDesk = 'HelpDesk',
	Incoming = 'incoming',
	ImagePlus = 'imagePlus',
	Minus = 'minus',
	OrderDelivered = 'orderDelivered',
	Outgoing = 'outgoing',
	Pally = 'pally',
	Pallies = 'pallies',
	Person = 'person',
	Plus = 'plus',
	ReturnTotes = 'returnTotes',
	Scan = 'scan',
	Search = 'search',
	Settings = 'settings',
	SettingsBlue = 'settingsBlue',
	Storage = 'storage',
	Storages = 'storages',
	Store = 'Store',
	Support = 'support',
	Trash = 'trash',
	Warning = 'warning',
}

export enum IconSizes {
	Tiny = 'tiny',
	Small = 'small',
	Normal = 'normal',
	Large = 'large',
	Huge = 'huge',
}

export interface IconProps {
	iconName: IconNames;
	color?: string;
	stroke?: string;
	className?: string;
	style?: CSSProperties;
	size?: IconSizes | number | string;
}
