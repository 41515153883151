import { OrderStore } from './order.type';

export const ACTION_OUTBOUND_ORDERS_FETCH = 'ACTION_OUTBOUND_ORDERS_FETCH';
export const ACTION_OUTBOUND_ORDERS_SET = 'ACTION_OUTBOUND_ORDERS_SET';

export const ACTION_ORDER_DETAIL_FETCH = 'ACTION_ORDER_DETAIL_FETCH';
export const ACTION_ORDER_DETAIL_SET = 'ACTION_ORDER_DETAIL_SET';

export const ACTION_OUTGOING_ORDER_UPDATE = 'ACTION_OUTGOING_ORDER_UPDATE';
export const ACTION_OUTGOING_ORDER_UPDATE_SET = 'ACTION_OUTGOING_ORDER_UPDATE_SET';

export const ACTION_OUTBOUND_ORDERS_CLEAR = 'ACTION_OUTBOUND_ORDERS_CLEAR';

export const ACTION_LAST_FETCHED_OUTBOUND_ORDERS_SET = 'ACTION_LAST_FETCHED_OUTBOUND_ORDERS_SET';
export const ACTION_LAST_FETCHED_OUTBOUND_ORDERS_PREVIOUS_DAYS_SET =
	'ACTION_LAST_FETCHED_OUTBOUND_ORDERS_PREVIOUS_DAYS_SET';

export const ACTION_CUSTOMER_BY_ORDER_ID_FETCH = 'ACTION_CUSTOMER_BY_ORDER_ID_FETCH';
export const ACTION_ORDER_CUSTOMER_SET = 'ACTION_ORDER_CUSTOMER_SET';

export const ORDER_INITIAL_STATE: OrderStore = {
	orders: {},
};
