import React, { FC } from 'react';
import classNames from 'classnames';

import './scannerSkeleton.style.scss';
import { ScannerSekeltonProps } from './scannerSkeleton.type';

const ScannerSkeleton: FC<ScannerSekeltonProps> = ({ className, children }: ScannerSekeltonProps) => {
	return <div className={classNames('scanner-skeleton', className)}>{children}</div>;
};

export { ScannerSkeleton };
