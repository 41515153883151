import { createReducer } from 'reduxsauce';

import { ActionType } from 'src/state/store/actionStatus/actionStatus.type';

import { ACTION_USER_NOT_AUTHORIZED_SET, USER_STORE_INITIAL_STATE } from './user.const';
import { UserNotAuthorizedReasonSetActionType, UserStore } from './user.type';

const userHasStoreAppAccessSet = (
	state: UserStore,
	action: ActionType<UserNotAuthorizedReasonSetActionType>,
): UserStore => {
	if (!action.payload) {
		return state;
	}

	const { userNotAuthorizedReason } = action.payload;

	return { ...state, userNotAuthorizedReason };
};

/* ------------- Hookup Reducers To Types ------------- */

export const userReducer = createReducer(USER_STORE_INITIAL_STATE, {
	[ACTION_USER_NOT_AUTHORIZED_SET]: userHasStoreAppAccessSet,
});
