import { ActionType } from 'src/state/store/actionStatus/actionStatus.type';

import { ACTION_USER_NOT_AUTHORIZED_SET } from './user.const';
import { UserNotAuthorizedReasonSetActionType, AuthorizationErrorCodes } from './user.type';

export const actionUserNotAuthorizedReasonSet = (
	userNotAuthorizedReason?: AuthorizationErrorCodes,
): ActionType<UserNotAuthorizedReasonSetActionType> => {
	return {
		type: ACTION_USER_NOT_AUTHORIZED_SET,
		payload: {
			userNotAuthorizedReason,
		},
	};
};
