import { useContext } from 'react';

import { LoaderContext, LoaderContextProps } from 'src/providers/loader.provider';

/**
 * Custom hook that can be used by any component to show/hide loader
 */
const useLoader = (): LoaderContextProps => useContext(LoaderContext);

export { useLoader };
