import { Store } from 'src/state/store.type';

import { AppLanguages } from './settings.const';
import { AppLanguage } from './settings.type';

export const selectAppLanguagePrefix = ({ settings }: Store): AppLanguage | undefined => {
	return settings.appLanguage;
};

export const selectAppLanguageFullName = ({ settings }: Store): string | undefined => {
	if (settings.appLanguage) {
		return AppLanguages[settings.appLanguage];
	}
};

export const selectApiVersion = ({ settings }: Store): string | undefined => {
	return settings.apiVersion;
};
