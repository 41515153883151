import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import ClipLoader from 'react-spinners/ClipLoader';
import { useAuthContext } from '@foodx/fx-react-utils';

import { Card } from 'src/components/card/card.component';
import { Header } from 'src/components/navigation/header/header.component';
import { CarrefourStore } from 'src/state/store/carrefourStore/carrefourStore.type';
import { selectCarrefourStores } from 'src/state/store/carrefourStore/carrefourStore.selector';
import {
	actionCarrefourStoresFetch,
	actionSelectedStoreChange,
} from 'src/state/store/carrefourStore/carrefourStore.action';
import { ReactComponent as Store } from 'src/assets/vectors/logos/store.svg';
import { useActionStatus } from 'src/hooks/useActionStatus.hook';
import { ACTION_CARREFOUR_STORES_FETCH } from 'src/state/store/carrefourStore/carrefourStore.const';
import { ActionStatusEnum } from 'src/state/store/actionStatus/actionStatus.type';
import { Colors } from 'src/assets';
import { ReactComponent as Support } from 'src/assets/vectors/icons/support.svg';
import { PrimaryButton } from 'src/components/button/primaryButton/primaryButton.component';
import { actionLogout } from 'src/state/store/general/general.action';
import { IconNames } from 'src/components/icon/icon.type';
import { IconButton } from 'src/components/button/iconButton/iconButton.component';
import { UserMetaData } from 'src/state/store/token/token.type';
import { getMSALUserMetaData } from 'src/helpers/auth.helper';
import { AuthorizationErrorCodes } from 'src/state/store/user/user.type';
import { selectUserNotAuthorizedReason } from 'src/state/store/user/user.selector';
import { actionUserNotAuthorizedReasonSet } from 'src/state/store/user/user.action';
import { AppVersionLabel } from 'src/components/label/appVersionLabel/appVersionLabel.component';
import { APP_VERSION } from 'src/config/app.config';
import { selectApiVersion } from 'src/state/store/settings/settings.selector';
import './storeSelection.scss';
import { useIsRole } from 'src/hooks/useIsRole.hook';
import { Roles } from 'src/models/roles.model';

import { StoreSelectionContainerProps } from './storeSelectionContainer.type';

const StoreSelectionContainer = ({ onNavigateToApp, onNavigateToBackoffice }: StoreSelectionContainerProps) => {
	const { t } = useTranslation();
	const { logout } = useAuthContext();
	const storeActionStatus: ActionStatusEnum | undefined = useActionStatus({
		type: ACTION_CARREFOUR_STORES_FETCH,
	})?.status;

	const stores: CarrefourStore[] = useSelector(selectCarrefourStores);
	const userNotAuthorizedReason: AuthorizationErrorCodes | undefined = useSelector(selectUserNotAuthorizedReason);
	const apiVersion: string | undefined = useSelector(selectApiVersion);

	const hasBackOffice = useIsRole(Roles.ADMIN);

	const dispatch = useDispatch();

	const userMetaData = useRef<UserMetaData | null>(null);

	useEffect(() => {
		fetchCarrefourStores();

		userMetaData.current = getMSALUserMetaData();

		return () => {
			userMetaData.current = null;
		};
	}, []);

	const setSelectedStore = (id: string) => {
		dispatch(actionSelectedStoreChange(id));
		dispatch(actionUserNotAuthorizedReasonSet());
		onNavigateToApp();
	};

	const fetchCarrefourStores = () => {
		dispatch(actionCarrefourStoresFetch());
	};

	const logUserOut = async () => {
		dispatch(actionLogout());
		await logout({ authority: `https://login.microsoftonline.com/${process.env.REACT_APP_AUTH_TENANT}` });
	};

	const renderBackoffice = () => {
		if (hasBackOffice) {
			return (
				<Card className="cards-container__card" key={'store.id'} onClick={() => onNavigateToBackoffice()}>
					<div className="card__logo card__icon">{<Support />}</div>
					<div className="card__description">
						<span className="description__name">{t('backoffice.title')}</span>
					</div>
				</Card>
			);
		}
	};

	const renderCards = () => {
		// if user has no access show unauthenticated message
		if (
			!userMetaData.current?.roles ||
			!userMetaData.current.roles.includes('store-team-member') ||
			userNotAuthorizedReason === AuthorizationErrorCodes.INVALID_ROLE
		) {
			return (
				<div className="store-selection__message-container">
					<span className="message-container__message">{t('storeSelection.noValidUserRole')}</span>
				</div>
			);
		}

		// If saga is running just return spinner
		if (storeActionStatus && storeActionStatus === ActionStatusEnum.RUNNING) {
			return (
				<div className="loading-container">
					<ClipLoader color={Colors.primary} loading={true} />
				</div>
			);
		}

		// if user meta data has no stores or API returns an empty array of stores we show an empty store message
		if (
			!userMetaData?.current?.storeIds ||
			userMetaData.current?.storeIds.length === 0 ||
			(storeActionStatus && storeActionStatus === ActionStatusEnum.SUCCESS && (!stores || stores.length === 0))
		) {
			return (
				<div className="store-selection__message-container">
					<span className="message-container__message">{t('storeSelection.emptyMessage')}</span>
				</div>
			);
		}

		// if there is any other message show retry button
		if (storeActionStatus && storeActionStatus === ActionStatusEnum.ERROR && (!stores || stores.length === 0)) {
			return (
				<div className="store-selection__message-container">
					<span className="message-container__message">{t('storeSelection.errorMessage')}</span>
					<PrimaryButton onClick={fetchCarrefourStores}>{t('storeSelection.tryAgainCta')}</PrimaryButton>
				</div>
			);
		}

		return (
			<div className="store-selection__cards-container">
				{renderBackoffice()}
				{stores.map((store: CarrefourStore) => (
					<Card className="cards-container__card" key={store.id} onClick={() => setSelectedStore(store.id)}>
						<div className="card__logo">{<Store />}</div>
						<div className="card__description">
							<span className="description__name">#{store.locationReferenceId}</span>
						</div>
					</Card>
				))}
			</div>
		);
	};

	return (
		<section className="store-selection">
			<Header className="header" title={t('storeSelection.title')}>
				<IconButton
					className="logout-cta"
					backgroundColor={Colors.green}
					iconName={IconNames.Exit}
					size={22}
					color={Colors.nightBlue}
					onClick={logUserOut}
				/>
			</Header>

			<div className="store-selection__content">
				{userNotAuthorizedReason && userNotAuthorizedReason === AuthorizationErrorCodes.INVALID_STORE ? (
					<span className="invalid-store-message">{t('storeSelection.invalidStore')} </span>
				) : null}

				{renderCards()}
			</div>

			<AppVersionLabel
				version={`v${APP_VERSION} ${apiVersion ? ' | API v' + apiVersion : ''}`}
				className={'store-selection__app-version'}
			/>
		</section>
	);
};

export { StoreSelectionContainer };
