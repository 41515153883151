import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';

import { DraggableBottomSheet } from 'src/components/draggableBottomSheet/draggableBottomSheet.component';
import { IconNames } from 'src/components/icon/icon.type';
import { Icon } from 'src/components/icon/icon.component';
import { Colors } from 'src/assets';
import { PrimaryButton } from 'src/components/button/primaryButton/primaryButton.component';
import { OutgoingCancelBottomSheet } from 'src/components/draggableBottomSheet/outgoingCancel/outgoingCancel.component';

import { OutgoingChangeStatusProps } from './outgoingChangeStatus.type';
import './outgoingChangeStatus.style.scss';

export const OutgoingChangeStatusBottomSheet: FC<OutgoingChangeStatusProps> = ({
	order,
	onDismiss,
	onCancel,
	onChangeTime,
	enableStorage,
	...props
}: OutgoingChangeStatusProps) => {
	const { t } = useTranslation();

	const [showCancelPopup, setShowCancelPopup] = useState<boolean>(false);

	const cancelOrder = () => {
		setShowCancelPopup(false);
		onCancel();
		onDismiss();
	};

	return (
		<>
			<OutgoingCancelBottomSheet
				open={showCancelPopup}
				onDismiss={() => setShowCancelPopup(false)}
				onCancel={cancelOrder}
			/>

			<DraggableBottomSheet onDismiss={onDismiss} {...props}>
				<div className="outgoing-change-status">
					<Icon className="status__icon" iconName={IconNames.GroceriesCarrefour} size={70} />

					<h1>{t('outgoingChangeStatus.title')}</h1>

					<div className="status__info">
						<h2>{t('changeTime.title', { orderId: order?.clientOrderId })}</h2>

						<span className="info__name">
							{order?.customer?.lastname} {order?.customer?.firstname}
						</span>

						<div className="info__extra">
							<div className="extra__row">
								<Icon
									className="info__icon"
									iconName={IconNames.Clock}
									size={25}
									color={Colors.nightBlue}
								/>

								<h2 className="info__description">
									{order ? format(order.timeslot.start, 'HH:mm') : ''}
								</h2>
							</div>

							{enableStorage ? (
								<div className="extra__row">
									<Icon
										className="info__icon"
										iconName={IconNames.Groceries}
										size={25}
										color={Colors.nightBlue}
									/>

									<h2 className="info__description">{order?.totes?.length || 0} totes</h2>
								</div>
							) : undefined}
						</div>
					</div>

					<div className="status__buttons">
						<PrimaryButton onClick={onChangeTime}>{t('outgoingChangeStatus.changeTime')}</PrimaryButton>
						<PrimaryButton backgroundColor={Colors.red} onClick={() => setShowCancelPopup(true)}>
							{t('outgoingChangeStatus.cancel')}
						</PrimaryButton>
						<PrimaryButton backgroundColor={Colors.lightBlue} color={Colors.brightBlue} onClick={onDismiss}>
							{t('outgoingChangeStatus.discard')}
						</PrimaryButton>
					</div>
				</div>
			</DraggableBottomSheet>
		</>
	);
};
