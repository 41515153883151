import React, { FC, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import isEqual from 'lodash.isequal';

import { ListItem } from 'src/components/list/listItem/listItem.component';
import { SettingsList } from 'src/components/list/settingsList/settingsList.component';
import { Switch } from 'src/components/switch/switch.component';
import { InputAddListItem } from 'src/components/list/listItem/inputAddListItem/inputAddListItem.component';
import { PHONE_REGEX } from 'src/helpers/phone.helpers';
import { Icon } from 'src/components/icon/icon.component';
import { IconNames, IconSizes } from 'src/components/icon/icon.type';
import { Colors } from 'src/assets';
import { selectSelectedStorePhoneNumbers } from 'src/state/store/carrefourStore/carrefourStore.selector';
import {
	actionCarrefourStoreSetNotifications,
	actionCarrefourStoresFetch,
} from 'src/state/store/carrefourStore/carrefourStore.action';
import { NotificationType } from 'src/state/store/carrefourStore/carrefourStore.type';

import './smsSettingsContainer.style.scss';

const SmsSettingsContainer: FC = () => {
	const { t } = useTranslation();
	const dispatch = useDispatch();

	const initialPhoneNumbers: string[] = useSelector(selectSelectedStorePhoneNumbers);

	const [enableSms, setEnableSms] = useState<boolean>(!!initialPhoneNumbers.length);
	const [phoneNumbers, setPhoneNumbers] = useState<string[]>([...initialPhoneNumbers]);
	const phoneNumbersRef = useRef(phoneNumbers);
	const enableSmsRef = useRef(enableSms);

	useEffect(() => {
		dispatch(actionCarrefourStoresFetch());

		return () => {
			const numbers = !enableSmsRef.current ? [] : phoneNumbersRef.current;

			if (!isEqual(initialPhoneNumbers, numbers)) {
				// if there are changes, save them
				dispatch(actionCarrefourStoreSetNotifications(NotificationType.Sms, numbers));
			}
		};
	}, []);

	useEffect(() => {
		phoneNumbersRef.current = phoneNumbers;
	}, [phoneNumbers]);

	useEffect(() => {
		enableSmsRef.current = enableSms;
	}, [enableSms]);

	const addPhoneNumber = (phone: string) => {
		const phoneNumber = phone.charAt(0) === '+' ? phone.replace('+', '00') : phone;

		if (phone && !phoneNumbers.some((e) => e === phoneNumber)) {
			setPhoneNumbers([...phoneNumbers, phoneNumber]);
		}
	};

	const removePhoneNumber = (phone: string) => {
		setPhoneNumbers(phoneNumbers.filter((phoneNumber) => phoneNumber !== phone));
	};

	const phoneListItems = phoneNumbers.map((phone) => (
		<ListItem key={phone} hasChevron={false}>
			<span className="content__label">{phone}</span>

			<div className="icon-wrapper" onClick={() => removePhoneNumber(phone)}>
				<Icon iconName={IconNames.Minus} color={Colors.red} size={IconSizes.Normal} />
			</div>
		</ListItem>
	));

	return (
		<div className="sms-settings-container">
			<SettingsList>
				<ListItem key="allow" hasChevron={false}>
					<span className="content__label">{t('smsSettings.enable')}</span>

					<Switch checked={enableSms} onChange={setEnableSms} />
				</ListItem>
				<InputAddListItem
					key="sms-input"
					type="tel"
					regex={PHONE_REGEX}
					onAdd={addPhoneNumber}
					disabled={!enableSms}
					placeholder={t('smsSettings.placeholder')}
				/>
				{phoneListItems}
			</SettingsList>
		</div>
	);
};

export { SmsSettingsContainer };
