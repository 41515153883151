import React, { FC, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { CSSTransition, SwitchTransition } from 'react-transition-group';

import { ReturnTotesModal } from 'src/components/modals/acceptDelivery/returnTotes/returnTotes.component';
import { InboundDelivery, ReturnDeliveries } from 'src/state/store/inbound/inbound.type';
import { DeliveryNotesModal } from 'src/components/modals/acceptDelivery/deliveryNotes/deliveryNotes.component';
import { selectInboundDelivery, selectReturnDeliveriesState } from 'src/state/store/inbound/inbound.selector';
import { Store } from 'src/state/store.type';
import { actionInboundDeliveryAccept } from 'src/state/store/inbound/inbound.action';
import ReturnOrdersModal from 'src/components/modals/acceptDelivery/returnOrders/returnOrders.component';

import { AcceptDeliveryContainerProps, AcceptDeliveryPages } from './acceptDeliveryContainer.type';
import { animationDuration } from './acceptDeliveryContainer.const';
import './acceptDeliveryContainer.style.scss';

const AcceptDeliveryContainer: FC<AcceptDeliveryContainerProps> = ({
	deliveryId,
	onClose,
}: AcceptDeliveryContainerProps) => {
	const delivery: InboundDelivery = useSelector((store: Store) => selectInboundDelivery(store, deliveryId));
	const returnDeliveries: ReturnDeliveries = useSelector(selectReturnDeliveriesState);

	const dispatch = useDispatch();

	const [currentPage, setCurrentPage] = useState<AcceptDeliveryPages | undefined>(AcceptDeliveryPages.DELIVERY_NOTES);

	const onAcceptOrder = (comment: string) => {
		dispatch(actionInboundDeliveryAccept(deliveryId, comment));
		navigateToNextPage();
	};

	const navigateToNextPage = () => {
		switch (currentPage) {
			case AcceptDeliveryPages.DELIVERY_NOTES: {
				setCurrentPage(AcceptDeliveryPages.EMPTY_TOTES);
				break;
			}
			case AcceptDeliveryPages.EMPTY_TOTES: {
				if (returnDeliveries?.show) {
					setCurrentPage(AcceptDeliveryPages.RETURN_ORDER);
					break;
				}
				setCurrentPage(undefined);
				setTimeout(() => {
					onClose();
				}, animationDuration);

				break;
			}
			case AcceptDeliveryPages.RETURN_ORDER: {
				setCurrentPage(undefined);
				setTimeout(() => {
					onClose();
				}, animationDuration);

				break;
			}
			default:
				setCurrentPage(undefined);
				setTimeout(() => {
					onClose();
				}, animationDuration);

				break;
		}
	};

	const renderCurrentModal = () => {
		switch (currentPage) {
			case AcceptDeliveryPages.DELIVERY_NOTES: {
				return <DeliveryNotesModal delivery={delivery} onConfirmPressed={onAcceptOrder} />;
			}

			case AcceptDeliveryPages.EMPTY_TOTES: {
				return <ReturnTotesModal onNextPressed={navigateToNextPage} />;
			}

			case AcceptDeliveryPages.RETURN_ORDER: {
				return (
					<ReturnOrdersModal
						onNextPressed={navigateToNextPage}
						nrOfOrdersToReturn={returnDeliveries.ordersToReturn}
					/>
				);
			}
			default:
				return null;
		}
	};

	return (
		<SwitchTransition>
			<CSSTransition
				in={true}
				key={currentPage}
				classNames="slide"
				appear={true}
				mountOnEnter
				unmountOnExit
				timeout={animationDuration}>
				{renderCurrentModal()}
			</CSSTransition>
		</SwitchTransition>
	);
};

export { AcceptDeliveryContainer };
