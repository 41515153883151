import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { BottomSheet } from 'src/components/bottomSheet/bottomSheet.component';
import { PrimaryButton } from 'src/components/button/primaryButton/primaryButton.component';

import { UpdateAppModalProps } from './updateApp.type';
import './updateApp.style.scss';

const UpdateAppModal: FC<UpdateAppModalProps> = ({ onUpdate }: UpdateAppModalProps) => {
	const { t } = useTranslation();

	return (
		<BottomSheet key={'delivery-notes'}>
			<div className="update-app">
				<h1>{t('updateModal.title')}</h1>

				<PrimaryButton onClick={onUpdate}>{t('updateModal.update')}</PrimaryButton>
			</div>
		</BottomSheet>
	);
};

export { UpdateAppModal };
