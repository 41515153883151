import React, { FC, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import PullToRefresh from 'react-simple-pull-to-refresh';

import { Card } from 'src/components/card/card.component';
import { Icon } from 'src/components/icon/icon.component';
import { IconNames } from 'src/components/icon/icon.type';
import { OrderIconLabel } from 'src/components/label/orderIconLabel/orderIconLabel.component';
import { OrderStatusLabel } from 'src/components/label/orderStatusLabel/orderStatusLabel.component';
import { List } from 'src/components/list/list.component';
import { ListItem } from 'src/components/list/listItem/listItem.component';
import { withTabHeader } from 'src/hoc/withTabHeader/withTabHeader.hoc';
import { OrderStatusLabelStatus } from 'src/components/label/orderStatusLabel/orderStatusLabel.type';
import { AcceptDeliveryContainer } from 'src/containers/acceptDelivery/acceptDelivery.container';
import { useModal } from 'src/hooks/useModal.hook';
import {
	selectAcceptedInboundDeliveries,
	selectNumberOfOpenInboundDeliveries,
	selectOpenInboundDeliveries,
	selectReturnDeliveriesState,
} from 'src/state/store/inbound/inbound.selector';
import { InboundDelivery, ReturnDeliveries } from 'src/state/store/inbound/inbound.type';
import {
	actionInboundDeliveriesFetch,
	actionInboundDeliveriesToReturnFetch,
} from 'src/state/store/inbound/inbound.action';
import { selectAppLanguagePrefix } from 'src/state/store/settings/settings.selector';
import { getHourAndMinuteString } from 'src/helpers/time.helpers';
import { AppLanguage } from 'src/state/store/settings/settings.type';
import { useIsOffline } from 'src/hooks/useIsOffline.hook';
import OfflineWarningModal from 'src/components/modals/offlineWarning/offlineWarning.component';
import './incomingContainer.style.scss';
import { PrimaryButton } from 'src/components/button/primaryButton/primaryButton.component';

import { TimeBeforeNextDeliveryLabel } from './timeBeforeNextDeliveryLabel/timeBeforeNextDeliveryLabel.component';
import { RELOAD_INTERVAL_INCOMING_DELIVERIES } from './incoming.const';

const IncomingContainerComponent: FC = () => {
	const containerRef = useRef<HTMLDivElement>(null);

	const [showIsOfflineWarning, setShowIsOfflineWarning] = useState<boolean>(false);

	const openInboundDeliveries: InboundDelivery[] = useSelector(selectOpenInboundDeliveries);

	const acceptedInboundDeliveries: InboundDelivery[] = useSelector(selectAcceptedInboundDeliveries);
	const numberOfOpenInboundDeliveries: number = useSelector(selectNumberOfOpenInboundDeliveries);
	const appLanguage: AppLanguage | undefined = useSelector(selectAppLanguagePrefix);
	const returnDeliveries: ReturnDeliveries = useSelector(selectReturnDeliveriesState);

	const { onOpenModal, onDismissModal } = useModal();
	const { t } = useTranslation();
	const isOffline = useIsOffline();

	const dispatch = useDispatch();

	useEffect(() => {
		fetchInboundDeliveries();
		const intervalReloadInboundDeliveries = setInterval(
			fetchInboundDeliveries,
			RELOAD_INTERVAL_INCOMING_DELIVERIES,
		);

		return () => {
			clearInterval(intervalReloadInboundDeliveries);
		};
	}, []);

	const fetchInboundDeliveries = () => {
		dispatch(actionInboundDeliveriesFetch());
		dispatch(actionInboundDeliveriesToReturnFetch());
	};

	const onRefresh = (): Promise<void> => {
		return new Promise((resolve) => {
			fetchInboundDeliveries();

			setTimeout(() => {
				return resolve();
			}, 1500);
		});
	};

	const closeOfflineWarningPopup = () => {
		setShowIsOfflineWarning(false);
	};

	const navigateToAcceptDelivery = (id: string) => {
		if (isOffline) {
			setShowIsOfflineWarning(true);

			return;
		}
		onOpenModal(<AcceptDeliveryContainer deliveryId={id} onClose={onDismissModal} />);
	};

	const renderListItem = (item: InboundDelivery) => {
		return (
			<ListItem
				key={item.id}
				className={'list__item'}
				disabled={item.delivered}
				onClick={!item.delivered ? () => navigateToAcceptDelivery(item.id) : undefined}>
				<div className={'item__content icon'}>
					<OrderStatusLabel
						label={getHourAndMinuteString(item.datetime)}
						status={item.delivered ? OrderStatusLabelStatus.COMPLETED : undefined}
					/>
				</div>
				<div className={'item__content'}>
					<OrderIconLabel
						numberOfItems={item.numberOfTotes}
						iconName={IconNames.Box}
						unit={t('incoming.boxes')}
					/>
				</div>
				<div className={'item__content'}>
					<OrderIconLabel
						numberOfItems={item.numberOfOrders}
						iconName={IconNames.Person}
						unit={t('incoming.orders')}
					/>
				</div>
			</ListItem>
		);
	};

	const renderNextOrders = () => {
		if (!openInboundDeliveries || openInboundDeliveries.length < 1) {
			return null;
		}

		return (
			<div className={'incoming-container__orders'}>
				<List<InboundDelivery>
					data={openInboundDeliveries}
					keyExtractor={(delivery: InboundDelivery) => delivery.id}
					renderItem={renderListItem}
					className={'orders__list'}
				/>
			</div>
		);
	};

	const callNumber = () => {
		const numberToCall = t('incoming.number');
		window.location.href = `tel:${numberToCall}`;
	};

	const renderPreviousOrders = () => {
		if (!acceptedInboundDeliveries || acceptedInboundDeliveries.length < 1) {
			return null;
		}

		return (
			<>
				<h2>{t('incoming.previousOrders')}</h2>
				<div className={classNames('incoming-container__orders', 'isCompleted')}>
					<List<InboundDelivery>
						data={acceptedInboundDeliveries}
						keyExtractor={(inbound: InboundDelivery) => inbound.id}
						renderItem={renderListItem}
						className={'orders__list'}
					/>
				</div>
			</>
		);
	};

	return (
		<div className={'incoming-container'}>
			<PullToRefresh onRefresh={onRefresh} pullingContent={<div />} className={classNames('pull-to-refresh')}>
				<div ref={containerRef} className={classNames('wrapper', 'safe-area-scroll')}>
					{returnDeliveries?.show ? (
						<Card className={'incoming-container__warning'}>
							<Icon iconName={IconNames.Warning} size={22} className={'warning__icon'} />
							<span className={'warning__text'}>{t('incoming.warningTotes')}</span>
						</Card>
					) : null}

					<div className={'incoming-container__order-info'}>
						<Card className={'order-info__item'}>
							<div className={'item__number'}>{numberOfOpenInboundDeliveries}</div>
							<span className={'item__text'}>{t('incoming.incomingDeliveryToday')}</span>
						</Card>

						<Card className={'order-info__item'}>
							<div className={'item__number'}>
								<TimeBeforeNextDeliveryLabel
									openInboundDeliveries={openInboundDeliveries}
									appLanguage={appLanguage}
								/>
							</div>
							<span className={'item__text'}>{t('incoming.beforeNextDelivery')}</span>
						</Card>
					</div>

					{renderNextOrders()}

					{renderPreviousOrders()}
				</div>
			</PullToRefresh>
			<div className="button-container">
				<PrimaryButton fullWidth={true} onClick={callNumber}>
					{t('incoming.call')}
				</PrimaryButton>
			</div>

			<OfflineWarningModal open={showIsOfflineWarning} onDismiss={closeOfflineWarningPopup} />
		</div>
	);
};

const IncomingContainer = withTabHeader(IncomingContainerComponent);

export { IncomingContainer };
