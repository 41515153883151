import React, { CSSProperties, FC } from 'react';
import classNames from 'classnames';
import { format } from 'date-fns';

import { Colors } from 'src/assets';
import { ListItem } from 'src/components/list/listItem/listItem.component';
import { OrderStateSquare } from 'src/components/orderStateSquare/orderStateSquare.component';
import { CustomerLabel } from 'src/components/label/customerLabel/customerLabel.component';
import { OrderState } from 'src/state/store/order/order.type';
import './orderListItem.style.scss';
import { Icon } from 'src/components/icon/icon.component';
import { IconNames } from 'src/components/icon/icon.type';

import { OrderListItemProps } from './orderListItem.type';

const OrderListItem: FC<OrderListItemProps> = ({
	orderId,
	customer,
	disabled = false,
	time,
	barcode,
	sequenceNumber,
	state,
	className,
	style,
	...props
}: OrderListItemProps) => {
	let chevronColor = Colors.brightBlue;
	let nameStyles: CSSProperties = {
		color: Colors.nightBlue,
	};
	let barcodeStyles: CSSProperties = {
		color: Colors.text,
	};
	let sequenceNumberStyle: CSSProperties = {
		color: Colors.nightBlue,
	};
	let sequenceNumberBoxStyle: CSSProperties = {
		borderColor: Colors.nightBlue,
	};
	let listItemStyles: CSSProperties = {
		backgroundColor: Colors.purewhite,
		...style,
	};

	switch (state) {
		case OrderState.CustomerLate:
			chevronColor = Colors.darkOrange;
			nameStyles.color = Colors.darkOrange;
			barcodeStyles.color = Colors.darkOrange;
			sequenceNumberStyle.color = Colors.darkOrange;
			sequenceNumberBoxStyle.borderColor = Colors.darkOrange;
			listItemStyles.backgroundColor = Colors.warmYellow;
			break;
		case OrderState.PickedUp:
			chevronColor = Colors.purewhite;
			nameStyles.color = Colors.green;
			barcodeStyles.color = Colors.green;
			sequenceNumberStyle.color = Colors.green;
			sequenceNumberBoxStyle.borderColor = Colors.green;
			listItemStyles.backgroundColor = Colors.softGreen;
			break;
		case OrderState.CancelReturn:
			chevronColor = Colors.purewhite;
			nameStyles.color = Colors.silverGrey;
			barcodeStyles.color = Colors.silverGrey;
			sequenceNumberStyle.color = Colors.silverGrey;
			sequenceNumberBoxStyle.borderColor = Colors.silverGrey;
			listItemStyles.backgroundColor = Colors.lightGrey;
			break;
	}

	return (
		<ListItem
			className={classNames('order-list-item', className, { disabled })}
			style={listItemStyles}
			chevronColor={chevronColor}
			disabled={disabled}
			{...props}>
			<OrderStateSquare time={time} state={state} className="square" />
			<div className="info">
				<CustomerLabel
					orderId={orderId}
					customer={customer}
					dateKey={format(time, 'yyyy-MM-dd')}
					style={nameStyles}
				/>
				<p className="info__barcode" style={barcodeStyles}>
					#{barcode}
				</p>
			</div>
			<div className="sequence-box" style={sequenceNumberBoxStyle}>
				{sequenceNumber ? (
					<p className="sequence-box__number" style={sequenceNumberStyle}>
						{sequenceNumber}
					</p>
				) : (
					<Icon className="item__icon" iconName={IconNames.Clock} />
				)}
			</div>
		</ListItem>
	);
};

export { OrderListItem };
