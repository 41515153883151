import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';

import { BackofficePortalContainer } from 'src/containers/backoffice/backofficePortal/backofficePortal.container';
import { withNavbarHeader } from 'src/hoc/withNavbarHeader/withNavbarHeader.hoc';
import { BackofficePages } from 'src/navigation/backoffice/backoffice.type';

const BackofficePortalComponent: FC = () => {
	const history = useHistory();
	const navRoRefund = () => {
		history.push(BackofficePages.Refund);
	};

	const navToSendMessage = () => {
		//TODO Implement message
	};

	return <BackofficePortalContainer navToRefund={navRoRefund} navToSendMessage={navToSendMessage} />;
};

const BackofficePage = withNavbarHeader(BackofficePortalComponent);

export { BackofficePage };
