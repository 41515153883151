import { createReducer } from 'reduxsauce';

import { ActionType } from 'src/state/store/actionStatus/actionStatus.type';

import { SETTINGS_STORE_INITIAL_STATE, ACTION_APP_LANGUAGE_SET, ACTION_API_VERSION_SET } from './settings.const';
import { ApiVersionSetActionType, AppLanguageSetActionType, SettingsStore } from './settings.type';

const appLanguageSet = (state: SettingsStore, action: ActionType<AppLanguageSetActionType>): SettingsStore => {
	if (!action.payload) {
		return state;
	}

	const { appLanguage } = action.payload;

	return { ...state, appLanguage };
};

const apiVersionSet = (state: SettingsStore, action: ActionType<ApiVersionSetActionType>): SettingsStore => {
	if (!action.payload) {
		return state;
	}

	const { apiVersion } = action.payload;

	return { ...state, apiVersion };
};

/* ------------- Hookup Reducers To Types ------------- */

export const settingsReducer = createReducer(SETTINGS_STORE_INITIAL_STATE, {
	[ACTION_APP_LANGUAGE_SET]: appLanguageSet,
	[ACTION_API_VERSION_SET]: apiVersionSet,
});
