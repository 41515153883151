import { fork } from 'redux-saga/effects';

import { inboundDeliveriesToReturnSaga } from 'src/state/sagas/inbound/inboundDeliveriesToReturnFetch.saga';

import { inboundDeliveryAcceptSaga } from './inboundDeliveryAccept.saga';
import { inboundDeliveriesFetchSaga } from './inboundDeliveriesFetch.saga';

export function* incomingSagas() {
	yield fork(inboundDeliveriesFetchSaga);
	yield fork(inboundDeliveryAcceptSaga);
	yield fork(inboundDeliveriesToReturnSaga);
}
