import { AppLanguage, SettingsStore } from './settings.type';

export const ACTION_APP_LANGUAGE_SET = 'ACTION_APP_LANGUAGE_SET';

export const ACTION_API_VERSION_FETCH = 'ACTION_API_VERSION_FETCH';
export const ACTION_API_VERSION_SET = 'ACTION_API_VERSION_SET';

export const AppLanguages: Record<AppLanguage, string> = {
	fr: 'Français',
	en: 'English',
	nl: 'Nederlands',
};

export const SETTINGS_STORE_INITIAL_STATE: SettingsStore = {
	appLanguage: undefined,
	apiVersion: undefined,
};
