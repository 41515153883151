export interface UserStore {
	userNotAuthorizedReason?: AuthorizationErrorCodes;
}

/* ------------- Action Payload Types  ------------- */
export interface UserNotAuthorizedReasonSetActionType {
	userNotAuthorizedReason?: AuthorizationErrorCodes;
}

export enum AuthorizationErrorCodes {
	INVALID_ROLE = 'INVALID_ROLE',
	INVALID_STORE = 'INVALID_STORE',
}
