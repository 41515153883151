import { call, CallEffect, put, select, takeLatest } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';

import { actionStatusAdd } from 'src/state/store/actionStatus/actionStatus.action';
import { ActionStatusEnum, ActionType } from 'src/state/store/actionStatus/actionStatus.type';
import { ACTION_SELECTED_CARREFOUR_STORE_SAVE_NOTIFICATIONS } from 'src/state/store/carrefourStore/carrefourStore.const';
import { MooseApiRoutes } from 'src/config/api.config';
import { fetchRequest, HttpMethod } from 'src/state/sagas/network/network.saga';
import { replaceColonPrefixed } from 'src/helpers/message.helpers';
import { selectSelectedStore } from 'src/state/store/carrefourStore/carrefourStore.selector';
import {
	actionCarrefourStoresFetch,
	actionStoreSetNotifications,
} from 'src/state/store/carrefourStore/carrefourStore.action';
import {
	CarrefourStore,
	NotificationType,
	SelectedStoreSaveNotificationsActionType,
} from 'src/state/store/carrefourStore/carrefourStore.type';
import { authorize } from 'src/state/sagas/auth/authorization.saga';
import { isOffline } from 'src/helpers/cache.helpers';

export function* storeSaveNotificationsSaga() {
	yield takeLatest(ACTION_SELECTED_CARREFOUR_STORE_SAVE_NOTIFICATIONS, saveNotificationsWorker);
}

function* saveNotificationsWorker(action: ActionType<SelectedStoreSaveNotificationsActionType>) {
	// Create action status running
	yield put(
		actionStatusAdd(
			{ type: ACTION_SELECTED_CARREFOUR_STORE_SAVE_NOTIFICATIONS },
			ActionStatusEnum.RUNNING,
			isOffline(),
		),
	);

	try {
		const selectedStore: CarrefourStore | undefined = yield select(selectSelectedStore);
		const { type, data } = action.payload!;

		if (!selectedStore || !type) {
			yield put(
				actionStatusAdd({ type: ACTION_SELECTED_CARREFOUR_STORE_SAVE_NOTIFICATIONS }, ActionStatusEnum.ERROR),
			);

			return;
		}

		const emails = type === NotificationType.Email ? data : selectedStore.notifications?.emails!;
		const phoneNumbers = type === NotificationType.Sms ? data : selectedStore.notifications?.phoneNumbers!;

		yield call(saveNotifications, selectedStore.id, emails, phoneNumbers);

		yield put(actionStoreSetNotifications(selectedStore.id, emails, phoneNumbers));

		yield put(actionCarrefourStoresFetch());

		// Create action status success
		yield put(
			actionStatusAdd({ type: ACTION_SELECTED_CARREFOUR_STORE_SAVE_NOTIFICATIONS }, ActionStatusEnum.SUCCESS),
		);
	} catch (e) {
		// General error
		yield put(
			actionStatusAdd(
				{ type: ACTION_SELECTED_CARREFOUR_STORE_SAVE_NOTIFICATIONS },
				ActionStatusEnum.ERROR,
				isOffline(),
			),
		);
	}
}

function* saveNotifications(
	storeId: string,
	emails: string[],
	phoneNumbers: string[],
): Generator<CallEffect, AxiosResponse, AxiosResponse> {
	return yield call(
		fetchRequest,
		{
			method: HttpMethod.Patch,
			url: replaceColonPrefixed(MooseApiRoutes.UpdateNotifications, { storeId }),
			data: {
				notifications: {
					emails,
					phoneNumbers,
				},
			},
		},
		[authorize],
	);
}
