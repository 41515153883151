import React, { FC } from 'react';

import { withNavbarHeader } from 'src/hoc/withNavbarHeader/withNavbarHeader.hoc';
import { SmsSettingsContainer } from 'src/containers/app/smsSettings/smsSettings.container';

const SmsSettingsPageComponent: FC = () => {
	return <SmsSettingsContainer />;
};

const SmsSettingsPage = withNavbarHeader(SmsSettingsPageComponent);

export { SmsSettingsPage };
