import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { DraggableBottomSheet } from 'src/components/draggableBottomSheet/draggableBottomSheet.component';
import { Colors } from 'src/assets';
import { StorageItemKey } from 'src/state/store/storage/storage.type';
import { PrimaryButton } from 'src/components/button/primaryButton/primaryButton.component';

import { ConfirmDeleteProps } from './confirmDelete.type';
import './confirmDelete.style.scss';

const ConfirmDeleteBottomSheet: FC<ConfirmDeleteProps> = ({
	storageItemType,
	onConfirm,
	onDismiss,
	...props
}: ConfirmDeleteProps) => {
	const { t } = useTranslation();

	const title = (): string => {
		switch (storageItemType) {
			case StorageItemKey.STORAGE_SLOT:
				return t('confirmDeleteModal.storageSlotDelete');
			case StorageItemKey.PALLY:
				return t('confirmDeleteModal.pallyDelete');
			case StorageItemKey.ORDER:
				return t('confirmDeleteModal.orderUnlink');
		}
	};

	return (
		<DraggableBottomSheet onDismiss={onDismiss} {...props}>
			<div className="confirm-delete">
				<h1>{title()}</h1>

				<div className={'confirm-delete__buttons'}>
					<PrimaryButton fullWidth={true} className="button" backgroundColor={Colors.red} onClick={onConfirm}>
						{t('general.confirm')}
					</PrimaryButton>
					<PrimaryButton
						fullWidth={true}
						className="button"
						backgroundColor={Colors.lightBlue}
						color={Colors.brightBlue}
						onClick={onDismiss}>
						{t('general.cancel')}
					</PrimaryButton>
				</div>
			</div>
		</DraggableBottomSheet>
	);
};

export { ConfirmDeleteBottomSheet };
