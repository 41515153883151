import React, { FC, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isSameDay } from 'date-fns';

import { Order, OrderState } from 'src/state/store/order/order.type';
import { Store } from 'src/state/store.type';
import { selectOrderById } from 'src/state/store/order/order.selector';
import './orderLate.style.scss';
import { actionOutgoingOrderUpdate } from 'src/state/store/order/order.action';
import { OutgoingCancelBottomSheet } from 'src/components/draggableBottomSheet/outgoingCancel/outgoingCancel.component';
import { OrderLateBottomSheet } from 'src/components/draggableBottomSheet/outgoingLateOrder/orderLate.component';
import { generateNextTimeSlot } from 'src/helpers/timeslot.helpers';
import { formatDateKeyForStore } from 'src/helpers/date.helpers';

import { OrderLateTypeContainerProps } from './orderLate.type';

const OrderLateContainer: FC<OrderLateTypeContainerProps> = ({
	dateKeyList,
	dateKeyOrder,
	orderId,
	open,
	setOpen,
}: OrderLateTypeContainerProps) => {
	const [orderLatePopupVisible, setOrderLatePopupVisible] = useState<boolean>(false);
	const dateFormatted = formatDateKeyForStore(dateKeyOrder);
	const order: Order | undefined = useSelector((state: Store) => selectOrderById(state, orderId, dateFormatted));
	const dispatch = useDispatch();

	const displayDay = dateFormatted ? isSameDay(new Date(dateFormatted), new Date(dateKeyList)) : false;

	const confirmOrderPickedUp = () => {
		if (orderId && dateFormatted) {
			dispatch(actionOutgoingOrderUpdate(orderId, OrderState.PickedUp, dateFormatted));

			setOpen(false);
		}
	};

	if (!order) {
		return null;
	}

	const delayOrder = () => {
		if (!order || !dateFormatted) {
			return;
		}
		const nextTimeslot = generateNextTimeSlot();
		dispatch(
			actionOutgoingOrderUpdate(order.id, OrderState.TimeChanged, dateFormatted, {
				start: nextTimeslot.start.getTime(),
				end: nextTimeslot.end.getTime(),
			}),
		);
		setOpen(false);
	};

	const openCancelOrder = () => {
		setOrderLatePopupVisible(true);
	};

	const closeCancelOrder = () => {
		setOrderLatePopupVisible(false);
	};

	const cancelOrder = () => {
		if (orderId && dateFormatted) {
			setOrderLatePopupVisible(false);
			setOpen(false);
			dispatch(actionOutgoingOrderUpdate(orderId, OrderState.CancelReturn, dateFormatted));
		}
	};

	return (
		<>
			<OrderLateBottomSheet
				order={order}
				onCancelOrder={openCancelOrder}
				onConfirmOrderPickedUP={confirmOrderPickedUp}
				onDelayOrder={delayOrder}
				open={open}
				displayOnSameDay={displayDay}
			/>
			<OutgoingCancelBottomSheet
				open={orderLatePopupVisible}
				onDismiss={closeCancelOrder}
				onCancel={cancelOrder}
			/>
		</>
	);
};

export { OrderLateContainer };
