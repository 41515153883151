import React, { FC } from 'react';

import { withNavbarHeader } from 'src/hoc/withNavbarHeader/withNavbarHeader.hoc';
import { LanguageContainer } from 'src/containers/app/language/language.container';

const LanguagePageComponent: FC = () => {
	return <LanguageContainer />;
};

const LanguagePage = withNavbarHeader(LanguagePageComponent);

export { LanguagePage };
