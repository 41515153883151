import { useSelector } from 'react-redux';

import { ActionStatus, ActionType } from 'src/state/store/actionStatus/actionStatus.type';
import { selectActionStatus } from 'src/state/store/actionStatus/actionStatus.selector';
import { Store } from 'src/state/store.type';

export const useActionStatus = (action: ActionType): ActionStatus | undefined => {
	if (!action) {
		return;
	}

	// eslint-disable-next-line react-hooks/rules-of-hooks
	return useSelector((store: Store) => selectActionStatus(store, action));
};
