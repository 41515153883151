import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { PrivateRoute } from 'src/components/navigation/privateRoute/privateRoute.component';
import { selectSelectedStoreId } from 'src/state/store/carrefourStore/carrefourStore.selector';
import { OutgoingOverviewPage } from 'src/pages/app/outgoing/outgoingOverview.page';
import { AppPages } from 'src/navigation/app/appRouter.type';
import { OrderActionsPage } from 'src/pages/app/outgoing/orderActions.page';
import { OrderScannerPage } from 'src/pages/app/outgoing/orderScanner.page';
import { OutgoingChangeTimePage } from 'src/pages/app/outgoing/outgoingChangeTime.page';
import { OrderDetailPage } from 'src/pages/app/outgoing/orderDetail.page';
import { useIsLoggedIn } from 'src/hooks/useIsLoggedIn.hook';
import { TotesScannerPage } from 'src/pages/app/outgoing/totesScanner.page';
import { HelpdeskPage } from 'src/pages/app/outgoing/helpdesk.page';
import { RefundPage } from 'src/pages/app/outgoing/refund.page';

import { OutgoingPages } from './outgoingRouter.type';

const OutgoingRouter: FC = () => {
	const { t } = useTranslation();
	const isLoggedIn = useIsLoggedIn();
	const hasStoreSelected = useSelector(selectSelectedStoreId);

	const location = useLocation<{ orderId: string; externalOrderId: string; currentOrderIndex: number }>();
	const locationDateKey = useLocation<{ dateKey: string; externalDateKey: string; currentDateKey: string }>();

	const { externalOrderId, orderId, currentOrderIndex } = location?.state || {
		orderId: undefined,
		externalOrderId: undefined,
	};

	const { currentDateKey } = locationDateKey?.state || {};

	const isAuthenticated: boolean = !!isLoggedIn && !!hasStoreSelected;

	return (
		<>
			<PrivateRoute exact isLoggedIn={isAuthenticated} path={AppPages.Outgoing}>
				<OutgoingOverviewPage currentDateKey={currentDateKey} />
			</PrivateRoute>

			<PrivateRoute exact isLoggedIn={isAuthenticated} path={OutgoingPages.OrderActions}>
				<OrderActionsPage currentOrderIndex={currentOrderIndex} currentDateKey={currentDateKey} />
			</PrivateRoute>
			<PrivateRoute exact isLoggedIn={isAuthenticated} path={OutgoingPages.Scanner}>
				<OrderScannerPage
					title={t('orderScanner.title', { orderId: externalOrderId ?? '' })}
					orderId={orderId}
					externalOrderId={externalOrderId}
					currentDateKey={currentDateKey}
				/>
			</PrivateRoute>

			{/* Can only route when orderId is provided in location state due security reasons */}
			<PrivateRoute exact isLoggedIn={isAuthenticated} path={OutgoingPages.OrderDetail}>
				<OrderDetailPage
					title={t('orderDetail.title', { orderId: externalOrderId ?? '' })}
					orderId={orderId}
					currentDateKey={currentDateKey}
					externalOrderId={externalOrderId}
				/>
			</PrivateRoute>
			<PrivateRoute exact isLoggedIn={isAuthenticated} path={OutgoingPages.ScanTotes}>
				<TotesScannerPage
					title={t('totesScanner.title', { orderId: externalOrderId ?? '' })}
					orderId={orderId}
					externalOrderId={externalOrderId}
					currentDateKey={currentDateKey}
				/>
			</PrivateRoute>

			<PrivateRoute exact isLoggedIn={isAuthenticated} path={OutgoingPages.Helpdesk}>
				<HelpdeskPage
					title="Helpdesk"
					orderId={orderId}
					externalOrderId={externalOrderId ?? ''}
					currentDateKey={currentDateKey}
				/>
			</PrivateRoute>

			<PrivateRoute exact isLoggedIn={isAuthenticated} path={OutgoingPages.Refund}>
				<RefundPage
					title="Refund"
					orderId={orderId}
					currentDateKey={currentDateKey}
					externalOrderId={externalOrderId}
				/>
			</PrivateRoute>

			<PrivateRoute exact isLoggedIn={isAuthenticated} path={OutgoingPages.ChangeTime}>
				<OutgoingChangeTimePage
					title={t('changeTime.title', { orderId: externalOrderId ?? '' })}
					externalOrderId={externalOrderId}
					currentDateKey={currentDateKey}
				/>
			</PrivateRoute>
		</>
	);
};

export { OutgoingRouter };
