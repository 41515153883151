import React, { FC, useEffect } from 'react';
import classNames from 'classnames';
import { createPortal } from 'react-dom';

import { ModalProps } from './modal.type';
import './modal.style.scss';

const modalRoot = document.getElementById('modal-root');

const Modal: FC<ModalProps> = ({ isOpen, className, children, backdropDismissable = true, onDismiss }) => {
	// element to which the modal will be rendered
	const el = document.createElement('div');

	useEffect(() => {
		// append to root when the children of Modal are mounted
		modalRoot?.appendChild(el);

		// do a cleanup
		return () => {
			modalRoot?.removeChild(el);
		};
	}, [el]);

	const stopPropagation = (e: any) => {
		e.stopPropagation();
	};

	if (!isOpen) return null;

	return createPortal(
		<div onClick={backdropDismissable ? onDismiss : undefined} className={classNames('modal', className)}>
			<div onClick={backdropDismissable ? stopPropagation : undefined}>{children}</div>
		</div>,
		el,
	);
};

export { Modal };
