import React, { FC } from 'react';

import { Icon } from 'src/components/icon/icon.component';
import { IconNames } from 'src/components/icon/icon.type';

import { HRLLabelProps } from './hrlLabel.type';
import './hrlLabel.style.scss';

const HRLLabel: FC<HRLLabelProps> = ({
	sequenceNumber,
	numberOfTotes,
	clientOrderId,
	alternateClientOrderReference,
}: HRLLabelProps) => {
	return (
		<div className="hrl">
			<div className="hrl__identification">
				<span className="identification__code">{sequenceNumber}</span>
				{/* <span className="identification__pally">P: -</span> */}
				<span className="identification__orderIdTitle">{clientOrderId}</span>
				{alternateClientOrderReference != null && (
					<span className="identification__alternateClientOrderReference">
						{alternateClientOrderReference}
					</span>
				)}
				{/* <span className="identification__orderId">{clientOrderId}</span> */}
			</div>

			<div className="label__location">
				<span className="location__totes">-/{numberOfTotes}</span>
				<Icon className="location__store-icon" iconName={IconNames.HRLStore} size={30} />
			</div>

			<div className="label__address noselect">
				<div className="address__date">
					2021-01-06T <b>19;0062 172000</b>
				</div>
				<div className="address__name">James Ward</div>
				<div className="address__store">Carrefour Express (0613)</div>
				<div className="address__description">
					store info test lorem ipsum <br />
					text text etc etc etc
				</div>
				<div className="address__extra">
					<span> 2021-08-24T10;D0</span>
					<span>00Z {'>'} 2021 - 08 - 24T11:00:00Z</span>
					<span>Please knock on the window, ring is borken</span>
				</div>
			</div>
		</div>
	);
};

export { HRLLabel };
