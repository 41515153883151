import React, { FC } from 'react';

import { withNavbarHeader } from 'src/hoc/withNavbarHeader/withNavbarHeader.hoc';
import { EmailSettingsContainer } from 'src/containers/app/emailSettings/emailSettings.container';

const EmailSettingsPageComponent: FC = () => {
	return <EmailSettingsContainer />;
};

const EmailSettingsPage = withNavbarHeader(EmailSettingsPageComponent);

export { EmailSettingsPage };
