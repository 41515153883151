import isAfter from 'date-fns/isAfter';

import { requestsToCache } from 'src/config/cache.config';
import CacheManager from 'src/state/cache/cacheManager';
import { ExtendedAxiosResponse, HttpMethod } from 'src/state/sagas/network/network.saga';
import { ActionType } from 'src/state/store/actionStatus/actionStatus.type';
import { RequestCacheConfig } from 'src/state/store/cache/cache.type';

export const shouldRefreshCache = (): boolean => {
	const midnight = new Date().setHours(24, 0, 0, 0);

	return isAfter(Date.now(), midnight);
};

export const shouldCacheResponseInServiceWorker = (url: URL, request: Request): boolean => {
	if (request.method !== HttpMethod.Get) {
		return false;
	}

	if (process.env.REACT_APP_BASE_URL && url.origin.includes(process.env.REACT_APP_BASE_URL)) {
		return true;
	}

	if (process.env.BASE_API_URL && url.origin.includes(process.env.BASE_API_URL)) {
		return true;
	}

	return false;
};

/**
 * Helper method that will transform the cache config to a list of actions that our startup saga has to trigger
 *
 * @returns array of action creators that we have to trigger on startup
 */
export const getInitialCachedApiRequests = (): Array<() => ActionType> => {
	const actionsToCallOnStartup = [];
	for (const key in requestsToCache) {
		if (!requestsToCache[key].needsSelectedStore) {
			actionsToCallOnStartup.push(requestsToCache[key].fetchAction);
		}
	}

	return actionsToCallOnStartup;
};

/**
 * Helper method that will transform the cache config to a list of actions that has to be executed when user selects store
 *
 * @returns array of action creators that we have to trigger on startup
 */
export const getSelectedStoreCachedApiRequests = (): Array<() => ActionType> => {
	const actionsToCallOnStartup = [];
	for (const key in requestsToCache) {
		if (requestsToCache[key].needsSelectedStore) {
			actionsToCallOnStartup.push(requestsToCache[key].fetchAction);
		}
	}

	return actionsToCallOnStartup;
};

export const isOffline = (): boolean => {
	return !navigator.onLine;
};

/**
 * Helper method that will transform the cache config to a list of requests
 *
 * @returns  array of action creators that will be used in our 'expiredCachedItemsClear' saga
 */
export const getListOfCachedRequestConfig = (): RequestCacheConfig[] => {
	return Object.values(requestsToCache);
};

export const transformCacheToCachedResponse = (data: any): ExtendedAxiosResponse => {
	return {
		data,
		status: 200,
		statusText: 'OK',
		config: {},
		headers: {},
		isCached: true,
	};
};

export const clearCache = async () => {
	await CacheManager.clear();
	sessionStorage.clear();
	localStorage.clear();
};
