import { combineReducers, Reducer } from 'redux';
import { createMigrate, PersistConfig, persistReducer } from 'redux-persist';
import localforage from 'localforage';

import { isDev } from 'src/helpers/env.helpers';
import { actionStatusReducer } from 'src/state/store/actionStatus/actionStatus.reducer';
import { cacheReducer } from 'src/state/store/cache/cache.reducer';
import { settingsReducer } from 'src/state/store/settings/settings.reducer';
import { storesReducer } from 'src/state/store/carrefourStore/carrefourStore.reducer';
import { orderReducer } from 'src/state/store/order/order.reducer';
import { inboundReducer } from 'src/state/store/inbound/inbound.reducer';
import { userReducer } from 'src/state/store/user/user.reducer';
import { storageReducer } from 'src/state/store/storage/storage.reducer';

import { Store } from './store.type';
import { migrations } from './migration/2021_09_10.migration';

const rootPersistConfig: PersistConfig<Store> = {
	key: 'root',
	storage: localforage,
	debug: isDev(),
	timeout: isDev() ? 10000 : 5000,
	version: 1,
	blacklist: ['actionStatuses', 'user'],
	// @ts-ignore
	migrate: createMigrate(migrations, { debug: true }),
};

const rootReducer: Reducer<Store> = combineReducers<Store>({
	actionStatuses: actionStatusReducer,
	cache: cacheReducer,
	inbounds: inboundReducer,
	settings: settingsReducer,
	store: storesReducer,
	order: orderReducer,
	user: userReducer,
	storage: storageReducer,
});

export default persistReducer(rootPersistConfig, rootReducer);
