import React, { FC, ReactNode, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

import { PageNotFound } from 'src/components/pageNotFound/pageNotFound.component';
import { SectionList } from 'src/components/list/sectionList/sectionList.component';
import { Icon } from 'src/components/icon/icon.component';
import { IconNames, IconSizes } from 'src/components/icon/icon.type';
import { Colors } from 'src/assets';
import { SecondaryButton } from 'src/components/button/secondaryButton/secondaryButton.component';
import { Button } from 'src/components/button/button.component';
import { formatDateKeyForStore } from 'src/helpers/date.helpers';
import { actionOrderDetailFetch, actionOutgoingOrderUpdate } from 'src/state/store/order/order.action';
import { Order, OrderState } from 'src/state/store/order/order.type';
import { selectOrderById } from 'src/state/store/order/order.selector';
import { Store } from 'src/state/store.type';
import { ForceCloseBottomSheet } from 'src/components/draggableBottomSheet/forceClose/forceClose.component';
import { ConfirmForceCloseBottomSheet } from 'src/components/draggableBottomSheet/confirmForceClose/confirmForceClose.component';
import OfflineWarningModal from 'src/components/modals/offlineWarning/offlineWarning.component';

import { DisplayOrderItem, DisplayOrderItemType, OrderDetailContainerProps } from './orderDetailContainer.type';
import './orderDetailContainer.style.scss';
import { mapOrderItemsToMatchingState } from './orderDetail.helper';

const OrderDetailContainer: FC<OrderDetailContainerProps> = ({
	currentDateKey,
	orderId,
	forceOpen,
	onNavigateToTotesScanner,
	onNavigateToOutgoingOverview,
	onNavigateToHelpdesk,
	onNavigateToRefund,
}: OrderDetailContainerProps) => {
	const { t } = useTranslation();

	const [openForceClose, setOpenForceClose] = useState<boolean>(false);
	const [confirmForceCloseOpen, setConfirmForceCloseOpen] = useState<boolean>(false);

	const order: Order | undefined = useSelector((state: Store) =>
		selectOrderById(state, orderId, formatDateKeyForStore(currentDateKey)),
	);
	const isPickedUpOrCancelledOrder: boolean =
		!!order && (order.state === OrderState.PickedUp || order.state === OrderState.CancelReturn);

	const dispatch = useDispatch();
	const [showIsOfflineWarning, setShowIsOfflineWarning] = useState<boolean>(false);

	// update order object with items
	useEffect(() => {
		if (orderId) {
			dispatch(actionOrderDetailFetch(orderId, forceOpen));
		}
	}, []);

	const closeOfflineWarningPopup = () => {
		setShowIsOfflineWarning(false);
	};

	const closeForceClosePopup = () => {
		setOpenForceClose(false);
	};

	const openForceClosePopup = () => {
		setOpenForceClose(true);
	};

	const openConfirmForceClose = () => {
		setConfirmForceCloseOpen(true);
	};

	const closeConfirmForceClose = () => {
		setConfirmForceCloseOpen(false);
	};

	const onSubmitForceClose = (numberOfTotes: number) => {
		if (order?.totes && order.totes.length === numberOfTotes) {
			forceCloseOrder();

			return;
		}

		openConfirmForceClose();
	};

	const forceCloseOrder = () => {
		if (orderId && currentDateKey) {
			dispatch(actionOutgoingOrderUpdate(orderId, OrderState.PickedUp, currentDateKey));

			setTimeout(() => {
				onNavigateToOutgoingOverview();
				// TODO: open POS app
			}, 250);
		}
	};

	const mappedOrderItems = useMemo(() => mapOrderItemsToMatchingState(order), [order]);

	if (!orderId) {
		return <PageNotFound description={t('orderDetail.orderIdNotSelected')} show404={false} />;
	}

	const renderSectionHeader = (item: string): ReactNode => {
		const quantityOfSection: number = (() => {
			const displayOrderItemType = item as DisplayOrderItemType;

			if (!mappedOrderItems || !mappedOrderItems[displayOrderItemType]) {
				return 0;
			}

			if (displayOrderItemType === DisplayOrderItemType.SWAPPED) {
				return mappedOrderItems[displayOrderItemType].filter((i) => i.swapped).length;
			}

			return mappedOrderItems[displayOrderItemType].length;
		})();

		if (quantityOfSection === 0) {
			return null;
		}

		return (
			<div className="section-list__header">
				<h2>
					{t(item)} ({quantityOfSection})
				</h2>
				{item === DisplayOrderItemType.INCLUDED && (
					<Button
						fullWidth={false}
						className="section-list__header__order-refund"
						onClick={onNavigateToRefund}>
						<Icon iconName={IconNames.EuroCircle} size={30} />
					</Button>
				)}
			</div>
		);
	};

	const renderItem = ({ id, quantity, name, rowType, swapped }: DisplayOrderItem, index: number): ReactNode => {
		let classNameFlavor: string | undefined;

		const numberOfItemsInSection: number =
			mappedOrderItems && mappedOrderItems[rowType] ? mappedOrderItems[rowType].length : 0;

		const isFirstItemInSection: boolean = numberOfItemsInSection === 1 || index === 0;
		const isLastItemInSection: boolean = index === numberOfItemsInSection - 1;

		switch (rowType) {
			case DisplayOrderItemType.SWAPPED: {
				if (swapped) {
					classNameFlavor = 'row__content--red';
				} else {
					classNameFlavor = 'row__content--green';
				}
				break;
			}
			case DisplayOrderItemType.UNAVAILABLE: {
				classNameFlavor = 'row__content--red';
				break;
			}
		}

		return (
			<div
				key={id}
				className={classNames(
					'section-list__row',
					isFirstItemInSection && 'section-list__row--first',
					isLastItemInSection && 'section-list__row--last',
				)}>
				<div className={classNames('row__content', classNameFlavor)}>
					<div className="content__quantity">
						{swapped ? (
							<Icon iconName={IconNames.Cross} size={IconSizes.Small} color={Colors.red} />
						) : (
							quantity
						)}
					</div>
					<div className="content__name">{name}</div>
				</div>
			</div>
		);
	};

	return (
		<>
			<div className={'order-details'}>
				{mappedOrderItems ? (
					<SectionList<DisplayOrderItem>
						className={'order-details__section-list'}
						sections={mappedOrderItems}
						renderSectionHeader={renderSectionHeader}
						keyExtractor={(item: DisplayOrderItem | { section: string }, index: number) => {
							if ('section' in item) {
								return item.section + index;
							}

							return item.id + item.quantity + item.name + item.swapped;
						}}
						renderItem={renderItem}
					/>
				) : (
					<p className="order-details__empty-message">{t('orderDetail.noItems')}</p>
				)}

				<div className="order-details__button-container">
					<SecondaryButton
						disabled={isPickedUpOrCancelledOrder}
						className="button-container__scan"
						onClick={onNavigateToTotesScanner}>
						<Icon className="scan__icon" iconName={IconNames.Scan} /> {t('orderDetail.scanCta')}
						{order?.totes && order.totes.length > 0 ? ` (0/${order.totes.length})` : null}
					</SecondaryButton>
					<div className="button-container__action-container">
						<Button fullWidth={false} className="action-container__support" onClick={onNavigateToHelpdesk}>
							<Icon iconName={IconNames.HelpDesk} size={23} />
						</Button>
						<Button
							onClick={openForceClosePopup}
							disabled={isPickedUpOrCancelledOrder}
							fullWidth={false}
							className="action-container__force-close">
							{t('orderDetail.forceCloseCta')}
						</Button>
					</div>
				</div>
			</div>

			<ForceCloseBottomSheet
				open={openForceClose}
				onSubmit={onSubmitForceClose}
				onDismiss={closeForceClosePopup}
			/>

			<ConfirmForceCloseBottomSheet
				open={confirmForceCloseOpen}
				onDismiss={closeConfirmForceClose}
				onConfirm={forceCloseOrder}
			/>

			<OfflineWarningModal open={showIsOfflineWarning} onDismiss={closeOfflineWarningPopup} />
		</>
	);
};

export { OrderDetailContainer };
