import React, { FC } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { PrivateRoute } from 'src/components/navigation/privateRoute/privateRoute.component';
import { TabBar } from 'src/components/navigation/tabBar/tabBar.component';
import { IncomingOverviewPage } from 'src/pages/app/incoming/incomingOverview.page';
import { SettingsOverviewPage } from 'src/pages/app/settings/settingsOverview.page';
import { LanguagePage } from 'src/pages/app/language/language.page';
import { NotificationSettingsPage } from 'src/pages/app/notificationSettings/notificationSettings.page';
import { EmailSettingsPage } from 'src/pages/app/emailSettings/emailSettings.page';
import { SmsSettingsPage } from 'src/pages/app/smsSettings/smsSettings.page';
import { Badge } from 'src/components/navigation/tabBar/badge/badge.component';
import { Icon } from 'src/components/icon/icon.component';
import { IconNames } from 'src/components/icon/icon.type';
import { selectNumberOfOpenInboundDeliveries } from 'src/state/store/inbound/inbound.selector';
import { withBoxedContainer } from 'src/hoc/withBoxedContainer/withBoxedContainer.hoc';
import { selectNumberExpectedOutboundOrders } from 'src/state/store/order/order.selector';
import { selectSelectedStoreStorageFeatures } from 'src/state/store/carrefourStore/carrefourStore.selector';
import { useIsLoggedIn } from 'src/hooks/useIsLoggedIn.hook';
import StorageRouter from 'src/navigation/app/storage/storage.router';
import { selectNumberOfOrdersInStorage } from 'src/state/store/storage/storage.selector';
import { Store } from 'src/state/store.type';
import { formatToday } from 'src/helpers/date.helpers';

import { AppPages } from './appRouter.type';
import { OutgoingRouter } from './outgoing/outgoing.router';

const AppRouterComponent: FC = () => {
	const location = useLocation();
	const { t } = useTranslation();

	const numberOfIncoming = useSelector(selectNumberOfOpenInboundDeliveries);
	const numberOfOutgoing = useSelector((state: Store) => selectNumberExpectedOutboundOrders(state, formatToday()));
	const numberOfStorage = useSelector(selectNumberOfOrdersInStorage);

	const isLoggedIn: boolean = useIsLoggedIn();
	const enableStorage: boolean = useSelector(selectSelectedStoreStorageFeatures);

	return (
		<>
			<PrivateRoute isLoggedIn={isLoggedIn} path={AppPages.Incoming}>
				<IncomingOverviewPage />
			</PrivateRoute>
			<PrivateRoute isLoggedIn={isLoggedIn} path={AppPages.Storage}>
				<StorageRouter />
			</PrivateRoute>
			<PrivateRoute isLoggedIn={isLoggedIn} path={AppPages.Outgoing}>
				<OutgoingRouter />
			</PrivateRoute>
			<PrivateRoute isLoggedIn={isLoggedIn} exact path={AppPages.Settings}>
				<SettingsOverviewPage />
			</PrivateRoute>
			<PrivateRoute isLoggedIn={isLoggedIn} exact path={AppPages.Language}>
				<LanguagePage title={t('language.title')} />
			</PrivateRoute>
			<PrivateRoute isLoggedIn={isLoggedIn} exact path={AppPages.NotificationSettings}>
				<NotificationSettingsPage title={t('notificationSettings.title')} />
			</PrivateRoute>
			<PrivateRoute isLoggedIn={isLoggedIn} exact path={AppPages.EmailSettings}>
				<EmailSettingsPage title={t('emailSettings.title')} />
			</PrivateRoute>
			<PrivateRoute isLoggedIn={isLoggedIn} exact path={AppPages.SmsSettings}>
				<SmsSettingsPage title={t('smsSettings.title')} />
			</PrivateRoute>

			{/* TAB BAR */}
			<TabBar
				tabs={[
					{
						label: t('tabs.incoming'),
						route: AppPages.Incoming,
						icon: (
							<Badge isActive={location.pathname?.includes(AppPages.Incoming)} label={numberOfIncoming} />
						),
					},
					{
						label: t('tabs.storage'),
						route: AppPages.Storage,
						icon: (
							<Badge isActive={location.pathname?.includes(AppPages.Storage)} label={numberOfStorage} />
						),
						disabled: !enableStorage,
					},
					{
						label: t('tabs.outgoing'),
						route: AppPages.Outgoing,
						icon: (
							<Badge isActive={location.pathname?.includes(AppPages.Outgoing)} label={numberOfOutgoing} />
						),
					},
					{
						label: t('tabs.settings'),
						route: AppPages.Settings,
						icon: location.pathname?.includes(AppPages.Settings) ? (
							<Icon iconName={IconNames.SettingsBlue} />
						) : (
							<Icon iconName={IconNames.Settings} />
						),
					},
				]}
			/>
		</>
	);
};

const AppRouter = withBoxedContainer(AppRouterComponent);

export { AppRouter };
