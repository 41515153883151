import { createSelector, Selector } from 'reselect';

import { Store } from 'src/state/store.type';
import { Pally, StorageSlot, StorageStore } from 'src/state/store/storage/storage.type';
import { flattenArray } from 'src/helpers/array.helper';

const selectStorageOverviewRecords = ({ storage }: Store): Pick<StorageStore, 'storageSlots' | 'pallies'> => {
	const { storageSlots, pallies } = storage;

	return { storageSlots, pallies };
};

export const selectStorageOverview = createSelector<
	Store,
	StorageStore,
	{ storageSlots: StorageSlot[]; pallies: Pally[] }
>(selectStorageOverviewRecords, ({ storageSlots, pallies }) => {
	return {
		storageSlots: Object.values(storageSlots),
		pallies: Object.values(pallies),
	};
});

export const selectLastFetchedStorage = ({ storage }: Store): number | undefined => {
	return storage.lastFetched;
};

export const selectDistinctNumberOfOrdersInStorage = createSelector<
	Store,
	Pick<StorageStore, 'storageSlots' | 'pallies'>,
	number
>(selectStorageOverviewRecords, ({ storageSlots, pallies }) => {
	const orderIds: string[] = [];

	const storageSlotsValues = Object.values(storageSlots);
	const palliesValues = Object.values(pallies).concat(flattenArray(storageSlotsValues.map((s) => s.pallies)));

	[...storageSlotsValues, ...palliesValues].forEach((i) => {
		if (i.orderIds) orderIds.push(...i.orderIds);
		if (i.orders) orderIds.push(...i.orders.map((order) => order.id));
	});

	const orderIdsSet = new Set(orderIds);

	return orderIdsSet.size;
});

export const selectNumberOfOrdersInStorage = createSelector<
	Store,
	Pick<StorageStore, 'storageSlots' | 'pallies'>,
	number
>(selectStorageOverviewRecords, ({ storageSlots, pallies }) => {
	const orderIds: string[] = [];

	const storageSlotsValues = Object.values(storageSlots);
	const palliesValues = Object.values(pallies).concat(flattenArray(storageSlotsValues.map((s) => s.pallies)));

	[...storageSlotsValues, ...palliesValues].forEach((i) => {
		if (i.orderIds) orderIds.push(...i.orderIds);
		if (i.orders) orderIds.push(...i.orders.map((order) => order.id));
	});

	return orderIds.length;
});

const selectStorageSlotsRecord = ({ storage }: Store): Record<number, StorageSlot> => {
	return storage.storageSlots;
};

export const selectStorageSlots = createSelector<Store, Record<string, StorageSlot>, StorageSlot[]>(
	selectStorageSlotsRecord,
	(storageSlotsRecord) => {
		return Object.values(storageSlotsRecord);
	},
);

const selectPalliesRecord = ({ storage }: Store): Record<number, Pally> => {
	return storage.pallies;
};

export const selectPallies = createSelector<Store, Record<string, Pally>, Pally[]>(
	selectPalliesRecord,
	(palliesRecord) => {
		return Object.values(palliesRecord);
	},
);

export const selectStorageSlotById = (storageSlotId?: string) =>
	createSelector<Store, Record<string, StorageSlot>, StorageSlot | undefined>(
		selectStorageSlotsRecord,
		(storageSlotsRecord) => {
			if (!storageSlotId) return;

			return storageSlotsRecord[storageSlotId];
		},
	);

export const selectPallyById = (pallyId?: string) =>
	createSelector<Store, Record<string, Pally>, Pally | undefined>(selectPalliesRecord, (palliesRecord) => {
		if (!pallyId) return;

		return palliesRecord[pallyId];
	});

export const selectStorageSlotDetails = createSelector<Store, StorageSlot | undefined, StorageSlot | undefined>(
	({ storage }: Store) => storage.storageSlot,
	(storageSlot) => {
		return storageSlot;
	},
);

export const selectPallyDetails = createSelector<Store, Pally | undefined, Pally | undefined>(
	({ storage }: Store) => storage.pally,
	(pally) => {
		return pally;
	},
);

export const selectOrderHasStorage = (orderId?: string): Selector<Store, boolean | undefined> =>
	createSelector<Store, Pick<StorageStore, 'storageSlots' | 'pallies'>, boolean | undefined>(
		({ storage }: Store) => ({ storageSlots: storage.storageSlots, pallies: storage.pallies }),
		({ storageSlots, pallies }) => {
			if (!orderId) return;

			const orderIds: string[] = [];

			const storageSlotsValues = Object.values(storageSlots);
			const palliesValues = Object.values(pallies).concat(flattenArray(storageSlotsValues.map((s) => s.pallies)));

			[...storageSlotsValues, ...palliesValues].forEach((i) => {
				if (i.orderIds) orderIds.push(...i.orderIds);
				if (i.orders) orderIds.push(...i.orders.map((order) => order.id));
			});

			const orderIdsSet = new Set(orderIds);

			return orderIdsSet.has(orderId);
		},
	);
