// list constants
export const ORDER_LIST_MARGIN = 20;

// row constants
export const ORDER_ROW_HEIGHT = 100;

// floating button
export const SHOW_LATE_ORDER_BUTTON_OFFSET = 60;

// item (inner container row constants)
export const ORDER_ROW_ITEM_MARGIN = 10;

// scroll
export const ORDER_ROW_SCROLL_OFFSET = ORDER_ROW_HEIGHT + ORDER_ROW_ITEM_MARGIN;
