import { StorageQr, StorageQrRawBody } from 'src/containers/app/storage/storageScanner/storageScannerContainer.type';
import { StorageItemKey } from 'src/state/store/storage/storage.type';

export const mapStorageQr = (raw: StorageQrRawBody): StorageQr => {
	if (!raw || !raw.type || !raw.id) {
		throw new Error('Invalid QR code');
	}

	let type: StorageItemKey;
	switch (raw.type) {
		case 1:
			type = StorageItemKey.STORAGE_SLOT;
			break;
		case 2:
			type = StorageItemKey.PALLY;
			break;
		default:
			throw new Error('Invalid QR code');
	}

	const code = raw.id;

	return { type, code };
};
