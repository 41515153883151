import { AxiosResponse } from 'axios';
import { call, CallEffect, put, PutEffect, select, SelectEffect } from 'redux-saga/effects';

import { requestsToCache } from 'src/config/cache.config';
import { isOffline, transformCacheToCachedResponse } from 'src/helpers/cache.helpers';
import { dateIsPassedMidnight } from 'src/helpers/time.helpers';
import {
	ExtendedAxiosRequestConfig,
	ExtendedAxiosResponse,
	TransformedNetworkHelper,
} from 'src/state/sagas/network/network.saga';
import { CacheSagaErrors, RequestToCacheRecord } from 'src/state/store/cache/cache.type';

export function* executeNetworkFirst(
	request: ExtendedAxiosRequestConfig,
	next: TransformedNetworkHelper,
	expiryDate: number,
	cacheKey: keyof RequestToCacheRecord,
): Generator<CallEffect | PutEffect | SelectEffect | Promise<any>, ExtendedAxiosResponse, any> {
	try {
		if (!request.url) {
			throw new Error(CacheSagaErrors.NO_VALID_REQUEST);
		}

		// return cached data in redux persist
		if (isOffline()) {
			const cachedData = yield select(requestsToCache[cacheKey].cachedDataSelector);

			return transformCacheToCachedResponse(cachedData);
		}

		// execute next step
		const response: AxiosResponse = yield call(next, request);

		// return response to saga
		return response;
	} catch (e) {
		if (!request.url) {
			throw new Error(CacheSagaErrors.NO_VALID_REQUEST);
		}

		const lastFetched: number | undefined = yield select(requestsToCache[cacheKey].lastFetchedSelector);
		// if cached item is expired we delete the response in the cache
		if (lastFetched && dateIsPassedMidnight(lastFetched)) {
			yield put(requestsToCache[cacheKey].clearAction());

			throw new Error(CacheSagaErrors.EXPIRED_CACHED_ITEM);
		}

		if (!isOffline() || e?.response?.status?.startsWith('4')) {
			throw new Error(e);
		}

		const cachedResponse = yield select(requestsToCache[cacheKey].cachedDataSelector);

		if (cachedResponse) {
			return transformCacheToCachedResponse(cachedResponse);
		}

		throw new Error(e);
	}
}

export function* networkFirst(
	request: ExtendedAxiosRequestConfig,
	next: TransformedNetworkHelper,
	expiryDate: number,
	cacheKey: keyof RequestToCacheRecord,
): Generator<any, AxiosResponse | undefined, AxiosResponse> {
	if (request.url) {
		return yield executeNetworkFirst(request, next, expiryDate, cacheKey);
	}
}

export const networkFirstHelper = (
	cacheKey: keyof RequestToCacheRecord,
	expiryDate: number = new Date().setHours(24),
) => {
	return (request: ExtendedAxiosRequestConfig, next: TransformedNetworkHelper) => {
		return networkFirst(request, next, expiryDate, cacheKey);
	};
};
