import { Store } from 'src/state/store.type';
import { ActionType } from 'src/state/store/actionStatus/actionStatus.type';

export interface CacheStore {
	lastSyncedInitialData?: number; // API data that doesn't require authentication e.g. store, employees
	lastSyncedAuthenticatedData?: number; // API data that requires authentication e.g. orders, deliveries,...
}

export enum CacheSagaErrors {
	NO_CACHED_ITEM = 'NO_CACHED_ITEM',
	NO_VALID_REQUEST = 'NO_VALID_REQUEST',
	EXPIRED_CACHED_ITEM = 'EXPIRED_CACHED_ITEM',
}

export interface RequestCacheConfig {
	url: string;
	fetchAction: () => ActionType;
	cachedDataSelector: (state: Store) => any;
	lastFetchedSelector: (state: Store) => any;
	clearAction: () => ActionType;
	needsSelectedStore?: boolean;
}

export type RequestToCacheRecord = Record<string, RequestCacheConfig>;

/* ------------- Action Payload Types  ------------- */
export interface LastSyncedCacheActionType {
	lastSynced: number;
}

export interface CachedStore {
	lastFetched?: number;
	lastFetchedPreviousDays?: number;
}

export interface LastFetchedSetActionType {
	lastFetched: number;
}

export interface LastFetchedPreviousDaysSetActionType {
	lastFetchedPreviousDays: number;
}
