import React, { FC, useEffect, useState } from 'react';
import Quagga from '@ericblade/quagga2';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useToasts } from 'react-toast-notifications';
import { useHistory } from 'react-router';

import { HoneywellFactory } from 'src/models/honeywellScanner/honeywellFactory.model';
import { HoneywellScanner } from 'src/components/scanner/honeywellScanner/honeywellScanner.component';
import { ScannerSkeleton } from 'src/components/scanner/scannerSkeleton/scannerSkeleton.component';
import { Switch } from 'src/components/switch/switch.component';
import { PWAScanner } from 'src/components/scanner/pwaScanner/pwaScanner.component';
import { Colors } from 'src/assets';
import { isDev, isTest } from 'src/helpers/env.helpers';

import { ScannerProps } from './scanner.type';
import './scanner.style.scss';

const Scanner: FC<ScannerProps> = ({ onFailed, onSuccess, onInited }: ScannerProps) => {
	const [honeywellIsAvailable, setHoneywellIsAvailable] = useState<boolean | undefined>();
	const [honeywellScannerEnabled, setHoneywellScannerEnabled] = useState<boolean | undefined>();

	const { t } = useTranslation();
	const { addToast } = useToasts();
	const { goBack } = useHistory();

	useEffect(() => {
		window.addEventListener('blur', onBlur);

		(async () => {
			await checkIfHoneywellIsAvailable();
		})();

		return () => {
			window.removeEventListener('blur', onBlur);

			Quagga.stop();
			HoneywellFactory.destroy();
		};
	}, []);

	const onBlur = () => {
		goBack(); // Dirty, but works
	};

	const checkIfHoneywellIsAvailable = async () => {
		try {
			await HoneywellFactory.build();
		} catch (e) {
			const message = typeof e === 'object' ? e.message || e : e;
			if (isDev() || isTest()) {
				addToast(`${t('scanner.honeywellSDKInitializationError')}\n ${message}`, { appearance: 'error' });
			}
		}

		setHoneywellIsAvailable(!!HoneywellFactory.barcodeScanner);
		setHoneywellScannerEnabled(!!HoneywellFactory.barcodeScanner);
	};

	const changeScanner = (honeywellScannerEnabled: boolean) => {
		if (honeywellScannerEnabled) {
			Quagga.stop().then(() => {
				setHoneywellScannerEnabled(honeywellScannerEnabled);
			});

			return;
		}

		setHoneywellScannerEnabled(honeywellScannerEnabled);
	};

	const onScannerInited = () => {
		addToast(honeywellScannerEnabled ? t('scanner.honeweywellSDKInited') : t('scanner.pwaScannerInited'), {
			appearance: 'info',
		});
		onInited();
	};

	if (honeywellIsAvailable === undefined || honeywellScannerEnabled === undefined) {
		return <ScannerSkeleton />;
	}

	return (
		<div className={'scanner-container'}>
			<div className={'scanner-container__scan-area'}>
				{honeywellScannerEnabled ? (
					<HoneywellScanner
						onHoneywellSDKInited={onScannerInited}
						onFailed={onFailed}
						onSuccess={onSuccess}
					/>
				) : (
					<PWAScanner onCameraInited={onScannerInited} onFailed={onFailed} onSuccess={onSuccess} />
				)}
			</div>

			<div className={classNames('scanner-switch', { disabled: !honeywellIsAvailable })}>
				<div className={classNames('scanner-switch__option', { selected: !honeywellScannerEnabled })}>
					{t('scanner.camera')}
				</div>
				<Switch
					disabled={!honeywellIsAvailable}
					checked={honeywellScannerEnabled}
					onColor={Colors.primary}
					handleDiameter={20}
					uncheckedIcon={false}
					checkedIcon={false}
					boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
					activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
					height={20}
					width={48}
					onChange={changeScanner}
				/>
				<div className={classNames('scanner-switch__option', { selected: honeywellScannerEnabled })}>
					{t('scanner.laser')}
				</div>
			</div>
		</div>
	);
};

export { Scanner };
