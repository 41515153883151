import { call, CallEffect, put, select, takeLatest } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';

import { MooseApiRoutes } from 'src/config/api.config';
import { replaceColonPrefixed } from 'src/helpers/message.helpers';
import { authorize } from 'src/state/sagas/auth/authorization.saga';
import { fetchRequest, HttpMethod } from 'src/state/sagas/network/network.saga';
import { actionStatusAdd } from 'src/state/store/actionStatus/actionStatus.action';
import { ActionStatusEnum, ActionType } from 'src/state/store/actionStatus/actionStatus.type';
import { selectSelectedStoreId } from 'src/state/store/carrefourStore/carrefourStore.selector';
import { ACTION_STORAGE_CREATE_PALLY } from 'src/state/store/storage/storage.const';
import { StorageCreatePallyActionType } from 'src/state/store/storage/storage.type';
import { createdItemMapper } from 'src/state/mappers/generic/createdItem.mapper';

function* createPally(
	storeId: string,
	code: number,
	label?: string,
	storageSlotId?: string,
	orderIds?: string[],
): Generator<CallEffect, AxiosResponse, AxiosResponse> {
	return yield call(
		fetchRequest,
		{
			method: HttpMethod.Post,
			url: replaceColonPrefixed(MooseApiRoutes.StorageCreatePally, {
				storeUuid: storeId,
			}),
			data: {
				label,
				code,
				storageSlotId,
				orderIds,
			},
		},
		[authorize],
		createdItemMapper,
	);
}

function* createPallyWorker(action: ActionType<StorageCreatePallyActionType>): any {
	yield put(actionStatusAdd({ type: ACTION_STORAGE_CREATE_PALLY }, ActionStatusEnum.RUNNING));

	try {
		const selectedCarrefourStore: string | undefined = yield select(selectSelectedStoreId);

		if (!action.payload || !selectedCarrefourStore) {
			return;
		}

		const { code, label, storageSlotId, orderIds } = action.payload;

		yield call(createPally, selectedCarrefourStore, code, label, storageSlotId, orderIds);

		yield put(actionStatusAdd({ type: ACTION_STORAGE_CREATE_PALLY }, ActionStatusEnum.SUCCESS));
	} catch (error) {
		yield put(actionStatusAdd({ type: ACTION_STORAGE_CREATE_PALLY }, ActionStatusEnum.ERROR));
	}
}

export function* createPallySaga() {
	yield takeLatest(ACTION_STORAGE_CREATE_PALLY, createPallyWorker);
}
