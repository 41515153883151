import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { add, format, isAfter, isBefore, isSameDay, sub } from 'date-fns';

import { IconNames, IconSizes } from 'src/components/icon/icon.type';
import { Icon } from 'src/components/icon/icon.component';
import { OutgoingOverviewContainer } from 'src/containers/app/outgoing/outgoingOverview/outgoingOverview.container';
import { OutgoingPages } from 'src/navigation/app/outgoing/outgoingRouter.type';
import { replaceColonPrefixed } from 'src/helpers/message.helpers';
import { selectLastFetchedOrders } from 'src/state/store/order/order.selector';
import { DateFormat, Order, OrderDisplay } from 'src/state/store/order/order.type';
import { OrdersOverviewProps } from 'src/navigation/app/appRouter.type';

const OutgoingOverviewPage: FC<OrdersOverviewProps> = ({ currentDateKey }: OrdersOverviewProps) => {
	const history = useHistory();
	const { t } = useTranslation();

	const [dateForOrders, setDateForOrders] = useState<Date>(currentDateKey ? new Date(currentDateKey) : new Date());
	const [disableNavigationNextDay, setDisableNavigationNextDay] = useState<boolean>(
		dateForOrders ? isSameDay(dateForOrders, new Date()) : true,
	);

	const [disableNavigationPreviousDay, setDisableNavigationPreviousDay] = useState<boolean>(false);

	useEffect(() => {
		const lastDayKey = localStorage.getItem('last_date_orderOverview');
		if (lastDayKey) {
			const date = new Date(lastDayKey);
			setDateForOrders(date);
			setDisableNavigationNextDay(isSameDay(date, new Date()));
			setDisableNavigationPreviousDay(isSameDay(date, sub(new Date(), { days: 6 })));
			localStorage.removeItem('last_date_orderOverview');
		}
	}, []);

	const navigateToOrderActions = (index: number, dateKey: string, orders: Order[]) => {
		const orderId = orders[index].id;
		localStorage.setItem('last_date_orderOverview', format(dateForOrders, DateFormat.dateAndTimezone));
		localStorage.removeItem(OrderDisplay.lastIndex);
		history.push(replaceColonPrefixed(OutgoingPages.OrderActions, { orderId, dateKey }), {
			currentOrderIndex: index,
			currentDateKey: dateKey,
			orders,
		});
	};

	const navigateToOrderDetails = (orderId: string, externalOrderId: string, currentDateKey: string) => {
		localStorage.setItem('last_date_orderOverview', format(dateForOrders, DateFormat.dateAndTimezone));
		history.push(replaceColonPrefixed(OutgoingPages.OrderDetail, { orderId, currentDateKey }), {
			orderId,
			externalOrderId,
			currentDateKey,
		});
	};

	const navigateToNextDay = () => {
		const nextDate = add(dateForOrders, { days: 1 });
		const today = new Date();
		if (isBefore(nextDate, today) || isSameDay(nextDate, today)) {
			setDisableNavigationNextDay(isSameDay(nextDate, today));
			setDisableNavigationPreviousDay(false);
			setDateForOrders(nextDate);
		}
	};

	const navigateToPreviousDay = () => {
		const previousDate = sub(dateForOrders, { days: 1 });
		const firstDay = sub(new Date(), { days: 6 });
		if (isAfter(previousDate, firstDay) || isSameDay(previousDate, firstDay)) {
			setDisableNavigationNextDay(false);
			setDisableNavigationPreviousDay(isSameDay(previousDate, firstDay));
			setDateForOrders(previousDate);
		}
	};

	return (
		<OutgoingOverviewContainer
			title={t('tabs.outgoing')}
			icon={<Icon iconName={IconNames.Outgoing} size={IconSizes.Large} />}
			lastFetchedSelector={selectLastFetchedOrders}
			extendedHeader
			onNavigateToOrderActions={navigateToOrderActions}
			onNavigateToOrderDetails={navigateToOrderDetails}
			onNavigateToNextDay={navigateToNextDay}
			onNavigateToPreviousDay={navigateToPreviousDay}
			currentDateKey={format(dateForOrders, DateFormat.dateAndTimezone)}
			disableNavigationNextDay={disableNavigationNextDay}
			disableNavigationPreviousDay={disableNavigationPreviousDay}
		/>
	);
};

export { OutgoingOverviewPage };
