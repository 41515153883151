import { LastFetchedPreviousDaysSetActionType, LastFetchedSetActionType } from 'src/state/store/cache/cache.type';
import { ActionType } from 'src/state/store/actionStatus/actionStatus.type';

import {
	ACTION_CUSTOMER_BY_ORDER_ID_FETCH,
	ACTION_LAST_FETCHED_OUTBOUND_ORDERS_PREVIOUS_DAYS_SET,
	ACTION_LAST_FETCHED_OUTBOUND_ORDERS_SET,
	ACTION_ORDER_CUSTOMER_SET,
	ACTION_ORDER_DETAIL_FETCH,
	ACTION_ORDER_DETAIL_SET,
	ACTION_OUTBOUND_ORDERS_CLEAR,
	ACTION_OUTBOUND_ORDERS_FETCH,
	ACTION_OUTBOUND_ORDERS_SET,
	ACTION_OUTGOING_ORDER_UPDATE,
	ACTION_OUTGOING_ORDER_UPDATE_SET,
} from './order.const';
import {
	Customer,
	Interval,
	Order,
	OrderCustomerFetchActionType,
	OrderCustomerSetActionType,
	OrderDetail,
	OrderDetailFetchActionType,
	OrderDetailSetActionType,
	OrdersFetchActionType,
	OrdersSetActionType,
	OrderState,
	OrderUpdateActionType,
} from './order.type';

export const actionOutboundOrderWithDetailsFetch = (): ActionType<OrdersFetchActionType> => {
	return {
		type: ACTION_OUTBOUND_ORDERS_FETCH,
		payload: {
			includeCustomers: true,
			includeItems: true,
		},
	};
};

export const actionOutboundOrdersFetch = (): ActionType<OrdersFetchActionType> => {
	return {
		type: ACTION_OUTBOUND_ORDERS_FETCH,
		payload: {},
	};
};

export const actionOutboundOrdersForDateFetch = (
	date: Date,
	includePreviousDays: number,
	includeCustomers: boolean,
	includeItems: boolean,
): ActionType<OrdersFetchActionType> => {
	return {
		type: ACTION_OUTBOUND_ORDERS_FETCH,
		payload: {
			date,
			includeCustomers,
			includeItems,
			includePreviousDays,
		},
	};
};

export const actionOutboundOrdersSet = (orders: Order[], hasDetails?: boolean): ActionType<OrdersSetActionType> => {
	return {
		type: ACTION_OUTBOUND_ORDERS_SET,
		payload: {
			orders,
			hasDetails,
		},
	};
};

export const actionOrderDetailFetch = (
	orderId: string,
	forceOpen?: boolean,
): ActionType<OrderDetailFetchActionType> => {
	return {
		type: ACTION_ORDER_DETAIL_FETCH,
		payload: {
			orderId,
			forceOpen,
		},
	};
};

export const actionOrderDetailSet = (orderDetail: OrderDetail): ActionType<OrderDetailSetActionType> => {
	return {
		type: ACTION_ORDER_DETAIL_SET,
		payload: {
			orderDetail,
		},
	};
};

export const actionOutgoingOrderUpdate = (
	orderId: string,
	state: OrderState,
	currentDateKey: string,
	timeslot?: Interval,
): ActionType<OrderUpdateActionType> => {
	return {
		type: ACTION_OUTGOING_ORDER_UPDATE,
		payload: {
			orderId,
			state,
			timeslot,
			currentDateKey,
		},
	};
};

export const actionOutgoingOrderUpdateSet = (
	orderId: string,
	state: OrderState,
	currentDateKey: string,
	timeslot?: Interval,
): ActionType<OrderUpdateActionType> => {
	return {
		type: ACTION_OUTGOING_ORDER_UPDATE_SET,
		payload: {
			orderId,
			state,
			currentDateKey,
			timeslot,
		},
	};
};

export const actionOutboundOrdersClear = (): ActionType => {
	return {
		type: ACTION_OUTBOUND_ORDERS_CLEAR,
	};
};

export const actionLastFetchedOutboundOrdersSet = (lastFetched: number): ActionType<LastFetchedSetActionType> => {
	return {
		type: ACTION_LAST_FETCHED_OUTBOUND_ORDERS_SET,
		payload: { lastFetched },
	};
};

export const actionLastFetchedOutboundOrdersPreviousDaysSet = (
	lastFetchedPreviousDays: number,
): ActionType<LastFetchedPreviousDaysSetActionType> => {
	return {
		type: ACTION_LAST_FETCHED_OUTBOUND_ORDERS_PREVIOUS_DAYS_SET,
		payload: { lastFetchedPreviousDays },
	};
};

/**
 * Action creator that will trigger saga to fetch the customer with the orderId
 *
 * @param orderId unique id of the order
 * @param dateKey
 */
export const actionCustomerByOrderIdFetch = (
	orderId: string,
	dateKey: string,
): ActionType<OrderCustomerFetchActionType> => {
	return {
		type: ACTION_CUSTOMER_BY_ORDER_ID_FETCH,
		payload: {
			orderId,
			dateKey,
		},
	};
};

/**
 * Action creator that will update an order with a new customer
 *
 * @param orderId
 * @param customer customer passed by the saga
 * @param dateKey
 */
export const actionOrderCustomerSet = (
	orderId: string,
	customer: Customer,
	dateKey: string,
): ActionType<OrderCustomerSetActionType> => {
	return {
		type: ACTION_ORDER_CUSTOMER_SET,
		payload: {
			orderId,
			customer,
			dateKey,
		},
	};
};
