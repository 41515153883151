import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';

import { DraggableBottomSheet } from 'src/components/draggableBottomSheet/draggableBottomSheet.component';
import { IconNames, IconSizes } from 'src/components/icon/icon.type';
import { Icon } from 'src/components/icon/icon.component';
import { Colors } from 'src/assets';
import { PrimaryButton } from 'src/components/button/primaryButton/primaryButton.component';
import { formatLocale } from 'src/helpers/date.helpers';

import { ConfirmTimeProps } from './confirmTime.type';
import './confirmTime.style.scss';

export const ConfirmTimeBottomSheet: FC<ConfirmTimeProps> = ({ timeslot, onConfirm, ...props }: ConfirmTimeProps) => {
	const { t } = useTranslation();

	return (
		<DraggableBottomSheet {...props}>
			<div className="confirm-time">
				<Icon className="confirm-time__icon" iconName={IconNames.Clock2} size={70} />

				<h1>{t('confirmTime.title')}</h1>

				<p>
					{timeslot
						? t('confirmTime.time', {
								date: formatLocale(timeslot.start, 'dd MMM'),
								start: format(timeslot.start, 'HH:mm'),
								end: format(timeslot.end, 'HH:mm'),
						  })
						: ''}
				</p>

				<PrimaryButton backgroundColor={Colors.green} onClick={onConfirm}>
					<Icon iconName={IconNames.Check} color={Colors.purewhite} size={IconSizes.Small} />

					{t('confirmTime.confirm')}
				</PrimaryButton>
			</div>
		</DraggableBottomSheet>
	);
};
