import { AxiosResponse } from 'axios';
import { call, CallEffect, put, takeLatest } from 'redux-saga/effects';

import { actionStatusAdd } from 'src/state/store/actionStatus/actionStatus.action';
import { ActionStatusEnum, ActionType } from 'src/state/store/actionStatus/actionStatus.type';
import { fetchRequest, HttpMethod } from 'src/state/sagas/network/network.saga';
import { MooseApiRoutes } from 'src/config/api.config';
import { InboundDeliveryAcceptActionType } from 'src/state/store/inbound/inbound.type';
import { ACTION_INBOUND_DELIVERY_ACCEPT } from 'src/state/store/inbound/inbound.const';
import { actionInboundDeliveriesFetch } from 'src/state/store/inbound/inbound.action';
import { replaceColonPrefixed } from 'src/helpers/message.helpers';
import { authorize } from 'src/state/sagas/auth/authorization.saga';
import { isOffline } from 'src/helpers/cache.helpers';

export function* inboundDeliveryAcceptSaga() {
	yield takeLatest(ACTION_INBOUND_DELIVERY_ACCEPT, inboundDeliveryAcceptWorker);
}

function* inboundDeliveryAcceptWorker(action: ActionType<InboundDeliveryAcceptActionType>) {
	// Create action status running
	yield put(actionStatusAdd({ type: ACTION_INBOUND_DELIVERY_ACCEPT }, ActionStatusEnum.RUNNING, isOffline()));

	try {
		if (!action.payload) {
			return;
		}

		const { deliveryId, comment } = action.payload;

		// do accept api call
		yield call(acceptInboundDelivery, deliveryId, comment);

		// fetch updated inbound deliveries
		yield put(actionInboundDeliveriesFetch());

		// Create action status success
		yield put(actionStatusAdd({ type: ACTION_INBOUND_DELIVERY_ACCEPT }, ActionStatusEnum.SUCCESS));
	} catch (e) {
		// General error
		yield put(actionStatusAdd({ type: ACTION_INBOUND_DELIVERY_ACCEPT }, ActionStatusEnum.ERROR, isOffline()));
	}
}

function* acceptInboundDelivery(deliveryId: string, comment: string): Generator<CallEffect<any>, AxiosResponse, any> {
	return yield call(
		fetchRequest,
		{
			method: HttpMethod.Put,
			url: replaceColonPrefixed(MooseApiRoutes.AcceptDelivery, { deliveryId }),
			data: {
				comment,
			},
		},
		[authorize],
	);
}
