import React, { FC } from 'react';
import { useHistory, useLocation } from 'react-router';

import { OrderDetailContainer } from 'src/containers/app/outgoing/orderDetail/orderDetail.container';
import { replaceColonPrefixed } from 'src/helpers/message.helpers';
import { withNavbarHeader } from 'src/hoc/withNavbarHeader/withNavbarHeader.hoc';
import { AppPages, OrderDetailPageProps } from 'src/navigation/app/appRouter.type';
import { OutgoingPages } from 'src/navigation/app/outgoing/outgoingRouter.type';

const OrderDetailPageComponent: FC<OrderDetailPageProps> = ({
	orderId,
	externalOrderId,
	currentDateKey,
}: OrderDetailPageProps) => {
	const history = useHistory();
	const location = useLocation<{ forceOpen?: boolean }>();

	const { forceOpen } = location?.state;
	const navigateToScanTotes = () => {
		history.push(
			replaceColonPrefixed(OutgoingPages.ScanTotes, {
				orderId: orderId || '',
				currentDateKey: currentDateKey || '',
			}),
			{
				orderId,
				externalOrderId,
				currentDateKey,
			},
		);
	};

	const navigateToHelpdesk = () => {
		history.push(
			replaceColonPrefixed(OutgoingPages.Helpdesk, {
				orderId: orderId || '',
				currentDateKey: currentDateKey || '',
			}),
			{
				orderId,
				externalOrderId,
				currentDateKey,
			},
		);
	};

	const navigateToRefund = () => {
		history.push(
			replaceColonPrefixed(OutgoingPages.Refund, {
				orderId: orderId || '',
				currentDateKey: currentDateKey || '',
			}),
			{
				orderId,
				currentDateKey,
				externalOrderId,
			},
		);
	};

	const navigateToOutgoingOverview = () => {
		history.push(replaceColonPrefixed(AppPages.Outgoing, { currentDateKey: currentDateKey as string }), {
			currentDateKey,
		});
	};

	return (
		<OrderDetailContainer
			onNavigateToOutgoingOverview={navigateToOutgoingOverview}
			onNavigateToTotesScanner={navigateToScanTotes}
			onNavigateToHelpdesk={navigateToHelpdesk}
			currentDateKey={currentDateKey}
			onNavigateToRefund={navigateToRefund}
			orderId={orderId}
			forceOpen={forceOpen}
		/>
	);
};

const OrderDetailPage = withNavbarHeader<OrderDetailPageProps>(OrderDetailPageComponent);

export { OrderDetailPage };
