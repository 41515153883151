import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useToasts } from 'react-toast-notifications';

import { Colors } from 'src/assets';
import { Button } from 'src/components/button/button.component';
import { Scanner } from 'src/components/scanner/scanner.component';
import { mapStorageQr } from 'src/helpers/storageQr.helper';
import { LinkMethod, ScannedStorageItem, StorageItemKey } from 'src/state/store/storage/storage.type';
import { actionStorageLink } from 'src/state/store/storage/storage.action';
import { ScannerType } from 'src/components/scanner/scanner.type';
import { isDev, isTest } from 'src/helpers/env.helpers';
import { selectAlternateClientOrderReferences } from 'src/state/store/order/order.selector';
import { useActionStatus } from 'src/hooks/useActionStatus.hook';
import { ACTION_STORAGE_LINK } from 'src/state/store/storage/storage.const';
import { ActionStatusEnum } from 'src/state/store/actionStatus/actionStatus.type';
import { useModal } from 'src/hooks/useModal.hook';
import StorageMoveExtendBottomSheet from 'src/components/bottomSheet/storageMoveExtend/storageMoveExtend.component';
import { selectOrderHasStorage } from 'src/state/store/storage/storage.selector';
import { IconButton } from 'src/components/button/iconButton/iconButton.component';
import { IconNames } from 'src/components/icon/icon.type';
import { vibrate } from 'src/helpers/device.helpers';
import { actionStatusRemove } from 'src/state/store/actionStatus/actionStatus.action';

import { StorageQrRawBody, StorageScannerContainerProps } from './storageScannerContainer.type';
import './storageScannerContainer.style.scss';

const StorageScannerContainer: FC<StorageScannerContainerProps> = ({
	onNavigateBack,
	storageSlot,
	pally,
}: StorageScannerContainerProps) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const { onOpenModal, onDismissModal } = useModal();
	const { addToast } = useToasts();

	// Indicates if a scanned barcode is unknown
	const [unknownItemScanned, setUnknownItemScanned] = useState<boolean>(false);
	// Indicates if there went something wrong with the PWA scanner
	const [showErrorMessage, setShowErrorMessage] = useState<string | undefined>();

	const [toScanText, setToScanText] = useState<string>(t('storageScanner.message'));
	const [scannedItems, setScannedItems] = useState<ScannedStorageItem[]>([]);
	const scannedItemsRef = useRef<ScannedStorageItem[]>([]);
	const [selectedMethod, setSelectedMethod] = useState<LinkMethod>();
	const [scanComplete, setScanComplete] = useState<boolean>(false);

	const orderHasStorage = useSelector(
		selectOrderHasStorage(scannedItems.find((i) => i.type === StorageItemKey.ORDER)?.data as string),
	);

	const alternateClientOrderReferences = useSelector(selectAlternateClientOrderReferences);
	const status = useActionStatus({ type: ACTION_STORAGE_LINK });

	const onInitedScanner = () => {
		setUnknownItemScanned(false);
		setShowErrorMessage(undefined);
	};

	// Cleanup
	useEffect(() => {
		return () => {
			onDismissModal();
			setUnknownItemScanned(false);
			setShowErrorMessage(undefined);
			setSelectedMethod(undefined);
			setScannedItems([]);
		};
	}, []);

	useEffect(() => {
		// Set already selected storage slot or pally
		if (storageSlot) {
			const scannedItem: ScannedStorageItem = {
				data: storageSlot.code,
				type: StorageItemKey.STORAGE_SLOT,
			};
			setScannedItems([scannedItem]);
		} else if (pally) {
			const scannedItem: ScannedStorageItem = {
				data: pally.code,
				type: StorageItemKey.PALLY,
			};
			setScannedItems([scannedItem]);
		}
	}, [storageSlot, pally]);

	useEffect(() => {
		scannedItemsRef.current = scannedItems;
		if (scannedItems.length === 1) {
			switch (scannedItems[0].type) {
				case StorageItemKey.STORAGE_SLOT:
					setToScanText(t('storageScanner.storageSlotScannedMessage'));
					break;
				case StorageItemKey.PALLY:
					setToScanText(t('storageScanner.pallyScannedMessage'));
					break;
				case StorageItemKey.ORDER:
					setToScanText(t('storageScanner.orderScannedMessage'));
					break;
			}
		} else if (scannedItems.length === 2) {
			setScanComplete(true);
		}
	}, [scannedItems]);

	useEffect(() => {
		if (scanComplete) {
			const scannedOrderIndex = scannedItems.findIndex((i) => i.type === StorageItemKey.ORDER);

			// User has scanned an order, no link method known yet
			if (scannedOrderIndex !== -1 && !selectedMethod) {
				if (orderHasStorage) {
					const otherItem = scannedItems[scannedOrderIndex === 0 ? 1 : 0];
					showMoveExtend(otherItem);

					return;
				}

				setSelectedMethod(LinkMethod.MOVE);

				return;
			}

			// Link API call
			dispatch(actionStorageLink(true, scannedItems, selectedMethod));
		}
	}, [scanComplete, selectedMethod]);

	useEffect(() => {
		if (status?.status === ActionStatusEnum.SUCCESS) {
			onComplete(true);
		} else if (status?.status === ActionStatusEnum.ERROR) {
			onComplete(false);
		}
	}, [status]);

	const showMoveExtend = (storageItem: ScannedStorageItem) => {
		onOpenModal(
			<StorageMoveExtendBottomSheet
				storageItem={storageItem}
				onSelectMethod={setSelectedMethod}
				onCancel={cancelScan}
			/>,
			{ backdropDismissible: false },
		);
	};

	const itemAlreadyScanned = (newScannedItem: ScannedStorageItem): boolean => {
		return scannedItemsRef.current.map((item) => item.type).includes(newScannedItem.type);
	};

	const getOrderId = (alternateClientOrderReference: string): string => {
		if (!alternateClientOrderReferences) {
			throw new Error('Alternate client order not found');
		}
		const orderId = alternateClientOrderReferences[alternateClientOrderReference];

		if (!orderId) {
			throw new Error('Order does not exist');
		}

		return orderId;
	};

	/**
	 * If data type equals string => barcode
	 * If data type equals an object => pally / storage slot
	 *
	 * @param scanResponse
	 */
	const onSuccess = useCallback(
		(scanResponse: string | Record<string, unknown>) => {
			let responseType: StorageItemKey | undefined;
			let data: string | number | undefined;

			try {
				if (typeof scanResponse === 'string') {
					responseType = StorageItemKey.ORDER;
					data = getOrderId(scanResponse);
				} else {
					const mappedQr = mapStorageQr(scanResponse as unknown as StorageQrRawBody);

					responseType = mappedQr.type;
					data = mappedQr.code;
				}
			} catch (e) {
				// Invalid QR code or barcode
				setUnknownItemScanned(true);
				scanVibrate(false);
			}

			if (!responseType || !data) {
				return;
			}

			const newScannedItem: ScannedStorageItem = {
				data,
				type: responseType,
			};

			// Check if already scanned
			if (itemAlreadyScanned(newScannedItem)) {
				return;
			}

			// Vibrate
			scanVibrate(true);

			setUnknownItemScanned(false);
			setShowErrorMessage(undefined);
			setScannedItems((previousScannedItems) => [...previousScannedItems, newScannedItem]);
		},
		[scannedItems],
	);

	const onFailed = (e: string | Record<string, unknown> | undefined, scannerType: ScannerType) => {
		let errorMessage: string | undefined;

		if (typeof e === 'string') {
			errorMessage = e;
		} else if (e && scannerType === ScannerType.HONEYWELL && (isDev() || isTest())) {
			errorMessage = e.message as string;
		}

		// Vibrate
		scanVibrate(false);

		setUnknownItemScanned(false);
		setShowErrorMessage(
			`${t(
				scannerType === ScannerType.HONEYWELL
					? 'scanner.honeywellSDKInitializationError'
					: 'scanner.pwaScannerInitializationError',
			)} ${errorMessage ? '\n' : ''} ${errorMessage || ''}`,
		);
	};

	const scanVibrate = (isSuccessful: boolean) => {
		if (isSuccessful) {
			vibrate(150);
		} else {
			vibrate(800);
		}
	};

	const cancelScan = () => {
		onNavigateBack();
	};

	const onComplete = (success: boolean) => {
		// Clear action status
		dispatch(actionStatusRemove({ type: ACTION_STORAGE_LINK }));

		if (success) {
			addToast(t('storageScanner.successNotification'), { appearance: 'success' });
		} else {
			addToast(t('storageScanner.errorNotification'), { appearance: 'error' });
		}

		onNavigateBack();
	};

	const testStorageSlotScan = () => {
		let code = parseInt(prompt('Storage slot code') || '', 10);
		if (isNaN(code)) return;

		onSuccess({ type: 1, id: code });
	};

	const testPallyScan = () => {
		const code = parseInt(prompt('Pally code') || '', 10);
		if (isNaN(code)) return;

		onSuccess({ type: 2, id: code });
	};

	const testOrderScan = () => {
		const alternateId = prompt('Order alternate client order id');
		if (!alternateId) return;

		onSuccess(alternateId);
	};

	return (
		<div className="storage-scanner">
			<p className="storage-scanner__message" dangerouslySetInnerHTML={{ __html: toScanText }} />
			{unknownItemScanned && (
				<p className="storage-scanner__error-message">{t('storageScanner.unknownItemScanned')}</p>
			)}
			{!!showErrorMessage && <p className="storage-scanner__error-message">{showErrorMessage} </p>}
			<div className="storage-scanner__scan-container">
				<Scanner onInited={onInitedScanner} onSuccess={onSuccess} onFailed={onFailed} />
			</div>
			<div className="storage-scanner__button-container">
				{isDev() || isTest() ? (
					<div className="storage-scanner__button-container__test">
						<IconButton
							className="button"
							fullWidth={true}
							backgroundColor={Colors.orange}
							size={15}
							iconName={IconNames.Storages}
							onClick={testStorageSlotScan}
						/>
						<IconButton
							className="button"
							fullWidth={true}
							backgroundColor={Colors.orange}
							size={15}
							iconName={IconNames.Pallies}
							onClick={testPallyScan}
						/>
						<IconButton
							className="button"
							fullWidth={true}
							backgroundColor={Colors.orange}
							size={15}
							iconName={IconNames.Groceries}
							onClick={testOrderScan}
						/>
					</div>
				) : undefined}
				<Button backgroundColor={Colors.red} onClick={cancelScan}>
					{t('general.cancel')}
				</Button>
			</div>
		</div>
	);
};

export default StorageScannerContainer;
