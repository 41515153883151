import { ActionType } from 'src/state/store/actionStatus/actionStatus.type';
import {
	ACTION_STORAGE_CLEAR,
	ACTION_STORAGE_CREATE_PALLY,
	ACTION_STORAGE_CREATE_STORAGE_SLOT,
	ACTION_STORAGE_DELETE_PALLY,
	ACTION_STORAGE_DELETE_STORAGE_SLOT,
	ACTION_STORAGE_LINK,
	ACTION_STORAGE_OVERVIEW_FETCH,
	ACTION_STORAGE_OVERVIEW_SET,
	ACTION_STORAGE_PALLY_DETAILS_FETCH,
	ACTION_STORAGE_PALLY_DETAILS_SET,
	ACTION_STORAGE_STORAGE_SLOT_DETAILS_FETCH,
	ACTION_STORAGE_STORAGE_SLOT_DETAILS_SET,
} from 'src/state/store/storage/storage.const';
import {
	LinkMethod,
	Pally,
	ScannedStorageItem,
	StorageCreatePallyActionType,
	StorageCreateStorageSlotActionType,
	StorageDeletePallyActionType,
	StorageDeleteStorageSlotActionType,
	StorageFetchPallyActionType,
	StorageFetchStorageSlotActionType,
	StorageLinkAddActionType,
	StorageOverviewFetchActionType,
	StorageOverviewSetActionType,
	StorageSetPallyActionType,
	StorageSetStorageSlotActionType,
	StorageSlot,
} from 'src/state/store/storage/storage.type';

export const actionStorageOverviewWithDetailsFetch = (): ActionType<StorageOverviewFetchActionType> => {
	return {
		type: ACTION_STORAGE_OVERVIEW_FETCH,
		payload: {
			// TODO
		},
	};
};

/**
 * Action creator that will fetch the storage overview
 *
 * @returns action creator to trigger the saga
 */
export const actionStorageOverviewFetch = (): ActionType<StorageOverviewFetchActionType> => {
	return {
		type: ACTION_STORAGE_OVERVIEW_FETCH,
	};
};

export const actionStorageOverviewSet = (
	storageSlots: StorageSlot[],
	pallies: Pally[],
): ActionType<StorageOverviewSetActionType> => {
	return {
		type: ACTION_STORAGE_OVERVIEW_SET,
		payload: { storageSlots, pallies },
	};
};

export const actionStorageSlotDetailsFetch = (storageSlotId: string): ActionType<StorageFetchStorageSlotActionType> => {
	return {
		type: ACTION_STORAGE_STORAGE_SLOT_DETAILS_FETCH,
		payload: { storageSlotId },
	};
};

export const actionPallyDetailsFetch = (pallyId: string): ActionType<StorageFetchPallyActionType> => {
	return {
		type: ACTION_STORAGE_PALLY_DETAILS_FETCH,
		payload: { pallyId },
	};
};

export const actionStorageSlotDetailsSet = (storageSlot: StorageSlot): ActionType<StorageSetStorageSlotActionType> => {
	return {
		type: ACTION_STORAGE_STORAGE_SLOT_DETAILS_SET,
		payload: { storageSlot },
	};
};

export const actionPallyDetailsSet = (pally: Pally): ActionType<StorageSetPallyActionType> => {
	return {
		type: ACTION_STORAGE_PALLY_DETAILS_SET,
		payload: { pally },
	};
};

export const actionStorageLink = (
	link: boolean,
	scannedItems: ScannedStorageItem[],
	method?: LinkMethod,
): ActionType<StorageLinkAddActionType> => {
	return {
		type: ACTION_STORAGE_LINK,
		payload: { link, scannedItems, method },
	};
};

export const actionStorageDeleteStorageSlot = (
	storageSlotId: string,
): ActionType<StorageDeleteStorageSlotActionType> => {
	return {
		type: ACTION_STORAGE_DELETE_STORAGE_SLOT,
		payload: {
			storageSlotId: storageSlotId,
		},
	};
};

export const actionStorageDeletePally = (pallyId: string): ActionType<StorageDeletePallyActionType> => {
	return {
		type: ACTION_STORAGE_DELETE_PALLY,
		payload: {
			pallyId: pallyId,
		},
	};
};

export const actionStorageCreateStorageSlot = (
	code: number,
	label?: string,
	pallyIds: string[] = [],
	orderIds: string[] = [],
): ActionType<StorageCreateStorageSlotActionType> => {
	return {
		type: ACTION_STORAGE_CREATE_STORAGE_SLOT,
		payload: {
			code,
			label,
			pallyIds,
			orderIds,
		},
	};
};

export const actionStorageCreatePally = (
	code: number,
	label?: string,
	storageSlotId?: string,
	orderIds: string[] = [],
): ActionType<StorageCreatePallyActionType> => {
	return {
		type: ACTION_STORAGE_CREATE_PALLY,
		payload: {
			code,
			label,
			storageSlotId,
			orderIds,
		},
	};
};

export const actionStorageClear = (): ActionType => {
	return {
		type: ACTION_STORAGE_CLEAR,
	};
};
