import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { ToastProvider } from 'react-toast-notifications';

import configureStore from 'src/state/createStore';
import RootRouter from 'src/navigation/root.router';
import { LanguageProvider } from 'src/providers/language.provider';
import { ModalProvider } from 'src/providers/modal.provider';
import { CacheProvider } from 'src/providers/cache.provider';
import { AuthProvider } from 'src/providers/auth.provider';
import { UpdateAppContainer } from 'src/containers/updateApp/updateApp.container';

import './App.scss';
import { LoaderProvider } from './providers/loader.provider';

const { store, persistor } = configureStore();
const App = () => {
	useEffect(() => {
		window.addEventListener('resize', appHeight);

		return () => {
			window.removeEventListener('resize', appHeight);
		};
	}, []);

	const appHeight = () => {
		const doc = document.documentElement;
		doc.style.setProperty('--metric-windowHeight', `${window.innerHeight}px`);
	};

	return (
		<Provider store={store}>
			<LanguageProvider>
				<LoaderProvider>
					<ModalProvider>
						<UpdateAppContainer>
							<AuthProvider>
								<PersistGate loading={null} persistor={persistor}>
									<ToastProvider autoDismiss={true}>
										<CacheProvider>
											<RootRouter />
										</CacheProvider>
									</ToastProvider>
								</PersistGate>
							</AuthProvider>
						</UpdateAppContainer>
					</ModalProvider>
				</LoaderProvider>
			</LanguageProvider>
		</Provider>
	);
};

export default App;
