import { createReducer } from 'reduxsauce';
import { format } from 'date-fns';

import { arrayToMapNestedRecord } from 'src/helpers/record.helpers';
import { ActionType } from 'src/state/store/actionStatus/actionStatus.type';
import { ACTION_LOGOUT } from 'src/state/store/general/general.const';
import { LastFetchedPreviousDaysSetActionType, LastFetchedSetActionType } from 'src/state/store/cache/cache.type';
import { formatDateKeyForStore } from 'src/helpers/date.helpers';

import {
	Order,
	OrderCustomerSetActionType,
	OrderDetailSetActionType,
	OrdersSetActionType,
	OrderStore,
	OrderUpdateActionType,
} from './order.type';
import {
	ACTION_LAST_FETCHED_OUTBOUND_ORDERS_PREVIOUS_DAYS_SET,
	ACTION_LAST_FETCHED_OUTBOUND_ORDERS_SET,
	ACTION_ORDER_CUSTOMER_SET,
	ACTION_ORDER_DETAIL_SET,
	ACTION_OUTBOUND_ORDERS_CLEAR,
	ACTION_OUTBOUND_ORDERS_SET,
	ACTION_OUTGOING_ORDER_UPDATE_SET,
	ORDER_INITIAL_STATE,
} from './order.const';

export const ordersSet = (state: OrderStore, action: ActionType<OrdersSetActionType>): OrderStore => {
	if (!action.payload) {
		return state;
	}
	const { orders, hasDetails } = action.payload;
	const mergedOrders: Order[] = orders.map((order) => {
		const orderDate = format(order.timeslot.start, 'yyyy-MM-dd');
		const customer = hasDetails
			? order.customer
			: state.orders[orderDate] && state.orders[orderDate][order.id]?.customer;
		const items = hasDetails
			? order.items
			: state.orders[orderDate] && state.orders[orderDate][order.id] && state.orders[orderDate][order.id]?.items;

		return { ...order, customer, items } as Order;
	});

	return {
		...state,
		orders: arrayToMapNestedRecord(mergedOrders, state.orders),
	};
};

export const orderDetailSet = (store: OrderStore, action: ActionType<OrderDetailSetActionType>): OrderStore => {
	// if order is not fetched we do nothing
	if (
		!action.payload ||
		!store.orders[format(action.payload.orderDetail.timeslot.start, 'yyyy-MM-dd')][action.payload.orderDetail.id]
	) {
		return store;
	}

	const previousOrder =
		store.orders[format(action.payload.orderDetail.timeslot.start, 'yyyy-MM-dd')][action.payload.orderDetail.id];
	const { orderDetail } = action.payload;
	const dateResult = store.orders[format(orderDetail.timeslot.start, 'yyyy-MM-dd')];

	// update order except for customer (cached in frontend)
	return {
		...store,
		orders: {
			...store.orders,
			[format(orderDetail.timeslot.start, 'yyyy-MM-dd')]: {
				...dateResult,
				[orderDetail.id]: {
					...previousOrder,
					...orderDetail,
					// customer: order.customer ? order.customer : previousOrder.customer,
				} as Order,
			} as Record<string, Order>,
		},
	};
};

export const updateOrder = (store: OrderStore, action: ActionType<OrderUpdateActionType>): OrderStore => {
	if (!action.payload || !store.orders[action.payload.currentDateKey][action.payload.orderId]) {
		return store;
	}

	const { orderId, state, currentDateKey, timeslot } = action.payload;

	let newCurrentDateKey: string = formatDateKeyForStore(currentDateKey)!;
	let orderResult: Record<string, Order>;
	let oldOrder: Order;

	if (timeslot) {
		newCurrentDateKey = format(timeslot?.start, 'yyyy-MM-dd');
		orderResult = store.orders[newCurrentDateKey];
		oldOrder = store.orders[currentDateKey][orderId];
	} else {
		orderResult = store.orders[currentDateKey];
		oldOrder = orderResult[orderId];
	}
	if (timeslot) {
		delete store.orders[currentDateKey][orderId];
	}

	return {
		...store,
		orders: {
			...store.orders,
			[timeslot ? newCurrentDateKey : currentDateKey]: {
				...orderResult,
				[orderId]: {
					...oldOrder,
					state,
					timeslot: timeslot
						? {
								...timeslot,
						  }
						: orderResult[orderId].timeslot,
				},
			},
		},
	};
};

const orderCustomerSet = (state: OrderStore, action: ActionType<OrderCustomerSetActionType>): OrderStore => {
	let store: OrderStore;
	try {
		if (!action.payload) {
			return state;
		}

		const { orderId, customer, dateKey } = action.payload;
		const formattedDateKey: string = formatDateKeyForStore(dateKey)!;
		const dateOrders = state.orders[formattedDateKey];

		if (!dateOrders || !dateOrders[orderId]) {
			return state;
		}

		store = {
			...state,
			orders: {
				...state.orders,
				[formattedDateKey]: {
					...dateOrders,
					[orderId]: {
						...dateOrders[orderId],
						customer,
					} as Order,
				},
			},
		};

		return store;
	} catch (e) {
		console.error(e);
	}

	return state;
};

const orderLastFetchedSet = (state: OrderStore, action: ActionType<LastFetchedSetActionType>): OrderStore => {
	if (!action.payload) {
		return state;
	}
	const { lastFetched } = action.payload;

	return {
		...state,
		lastFetched,
	};
};

const orderLastFetchedPreviousDaysSet = (
	state: OrderStore,
	action: ActionType<LastFetchedPreviousDaysSetActionType>,
): OrderStore => {
	if (!action.payload) {
		return state;
	}
	const { lastFetchedPreviousDays } = action.payload;

	return {
		...state,
		lastFetchedPreviousDays,
	};
};

export const clear = (): OrderStore => {
	return ORDER_INITIAL_STATE;
};

/* ------------- Hookup Reducers To Types ------------- */

export const orderReducer = createReducer(ORDER_INITIAL_STATE, {
	[ACTION_OUTBOUND_ORDERS_SET]: ordersSet,
	[ACTION_OUTGOING_ORDER_UPDATE_SET]: updateOrder,
	[ACTION_ORDER_DETAIL_SET]: orderDetailSet,
	[ACTION_LAST_FETCHED_OUTBOUND_ORDERS_SET]: orderLastFetchedSet,
	[ACTION_LAST_FETCHED_OUTBOUND_ORDERS_PREVIOUS_DAYS_SET]: orderLastFetchedPreviousDaysSet,
	[ACTION_ORDER_CUSTOMER_SET]: orderCustomerSet,
	[ACTION_OUTBOUND_ORDERS_CLEAR]: clear,
	[ACTION_LOGOUT]: clear,
});
