import { MSALStorageKeys, UserMetaData } from 'src/state/store/token/token.type';

export const getMSALAccessToken = (): string | undefined | null => {
	return localStorage.getItem(MSALStorageKeys.accessToken);
};

export const getMSALUserMetaDataToken = (): string | undefined | null => {
	return localStorage.getItem(MSALStorageKeys.metaDataToken);
};

export const getIsOfflineAuthenticated = (): boolean => {
	return !!localStorage.getItem(MSALStorageKeys.idToken) && !!localStorage.getItem(MSALStorageKeys.metaData);
};

export const getMSALUserMetaData = (): UserMetaData | null => {
	const metaData: string | null = localStorage.getItem(MSALStorageKeys.metaData);

	return metaData && JSON.parse(metaData);
};

export const hasOfflineStoreAccess = (): boolean => {
	const metaData: UserMetaData | null = getMSALUserMetaData();

	return metaData?.roles?.includes('store-team-member') || false;
};
