import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import { BottomSheet } from 'react-spring-bottom-sheet';

import { OrderStateSquare } from 'src/components/orderStateSquare/orderStateSquare.component';
import { CustomerLabel } from 'src/components/label/customerLabel/customerLabel.component';
import { Icon } from 'src/components/icon/icon.component';
import { IconNames } from 'src/components/icon/icon.type';
import { SecondaryButton } from 'src/components/button/secondaryButton/secondaryButton.component';
import { PrimaryButton } from 'src/components/button/primaryButton/primaryButton.component';
import { Button } from 'src/components/button/button.component';

import { OrderLateProps } from './orderLate.type';
import './orderLate.style.scss';

export const OrderLateBottomSheet: FC<OrderLateProps> = ({
	order,
	onCancelOrder,
	onDelayOrder,
	onConfirmOrderPickedUP,
	displayOnSameDay,
	...props
}: OrderLateProps) => {
	const { t } = useTranslation();

	return (
		<BottomSheet {...props}>
			<div className="draggable-bottom-sheet">
				<div className="orderLate-header">
					<OrderStateSquare
						time={new Date(order.timeslot.start)}
						state={order.state}
						displayDay={!displayOnSameDay}
						className="square"
					/>
					<div className="info">
						<CustomerLabel
							orderId={order.id}
							dateKey={format(order.timeslot.start, 'yyyy-MM-dd')}
							customer={order.customer}
						/>
						<p className="info__barcode">#{order.clientOrderId}</p>
					</div>
				</div>
				<div className="card-body">
					<div className="card-body__content">
						<div className="card-body__warning">
							<Icon className="item__icon" iconName={IconNames.Warning} />
							<div className="card-body__text">
								<h3>{t('orderLate.warningMessage')}</h3>
								<p>{t('orderLate.warningDescription')}</p>
							</div>
						</div>
						{order.customer && order.customer.phoneNumber ? (
							<div className="card-body__warning">
								<Icon className="item__icon" iconName={IconNames.Warning} />
								<div className="card-body__text">
									{/*<h3>{t('orderLate.warningMessage')}</h3>*/}
									<p>
										{t('orderLate.contact', {
											firstName: order.customer?.firstname,
											phoneNumber: order.customer?.phoneNumber,
										})}
									</p>
								</div>
							</div>
						) : (
							<div className="card-body__warning">
								<Icon className="item__icon" iconName={IconNames.Warning} />
								<div className="card-body__text">
									{/*<h3>{t('orderLate.warningMessage')}</h3>*/}
									<p>{t('orderLate.noContact')}</p>
								</div>
							</div>
						)}
					</div>
				</div>
				<div className="button-container__late-actions">
					<SecondaryButton fullWidth={true} onClick={onConfirmOrderPickedUP}>
						{t('orderLate.pickedUp')}
					</SecondaryButton>
					<PrimaryButton fullWidth={true} onClick={onDelayOrder}>
						{t('orderLate.delay1h')}
					</PrimaryButton>
					<Button fullWidth={true} className="action-container__decline" onClick={onCancelOrder}>
						{t('orderLate.canceled')}
					</Button>
				</div>
			</div>
		</BottomSheet>
	);
};
