import React, { FC } from 'react';
import { ClipLoader } from 'react-spinners';

import { Colors } from 'src/assets';
import { Icon } from 'src/components/icon/icon.component';
import { IconSizes } from 'src/components/icon/icon.type';

import { FullPageLoaderProps } from './fullPageLoader.type';
import './fullPageLoader.style.scss';

const FullPageLoader: FC<FullPageLoaderProps> = ({ loading, icon }: FullPageLoaderProps) => {
	return loading ? (
		<div className="full-page-loader">
			{icon ? <Icon iconName={icon} size={IconSizes.Huge} className="full-page-loader__icon" /> : undefined}
			<ClipLoader color={Colors.primary} loading={true} />
		</div>
	) : null;
};

export { FullPageLoader };
