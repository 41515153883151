import React, { ComponentType } from 'react';

import { NavbarHeader } from 'src/components/navigation/header/navbarHeader/navbarHeader.component';

import { WithNavbarHeaderProps } from './withNavbarHeader.type';
import './withNavbarHeader.style.scss';

const withNavbarHeader =
	<P extends WithNavbarHeaderProps>(Component: ComponentType<P>): React.FC<P> =>
	// eslint-disable-next-line react/display-name
	({ title, icon, subtitle, children, ...props }: WithNavbarHeaderProps) => {
		return (
			<div className="with-navbar-header">
				<NavbarHeader
					subtitle={subtitle}
					icon={icon}
					className="with-navbar-header__header"
					title={title}
					style={{ zIndex: 3 }}>
					{children}
				</NavbarHeader>
				<div className="with-navbar-header__content">
					<Component {...(props as P)} />
				</div>
			</div>
		);
	};

export { withNavbarHeader };
