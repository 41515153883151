import React, { CSSProperties, FC } from 'react';
import classNames from 'classnames';

import { Icon } from 'src/components/icon/icon.component';
import { fullWidthButtonStyle } from 'src/components/button/button.const';
import { ButtonProps } from 'src/components/button/button.type';

import { IconButtonProps } from './iconButton.type';

import './iconButton.style.scss';

const IconButton: FC<IconButtonProps> = ({
	children,
	fullWidth,
	style,
	backgroundColor,
	className,
	iconName,
	color,
	stroke,
	size,
	...props
}: IconButtonProps) => {
	const buttonStyles: CSSProperties = { ...(fullWidth && fullWidthButtonStyle), ...style, backgroundColor };

	const buttonClasses = classNames({
		'icon-button': true,
		'icon-button--has-children': children !== undefined,
	});

	return (
		<button
			className={(className ? className : '') + ' ' + buttonClasses}
			style={buttonStyles}
			{...(props as ButtonProps)}>
			<Icon iconName={iconName} color={color} stroke={stroke} size={size} />
			{children}
		</button>
	);
};

export { IconButton };
