export interface ScannerProps extends BaseScannerProps {
	onInited: () => void;
}

export interface BaseScannerProps {
	onSuccess: (data: string | Record<string, unknown>) => void;
	onFailed: (error: string | Record<string, unknown> | undefined, type: ScannerType) => void;
}

export enum ScannerType {
	HONEYWELL,
	PWA,
}
