import React, { FC } from 'react';
import classNames from 'classnames';

import { Header } from 'src/components/navigation/header/header.component';

import { TabHeaderProps } from './tabHeader.type';
import './tabHeader.style.scss';

const TabHeader: FC<TabHeaderProps> = ({
	title,
	storeName,
	lastSynced,
	type,
	icon,
	children,
	className,
	style,
}: TabHeaderProps) => {
	return (
		<Header className={classNames('tabHeader', className)} style={style}>
			<div className={'tabHeader__content'}>
				<div className={'content__icon'}>{icon}</div>
				<div className={'content__text'}>
					<h1>{title}</h1>
					<span className={'text__description'}>
						{type && type != '' ? <span className={'text__type'}>{type}</span> : null}{' '}
						{storeName && storeName != '' ? <span className={'text__name'}>{storeName}</span> : null}
					</span>

					{lastSynced && <span className="text__lastSynced">{lastSynced}</span>}
				</div>
			</div>
			<div className={'tabHeader__children'}>{children}</div>
		</Header>
	);
};

export { TabHeader };
