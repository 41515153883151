import createSagaMiddleware from 'redux-saga';
import { createStore, applyMiddleware } from 'redux';
import { persistStore } from 'redux-persist';
import { composeWithDevTools } from 'redux-devtools-extension';

import rootReducer from './root.reducer';
import root from './sagas/root.saga';

const configureStore = () => {
	// create the saga middleware
	const sagaMiddleware = createSagaMiddleware();

	// Create store
	const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(sagaMiddleware)));

	// Create persistor to persist redux store
	const persistor = persistStore(store);

	// Run sagas
	sagaMiddleware.run(root);

	// Done
	return { store, persistor };
};

export default configureStore;
