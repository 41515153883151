import { AxiosResponse } from 'axios';
import { call, CallEffect, put, select, takeLatest } from 'redux-saga/effects';
import { format, sub } from 'date-fns';

import { selectSelectedStoreId } from 'src/state/store/carrefourStore/carrefourStore.selector';
import { actionStatusAdd } from 'src/state/store/actionStatus/actionStatus.action';
import { ActionStatusEnum, ActionType } from 'src/state/store/actionStatus/actionStatus.type';
import { ACTION_OUTBOUND_ORDERS_FETCH } from 'src/state/store/order/order.const';
import { fetchRequest, HttpMethod } from 'src/state/sagas/network/network.saga';
import { MooseApiRoutes } from 'src/config/api.config';
import { orderMapper } from 'src/state/mappers/order/order.mapper';
import { Order, OrdersFetchActionType } from 'src/state/store/order/order.type';
import {
	actionLastFetchedOutboundOrdersPreviousDaysSet,
	actionLastFetchedOutboundOrdersSet,
	actionOutboundOrdersSet,
} from 'src/state/store/order/order.action';
import { replaceColonPrefixed } from 'src/helpers/message.helpers';
import { networkFirstHelper } from 'src/state/sagas/network/networkFirst.saga';
import { authorize } from 'src/state/sagas/auth/authorization.saga';
import { orderMultiDayMapper } from 'src/state/mappers/order/orderMultiDay.mapper';

function* fetchOutboundOrdersMultipleDays(
	storeId: string,
	includeCustomers?: boolean,
	includeItems?: boolean,
	startDate?: Date,
	endDate?: Date,
): Generator<CallEffect, AxiosResponse, AxiosResponse> {
	return yield call(
		fetchRequest,
		{
			method: HttpMethod.Get,
			url: `${replaceColonPrefixed(MooseApiRoutes.OutboundOrders, { storeId })}?${
				includeCustomers ? 'customers=true' : ''
			}${includeItems ? '&items=true' : ''}${
				startDate && endDate
					? `&startDate=${format(startDate, 'yyyy-MM-dd')}&endDate=${format(endDate, 'yyyy-MM-dd')}`
					: ''
			}`,
		},
		[networkFirstHelper(MooseApiRoutes.OutboundOrders), authorize],
		orderMultiDayMapper,
	);
}

function* fetchOutboundOrders(
	storeId: string,
	includeCustomers?: boolean,
	includeItems?: boolean,
	date?: Date,
): Generator<CallEffect, AxiosResponse, AxiosResponse> {
	return yield call(
		fetchRequest,
		{
			method: HttpMethod.Get,
			url: `${replaceColonPrefixed(MooseApiRoutes.OutboundOrders, { storeId })}?${
				includeCustomers ? 'customers=true' : ''
			}${includeItems ? '&items=true' : ''}${
				date ? `&startDate=${format(date, 'yyyy-MM-dd')}&endDate=${format(date, 'yyyy-MM-dd')}` : ''
			}`,
		},
		[networkFirstHelper(MooseApiRoutes.OutboundOrders), authorize],
		orderMapper,
	);
}

function* outboundOrdersFetchWorker(action: ActionType<OrdersFetchActionType>) {
	// Create action status running
	yield put(actionStatusAdd({ type: ACTION_OUTBOUND_ORDERS_FETCH }, ActionStatusEnum.RUNNING));

	try {
		const selectedStoreId: string = yield select(selectSelectedStoreId);

		const includeCustomers: boolean | undefined = action.payload?.includeCustomers;
		const includeItems: boolean | undefined = action.payload?.includeItems;
		const includeDate: Date | undefined = action.payload?.date;
		const includePreviousDays: number | undefined = action.payload?.includePreviousDays;

		const startDate: Date | undefined =
			includeDate && includePreviousDays ? sub(includeDate, { days: includePreviousDays }) : undefined;

		let responseData: Order[];
		if (!includePreviousDays || includePreviousDays == 0) {
			const { data }: AxiosResponse<Order[]> = yield call(
				fetchOutboundOrders,
				selectedStoreId,
				includeCustomers,
				includeItems,
				includeDate,
			);
			responseData = data;
		} else {
			const { data }: AxiosResponse<Order[][]> = yield call(
				fetchOutboundOrdersMultipleDays,
				selectedStoreId,
				includeCustomers,
				includeItems,
				startDate,
				includeDate,
			);
			responseData = data.reduce((result, value) => result.concat(value), []);

			yield put(actionLastFetchedOutboundOrdersPreviousDaysSet(Date.now()));
		}

		const hasDetails = includeCustomers && includeItems;

		yield put(actionOutboundOrdersSet(responseData, hasDetails));

		yield put(actionLastFetchedOutboundOrdersSet(Date.now()));

		// Create action status success
		yield put(actionStatusAdd({ type: ACTION_OUTBOUND_ORDERS_FETCH }, ActionStatusEnum.SUCCESS));
	} catch (e) {
		// General error
		yield put(actionStatusAdd({ type: ACTION_OUTBOUND_ORDERS_FETCH }, ActionStatusEnum.ERROR));
	}
}

export function* outboundOrdersFetchSaga() {
	yield takeLatest(ACTION_OUTBOUND_ORDERS_FETCH, outboundOrdersFetchWorker);
}
