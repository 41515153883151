import React, { ReactElement, ReactNode } from 'react';

import { ListProps } from './list.type';

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
function List<I = Record<string, any>>({
	data,
	className,
	style,
	renderItem,
	keyExtractor,
}: ListProps<I>): ReactElement | null {
	const renderListItem = (item: I, index: number): ReactNode => {
		return <div key={keyExtractor(item, index)}>{renderItem(item, index)}</div>;
	};

	return (
		<div className={className} style={style}>
			{data.map(renderListItem)}
		</div>
	);
}

export { List };
