import React, { FC } from 'react';
import classnames from 'classnames';

import { BottomSheetProps } from './bottomSheet.type';
import './bottomSheet.style.scss';

const BottomSheet: FC<BottomSheetProps> = ({ children, className }: BottomSheetProps) => {
	return <div className={classnames('sheet', className)}>{children}</div>;
};

export { BottomSheet };
