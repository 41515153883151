import React, { CSSProperties, FC } from 'react';
import classNames from 'classnames';

import { Colors } from 'src/assets';
import { Icon } from 'src/components/icon/icon.component';

import { ListIndicatorProps } from './listIndicator.type';
import './listIndicator.style.scss';

const ListIndicator: FC<ListIndicatorProps> = ({
	message,
	color = Colors.brightBlue,
	icon,
	iconPlacement = 'after',
	className,
	style,
	...props
}: ListIndicatorProps) => {
	const indicatorStyles: CSSProperties = {
		backgroundColor: color,
	};

	return (
		<div className={classNames('indicator-wrapper', className)} style={style}>
			<button type="button" className={'indicator'} style={indicatorStyles} {...props}>
				{icon && (iconPlacement === 'before' || iconPlacement === 'both') ? (
					<Icon iconName={icon} size={13} color={Colors.purewhite} className={'indicator__icon'} />
				) : undefined}
				<p className="indicator__text">{message}</p>
				{icon && (iconPlacement === 'after' || iconPlacement === 'both') ? (
					<Icon iconName={icon} size={13} color={Colors.purewhite} className={'indicator__icon'} />
				) : undefined}
			</button>
		</div>
	);
};

export { ListIndicator };
