import { fork } from 'redux-saga/effects';

import { storesFetchSaga } from './storesFetch.saga';
import { storeSaveNotificationsSaga } from './storeSaveNotifications.saga';
import { storeSetStorageSaga } from './storeSetStorage.saga';
import { selectedStoreChangeSaga } from './selectedStoreChange.saga';

export function* storeSagas() {
	yield fork(storesFetchSaga);
	yield fork(storeSaveNotificationsSaga);
	yield fork(storeSetStorageSaga);
	yield fork(selectedStoreChangeSaga);
}
