import React from 'react';
import ReactSwitch, { ReactSwitchProps } from 'react-switch';

import { Colors } from 'src/assets';

export const Switch = ({
	checked = false,
	onChange = () => {},
	offColor = Colors.systemGrey,
	onColor = Colors.systemGreen,
	checkedIcon = false,
	uncheckedIcon = false,
	height = 31,
	width = 51,
	handleDiameter = 27,
	boxShadow = '0px 3px 8px rgba(0, 0, 0, 0.15), 0px 3px 1px rgba(0, 0, 0, 0.06)',
	...props
}: ReactSwitchProps) => (
	<ReactSwitch
		checked={checked}
		onChange={onChange}
		offColor={offColor}
		onColor={onColor}
		checkedIcon={checkedIcon}
		uncheckedIcon={uncheckedIcon}
		height={height}
		width={width}
		handleDiameter={handleDiameter}
		boxShadow={boxShadow}
		{...props}
	/>
);
