import { useContext } from 'react';

import { ModalContextProps, ModalContext } from 'src/providers/modal.provider';

/**
 * Custom hook that can be used by any component to show/hide modal
 */
const useModal = (): ModalContextProps => {
	const modal: ModalContextProps = useContext(ModalContext);

	return modal;
};

export { useModal };
