import { AxiosResponse } from 'axios';
import { call, CallEffect, put, PutEffect, takeEvery } from 'redux-saga/effects';

import { actionStatusAdd } from 'src/state/store/actionStatus/actionStatus.action';
import { ActionStatusEnum, ActionType } from 'src/state/store/actionStatus/actionStatus.type';
import { ACTION_CUSTOMER_BY_ORDER_ID_FETCH } from 'src/state/store/order/order.const';
import { fetchRequest, HttpMethod } from 'src/state/sagas/network/network.saga';
import { MooseApiRoutes } from 'src/config/api.config';
import { Customer, OrderCustomerFetchActionType } from 'src/state/store/order/order.type';
import { actionOrderCustomerSet } from 'src/state/store/order/order.action';
import { replaceColonPrefixed } from 'src/helpers/message.helpers';
import { customerMapper } from 'src/state/mappers/order/customer.mapper';
import { isOffline } from 'src/helpers/cache.helpers';
import { authorize } from 'src/state/sagas/auth/authorization.saga';

function* fetchCustomer(orderId: string): Generator<CallEffect, AxiosResponse, AxiosResponse> {
	return yield call(
		fetchRequest,
		{
			method: HttpMethod.Get,
			url: replaceColonPrefixed(MooseApiRoutes.Customer, {
				orderId,
			}),
		},
		[authorize],
		customerMapper,
	);
}

function* orderCustomerFetchWorker(
	action: ActionType<OrderCustomerFetchActionType>,
): Generator<CallEffect<any> | PutEffect, any, any> {
	// Create action status running
	try {
		if (!action.payload) {
			return;
		}

		const { orderId, dateKey } = action.payload;

		yield put(actionStatusAdd(action, ActionStatusEnum.RUNNING));

		const { data }: AxiosResponse<Customer> = yield call(fetchCustomer, orderId);

		yield put(actionOrderCustomerSet(orderId, data, dateKey));

		// Create action status success
		yield put(actionStatusAdd(action, ActionStatusEnum.SUCCESS));
	} catch (e) {
		// General error
		yield put(actionStatusAdd(action, ActionStatusEnum.ERROR, isOffline()));
	}
}

export function* orderCustomerFetchSaga() {
	yield takeEvery(ACTION_CUSTOMER_BY_ORDER_ID_FETCH, orderCustomerFetchWorker);
}
