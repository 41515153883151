import { isAfter, isBefore, closestTo, format } from 'date-fns';

import { AppLanguage } from 'src/state/store/settings/settings.type';

export const getHourAndMinuteString = (date: number | Date): string => {
	return format(date, 'HH:mm');
};

export const isAfterDateTime = (date: Date | number, dateToCompare: Date | number): boolean => {
	return isAfter(date, dateToCompare);
};

export const isBeforeDateTime = (date: Date | number, dateToCompare: Date | number): boolean => {
	return isBefore(date, dateToCompare);
};

export const getAbbreviationOfHour = (language: AppLanguage): string => {
	return language === 'nl' ? 'u' : 'h';
};

export const getClosestToDate = (dateToCompare: Date | number, dates: Array<Date | number>): Date | undefined => {
	return closestTo(dateToCompare, dates);
};

export const dateIsPassedMidnight = (dateToCompare: Date | number): boolean => {
	const midnight = new Date().setHours(0, 0, 0, 0);

	return isAfter(midnight, dateToCompare);
};
