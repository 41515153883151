import React, { ComponentType } from 'react';
import classNames from 'classnames';

import { WithBoxedContainerProps } from './withBoxedContainer.type';
import './withBoxedContainer.style.scss';

const withBoxedContainer =
	<P extends WithBoxedContainerProps>(Component: ComponentType<P>): React.FC<P> =>
	// eslint-disable-next-line react/display-name
	({ className, ...props }: WithBoxedContainerProps) => {
		return (
			<div className={classNames('boxed-container', className)}>
				<Component {...(props as P)} />
			</div>
		);
	};

export { withBoxedContainer };
