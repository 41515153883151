import { LastFetchedSetActionType } from 'src/state/store/cache/cache.type';
import { ActionType } from 'src/state/store/actionStatus/actionStatus.type';

import {
	ACTION_INBOUND_DELIVERIES_CLEAR,
	ACTION_INBOUND_DELIVERIES_FETCH,
	ACTION_INBOUND_DELIVERIES_SET,
	ACTION_INBOUND_DELIVERY_ACCEPT,
	ACTION_LAST_FETCHED_INBOUND_DELIVERIES_SET,
	ACTION_INBOUND_DELIVERIES_TO_RETURN_FETCH,
	ACTION_INBOUND_DELIVERIES_TO_RETURN_SET,
} from './inbound.const';
import {
	InboundDelivery,
	InboundDeliveriesSetActionType,
	InboundDeliveryAcceptActionType,
	InboundDeliveriesToReturnSetActionType,
} from './inbound.type';

/**
 * Action creator that will fetch all incoming deliveries
 *
 * @returns action creator to trigger saga
 */
export const actionInboundDeliveriesFetch = (): ActionType => {
	return {
		type: ACTION_INBOUND_DELIVERIES_FETCH,
	};
};

/**
 * Action creator that will fetch all deliveries that need to be returned
 *
 * @returns action creator to trigger saga
 */
export const actionInboundDeliveriesToReturnFetch = (): ActionType => {
	return {
		type: ACTION_INBOUND_DELIVERIES_TO_RETURN_FETCH,
	};
};

/**
 * @param  ordersToReturn 	number of orders to be returned
 * @returns action creator to update state
 */
export const actionInboundDeliveriesToReturnSet = (
	ordersToReturn: number,
): ActionType<InboundDeliveriesToReturnSetActionType> => {
	return {
		type: ACTION_INBOUND_DELIVERIES_TO_RETURN_SET,
		payload: { ordersToReturn },
	};
};

/**
 *
 * @param inboundDeliveries deliveries that are fetched by the API
 * @returns action creator to update state
 */
export const actionInboundDeliveriesSet = (
	inboundDeliveries: InboundDelivery[],
): ActionType<InboundDeliveriesSetActionType> => {
	return {
		type: ACTION_INBOUND_DELIVERIES_SET,
		payload: { inboundDeliveries },
	};
};

/**
 *
 * @param deliveryId selected delivery of inbound
 * @param comment comment that can be added by user
 * @returns acrtion creator to trigger saga
 */
export const actionInboundDeliveryAccept = (
	deliveryId: string,
	comment: string,
): ActionType<InboundDeliveryAcceptActionType> => {
	return {
		type: ACTION_INBOUND_DELIVERY_ACCEPT,
		payload: {
			deliveryId,
			comment,
		},
	};
};

/**
 *
 * @param inboundDeliveries deliveries that are fetched by the API
 * @returns action creator to update state
 */
export const actionInboundDeliveriesClear = (): ActionType => {
	return {
		type: ACTION_INBOUND_DELIVERIES_CLEAR,
	};
};

export const actionLastFetchedInboundDeliveriesSet = (lastFetched: number): ActionType<LastFetchedSetActionType> => {
	return {
		type: ACTION_LAST_FETCHED_INBOUND_DELIVERIES_SET,
		payload: { lastFetched },
	};
};
