import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { Camera, CameraResultType } from '@capacitor/camera';
import { useToasts } from 'react-toast-notifications';

import { processFormDataImages } from 'src/helpers/image.helper';
import { actionOutgoingHelpdeskRequest } from 'src/state/store/outgoing/outgoing.action';
import { selectOrderById } from 'src/state/store/order/order.selector';
import { Store } from 'src/state/store.type';
import { formatDateKeyForStore } from 'src/helpers/date.helpers';
import { Order } from 'src/state/store/order/order.type';
import { Icon } from 'src/components/icon/icon.component';
import { IconNames } from 'src/components/icon/icon.type';
import { Input } from 'src/components/input/input.component';
import { TextArea } from 'src/components/textArea/textArea.component';
import { IconButton } from 'src/components/button/iconButton/iconButton.component';
import { Colors } from 'src/assets';
import './orderHelpdeskContainer.style.scss';
import { ActionStatus, ActionStatusEnum } from 'src/state/store/actionStatus/actionStatus.type';
import { useActionStatus } from 'src/hooks/useActionStatus.hook';
import { ACTION_OUTGOING_HELPDESK_REQUEST } from 'src/state/store/outgoing/outgoing.const';

import { OrderHelpdeskContainerProps } from './orderHelpdeskContainer.type';

const OrderHelpdeskContainer: FC<OrderHelpdeskContainerProps> = ({
	orderId,
	onNavigateBack,
	currentDateKey,
}: OrderHelpdeskContainerProps) => {
	const order: Order | undefined = useSelector((state: Store) =>
		selectOrderById(state, orderId, formatDateKeyForStore(currentDateKey)),
	);

	const { t } = useTranslation();

	const dispatch = useDispatch();

	const { addToast } = useToasts();

	const helpdeskAction: ActionStatus | undefined = useActionStatus({
		type: ACTION_OUTGOING_HELPDESK_REQUEST,
	});

	const [images, setImages] = useState<string[]>([]);

	const FormSchema = Yup.object().shape({
		email: Yup.string().email(t('outgoingHelpdesk.emailError')).required(t('outgoingHelpdesk.requiredError')),
		subject: Yup.string().required(t('outgoingHelpdesk.requiredError')),
		message: Yup.string().required(t('outgoingHelpdesk.requiredError')),
		images: Yup.array().notRequired(),
	});

	const formik = useFormik<{
		orderUuid?: string;
		email: string;
		subject: string;
		message: string;
	}>({
		initialValues: {
			orderUuid: order?.id,
			email: '',
			subject: '',
			message: '',
		},
		enableReinitialize: true,
		validationSchema: FormSchema,
		onSubmit: (values) => {
			processFormDataImages(images)
				.then((formdata) => {
					if (values.orderUuid) {
						formdata.append('orderUuid', values.orderUuid);
						formdata.append('email', values.email);
						formdata.append('subject', values.subject);
						formdata.append('message', values.message);

						dispatch(actionOutgoingHelpdeskRequest(values.orderUuid, formdata));
					}
				})
				.catch((error) => {
					console.log(error);
				});
		},
	});

	useEffect(() => {
		if (!formik.isSubmitting) {
			return;
		}
		if (helpdeskAction && helpdeskAction.isOffline) {
			addToast(t('offlineWarningModal.description'), {
				appearance: 'warning',
			});
			onNavigateBack();
		} else if (helpdeskAction && helpdeskAction.status) {
			switch (helpdeskAction.status) {
				case ActionStatusEnum.SUCCESS:
					addToast(t('outgoingHelpdesk.successMessage', { orderId: order?.clientOrderId }), {
						appearance: 'success',
					});
					onNavigateBack();
					break;
				case ActionStatusEnum.ERROR:
					addToast(t('outgoingHelpdesk.errorMessage', { orderId: order?.clientOrderId }), {
						appearance: 'error',
					});
			}
		}
	}, [helpdeskAction]);

	const takePicture = async () => {
		try {
			if (images.length >= 5) {
				return;
			}

			const image = await Camera.getPhoto({
				quality: 60,
				allowEditing: false,
				saveToGallery: false,
				resultType: CameraResultType.DataUrl,
				width: 600,
				height: 600,
			});

			setImages((oldImages) => {
				if (image.dataUrl) {
					return [...oldImages, image.dataUrl];
				}

				return oldImages;
			});
		} catch (error) {
			console.log(error);
		}
	};

	const deletePicture = (index: number) => {
		if (images.length >= 0) {
			setImages((oldImages) => oldImages.filter((i) => i !== images[index]));
		}
	};

	return (
		<>
			<div className="outgoing-change-status">
				<Icon className="helpdesk__icon" iconName={IconNames.Support} size={70} />

				<h1>{t('outgoingHelpdesk.title')}</h1>

				<div className="helpdesk__info">
					<h2>{t('orderDetail.title', { orderId: order?.clientOrderId })}</h2>

					<span className="info__name">
						{order?.customer?.lastname} {order?.customer?.firstname}
					</span>
				</div>

				<form id="helpdesk-form" className="helpdesk__form" onSubmit={formik.handleSubmit}>
					<Input
						placeholder={t('outgoingHelpdesk.email')}
						fullWidth={true}
						id="email"
						name="email"
						type="email"
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
						value={formik.values.email}
						error={formik.errors.email}
						touched={formik.touched.email}
					/>
					<Input
						placeholder={t('outgoingHelpdesk.subject')}
						fullWidth={true}
						id="subject"
						name="subject"
						type="text"
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
						value={formik.values.subject}
						error={formik.errors.subject}
						touched={formik.touched.subject}
					/>
					<TextArea
						placeholder={t('outgoingHelpdesk.message')}
						id="message"
						name="message"
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
						value={formik.values.message}
						rows={10}
						error={formik.errors.message}
						touched={formik.touched.message}
					/>

					<div className="picture-preview">
						<div className="picture-preview__list">
							{images.length >= 0 &&
								images.map((value) => {
									const index = images.indexOf(value);

									return (
										<div key={index} className="picture-preview__list__item">
											<img
												src={images[index]}
												alt="uploaded image"
												className=" picture-preview__list__item__thumb"
											/>
											<p className="picture-preview__list__item__text">
												{t('outgoingHelpdesk.picture')} {index + 1}
											</p>
											<IconButton
												id="delete"
												type="button"
												className="picture-preview__list__item__delete"
												onClick={() => {
													deletePicture(index);
												}}
												iconName={IconNames.Trash}
											/>
										</div>
									);
								})}
							{images.length <= 4 && (
								<div className="picture-preview__list__item add" onClick={takePicture}>
									<Icon iconName={IconNames.ImagePlus} size={40} />
									<p className="picture-preview__list__item__text">
										{images.length === 0
											? `${t('outgoingHelpdesk.pictureAdd')}`
											: `${t('outgoingHelpdesk.pictureAddMore')}`}
									</p>
								</div>
							)}
						</div>
					</div>

					<IconButton
						form="helpdesk-form"
						type="submit"
						fullWidth={true}
						className="button"
						backgroundColor={Colors.green}
						iconName={IconNames.Check}
						size={15}
						color="white"
						disabled={!formik.isValid}>
						{t('outgoingHelpdesk.submit')}
					</IconButton>
				</form>
			</div>
		</>
	);
};

export { OrderHelpdeskContainer };
