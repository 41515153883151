import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Colors } from 'src/assets';
import { DraggableBottomSheet } from 'src/components/draggableBottomSheet/draggableBottomSheet.component';
import { PrimaryButton } from 'src/components/button/primaryButton/primaryButton.component';
import { Icon } from 'src/components/icon/icon.component';
import { IconNames, IconSizes } from 'src/components/icon/icon.type';
import './offlineWarning.style.scss';

import { OfflineWarningModalProps } from './offlineWarning.type';

const OfflineWarningModal: FC<OfflineWarningModalProps> = ({ onDismiss, ...props }: OfflineWarningModalProps) => {
	const { t } = useTranslation();

	return (
		<DraggableBottomSheet onDismiss={onDismiss} {...props}>
			<div className="offline-warning">
				<Icon
					className={'offline-warning__icon'}
					color={Colors.nightBlue}
					iconName={IconNames.Warning}
					size={IconSizes.Huge}
				/>

				<p className="offline-warning__description">{t('offlineWarningModal.description')}</p>
				<PrimaryButton onClick={onDismiss}>{t('offlineWarningModal.button')}</PrimaryButton>
			</div>
		</DraggableBottomSheet>
	);
};

export default OfflineWarningModal;
