/**
 * Replace values in a string value that start with :
 *
 * @param msg
 * @param data
 */
export const replaceColonPrefixed = (msg: string, data: Record<string, string | number | string[]>): string => {
	for (const key of Object.keys(data)) {
		const replaceObj = data[key];
		let replaceMsg: string;

		switch (typeof replaceObj) {
			case 'number':
				replaceMsg = replaceObj.toString(10);
				break;
			case 'string':
				replaceMsg = replaceObj;
				break;
			case 'object':
				replaceMsg = replaceObj.reduce((previousValue, currentValue) => {
					return `${previousValue}, ${currentValue}`;
				});
				break;
		}

		msg = msg.replace(`:${key}`, replaceMsg);
	}

	return msg;
};
