import React, { CSSProperties, ReactNode } from 'react';
import { ListOnScrollProps } from 'react-window';

export enum ListItemType {
	Section = 'section',
	Item = 'item',
}

export interface GenericListProps<I extends Record<string, any>> {
	renderItem: (item: I, index: number, style?: CSSProperties) => ReactNode;
	className?: string;
	style?: CSSProperties;
}

export interface ListProps<I extends Record<string, any>> extends GenericListProps<I> {
	data: I[];
	keyExtractor: (item: I, index: number) => string;
}

export interface VirtualizedListProps<I extends Record<string, any>> extends GenericListProps<I> {
	rowHeight: number | ((index: number) => number);
	initialScrollOffset?: number;
	itemMargin?: number;
	listMargin?: {
		top?: number;
		bottom?: number;
		left?: number;
		right?: number;
	};
	outerRef?: React.Ref<any>;
	onScroll?: (props: ListOnScrollProps) => any;
	className?: string;
}
