import React, { ChangeEvent, CSSProperties, useState } from 'react';
import classNames from 'classnames';

import { fullWidthInputStyle, errorInputStyle } from './input.const';
import { InputProps } from './input.type';
import './input.scss';

const Input = ({ fullWidth, style, children, placeholder, className, value, error, touched, ...props }: InputProps) => {
	const [isActive, setIsActive] = useState<boolean>(false);

	const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
		const { target } = event;

		setIsActive(target.value !== '');

		if (props.onChange) {
			props.onChange(event);
		}
	};
	const inputStyles: CSSProperties = { ...(fullWidth && fullWidthInputStyle), ...style };

	const errorStyles: CSSProperties = error && touched ? { ...errorInputStyle } : {};

	return (
		<div className={classNames('input', className)} style={inputStyles}>
			{/* eslint-disable-next-line react/jsx-props-no-spreading */}
			<input {...props} style={errorStyles} value={value} onChange={handleChange}>
				{children}
			</input>
			<label className={classNames({ active: isActive, 'has-value': value !== '' })}>{placeholder}</label>
			{error && touched ? <span className="error">{error}</span> : null}
		</div>
	);
};

export { Input };
