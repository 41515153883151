import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { DraggableBottomSheet } from 'src/components/draggableBottomSheet/draggableBottomSheet.component';
import { Icon } from 'src/components/icon/icon.component';
import { IconNames } from 'src/components/icon/icon.type';
import { Input } from 'src/components/input/input.component';
import { Colors } from 'src/assets';
import { PrimaryButton } from 'src/components/button/primaryButton/primaryButton.component';

import { ForceCloseBottomSheetProps } from './forceClose.type';
import './forceClose.style.scss';

const ForceCloseBottomSheet: FC<ForceCloseBottomSheetProps> = ({
	onDismiss,
	onSubmit,
	...props
}: ForceCloseBottomSheetProps) => {
	const { t } = useTranslation();

	const FormSchema = Yup.object().shape({
		numberOfTotes: Yup.string().required(t('forceCloseModal.required')),
	});
	const formik = useFormik({
		initialValues: {
			numberOfTotes: 0,
		},
		validationSchema: FormSchema,
		onSubmit: (values) => {
			onSubmit(values.numberOfTotes);
		},
	});

	return (
		<DraggableBottomSheet onDismiss={onDismiss} {...props}>
			<div className="force-close">
				<Icon className="force-close__icon" iconName={IconNames.Groceries} size={70} />

				<h1>{t('forceCloseModal.title')}</h1>

				<p>{t('forceCloseModal.description')}</p>

				<form id="force-close-form" className="force-close__form" onSubmit={formik.handleSubmit}>
					<Input
						placeholder={t('forceCloseModal.numberOfTotes')}
						fullWidth={true}
						id="numberOfTotes"
						name="numberOfTotes"
						type="number"
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
						value={formik.values.numberOfTotes}
						error={formik.errors.numberOfTotes}
						touched={formik.touched.numberOfTotes}
					/>

					<PrimaryButton
						disabled={!!formik.errors.numberOfTotes}
						type="submit"
						backgroundColor={Colors.green}>
						{t('forceCloseModal.forceClose')}
					</PrimaryButton>
				</form>

				<div className="force-close__buttons">
					<PrimaryButton backgroundColor={Colors.lightBlue} color={Colors.brightBlue} onClick={onDismiss}>
						{t('outgoingCancel.discard')}
					</PrimaryButton>
				</div>
			</div>
		</DraggableBottomSheet>
	);
};

export { ForceCloseBottomSheet };
