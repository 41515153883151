import { all, call, CallEffect, put, select, SelectEffect, takeLatest } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';

import { MooseApiRoutes } from 'src/config/api.config';
import { replaceColonPrefixed } from 'src/helpers/message.helpers';
import {
	LinkMethod,
	Pally,
	ScannedStorageItem,
	StorageItemKey,
	StorageLinkAddActionType,
	StorageSlot,
} from 'src/state/store/storage/storage.type';
import { authorize } from 'src/state/sagas/auth/authorization.saga';
import { fetchRequest, HttpMethod } from 'src/state/sagas/network/network.saga';
import { actionStatusAdd } from 'src/state/store/actionStatus/actionStatus.action';
import { ACTION_STORAGE_LINK } from 'src/state/store/storage/storage.const';
import { ActionStatusEnum, ActionType } from 'src/state/store/actionStatus/actionStatus.type';
import { selectSelectedStoreId } from 'src/state/store/carrefourStore/carrefourStore.selector';
import { selectPallies, selectStorageSlots } from 'src/state/store/storage/storage.selector';
import { createdItemMapper } from 'src/state/mappers/generic/createdItem.mapper';
import { actionStorageOverviewFetch } from 'src/state/store/storage/storage.action';

function* createStorageSlot(
	storeId: string,
	code: number,
	label?: string,
): Generator<CallEffect, AxiosResponse, AxiosResponse> {
	return yield call(
		fetchRequest,
		{
			method: HttpMethod.Post,
			url: replaceColonPrefixed(MooseApiRoutes.StorageCreateStorageSlot, {
				storeUuid: storeId,
			}),
			data: { code, label },
		},
		[authorize],
		createdItemMapper,
	);
}

function* createPally(
	storeId: string,
	code: number,
	label?: string,
): Generator<CallEffect, AxiosResponse, AxiosResponse> {
	return yield call(
		fetchRequest,
		{
			method: HttpMethod.Post,
			url: replaceColonPrefixed(MooseApiRoutes.StorageCreatePally, {
				storeUuid: storeId,
			}),
			data: { code, label },
		},
		[authorize],
		createdItemMapper,
	);
}

function* linkStorages(
	storeUuid: string,
	link: boolean,
	scannedItems: ScannedStorageItem[],
	method = LinkMethod.EXTEND,
): Generator<CallEffect, AxiosResponse, AxiosResponse> {
	const mappedIds = scannedItems.reduce((acc: Record<string, string | number>, scannedItem: ScannedStorageItem) => {
		switch (scannedItem.type) {
			case StorageItemKey.ORDER: {
				acc = { ...acc, orderId: scannedItem.data };
				break;
			}

			case StorageItemKey.PALLY: {
				acc = { ...acc, pallyId: scannedItem.data };
				break;
			}
			case StorageItemKey.STORAGE_SLOT: {
				acc = { ...acc, storageSlotId: scannedItem.data };
				break;
			}
		}

		return acc;
	}, {});

	return yield call(
		fetchRequest,
		{
			method: HttpMethod.Post,
			url: replaceColonPrefixed(MooseApiRoutes.StorageLink, { storeUuid }),
			data: {
				link,
				method,
				...mappedIds,
			},
		},
		[authorize],
	);
}

function* checkItemExistence(type: StorageItemKey, code: string | number): Generator {
	if (type === StorageItemKey.ORDER || typeof code === 'string') return;

	let storageSlots = (yield select(selectStorageSlots)) as StorageSlot[];

	if (type === StorageItemKey.STORAGE_SLOT) {
		return !!storageSlots.find((item) => item.code === code);
	}

	if (type === StorageItemKey.PALLY) {
		let pallies = (yield select(selectPallies)) as Pally[];

		return (
			!!storageSlots.find((s) => s.pallies.find((p) => p.code === code)) ||
			!!pallies.find((item) => item.code === code)
		);
	}
}

function* findStorageSlotId(code: number): Generator<SelectEffect, string, StorageSlot[]> {
	const storageSlots = yield select(selectStorageSlots);

	return storageSlots.find((slot) => slot.code === code)!.id;
}

function* findPallyId(code: number): Generator<SelectEffect, string, Pally[]> {
	const storageSlots = (yield select(selectStorageSlots)) as StorageSlot[];
	const pallies = yield select(selectPallies);

	const storageSlotPallies = storageSlots.map((s) => s.pallies).reduce((acc, val) => acc.concat(val));

	return pallies.concat(storageSlotPallies).find((p) => p.code === code)!.id;
}

function* linkStorageWorker(action: ActionType<StorageLinkAddActionType>) {
	yield put(actionStatusAdd({ type: ACTION_STORAGE_LINK }, ActionStatusEnum.RUNNING));

	try {
		const selectedCarrefourStore: string | undefined = yield select(selectSelectedStoreId);

		if (!selectedCarrefourStore || !action.payload) {
			return;
		}

		const { link, scannedItems, method } = action.payload;

		// If there are less or more than 2 scanned items, stop saga
		if (scannedItems.length !== 2) {
			return;
		}

		const itemsToLink: ScannedStorageItem[] = [];
		if (link) {
			// Check if scannedItems exist
			const itemsToCreate: Array<ScannedStorageItem | null> = [];
			for (let scannedItem of scannedItems) {
				const exists: boolean = yield call(checkItemExistence, scannedItem.type, scannedItem.data);
				if (exists) {
					itemsToCreate.push(null);
				} else {
					itemsToCreate.push(scannedItem);
				}
			}

			// Create non-existing items
			const [firstResponse, secondResponse] = yield all(
				itemsToCreate.map((item) => {
					if (!item) return null;

					if (item.type === StorageItemKey.STORAGE_SLOT) {
						return call(createStorageSlot, selectedCarrefourStore, item.data as number);
					}

					if (item.type === StorageItemKey.PALLY) {
						return call(createPally, selectedCarrefourStore, item.data as number);
					}
				}),
			);

			// Get all scanned items with id, ready to link
			if (firstResponse?.data) {
				itemsToLink.push({ type: itemsToCreate[0]!.type, data: firstResponse?.data });
			}
			if (secondResponse?.data) {
				itemsToLink.push({ type: itemsToCreate[1]!.type, data: secondResponse?.data });
			}
		}

		const alreadyLinkedTypes = itemsToLink.map((item) => item.type);

		if (!alreadyLinkedTypes.includes(StorageItemKey.STORAGE_SLOT)) {
			const storageSlotScannedItem = scannedItems.find((item) => item.type === StorageItemKey.STORAGE_SLOT);
			if (storageSlotScannedItem) {
				itemsToLink.push({
					...storageSlotScannedItem,
					data: yield findStorageSlotId(storageSlotScannedItem.data as number),
				});
			}
		}

		if (!alreadyLinkedTypes.includes(StorageItemKey.PALLY)) {
			const pallyScannedItem = scannedItems.find((item) => item.type === StorageItemKey.PALLY);
			if (pallyScannedItem) {
				itemsToLink.push({
					...pallyScannedItem,
					data: yield findPallyId(pallyScannedItem.data as number),
				});
			}
		}

		const orderScannedItem = scannedItems.find((item) => item.type === StorageItemKey.ORDER);
		if (orderScannedItem) {
			itemsToLink.push(orderScannedItem);
		}

		// Link storage items API call
		yield call(linkStorages, selectedCarrefourStore, link, itemsToLink, method);

		yield put(actionStorageOverviewFetch());

		yield put(actionStatusAdd({ type: ACTION_STORAGE_LINK }, ActionStatusEnum.SUCCESS));
	} catch (error) {
		yield put(actionStatusAdd({ type: ACTION_STORAGE_LINK }, ActionStatusEnum.ERROR));
	}
}

export function* storageLinkSaga() {
	yield takeLatest(ACTION_STORAGE_LINK, linkStorageWorker);
}
