import { HoneywellBarcodeReader } from './honeywellBarcodeReader.model';
import { BarcodeReaderResult } from './honeywellSDK.type';

export class HoneywellFactory {
	static hasHoneywellScanner: boolean = false;
	static barcodeScanner: HoneywellBarcodeReader | null;
	static errorWhileCreating: BarcodeReaderResult | undefined;

	static async build(readerName: string = 'dcs.scanner.imager'): Promise<void> {
		const BarcodeReaderSDK = (window as any)['BarcodeReader'];

		return new Promise((resolve, reject) => {
			if (!BarcodeReaderSDK) {
				HoneywellFactory.barcodeScanner = null;
				HoneywellFactory.hasHoneywellScanner = false;

				return resolve();
			}

			if (HoneywellFactory.barcodeScanner) {
				return resolve();
			}

			const scanner = new BarcodeReaderSDK(readerName, (result: BarcodeReaderResult) => {
				if (result.status === 0) {
					HoneywellFactory.barcodeScanner = new HoneywellBarcodeReader(scanner.barcodeReader);
					HoneywellFactory.hasHoneywellScanner = !!HoneywellFactory.barcodeScanner;
				} else {
					console.error('Failed to create Honeywell barcode instance ', result);
					HoneywellFactory.errorWhileCreating = result;

					return reject(result);
				}

				return resolve();
			});
		});
	}

	static destroy() {
		if (HoneywellFactory.barcodeScanner) {
			HoneywellFactory.barcodeScanner.close((result: BarcodeReaderResult) => {
				if (result.status === 0) {
					HoneywellFactory.barcodeScanner = null;
				}
			});
		}
	}
}
