import { AxiosResponse } from 'axios';
import { call, CallEffect, put, PutEffect } from 'redux-saga/effects';

import { getMSALAccessToken, getMSALUserMetaDataToken } from 'src/helpers/auth.helper';
import { ExtendedAxiosRequestConfig, TransformedNetworkHelper } from 'src/state/sagas/network/network.saga';
import { actionSelectedStoreRemove } from 'src/state/store/carrefourStore/carrefourStore.action';
import { actionUserNotAuthorizedReasonSet } from 'src/state/store/user/user.action';
import { AuthorizationErrorCodes } from 'src/state/store/user/user.type';

/**
 * Helper function to handle authorization on network requests.
 * Adds authorization header
 *
 * @param request An axios request.
 * @param next The next helper to execute.
 * @returns returns an axios response.
 */
export function* authorize(
	request: ExtendedAxiosRequestConfig,
	next: TransformedNetworkHelper,
): Generator<CallEffect<any> | PutEffect, AxiosResponse | undefined, AxiosResponse | undefined> {
	// check if token status is running and wait until other call is finished
	const token: string | null | undefined = getMSALAccessToken();
	const metaToken: string | null | undefined = getMSALUserMetaDataToken();
	const baseSubscriptionKey = process.env.REACT_APP_BASE_SUBSCRIPTION_KEY;

	if (token) {
		// Add authorization header
		if (!request.headers) {
			request.headers = {};
		}

		request.headers.Authorization = 'Bearer ' + token;

		if (metaToken) {
			request.headers['X-User-Meta-Token'] = metaToken;
		}

		if (baseSubscriptionKey) {
			request.headers['Ocp-Apim-Subscription-Key'] = baseSubscriptionKey;
		}

		try {
			return yield call(next, request);
		} catch (e) {
			if (
				e?.response?.status?.toString() === '401' &&
				e?.response?.data?.extraInfo?.reason === AuthorizationErrorCodes.INVALID_ROLE
			) {
				yield put(actionUserNotAuthorizedReasonSet(AuthorizationErrorCodes.INVALID_ROLE));
			}

			// clear selected store when invalid store
			if (
				e?.response?.status?.toString() === '403' &&
				e?.response?.data?.extraInfo?.reason === AuthorizationErrorCodes.INVALID_STORE
			) {
				yield put(actionUserNotAuthorizedReasonSet(AuthorizationErrorCodes.INVALID_STORE));
				yield put(actionSelectedStoreRemove());
			}

			throw e;
		}
	}
}
