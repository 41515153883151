import { ApiCarrefourStore, CarrefourStore } from 'src/state/store/carrefourStore/carrefourStore.type';
import { Mapper } from 'src/state/mappers/mapper.type';

class CarrefourStoreMapper extends Mapper<ApiCarrefourStore, CarrefourStore> {
	map(data: ApiCarrefourStore): CarrefourStore {
		if (!data.id || !data.locationReferenceId) {
			return this.throwInvalid();
		}

		return {
			...data,
		};
	}
}

export const carrefourStoreMapper = new CarrefourStoreMapper();
