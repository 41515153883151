import React, { ChangeEvent, FC, KeyboardEvent, useState } from 'react';
import classNames from 'classnames';

import { ListItem } from 'src/components/list/listItem/listItem.component';
import { Icon } from 'src/components/icon/icon.component';
import { IconNames, IconSizes } from 'src/components/icon/icon.type';
import { Colors } from 'src/assets';

import { InputAddListItemProps } from './inputAddListItem.type';
import './inputAddListItem.style.scss';

const InputAddListItem: FC<InputAddListItemProps> = ({
	type = 'text',
	placeholder = 's',
	regex,
	onAdd,
	className,
	disabled,
}: InputAddListItemProps) => {
	const [value, setValue] = useState<string>('');
	const [valid, setValid] = useState<boolean>(false);

	const onChange = (event: ChangeEvent<HTMLInputElement>) => {
		const { value } = event.target;

		setValue(value);
		setValid(regex ? !!value.match(regex) : !!value.length);
	};

	const onKeyDown = (event: KeyboardEvent) => {
		if (event.key?.toLowerCase() === 'enter') {
			add();
		}
	};

	const add = () => {
		if (valid && !disabled) {
			onAdd(value.toLowerCase());

			setValue('');
			setValid(false);
		}
	};

	return (
		<ListItem className={classNames('input-add-list-item', className)} hasChevron={false}>
			<input
				className="content__input"
				type={type}
				placeholder={placeholder}
				value={value}
				disabled={disabled}
				onChange={onChange}
				onKeyDown={onKeyDown}
			/>

			<div className="input-add__icon-wrapper" onClick={add}>
				<Icon
					iconName={IconNames.Plus}
					color={valid && !disabled ? Colors.brightBlue : Colors.lightGrey}
					size={IconSizes.Normal}
				/>
			</div>
		</ListItem>
	);
};

export { InputAddListItem };
