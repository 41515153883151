export interface OrderDetailContainerProps {
	currentDateKey?: string;
	orderId?: string;
	forceOpen?: boolean;
	onNavigateToTotesScanner: () => void;
	onNavigateToOutgoingOverview: () => void;
	onNavigateToHelpdesk: () => void;
	onNavigateToRefund: () => void;
}

export type DisplayOrderItem = {
	id: string;
	name: string;
	quantity: number;
	swapped?: boolean;
	rowType: DisplayOrderItemType;
};

export enum DisplayOrderItemType {
	SWAPPED = 'orderDetail.swapped',
	UNAVAILABLE = 'orderDetail.unavailable',
	INCLUDED = 'orderDetail.included',
}
