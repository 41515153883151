import React, { FC } from 'react';
import { Redirect, Route } from 'react-router-dom';

import { RootPages } from 'src/navigation/router.type';

import { PrivateRouteProps } from './privateRoute.type';

/**
 * A wrapper for <Route> that redirects to the login screen if you're not yet authenticated.
 */
const PrivateRoute: FC<PrivateRouteProps> = ({ isLoggedIn = false, children, ...props }: PrivateRouteProps) => {
	return (
		<Route
			{...props}
			render={({ location }) =>
				isLoggedIn ? (
					children
				) : (
					<Redirect
						to={{
							pathname: RootPages.StoreSelection,
							state: { from: location },
						}}
					/>
				)
			}
		/>
	);
};

export { PrivateRoute };
