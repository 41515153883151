import React, { FC, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import isEqual from 'lodash.isequal';

import { ListItem } from 'src/components/list/listItem/listItem.component';
import { SettingsList } from 'src/components/list/settingsList/settingsList.component';
import { Switch } from 'src/components/switch/switch.component';
import { InputAddListItem } from 'src/components/list/listItem/inputAddListItem/inputAddListItem.component';
import { EMAIL_REGEX } from 'src/helpers/email.helpers';
import { Icon } from 'src/components/icon/icon.component';
import { IconNames, IconSizes } from 'src/components/icon/icon.type';
import { Colors } from 'src/assets';
import { selectSelectedStoreEmails } from 'src/state/store/carrefourStore/carrefourStore.selector';
import {
	actionCarrefourStoreSetNotifications,
	actionCarrefourStoresFetch,
} from 'src/state/store/carrefourStore/carrefourStore.action';
import { NotificationType } from 'src/state/store/carrefourStore/carrefourStore.type';

import './emailSettingsContainer.style.scss';

const EmailSettingsContainer: FC = () => {
	const { t } = useTranslation();
	const dispatch = useDispatch();

	const initialEmailAddresses: string[] = useSelector(selectSelectedStoreEmails);

	const [enableMails, setEnableMails] = useState<boolean>(!!initialEmailAddresses?.length);
	const [emailAddresses, setEmailAddresses] = useState<string[]>([...initialEmailAddresses]);
	const emailAddressesRef = useRef(emailAddresses);
	const enableMailsRef = useRef(enableMails);

	useEffect(() => {
		dispatch(actionCarrefourStoresFetch());

		return () => {
			const emails = !enableMailsRef.current ? [] : emailAddressesRef.current;

			if (!isEqual(initialEmailAddresses, emails)) {
				// if there are changes, save them
				dispatch(actionCarrefourStoreSetNotifications(NotificationType.Email, emails));
			}
		};
	}, []);

	useEffect(() => {
		emailAddressesRef.current = emailAddresses;
	}, [emailAddresses]);

	useEffect(() => {
		enableMailsRef.current = enableMails;
	}, [enableMails]);

	const addEmail = (email: string) => {
		if (email && !emailAddresses.some((e) => e === email)) {
			setEmailAddresses([...emailAddresses, email]);
		}
	};

	const removeEmail = (email: string) => {
		setEmailAddresses(emailAddresses.filter((emailAddress) => emailAddress !== email));
	};

	const emailListItems = emailAddresses.map((email) => (
		<ListItem key={email} hasChevron={false}>
			<span className="content__label">{email}</span>

			<div className="icon-wrapper" onClick={() => removeEmail(email)}>
				<Icon iconName={IconNames.Minus} color={Colors.red} size={IconSizes.Normal} />
			</div>
		</ListItem>
	));

	return (
		<div className="email-settings-container">
			<SettingsList>
				<ListItem key="allow" hasChevron={false}>
					<span className="content__label">{t('emailSettings.enable')}</span>

					<Switch checked={enableMails} onChange={setEnableMails} />
				</ListItem>
				<InputAddListItem
					key="email-input"
					type="email"
					regex={EMAIL_REGEX}
					onAdd={addEmail}
					disabled={!enableMails}
					placeholder={t('emailSettings.placeholder')}
				/>
				{emailListItems}
			</SettingsList>
		</div>
	);
};

export { EmailSettingsContainer };
