import { AxiosResponse } from 'axios';
import { call, CallEffect, put, takeLatest, select } from 'redux-saga/effects';

import { actionStatusAdd } from 'src/state/store/actionStatus/actionStatus.action';
import { ActionStatusEnum } from 'src/state/store/actionStatus/actionStatus.type';
import { fetchRequest } from 'src/state/sagas/network/network.saga';
import { MooseApiRoutes } from 'src/config/api.config';
import { InboundDelivery } from 'src/state/store/inbound/inbound.type';
import {
	actionInboundDeliveriesSet,
	actionLastFetchedInboundDeliveriesSet,
} from 'src/state/store/inbound/inbound.action';
import { ACTION_INBOUND_DELIVERIES_FETCH } from 'src/state/store/inbound/inbound.const';
import { selectSelectedStoreId } from 'src/state/store/carrefourStore/carrefourStore.selector';
import { inboundDeliveriesMapper } from 'src/state/mappers/inbound/inbound.mapper';
import { replaceColonPrefixed } from 'src/helpers/message.helpers';
import { networkFirstHelper } from 'src/state/sagas/network/networkFirst.saga';
import { authorize } from 'src/state/sagas/auth/authorization.saga';

function* fetchInboundDeliveries(storeId: string): Generator<CallEffect<any>, AxiosResponse, any> {
	return yield call(
		fetchRequest,
		{ url: replaceColonPrefixed(MooseApiRoutes.Inbound, { storeId }) },
		[networkFirstHelper(MooseApiRoutes.Inbound), authorize],
		inboundDeliveriesMapper,
	);
}

function* inboundFetchWorker() {
	// Create action status running
	yield put(actionStatusAdd({ type: ACTION_INBOUND_DELIVERIES_FETCH }, ActionStatusEnum.RUNNING));

	try {
		const selectedStoreId: string | undefined = yield select(selectSelectedStoreId);

		if (!selectedStoreId) {
			return;
		}

		const { data }: AxiosResponse<InboundDelivery[]> = yield call(fetchInboundDeliveries, selectedStoreId);

		yield put(actionInboundDeliveriesSet(data));

		yield put(actionLastFetchedInboundDeliveriesSet(Date.now()));

		// Create action status success
		yield put(actionStatusAdd({ type: ACTION_INBOUND_DELIVERIES_FETCH }, ActionStatusEnum.SUCCESS));
	} catch (e) {
		// General error
		yield put(actionStatusAdd({ type: ACTION_INBOUND_DELIVERIES_FETCH }, ActionStatusEnum.ERROR));
	}
}

export function* inboundDeliveriesFetchSaga() {
	yield takeLatest(ACTION_INBOUND_DELIVERIES_FETCH, inboundFetchWorker);
}
