import React, { CSSProperties, FC } from 'react';
import classnames from 'classnames';

import { Icon } from 'src/components/icon/icon.component';
import { IconNames } from 'src/components/icon/icon.type';

import { OrderStatusLabelProps, OrderStatusLabelStatus } from './orderStatusLabel.type';
import { getBackgroundColorOfStatus } from './orderStatusLabel.helper';
import './orderStatusLabel.style.scss';

const OrderStatusLabel: FC<OrderStatusLabelProps> = ({
	label,
	className,
	status = OrderStatusLabelStatus.OPEN,
	style,
	background,
}: OrderStatusLabelProps) => {
	const orderStatusLabelStyle: CSSProperties = {
		...(status && getBackgroundColorOfStatus(status)),
		...(background && { backgroundColor: background }),
		...style,
	};

	return (
		<div className={classnames('order-status-label', className)} style={orderStatusLabelStyle}>
			{status === OrderStatusLabelStatus.COMPLETED ? (
				<div className={'icon-container'}>
					<Icon iconName={IconNames.CheckedCircle} className={'icon-container__icon'} size={20} />
				</div>
			) : null}
			{label}
		</div>
	);
};

export { OrderStatusLabel };
