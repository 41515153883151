/**
 * Flattens array
 *
 * @example `[0, 1, 2, [3, 4]]` becomes `[0, 1, 2, 3, 4]`
 *
 * @param arr
 * @param d
 */
export const flattenArray = (arr: any[], d = Infinity): any[] => {
	return d > 0
		? arr.reduce((acc, val) => acc.concat(Array.isArray(val) ? flattenArray(val, d - 1) : val), [])
		: arr.slice();
};
