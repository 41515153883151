import { Mapper } from 'src/state/mappers/mapper.type';
import { ApiInbound, InboundDelivery } from 'src/state/store/inbound/inbound.type';

export class InboundMapper extends Mapper {
	map = (data: ApiInbound): InboundDelivery => {
		if (!data || !data.id) {
			return this.throwInvalid();
		}

		return {
			...data,
			datetime: Date.parse(data.datetime),
		};
	};
}

export const inboundDeliveriesMapper = new InboundMapper();
