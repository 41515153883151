import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { DraggableBottomSheet } from 'src/components/draggableBottomSheet/draggableBottomSheet.component';
import { Icon } from 'src/components/icon/icon.component';
import { IconNames, IconSizes } from 'src/components/icon/icon.type';
import { PrimaryButton } from 'src/components/button/primaryButton/primaryButton.component';
import { Colors } from 'src/assets';
import { StorageItemKey } from 'src/state/store/storage/storage.type';

import { StorageItemListProps } from './storageItemList.type';
import './storageItemList.style.scss';

export const StorageItemListBottomSheet: FC<StorageItemListProps> = ({
	itemType,
	labels,
	onDismiss,
	...props
}: StorageItemListProps) => {
	const { t } = useTranslation();

	const iconName = itemType === StorageItemKey.STORAGE_SLOT ? IconNames.Storages : IconNames.Pallies;
	const title =
		itemType === StorageItemKey.STORAGE_SLOT ? t('storageItemList.storageSlots') : t('storageItemList.pallies');

	const renderRow = (item: string) => {
		return <p className="label">{item}</p>;
	};

	return (
		<DraggableBottomSheet onDismiss={onDismiss} {...props}>
			<div className="storage-item-list">
				<Icon className="storage-item-list__icon" iconName={iconName} size={IconSizes.Large} />

				<h1>{title}</h1>

				<div className="storage-item-list__list-wrapper">{labels.map(renderRow)}</div>

				<div className="storage-item-list__buttons">
					<PrimaryButton backgroundColor={Colors.red} onClick={onDismiss}>
						{t('storageItemList.close')}
					</PrimaryButton>
				</div>
			</div>
		</DraggableBottomSheet>
	);
};
