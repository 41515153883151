export interface OrderScannerContainerProps {
	currentDateKey?: string;
	orderId: string | undefined;
	onNavigateToOrderItems: () => void;
	onNavigateBack: () => void;
}

export enum OrderScanStatus {
	INITIAL = 'orderScanner.initialMessage',
	SUCCESS = 'orderScanner.successMessage',
	FAILED = 'scanner.pwaScannerInitializationError',
	FAILED_HONEYWELL_SDK = 'scanner.honeywellSDKInitializationError',
}
