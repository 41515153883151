import { format } from 'date-fns';

import { Order } from 'src/state/store/order/order.type';
import { Store } from 'src/state/store.type';

export const migrations = {
	0: (state: any): Store => {
		return {
			...state,
		};
	},
	1: (state: any): Store => {
		let store: Store = { ...state, order: { lastFetched: state.order.lastFetched, orders: {} } };
		try {
			const orders: Order[] = Object.values(state.order.orders);
			if (orders && orders.length > 0) {
				orders.forEach((order: Order) => {
					const dateKey = format(order.timeslot.start, 'yyyy-MM-dd');
					const ordersForDate = store.order.orders[dateKey];

					store = {
						...store,
						order: {
							...store.order,
							orders: {
								...store.order.orders,
								[dateKey]: {
									...ordersForDate,
									[order.id]: order,
								},
							},
						},
					};
				});
			}

			return store;
		} catch (err) {
			console.log(err);

			return { ...state, order: { lastFetched: state.order.lastFetched, orders: {} } };
		}
	},
};
