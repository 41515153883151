import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';

import { IconNames } from 'src/components/icon/icon.type';
import { PrivateRoute } from 'src/components/navigation/privateRoute/privateRoute.component';
import { AppPages } from 'src/navigation/app/appRouter.type';
import { StoragePages } from 'src/navigation/app/storage/storageRouter.type';
import StorageDetailPage from 'src/pages/app/storage/storageDetail.page';
import { StorageOverviewPage } from 'src/pages/app/storage/storageOverview.page';
import StoragePallyDetailPage from 'src/pages/app/storage/storagePallyDetail.page';
import StorageScannerPage from 'src/pages/app/storage/storageScanner.page';
import { selectSelectedStoreId } from 'src/state/store/carrefourStore/carrefourStore.selector';
import { selectPallyDetails, selectStorageSlotById } from 'src/state/store/storage/storage.selector';

const StorageRouter: FC = () => {
	const { t } = useTranslation();
	const isLoggedIn = useLocation<any>();
	const hasStoreSelected = useSelector(selectSelectedStoreId);
	const pally = useSelector(selectPallyDetails);
	const storageSlotForPally = useSelector(selectStorageSlotById(pally?.storageSlotId));
	const isAuthenticated: boolean = !!isLoggedIn && !!hasStoreSelected;

	const location = useLocation<any>();
	const { storageItem } = location?.state || { storageItem: undefined };

	return (
		<>
			<PrivateRoute exact isLoggedIn={isAuthenticated} path={AppPages.Storage}>
				<StorageOverviewPage />
			</PrivateRoute>

			<PrivateRoute exact isLoggedIn={isAuthenticated} path={StoragePages.StorageDetail}>
				<StorageDetailPage icon={IconNames.Storages} title={storageItem?.label} storageItem={storageItem} />
			</PrivateRoute>

			<PrivateRoute exact isLoggedIn={isAuthenticated} path={StoragePages.PallyDetail}>
				<StoragePallyDetailPage
					icon={IconNames.Pallies}
					title={storageItem?.label}
					subtitle={
						storageSlotForPally
							? t('storagePallyDetailPage.onSlot', { label: storageSlotForPally.label })
							: undefined
					}
					storageItem={storageItem}
				/>
			</PrivateRoute>

			<PrivateRoute exact isLoggedIn={isAuthenticated} path={StoragePages.StorageScanner}>
				<StorageScannerPage title={t('storageScanner.title')} storageItem={storageItem} />
			</PrivateRoute>
		</>
	);
};

export default StorageRouter;
