import { fork } from 'redux-saga/effects';

import { expiredCachedItemsClearSaga } from './expiredCachedItemsClear.saga';
import { initialStartupDataFetchSaga } from './initialStartupDataFetch.saga';
import { selectedStoreStartupDataFetchSaga } from './selectedStoreStartupDataFetch.saga';

export function* cacheSagas() {
	yield fork(initialStartupDataFetchSaga);
	yield fork(selectedStoreStartupDataFetchSaga);
	yield fork(expiredCachedItemsClearSaga);
}
