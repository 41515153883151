import React, { FC, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { format, isBefore, sub } from 'date-fns';

import { withTabHeader } from 'src/hoc/withTabHeader/withTabHeader.hoc';
import { DateFormat, Order, OrderState } from 'src/state/store/order/order.type';
import { actionOutboundOrdersForDateFetch } from 'src/state/store/order/order.action';
import {
	mapLateOrdersWithCurrentTime,
	selectLastFetchedOrdersPreviousDays,
	selectOutboundLateOrdersPreviousDays,
	selectOutboundOrdersRecord,
} from 'src/state/store/order/order.selector';
import './outgoingOverviewContainer.style.scss';
import { OrderLateContainer } from 'src/containers/app/outgoing/orderLate/orderLate.container';
import OrderNavigation from 'src/components/navigation/orderNavigation/orderNavigation.component';
import { OrderOverviewContainer } from 'src/containers/app/outgoing/orderOverview/orderOverview.container';
import { formatDateKeyForStore } from 'src/helpers/date.helpers';

import { OutgoingOverviewContainerProps } from './outgoingOverviewContainer.type';

const OutgoingOverviewContainerComponent: FC<OutgoingOverviewContainerProps> = ({
	onNavigateToOrderActions,
	onNavigateToOrderDetails,
	onNavigateToNextDay,
	onNavigateToPreviousDay,
	currentDateKey,
	disableNavigationNextDay,
	disableNavigationPreviousDay,
}: OutgoingOverviewContainerProps) => {
	const dateFormatted = formatDateKeyForStore(currentDateKey)!;

	const storeOrdersRecord: Record<string, Record<string, Order>> = useSelector(selectOutboundOrdersRecord);
	const lateOrdersPreviousDays = useSelector(selectOutboundLateOrdersPreviousDays);
	const lastFetched = useSelector(selectLastFetchedOrdersPreviousDays);

	// Array representation of store data filtered by current date
	const ordersByDate = useMemo(() => {
		const orders = storeOrdersRecord[dateFormatted];
		if (orders) {
			return mapLateOrdersWithCurrentTime(Object.values(orders));
		}

		return [];
	}, [storeOrdersRecord, storeOrdersRecord[dateFormatted]]);

	const [nextOpenLateOrder, setNextOpenLateOrder] = useState<Order | undefined>();

	const dispatch = useDispatch();

	useEffect(() => {
		fetchOrders();
	}, [dateFormatted]);

	useEffect(() => {
		showCardLateOrders(ordersByDate);
	}, [ordersByDate]);

	useEffect(() => {
		if (ordersByDate || lateOrdersPreviousDays) {
			const orders: Order[] = [];
			orders.push(...mapLateOrdersWithCurrentTime(lateOrdersPreviousDays));
			orders.push(...ordersByDate);
			showCardLateOrders(orders);
		}
	}, [lateOrdersPreviousDays, ordersByDate]);

	const fetchOrders = () => {
		let reloadAll: boolean = true;

		if (lastFetched) {
			reloadAll = isBefore(new Date(lastFetched), sub(Date.now(), { hours: 6 }));
		}
		if (reloadAll) {
			// All orders are retrieved, including items and customers
			dispatch(actionOutboundOrdersForDateFetch(new Date(currentDateKey), 6, true, true));
		} else if (storeOrdersRecord[dateFormatted]) {
			// Only orders for the visible day are retrieved again, including customers and details
			dispatch(actionOutboundOrdersForDateFetch(new Date(currentDateKey), 0, false, false));
		} else {
			// Only orders for the current day are retrieved, without customers and details
			dispatch(actionOutboundOrdersForDateFetch(new Date(currentDateKey), 0, true, true));
		}
	};

	const onRefresh = (): Promise<void> => {
		return new Promise((resolve) => {
			fetchOrders();

			setTimeout(() => {
				return resolve();
			}, 1500);
		});
	};

	const showCardLateOrders = (orders: Order[]) => {
		if (orders.length > 0) {
			const compareDate = sub(new Date(), { hours: 2 });
			const lateOrders = orders.filter(
				(order) =>
					order.state === OrderState.CustomerLate && isBefore(new Date(order.timeslot.start), compareDate),
			);
			setNextOpenLateOrder(lateOrders[0]);

			return;
		}

		closeLateOrderPopup();
	};

	const closeLateOrderPopup = () => {
		setNextOpenLateOrder(undefined);
	};

	return (
		<>
			<OrderNavigation
				dateToDisplay={currentDateKey}
				onNavigateToNextDay={onNavigateToNextDay}
				onNavigateToPreviousDay={onNavigateToPreviousDay}
				disableNavigationNextDay={disableNavigationNextDay}
				disableNavigationPreviousDay={disableNavigationPreviousDay}
			/>
			<OrderOverviewContainer
				onNavigateToOrderActions={onNavigateToOrderActions}
				onNavigateToOrderDetails={onNavigateToOrderDetails}
				currentDateKey={currentDateKey}
				ordersByDate={ordersByDate}
				onRefresh={onRefresh}
			/>
			<OrderLateContainer
				orderId={nextOpenLateOrder?.id}
				dateKeyOrder={
					nextOpenLateOrder ? format(nextOpenLateOrder.timeslot.start, DateFormat.dateAndTimezone) : undefined
				}
				dateKeyList={currentDateKey}
				open={!!nextOpenLateOrder}
				setOpen={closeLateOrderPopup}
			/>
		</>
	);
};

const OutgoingOverviewContainer = withTabHeader<OutgoingOverviewContainerProps>(OutgoingOverviewContainerComponent);

export { OutgoingOverviewContainer };
