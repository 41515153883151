import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { BottomSheet } from 'src/components/bottomSheet/bottomSheet.component';
import { SecondaryButton } from 'src/components/button/secondaryButton/secondaryButton.component';
import { IconNames, IconSizes } from 'src/components/icon/icon.type';
import { Icon } from 'src/components/icon/icon.component';

import { ReturnTotesModalProps } from './returnTotes.type';
import './returnTotes.style.scss';

const ReturnTotesModal: FC<ReturnTotesModalProps> = ({ onNextPressed }: ReturnTotesModalProps) => {
	const { t } = useTranslation();

	return (
		<BottomSheet key="return-totes">
			<div className="return-totes">
				<Icon className={'return-totes__icon'} iconName={IconNames.ReturnTotes} size={IconSizes.Huge} />

				<div
					className="return-totes__description"
					dangerouslySetInnerHTML={{ __html: t('returnTotesModal.description') }}></div>
				<SecondaryButton onClick={onNextPressed}>
					<Icon className="return-totes__button-icon" iconName={IconNames.Check} size={IconSizes.Small} />
					{t('returnTotesModal.submit')}
				</SecondaryButton>
			</div>
		</BottomSheet>
	);
};

export { ReturnTotesModal };
