import React, { FC } from 'react';
import classnames from 'classnames';

import { Colors } from 'src/assets';
import { Icon } from 'src/components/icon/icon.component';
import { IconNames, IconSizes } from 'src/components/icon/icon.type';

import { ListItemProps } from './listItem.type';
import './listItem.style.scss';

const ListItem: FC<ListItemProps> = ({
	children,
	className,
	style,
	hasChevron = true,
	chevronColor = Colors.brightBlue,
	disabled = false,
	onClick,
	onMouseDown,
}: ListItemProps) => {
	const renderChevron = () => {
		return (
			<div className={'chevron'}>
				<Icon iconName={IconNames.Chevron} color={chevronColor} size={IconSizes.Small} />
			</div>
		);
	};

	return (
		<div
			className={classnames('list-item', { 'list-item--active': !!onClick && !disabled }, className, {
				disabled,
			})}
			style={style}
			onMouseDown={onMouseDown}
			onClick={onClick}>
			<div className={'list-item__content'}>{children}</div>
			{hasChevron ? renderChevron() : null}
		</div>
	);
};

export { ListItem };
