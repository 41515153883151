import React, { CSSProperties, FC, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import StorageListItem from 'src/components/list/listItem/storageListItem/storageListItem.component';
import { Colors } from 'src/assets';
import { Pally, ScannedStorageItem, StorageItemKey } from 'src/state/store/storage/storage.type';
import { FlatList } from 'src/components/list/flatList/flatList.component';
import { Order } from 'src/state/store/order/order.type';
import { IconButton } from 'src/components/button/iconButton/iconButton.component';
import { IconNames } from 'src/components/icon/icon.type';
import { selectPallyDetails } from 'src/state/store/storage/storage.selector';
import { actionPallyDetailsFetch, actionStorageLink } from 'src/state/store/storage/storage.action';
import { useActionStatus } from 'src/hooks/useActionStatus.hook';
import { ACTION_STORAGE_LINK } from 'src/state/store/storage/storage.const';
import { ActionStatusEnum } from 'src/state/store/actionStatus/actionStatus.type';

import { StoragePallyDetailContainerProps } from './storagePallyDetailContainer.type';
import { STORAGE_LIST_MARGIN, STORAGE_ROW_HEIGHT, STORAGE_ROW_ITEM_MARGIN } from './storagePallyDetailContainer.const';
import './storagePallyDetailContainer.style.scss';

const StoragePallyDetailContainer: FC<StoragePallyDetailContainerProps> = ({
	storageItem,
	onNavigateToStorageScanner,
}: StoragePallyDetailContainerProps) => {
	const listRef = useRef<any>(null);
	const containerRef = useRef<HTMLDivElement>(null);

	const [orders, setOrders] = useState<Order[]>([]);

	const { t } = useTranslation();

	const pallyDetails = useSelector(selectPallyDetails);

	const dispatch = useDispatch();

	const unlinkStatus = useActionStatus({ type: ACTION_STORAGE_LINK });

	const fetchDetails = () => {
		dispatch(actionPallyDetailsFetch(storageItem.id));
	};

	useEffect(() => {
		fetchDetails();
	}, []);

	useEffect(() => {
		if (unlinkStatus?.status === ActionStatusEnum.SUCCESS) {
			fetchDetails();
		}
	}, [unlinkStatus]);

	useEffect(() => {
		setOrders(pallyDetails?.orders || []);
	}, [pallyDetails]);

	const navigateToAddToStoragePage = (storageItem: Pally) => {
		onNavigateToStorageScanner(storageItem);
	};

	const handleDelete = (item: Order) => {
		const scannedItems: ScannedStorageItem[] = [
			{ type: StorageItemKey.ORDER, data: item.id },
			{ type: StorageItemKey.PALLY, data: storageItem.code },
		];
		dispatch(actionStorageLink(false, scannedItems));
	};

	const renderRow = (item: Order, index: number, style?: CSSProperties) => {
		return (
			<StorageListItem
				item={item}
				key={`${index}-${item.id}`}
				onDelete={() => handleDelete(item)}
				style={style}
			/>
		);
	};

	return (
		<div className="storage-container">
			<div ref={containerRef} className="list-wrapper">
				<FlatList<Order>
					outerRef={listRef}
					data={orders}
					renderItem={renderRow}
					rowHeight={STORAGE_ROW_HEIGHT}
					itemMargin={STORAGE_ROW_ITEM_MARGIN}
					listMargin={{
						top: STORAGE_LIST_MARGIN,
						bottom: STORAGE_LIST_MARGIN + STORAGE_ROW_HEIGHT,
						left: STORAGE_LIST_MARGIN,
						right: STORAGE_LIST_MARGIN,
					}}
				/>
			</div>
			<div className="storage__button-container">
				<IconButton
					fullWidth={true}
					backgroundColor={Colors.primary}
					size={15}
					className="button"
					iconName={IconNames.Enter}
					onClick={() => navigateToAddToStoragePage(storageItem)}>
					{t('storageDetailPage.button', { storage: storageItem?.label })}
				</IconButton>
			</div>
		</div>
	);
};

export default StoragePallyDetailContainer;
