export enum MooseApiRoutes {
	Version = '/version',
	// Stores
	Stores = '/stores',
	// Inbound / deliveries
	Inbound = '/inbound/:storeId',
	InboundToReturn = '/inbound/:storeId/return',
	AcceptDelivery = '/inbound/:deliveryId',
	// Outbound / orders
	OutboundOrders = '/outbound/:storeId',
	OutgoingOrderUpdate = '/outbound/order/:orderId',
	OutgoingOrderItems = '/outbound/order/:orderId',
	// Settings
	UpdateNotifications = '/stores/:storeId',
	// Helpdesk
	Helpdesk = '/helpdesk/:orderUuid',
	// Refund
	Refund = '/refund/:orderUuid',
	// Customers
	Customer = '/customers/:orderId',
	// Storage
	Storage = '/storage/:storeId',
	StorageLink = '/storage/:storeUuid/link',
	StorageStorageSlotDetails = '/storage/:storeUuid/storageslots/:storageSlotUuid',
	StoragePallyDetails = '/storage/:storeUuid/pallies/:pallyUuid',
	StorageDeleteStorageSlot = '/storage/:storeUuid/storageslots/:storageSlotUuid',
	StorageDeletePally = '/storage/:storeUuid/pallies/:pallyUuid',
	StorageCreateStorageSlot = '/storage/:storeUuid/storageslots',
	StorageCreatePally = '/storage/:storeUuid/pallies',
}
