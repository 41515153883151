import React, { FC } from 'react';
import classNames from 'classnames';

import { HeaderProps } from './header.type';
import './header.style.scss';

const Header: FC<HeaderProps> = ({ title, children, style, className }: HeaderProps) => {
	return (
		<div className={classNames('header', className)} style={style}>
			{title ? <h1>{title}</h1> : null}
			{children}
		</div>
	);
};

export { Header };
