import Compressor from 'compressorjs';

export const compressImage = (image?: Blob, options?: Compressor.Options) => {
	if (image) {
		return new Promise<Blob>((resolve) => {
			new Compressor(image, {
				quality: options?.quality || 0.5,
				...options,
				success: (file: Blob) => {
					resolve(file);
				},
				error: (error: Error) => {
					throw error;
				},
			});
		});
	}
};

export const convertDataURLtoBlob = (url: string): Blob | undefined => {
	if (!url) {
		throw new Error('No url given');
	}

	const arr = url.split(',');

	if (arr.length < 2) {
		throw new Error('Invalid split');
	}
	const matchedRes = arr[0].match(/:(.*?);/);
	const mime = matchedRes && matchedRes[1];

	if (!mime) {
		throw new Error('invalid mime');
	}
	const bstr = atob(arr[1]);
	let n = bstr.length;
	const u8arr = new Uint8Array(n);
	while (n--) {
		u8arr[n] = bstr.charCodeAt(n);
	}

	return new Blob([u8arr], { type: mime });
};

export const getDataURLFileSize = (dataUrl: string) => {
	const head = 'data:image/png;base64,';

	return Math.round(((dataUrl.length - head.length) * 3) / 4) / 1024 / 1024;
};

export const processFormDataImages = (images: string[]): Promise<FormData> => {
	// eslint-disable-next-line no-async-promise-executor
	return new Promise<FormData>(async (resolve, reject) => {
		const formData = new FormData();
		for (let key = 0; key < images.length; key++) {
			const imgBlob = convertDataURLtoBlob(images[key]);

			if (!imgBlob) {
				return reject(new Error('Failed to convert DataURL to Blob object'));
			}

			const compressedImg = await compressImage(imgBlob);

			if (!compressedImg) {
				return reject(new Error('Failed to compress image'));
			}

			formData.append(`Picture ${key}`, compressedImg);
		}

		resolve(formData);
	});
};
