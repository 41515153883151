import { call, put, select, takeLatest } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';

import { MooseApiRoutes } from 'src/config/api.config';
import { replaceColonPrefixed } from 'src/helpers/message.helpers';
import { authorize } from 'src/state/sagas/auth/authorization.saga';
import { fetchRequest, HttpMethod } from 'src/state/sagas/network/network.saga';
import { networkFirstHelper } from 'src/state/sagas/network/networkFirst.saga';
import { actionStatusAdd } from 'src/state/store/actionStatus/actionStatus.action';
import { ActionStatusEnum } from 'src/state/store/actionStatus/actionStatus.type';
import { selectSelectedStoreId } from 'src/state/store/carrefourStore/carrefourStore.selector';
import { actionStorageOverviewSet } from 'src/state/store/storage/storage.action';
import { ACTION_STORAGE_OVERVIEW_FETCH } from 'src/state/store/storage/storage.const';
import { storageMapper } from 'src/state/mappers/storage/storage.mapper';

function* fetchStorageOverview(storeId: string): any {
	return yield call(
		fetchRequest,
		{ method: HttpMethod.Get, url: replaceColonPrefixed(MooseApiRoutes.Storage, { storeId }) },
		[networkFirstHelper(MooseApiRoutes.Storage), authorize],
		storageMapper,
	);
}

function* storageFetchWorker(): any {
	// Create action status running
	yield put(actionStatusAdd({ type: ACTION_STORAGE_OVERVIEW_FETCH }, ActionStatusEnum.RUNNING));
	try {
		const selectedCarrefourStore: string | undefined = yield select(selectSelectedStoreId);

		if (!selectedCarrefourStore) {
			return;
		}

		const { data }: AxiosResponse = yield call(fetchStorageOverview, selectedCarrefourStore);

		yield put(actionStorageOverviewSet(data.storageSlots, data.pallies));

		// Create action status success
		yield put(actionStatusAdd({ type: ACTION_STORAGE_OVERVIEW_FETCH }, ActionStatusEnum.SUCCESS));
	} catch (error) {
		yield put(actionStatusAdd({ type: ACTION_STORAGE_OVERVIEW_FETCH }, ActionStatusEnum.ERROR));
	}
}

export function* storageOverviewFetchSaga() {
	yield takeLatest(ACTION_STORAGE_OVERVIEW_FETCH, storageFetchWorker);
}
