import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { DraggableBottomSheet } from 'src/components/draggableBottomSheet/draggableBottomSheet.component';
import { IconNames } from 'src/components/icon/icon.type';
import { Icon } from 'src/components/icon/icon.component';
import { Colors } from 'src/assets';
import { PrimaryButton } from 'src/components/button/primaryButton/primaryButton.component';

import { OutgoingCancelProps } from './outgoingCancel.type';
import './outgoingCancel.style.scss';

export const OutgoingCancelBottomSheet: FC<OutgoingCancelProps> = ({
	onDismiss,
	onCancel,
	...props
}: OutgoingCancelProps) => {
	const { t } = useTranslation();

	return (
		<DraggableBottomSheet onDismiss={onDismiss} {...props}>
			<div className="outgoing-cancel">
				<Icon className="outgoing-cancel__icon" iconName={IconNames.GroceriesCarrefour} size={70} />

				<h1>{t('outgoingCancel.title')}</h1>

				<div className="outgoing-cancel__buttons">
					<PrimaryButton backgroundColor={Colors.red} onClick={onCancel}>
						{t('outgoingCancel.cancel')}
					</PrimaryButton>
					<PrimaryButton backgroundColor={Colors.lightBlue} color={Colors.brightBlue} onClick={onDismiss}>
						{t('outgoingCancel.discard')}
					</PrimaryButton>
				</div>
			</div>
		</DraggableBottomSheet>
	);
};
