import { ApiCustomer, Customer } from 'src/state/store/order/order.type';
import { Mapper } from 'src/state/mappers/mapper.type';

class CustomerMapper extends Mapper<ApiCustomer, Customer> {
	map(data: ApiCustomer): Customer {
		if (!data.firstname || !data.lastname) {
			return this.throwInvalid();
		}

		return {
			firstname: data.firstname,
			lastname: data.lastname,
			phoneNumber: data.phoneNumber,
		};
	}
}

export const customerMapper = new CustomerMapper();
