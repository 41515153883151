import React, { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { useActionStatus } from 'src/hooks/useActionStatus.hook';
import { ActionStatusEnum } from 'src/state/store/actionStatus/actionStatus.type';
import { actionCustomerByOrderIdFetch } from 'src/state/store/order/order.action';

import { CustomerLabelProps } from './customerLabel.type';
import './customerLabel.style.scss';

const CustomerLabel: FC<CustomerLabelProps> = ({ customer, orderId, style, dateKey }: CustomerLabelProps) => {
	const { t } = useTranslation();
	const customerActionStatus: ActionStatusEnum | undefined = useActionStatus(
		actionCustomerByOrderIdFetch(orderId, dateKey),
	)?.status;

	const renderCustomer = (): ReactNode => {
		switch (customerActionStatus) {
			case ActionStatusEnum.RUNNING: {
				return t('outgoing.loading');
			}

			case ActionStatusEnum.ERROR: {
				return t('outgoing.customerError');
			}

			case ActionStatusEnum.SUCCESS: {
				return (
					<>
						{customer?.lastname}
						<br />
						{customer?.firstname}
					</>
				);
			}
			default: {
				if (customer) {
					return (
						<>
							{customer.lastname}
							<br />
							{customer.firstname}
						</>
					);
				}

				return t('outgoing.loading');
			}
		}
	};

	return (
		<p className="customer-label" style={style}>
			{renderCustomer()}
		</p>
	);
};

export { CustomerLabel };
