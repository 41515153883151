import { getMSALAccessToken } from './auth.helper';

export const httpRequest = (url: string, method: string, token: string, payload?: any) => {
	const baseSubscriptionKey = process.env.REACT_APP_BASE_SUBSCRIPTION_KEY;
	const BearToken: string | null | undefined = getMSALAccessToken();
	const config = {
		method,
		headers: {
			'X-User-Meta-Token': token,
			'Ocp-Apim-Subscription-Key': baseSubscriptionKey ?? '',
			Authorization: 'Bearer ' + BearToken ?? '',
		},
		body: undefined,
	};

	if (method === 'post') {
		config.body = payload;
	}

	return fetch(url, config).then(async (response) => {
		// Check if the request is 200
		if (response.ok) {
			let data = response;

			if (data.headers.get('content-Type')) {
				// if the type is json return, interpret it as json
				if (data.headers.get('Content-Type')!.indexOf('application/json') > -1) {
					data = await response.json();
				}

				return data;
			}
		}

		// if an errors, anything but 200 then reject with the actuall response
		return Promise.reject(response);
	});
};
