import React, { FC, useEffect, useState } from 'react';
import Quagga from '@ericblade/quagga2';
import QrCodeReader from '@ericblade/quagga2-reader-qr';

import { ScannerSkeleton } from 'src/components/scanner/scannerSkeleton/scannerSkeleton.component';
import { ScannerType } from 'src/components/scanner/scanner.type';

import './pwaScanner.style.scss';
import { PWAScannerProps, QuaggaBarcodeResponse, QuaggaDecodedCode } from './pwaScanner.type';
import { getQuaggaErrorsMedian } from './pwaScanner.helper';

Quagga.registerReader('qrcode', QrCodeReader);

const PWAScanner: FC<PWAScannerProps> = ({
	onSuccess,
	onFailed,
	onCameraInited,
	formats = ['code_128_reader', 'qrcode'],
}: PWAScannerProps) => {
	const [videoInit, setVideoInit] = useState<boolean>(false);

	useEffect(() => {
		startScanner();

		return () => {
			setVideoInit(false);
			stopScanner();
		};
	}, []);

	const startScanner = () => {
		Quagga.init(
			{
				inputStream: {
					name: 'Live',
					type: 'LiveStream',
					singleChannel: false,
					//@ts-ignore
					target: document.querySelector('#video'),
				},
				numOfWorkers: 1,
				locate: true,
				decoder: {
					readers: formats,
				},
				frequency: 1,
			},
			(err) => {
				if (err) {
					onFailed(err, ScannerType.PWA);

					return;
				}
				onInitSuccess();
			},
		);
		Quagga.onDetected(errorCheck);
	};

	const stopScanner = async () => {
		await Quagga.stop();
	};

	const onInitSuccess = () => {
		Quagga.start();
		setVideoInit(true);
		onCameraInited();
	};

	const errorCheck = (result: QuaggaBarcodeResponse) => {
		if (result.codeResult && result.codeResult.code && result.codeResult.format === 'qr_code') {
			try {
				const qrScannerResult = JSON.parse(result.codeResult.code);
				onSuccess(qrScannerResult);
			} catch (e) {
				onSuccess(result.codeResult.code);
			}

			return;
		}

		const errors =
			result?.codeResult?.decodedCodes?.reduce((acc: number[], currentCode: QuaggaDecodedCode) => {
				if (currentCode.error) {
					acc.push(currentCode.error);
				}

				return acc;
			}, []) || [];

		const median = getQuaggaErrorsMedian(errors);

		// if Quagga is at least 90% certain that it read correctly, then accept the code.
		if (!!median && median < 0.1) {
			onSuccess(result.codeResult.code || '');
		}
	};

	return (
		<div className="pwa-scanner">
			<div className="video" id="video" />
			{videoInit ? '' : <ScannerSkeleton />}
		</div>
	);
};

export { PWAScanner };
