import { call, CallEffect, put, takeLatest } from 'redux-saga/effects';

import { actionStatusAdd } from 'src/state/store/actionStatus/actionStatus.action';
import { ActionStatusEnum } from 'src/state/store/actionStatus/actionStatus.type';
import { ACTION_CARREFOUR_STORES_FETCH } from 'src/state/store/carrefourStore/carrefourStore.const';
import {
	actionCarrefourStoresLastFetchedSet,
	actionCarrefourStoresSet,
} from 'src/state/store/carrefourStore/carrefourStore.action';
import { CarrefourStore } from 'src/state/store/carrefourStore/carrefourStore.type';
import { MooseApiRoutes } from 'src/config/api.config';
import { ExtendedAxiosResponse, fetchRequest, HttpMethod } from 'src/state/sagas/network/network.saga';
import { networkFirstHelper } from 'src/state/sagas/network/networkFirst.saga';
import { carrefourStoreMapper } from 'src/state/mappers/store/carrefourStore.mapper';
import { authorize } from 'src/state/sagas/auth/authorization.saga';
import { isOffline } from 'src/helpers/cache.helpers';

function* fetchCarrefourStores(): Generator<CallEffect<any>, ExtendedAxiosResponse, ExtendedAxiosResponse> {
	return yield call(
		fetchRequest,
		{ method: HttpMethod.Get, url: MooseApiRoutes.Stores },
		[networkFirstHelper(MooseApiRoutes.Stores), authorize],
		carrefourStoreMapper,
	);
}

function* storesFetchWorker() {
	// Create action status running
	yield put(actionStatusAdd({ type: ACTION_CARREFOUR_STORES_FETCH }, ActionStatusEnum.RUNNING));

	try {
		const { data }: ExtendedAxiosResponse<CarrefourStore[]> = yield call(fetchCarrefourStores);

		yield put(actionCarrefourStoresSet(data));

		yield put(actionCarrefourStoresLastFetchedSet(Date.now()));

		// Create action status success
		yield put(actionStatusAdd({ type: ACTION_CARREFOUR_STORES_FETCH }, ActionStatusEnum.SUCCESS));
	} catch (e) {
		// General error
		yield put(actionStatusAdd({ type: ACTION_CARREFOUR_STORES_FETCH }, ActionStatusEnum.ERROR, isOffline()));
	}
}

export function* storesFetchSaga() {
	yield takeLatest(ACTION_CARREFOUR_STORES_FETCH, storesFetchWorker);
}
