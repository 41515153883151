import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { format, isSameHour } from 'date-fns';
import classNames from 'classnames';

import { Order, OrderDisplay, OrderState, Timeslot } from 'src/state/store/order/order.type';
import { selectOrderById } from 'src/state/store/order/order.selector';
import { Store } from 'src/state/store.type';
import { PrimaryButton } from 'src/components/button/primaryButton/primaryButton.component';
import { ConfirmTimeBottomSheet } from 'src/components/draggableBottomSheet/confirmTime/confirmTime.component';
import { Colors } from 'src/assets';
import { generateTimeSlots } from 'src/helpers/timeslot.helpers';
import { actionOutgoingOrderUpdate } from 'src/state/store/order/order.action';
import { formatDateKeyForStore } from 'src/helpers/date.helpers';

import { OutgoingChangeTimeContainerProps } from './outgoingChangeTimeContainer.type';
import './outgoingChangeTime.style.scss';

const OutgoingChangeTimeContainer: FC<OutgoingChangeTimeContainerProps> = ({
	currentDateKey,
	orderId,
	goBack,
}: OutgoingChangeTimeContainerProps) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const dateFormatted = formatDateKeyForStore(currentDateKey);
	const order: Order | undefined = useSelector((state: Store) => selectOrderById(state, orderId, dateFormatted));

	const [openConfirm, setOpenConfirm] = useState<boolean>(false);
	const [timeslots, setTimeslots] = useState<Timeslot[]>([]);
	const [timeslot, setTimeslot] = useState<Timeslot>();

	useEffect(() => {
		const timeslots = generateTimeSlots();

		setTimeslots(timeslots);

		const timeslotStart = order ? new Date(order?.timeslot.start) : null;

		if (timeslotStart) {
			setTimeslot(timeslots.find((slot) => !slot?.disabled && isSameHour(slot.start, timeslotStart)));
		}
	}, []);

	const onConfirm = () => {
		if (!order || !timeslot || !dateFormatted) {
			return;
		}

		dispatch(
			actionOutgoingOrderUpdate(order.id, OrderState.TimeChanged, dateFormatted, {
				start: timeslot.start.getTime(),
				end: timeslot.end.getTime(),
			}),
		);
		//Changing the time of an order causes a change in index
		localStorage.setItem(OrderDisplay.toId, order.id.toString());

		setOpenConfirm(false);

		// will make sure the animation of the popup finishes and then go back
		setTimeout(goBack, 450);
	};

	const timeslotsElements = timeslots.map((slot) => (
		<div
			key={slot.id}
			className={classNames(
				'timeslot',
				{ 'timeslot--disabled': slot.disabled },
				{ 'timeslot--active': slot.id === timeslot?.id },
			)}
			onClick={slot.disabled ? () => {} : () => setTimeslot(slot)}>
			{format(slot.start, 'HH:mm')} - {format(slot.end, 'HH:mm')}
		</div>
	));

	return (
		<>
			<div className="outgoing-change-time">
				<h1>{t('changeTime.subTitle')}</h1>
				<div className="timeslot-container">{timeslotsElements}</div>

				<div className="outgoing-change-time__buttons">
					<PrimaryButton
						backgroundColor={Colors.green}
						disabled={!timeslot}
						onClick={() => setOpenConfirm(true)}>
						{t('changeTime.confirm')}
					</PrimaryButton>
					<PrimaryButton backgroundColor={Colors.lightBlue} color={Colors.brightBlue} onClick={goBack}>
						{t('changeTime.discard')}
					</PrimaryButton>
				</div>
			</div>

			<ConfirmTimeBottomSheet
				timeslot={timeslot}
				open={openConfirm}
				onDismiss={() => setOpenConfirm(false)}
				onConfirm={onConfirm}
			/>
		</>
	);
};

export { OutgoingChangeTimeContainer };
