export interface ActionType<T = any> {
	type: string;
	payload?: T; // Should not be optional, but reduxsauce's createReducer requires AnyAction
	error?: true;
	reload?: boolean;
}

export interface ActionStatusesStore {
	[hash: string]: ActionStatus;
}

export interface ActionStatus {
	hash: string;
	status: ActionStatusEnum;
	errors?: ActionError[];
	isOffline: boolean;
	data?: any;
}

export enum ActionStatusEnum {
	RUNNING = 'RUNNING',
	RELOADING = 'RELOADING',
	SUCCESS = 'SUCCESS',
	ERROR = 'ERROR',
	NOT_RUNNING = 'NOT_RUNNING',
}

export interface ActionError {
	code: string;
	[param: string]: any;
}

/* ------------- Action Payload Types  ------------- */
export interface ActionStatusAddActionType {
	actionStatus: ActionStatus;
}

export interface ActionStatusRemoveActionType {
	hash: string;
}
