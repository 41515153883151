import { AxiosResponse } from 'axios';
import { call, CallEffect, put, PutEffect, select, SelectEffect, takeLatest } from 'redux-saga/effects';

import { ActionStatusEnum, ActionType } from 'src/state/store/actionStatus/actionStatus.type';
import { Pally, StorageFetchPallyActionType } from 'src/state/store/storage/storage.type';
import { actionStatusAdd } from 'src/state/store/actionStatus/actionStatus.action';
import { ACTION_STORAGE_PALLY_DETAILS_FETCH } from 'src/state/store/storage/storage.const';
import { selectSelectedStoreId } from 'src/state/store/carrefourStore/carrefourStore.selector';
import { fetchRequest, HttpMethod } from 'src/state/sagas/network/network.saga';
import { replaceColonPrefixed } from 'src/helpers/message.helpers';
import { MooseApiRoutes } from 'src/config/api.config';
import { authorize } from 'src/state/sagas/auth/authorization.saga';
import { actionPallyDetailsSet } from 'src/state/store/storage/storage.action';
import { pallyMapper } from 'src/state/mappers/storage/pally.mapper';

function* fetchPallyDetails(storeId: string, pallyId: string): Generator<CallEffect, AxiosResponse, AxiosResponse> {
	return yield call(
		fetchRequest,
		{
			method: HttpMethod.Get,
			url: replaceColonPrefixed(MooseApiRoutes.StoragePallyDetails, {
				storeUuid: storeId,
				pallyUuid: pallyId,
			}),
		},
		[authorize],
		pallyMapper,
	);
}

function* fetchPallyDetailsWorker(
	action: ActionType<StorageFetchPallyActionType>,
): Generator<CallEffect | PutEffect | SelectEffect, AxiosResponse | string | undefined, any> {
	if (!action.payload) return;

	// Create action status running
	yield put(actionStatusAdd({ type: ACTION_STORAGE_PALLY_DETAILS_FETCH }, ActionStatusEnum.SUCCESS));

	try {
		// Get selected store id
		const selectedCarrefourStoreId: string = yield select(selectSelectedStoreId)!;
		// Get storage slot id
		const { pallyId } = action.payload;

		// Execute API call
		const { data }: AxiosResponse<Pally> = yield call(fetchPallyDetails, selectedCarrefourStoreId, pallyId);
		// Save pally to store
		yield put(actionPallyDetailsSet(data));

		// Create action status success
		yield put(actionStatusAdd({ type: ACTION_STORAGE_PALLY_DETAILS_FETCH }, ActionStatusEnum.SUCCESS));
	} catch (err) {
		// Create action status error
		yield put(actionStatusAdd({ type: ACTION_STORAGE_PALLY_DETAILS_FETCH }, ActionStatusEnum.ERROR));
	}
}

export function* pallyDetailsFetchSaga() {
	yield takeLatest(ACTION_STORAGE_PALLY_DETAILS_FETCH, fetchPallyDetailsWorker);
}
