import { getActionHash } from 'src/state/store/actionStatus/actionStatus.selector';
import {
	ActionError,
	ActionStatusEnum,
	ActionStatusRemoveActionType,
	ActionStatusAddActionType,
	ActionType,
} from 'src/state/store/actionStatus/actionStatus.type';

import { ACTION_STATUS_ADD, ACTION_CLEAR_ACTION_STATUS_STORE, ACTION_STATUS_REMOVE } from './actionStatus.const';

/* ------------- Action Creators  ------------- */
export const actionStatusAdd = (
	action: ActionType,
	status: ActionStatusEnum,
	isOffline: boolean = false,
	errors?: ActionError[],
	data?: any,
): ActionType<ActionStatusAddActionType> => {
	return {
		type: ACTION_STATUS_ADD,
		payload: {
			actionStatus: {
				hash: getActionHash(action),
				status,
				errors,
				isOffline,
				data,
			},
		},
	};
};

export const actionStatusRemove = (action: ActionType): ActionType<ActionStatusRemoveActionType> => {
	return {
		type: ACTION_STATUS_REMOVE,
		payload: {
			hash: getActionHash(action),
		},
	};
};

export const actionClearActionStatusStore = (): ActionType => {
	return {
		type: ACTION_CLEAR_ACTION_STATUS_STORE,
	};
};
