import { all, put, takeLatest } from 'redux-saga/effects';

import { actionStatusAdd } from 'src/state/store/actionStatus/actionStatus.action';
import { ActionStatusEnum } from 'src/state/store/actionStatus/actionStatus.type';
import { ACTION_SELECTED_STORE_STARTUP_DATA_FETCH } from 'src/state/store/cache/cache.const';
import { getSelectedStoreCachedApiRequests } from 'src/helpers/cache.helpers';
import { actionLastSyncedAuthenticatedCacheSet } from 'src/state/store/cache/cache.action';

export function* selectedStoreStartupDataFetchSaga() {
	yield takeLatest(ACTION_SELECTED_STORE_STARTUP_DATA_FETCH, selectedStoreStartupDataFetchWorker);
}

function* selectedStoreStartupDataFetchWorker() {
	// Create action status running
	yield put(actionStatusAdd({ type: ACTION_SELECTED_STORE_STARTUP_DATA_FETCH }, ActionStatusEnum.RUNNING));

	try {
		const actionsToCallOnWhenHasSelectedStore = getSelectedStoreCachedApiRequests();

		yield all(actionsToCallOnWhenHasSelectedStore.map((action) => put(action())));

		yield put(actionLastSyncedAuthenticatedCacheSet(Date.now()));

		// Create action status success
		yield put(actionStatusAdd({ type: ACTION_SELECTED_STORE_STARTUP_DATA_FETCH }, ActionStatusEnum.SUCCESS));
	} catch (e) {
		// General error
		yield put(actionStatusAdd({ type: ACTION_SELECTED_STORE_STARTUP_DATA_FETCH }, ActionStatusEnum.ERROR));
	}
}
