import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useToasts } from 'react-toast-notifications';

import { Colors } from 'src/assets';
import { Button } from 'src/components/button/button.component';
import { Scanner } from 'src/components/scanner/scanner.component';
import { Store } from 'src/state/store.type';
import { selectOrderById } from 'src/state/store/order/order.selector';
import { Order, OrderState } from 'src/state/store/order/order.type';
import { SecondaryButton } from 'src/components/button/secondaryButton/secondaryButton.component';
import { actionOutgoingOrderUpdate } from 'src/state/store/order/order.action';
import { ScannerType } from 'src/components/scanner/scanner.type';
import { isDev, isTest } from 'src/helpers/env.helpers';
import { formatDateKeyForStore } from 'src/helpers/date.helpers';

import { TotesScannerProps } from './totesScannerContainer.type';
import './totesScannerContainer.style.scss';

const TotesScannerContainer: FC<TotesScannerProps> = ({
	currentDateKey,
	orderId,
	onNavigateToOutgoingOverview,
	onNavigateBack,
}: TotesScannerProps) => {
	const dateFormatted = formatDateKeyForStore(currentDateKey);
	const order: Order | undefined = useSelector((state: Store) => selectOrderById(state, orderId, dateFormatted));

	const previousScannedTotesRef = useRef<string[]>([]);

	// Indicates if a scanned barcode is unkown
	const [unkownItemScanned, setUnkownItemScanned] = useState<boolean>(false);
	// Indicates if there went something wrong with the PWA scanner
	const [showErrorMessage, setShowErrorMessage] = useState<string | undefined>();
	// Reference to all scanned totes
	const [scannedTotes, setScannedTotes] = useState<string[]>([]);

	const numberOfTotes = order?.totes.length || 0;

	const { t } = useTranslation();
	const { addToast } = useToasts();

	const dispatch = useDispatch();

	useEffect(() => {
		previousScannedTotesRef.current = scannedTotes;
	}, [scannedTotes]);

	const onSuccess = useCallback(
		(data: string | Record<string, unknown>) => {
			if (!order?.totes || order.totes.length <= 0) {
				return;
			}

			const barcode: string = (data as string).toString();
			const totes: string[] = order.totes.map((tote) => tote.barcode);

			if (previousScannedTotesRef.current.includes(barcode)) {
				addToast(t('totesScanner.alreadyScanned'));

				return;
			}

			// check if scanned barcode has
			if (totes.includes(barcode)) {
				addToast(t('totesScanner.scanSuccess'), {
					appearance: 'success',
				});

				setScannedTotes((prevState) => [...prevState, barcode]);
				setUnkownItemScanned(false);
				setShowErrorMessage(undefined);

				return;
			}

			addToast(t('totesScanner.unkownItemScanned'), { appearance: 'error' });
			setUnkownItemScanned(true);
		},
		[scannedTotes],
	);

	const proceedToPayment = () => {
		if (orderId && dateFormatted) {
			dispatch(actionOutgoingOrderUpdate(orderId, OrderState.PickedUp, dateFormatted));

			setTimeout(() => {
				onNavigateToOutgoingOverview();
				// TODO: open POS app
			}, 250);
		}
	};

	const onFailed = (e: string | Record<string, unknown> | undefined, scannerType: ScannerType) => {
		let errorMessage: string | undefined;

		if (typeof e === 'string') {
			errorMessage = e;
		} else if (e && scannerType === ScannerType.HONEYWELL && (isDev() || isTest())) {
			errorMessage = e.message as string;
		}

		setUnkownItemScanned(false);
		setShowErrorMessage(
			`${t(
				scannerType === ScannerType.HONEYWELL
					? 'scanner.honeywellSDKInitializationError'
					: 'scanner.pwaScannerInitializationError',
			)} ${errorMessage ? '\n' : ''} ${errorMessage || ''}`,
		);
	};

	const cancelScan = () => {
		onNavigateBack();
	};

	const onInitedScanner = () => {
		setUnkownItemScanned(false);
		setShowErrorMessage(undefined);
	};

	return (
		<div className="totes-scanner">
			<p className="totes-scanner__instruction">
				{t('totesScanner.instruction', { numberOfTotes: numberOfTotes })}
				{unkownItemScanned && <p> {t('totesScanner.unkownItemScanned')} </p>}
				{!!showErrorMessage && <p>{showErrorMessage} </p>}
			</p>

			<div className="totes-scanner__scan-container">
				<Scanner onInited={onInitedScanner} onSuccess={onSuccess} onFailed={onFailed} />
			</div>

			<p className="totes-scanner__scan-status">
				{t('totesScanner.scanStatus', {
					numberOfScannedTotes: scannedTotes.length,
					numberOfTotes: numberOfTotes,
				})}
			</p>

			<div className="totes-scanner__button-container">
				{numberOfTotes > 0 && scannedTotes.length === numberOfTotes ? (
					<SecondaryButton onClick={proceedToPayment}>
						{t('totesScanner.proceedToPaymentCta')}
					</SecondaryButton>
				) : (
					<Button backgroundColor={Colors.red} onClick={cancelScan}>
						{t('general.cancel')}
					</Button>
				)}
			</div>
		</div>
	);
};

export { TotesScannerContainer };
