import { ReactComponent as ArrowUp } from 'src/assets/vectors/icons/arrowUp.svg';
import { ReactComponent as Box } from 'src/assets/vectors/icons/box.svg';
import { ReactComponent as Check } from 'src/assets/vectors/icons/check.svg';
import { ReactComponent as CheckedCircle } from 'src/assets/vectors/icons/checkedCircle.svg';
import { ReactComponent as Chevron } from 'src/assets/vectors/icons/chevron.svg';
import { ReactComponent as ChevronLeft } from 'src/assets/vectors/icons/chevronLeft.svg';
import { ReactComponent as Clock } from 'src/assets/vectors/icons/clock.svg';
import { ReactComponent as Clock2 } from 'src/assets/vectors/icons/clock2.svg';
import { ReactComponent as Cross } from 'src/assets/vectors/icons/cross.svg';
import { ReactComponent as Edit } from 'src/assets/vectors/icons/edit.svg';
import { ReactComponent as EuroCircle } from 'src/assets/vectors/icons/euro-circle.svg';
import { ReactComponent as Enter } from 'src/assets/vectors/icons/enter.svg';
import { ReactComponent as Exit } from 'src/assets/vectors/icons/exit.svg';
import { ReactComponent as Eye } from 'src/assets/vectors/icons/eye.svg';
import { ReactComponent as Grid } from 'src/assets/vectors/icons/grid.svg';
import { ReactComponent as Groceries } from 'src/assets/vectors/icons/groceries.svg';
import { ReactComponent as GroceriesCarrefour } from 'src/assets/vectors/icons/groceriesCarrefour.svg';
import { ReactComponent as HRLStore } from 'src/assets/vectors/icons/hrlStore.svg';
import { ReactComponent as HelpDesk } from 'src/assets/vectors/icons/helpDesk.svg';
import { ReactComponent as Incoming } from 'src/assets/vectors/icons/incoming.svg';
import { ReactComponent as ImagePlus } from 'src/assets/vectors/icons/image-plus.svg';
import { ReactComponent as Minus } from 'src/assets/vectors/icons/minus.svg';
import { ReactComponent as OrderDelivered } from 'src/assets/vectors/icons/orderDelivered.svg';
import { ReactComponent as Outgoing } from 'src/assets/vectors/icons/outgoing.svg';
import { ReactComponent as Pally } from 'src/assets/vectors/icons/pally.svg';
import { ReactComponent as Pallies } from 'src/assets/vectors/icons/pallies.svg';
import { ReactComponent as Person } from 'src/assets/vectors/icons/person.svg';
import { ReactComponent as Plus } from 'src/assets/vectors/icons/plus.svg';
import { ReactComponent as ReturnTotes } from 'src/assets/vectors/icons/returnTotes.svg';
import { ReactComponent as Scan } from 'src/assets/vectors/icons/scan.svg';
import { ReactComponent as Search } from 'src/assets/vectors/icons/search.svg';
import { ReactComponent as Settings } from 'src/assets/vectors/icons/settings.svg';
import { ReactComponent as SettingsBlue } from 'src/assets/vectors/icons/settingsBlue.svg';
import { ReactComponent as Storage } from 'src/assets/vectors/icons/storage.svg';
import { ReactComponent as Storages } from 'src/assets/vectors/icons/storages.svg';
import { ReactComponent as Store } from 'src/assets/vectors/logos/store.svg';
import { ReactComponent as Support } from 'src/assets/vectors/icons/support.svg';
import { ReactComponent as Warning } from 'src/assets/vectors/icons/warning.svg';
import { ReactComponent as Trash } from 'src/assets/vectors/icons/trash.svg';
import { ReactComponent as Download } from 'src/assets/vectors/icons/download.svg';

import { IconNames, IconSizes } from './icon.type';

export const ICONS = {
	[IconNames.ArrowUp]: ArrowUp,
	[IconNames.Box]: Box,
	[IconNames.Check]: Check,
	[IconNames.CheckedCircle]: CheckedCircle,
	[IconNames.Chevron]: Chevron,
	[IconNames.ChevronLeft]: ChevronLeft,
	[IconNames.Clock]: Clock,
	[IconNames.Clock2]: Clock2,
	[IconNames.Cross]: Cross,
	[IconNames.Download]: Download,
	[IconNames.Edit]: Edit,
	[IconNames.Enter]: Enter,
	[IconNames.EuroCircle]: EuroCircle,
	[IconNames.Exit]: Exit,
	[IconNames.Eye]: Eye,
	[IconNames.Grid]: Grid,
	[IconNames.Groceries]: Groceries,
	[IconNames.GroceriesCarrefour]: GroceriesCarrefour,
	[IconNames.HelpDesk]: HelpDesk,
	[IconNames.HRLStore]: HRLStore,
	[IconNames.Incoming]: Incoming,
	[IconNames.ImagePlus]: ImagePlus,
	[IconNames.Minus]: Minus,
	[IconNames.OrderDelivered]: OrderDelivered,
	[IconNames.Outgoing]: Outgoing,
	[IconNames.Pally]: Pally,
	[IconNames.Pallies]: Pallies,
	[IconNames.Person]: Person,
	[IconNames.Plus]: Plus,
	[IconNames.ReturnTotes]: ReturnTotes,
	[IconNames.Scan]: Scan,
	[IconNames.Search]: Search,
	[IconNames.Settings]: Settings,
	[IconNames.SettingsBlue]: SettingsBlue,
	[IconNames.Storage]: Storage,
	[IconNames.Storages]: Storages,
	[IconNames.Store]: Store,
	[IconNames.Support]: Support,
	[IconNames.Warning]: Warning,
	[IconNames.Trash]: Trash,
};

export const ICON_SIZES = {
	[IconSizes.Tiny]: 12,
	[IconSizes.Small]: 16,
	[IconSizes.Normal]: 25,
	[IconSizes.Large]: 50,
	[IconSizes.Huge]: 82,
};
