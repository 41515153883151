import { all, put, takeLatest } from 'redux-saga/effects';

import { actionStatusAdd } from 'src/state/store/actionStatus/actionStatus.action';
import { ActionStatusEnum } from 'src/state/store/actionStatus/actionStatus.type';
import { ACTION_INITIAL_STARTUP_DATA_FETCH } from 'src/state/store/cache/cache.const';
import { getInitialCachedApiRequests } from 'src/helpers/cache.helpers';
import { actionLastSyncedInitialCacheSet } from 'src/state/store/cache/cache.action';

export function* initialStartupDataFetchSaga() {
	yield takeLatest(ACTION_INITIAL_STARTUP_DATA_FETCH, initialStartupDataFetchWorker);
}

function* initialStartupDataFetchWorker() {
	// Create action status running
	yield put(actionStatusAdd({ type: ACTION_INITIAL_STARTUP_DATA_FETCH }, ActionStatusEnum.RUNNING));

	try {
		const actionsToCallOnStartup = getInitialCachedApiRequests();

		yield all(actionsToCallOnStartup.map((action) => put(action())));

		yield put(actionLastSyncedInitialCacheSet(Date.now()));

		// Create action status success
		yield put(actionStatusAdd({ type: ACTION_INITIAL_STARTUP_DATA_FETCH }, ActionStatusEnum.SUCCESS));
	} catch (e) {
		// General error
		yield put(actionStatusAdd({ type: ACTION_INITIAL_STARTUP_DATA_FETCH }, ActionStatusEnum.ERROR));
	}
}
