import { format } from 'date-fns';

import { Order } from 'src/state/store/order/order.type';

export const arrayToMapRecord = <I extends { id: string | number }>(list: I[]): Record<string | number, I> => {
	return list.reduce((previousValue: Record<string | number, I>, currentValue: I) => {
		previousValue[currentValue.id] = currentValue;

		return previousValue;
	}, {});
};

export const arrayToMapNestedRecord = (
	list: Order[],
	oldStore?: Record<string, Record<string, Order>>,
): Record<string, Record<string, Order>> => {
	const ordersForDateKeys = list.map((order) => format(order.timeslot.start, 'yyyy-MM-dd'));
	new Set(ordersForDateKeys).forEach((dateKey) => {
		oldStore = {
			...oldStore,
			[dateKey]: {},
		};
	});

	return list.reduce(
		(previousValue: Record<string, Record<string, Order>>, currentValue: Order) => {
			try {
				const uniqueIdentifier = format(new Date(currentValue.timeslot.start), 'yyyy-MM-dd');
				if (previousValue[uniqueIdentifier]) {
					previousValue[uniqueIdentifier] = {
						...previousValue[uniqueIdentifier],
						[currentValue.id]: currentValue,
					};
				} else {
					previousValue;
					previousValue[uniqueIdentifier] = { [currentValue.id]: currentValue };
				}
			} catch (e) {
				console.error(e);
			}

			return previousValue;
		},
		oldStore ? oldStore : {},
	);
};
