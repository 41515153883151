import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { useIsLoggedIn } from 'src/hooks/useIsLoggedIn.hook';
import { useIsRole } from 'src/hooks/useIsRole.hook';
import { Roles } from 'src/models/roles.model';
import { withBoxedContainer } from 'src/hoc/withBoxedContainer/withBoxedContainer.hoc';
import { PrivateRoute } from 'src/components/navigation/privateRoute/privateRoute.component';
import { BackofficePage } from 'src/pages/backoffice/backofficePortal.page';
import { RefundExportPage } from 'src/pages/backoffice/refundExport.page';

import { BackofficePages } from './backoffice.type';

const BackofficeRouterComponent: FC = () => {
	const { t } = useTranslation();

	const isLoggedIn: boolean = useIsLoggedIn();
	const isAdmin: boolean = useIsRole(Roles.ADMIN);

	return (
		<>
			<PrivateRoute isLoggedIn={isLoggedIn && isAdmin} exact path={BackofficePages.Overview}>
				<BackofficePage title={t('backoffice.title')} />
			</PrivateRoute>
			<PrivateRoute isLoggedIn={isLoggedIn && isAdmin} exact path={BackofficePages.Refund}>
				<RefundExportPage title={t('refund.title')} />
			</PrivateRoute>
		</>
	);
};

const BackofficeRouter = withBoxedContainer(BackofficeRouterComponent);

export { BackofficeRouter };
