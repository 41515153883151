import React from 'react';
import classnames from 'classnames';

import { Button } from 'src/components/button/button.component';
import { ButtonProps } from 'src/components/button/button.type';

import './secondaryButton.style.scss';

/**
 * Green carrefour styled button
 */
const SecondaryButton = ({ children, className, ...props }: ButtonProps) => {
	return (
		<Button
			className={classnames('secondary-button', className)}
			style={{ backgroundColor: 'var(--color-green)' }}
			{...props}>
			<span className="secondary-button__text">{children} </span>
		</Button>
	);
};

export { SecondaryButton };
