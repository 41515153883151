import { CSSProperties } from 'react';

export interface OrderStatusLabelProps {
	label: string;
	background?: string;
	status?: OrderStatusLabelStatus;
	style?: CSSProperties;
	className?: string;
}

export enum OrderStatusLabelStatus {
	OPEN = 'open',
	CLOSED = 'closed',
	COMPLETED = 'completed',
}
