import React, { FC } from 'react';
import { useHistory } from 'react-router';

import StoragePallyDetailContainer from 'src/containers/app/storage/storagePallyDetail/storagePallyDetail.container';
import { withNavbarHeader } from 'src/hoc/withNavbarHeader/withNavbarHeader.hoc';
import { StoragePallyDetailPageProps } from 'src/navigation/app/appRouter.type';
import { StoragePages } from 'src/navigation/app/storage/storageRouter.type';
import { Pally } from 'src/state/store/storage/storage.type';

const StoragePallyDetailPage: FC<StoragePallyDetailPageProps> = ({ storageItem }: StoragePallyDetailPageProps) => {
	const history = useHistory();

	const navigateToStoragePage = (storageItem: Pally) => {
		history.push(StoragePages.StorageScanner, { storageItem });
	};

	return <StoragePallyDetailContainer storageItem={storageItem} onNavigateToStorageScanner={navigateToStoragePage} />;
};

export default withNavbarHeader(StoragePallyDetailPage);
