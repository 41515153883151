import { Order, OrderItem, Substitution } from 'src/state/store/order/order.type';

import { DisplayOrderItem, DisplayOrderItemType } from './orderDetailContainer.type';
/**
 * Helper function that will transform the order items to an key value object
 * This is used to render the section list
 *
 * @param order current order
 * @returns A key (section) value (matching order items) object
 */
export const mapOrderItemsToMatchingState = (
	order: Order | undefined,
): Record<DisplayOrderItemType, DisplayOrderItem[]> | null => {
	if (!order || !order.items?.length) {
		return null;
	}

	const mappedOrders = order.items.reduce(
		(
			acc: {
				swappedItems: DisplayOrderItem[];
				unavailableItems: DisplayOrderItem[];
				includedItems: DisplayOrderItem[];
			},
			currentOrderItem: OrderItem,
		) => {
			if (currentOrderItem.quantitySubstituted > 0 && currentOrderItem.substitutions?.length > 0) {
				const itemToBeSwapped: DisplayOrderItem = {
					id: currentOrderItem.id,
					name: currentOrderItem.name,
					quantity: currentOrderItem.quantityShipped,
					rowType: DisplayOrderItemType.SWAPPED,
					swapped: true,
				};

				acc.swappedItems.push(itemToBeSwapped);

				const swappedItems: DisplayOrderItem[] = currentOrderItem.substitutions.map(
					(substitution: Substitution) => {
						return {
							id: substitution.id,
							name: substitution.name,
							quantity: substitution.quantity,
							rowType: DisplayOrderItemType.SWAPPED,
						};
					},
				);

				acc.swappedItems = [...acc.swappedItems, ...swappedItems];
			}

			if (currentOrderItem.quantityShipped > 0) {
				const includedItem: DisplayOrderItem = {
					id: currentOrderItem.id,
					name: currentOrderItem.name,
					quantity: currentOrderItem.quantityShipped,
					rowType: DisplayOrderItemType.INCLUDED,
				};
				acc.includedItems.push(includedItem);
			}

			if (currentOrderItem.quantityShorted > 0) {
				const shortedItem: DisplayOrderItem = {
					id: currentOrderItem.id,
					name: currentOrderItem.name,
					quantity: currentOrderItem.quantityShorted,
					rowType: DisplayOrderItemType.UNAVAILABLE,
				};
				acc.unavailableItems.push(shortedItem);
			}

			return acc;
		},
		{
			swappedItems: [],
			unavailableItems: [],
			includedItems: [],
		},
	);

	return {
		[DisplayOrderItemType.SWAPPED]: mappedOrders.swappedItems,
		[DisplayOrderItemType.UNAVAILABLE]: mappedOrders.unavailableItems,
		[DisplayOrderItemType.INCLUDED]: mappedOrders.includedItems,
	};
};
