import React, { FC } from 'react';
import classnames from 'classnames';

import { Icon } from 'src/components/icon/icon.component';

import { OrderIconLabelProps } from './orderIconLabel.type';
import './orderIconLabel.style.scss';

const OrderIconLabel: FC<OrderIconLabelProps> = ({
	iconName,
	numberOfItems,
	unit,
	className,
	style,
}: OrderIconLabelProps) => {
	return (
		<div className={classnames('order-icon-label', className)} style={style}>
			<Icon className={'order-icon-label__icon'} iconName={iconName} />
			<div className={'order-icon-label__content'}>
				<span className={'content_number'}>{numberOfItems}</span>
				<span className={'content_unit'}>{unit}</span>
			</div>
		</div>
	);
};

export { OrderIconLabel };
