import { CachedStore } from 'src/state/store/cache/cache.type';

export interface C4StoreStateStore extends CachedStore {
	selectedStoreId?: string;
	stores: {
		[id: string]: CarrefourStore;
	};
}

export interface CarrefourStore {
	id: string;
	name?: string;
	locationReferenceId: string;
	companyId: string;
	warehouseId: string;
	storageFeatures: boolean;
	notifications?: {
		emails: string[];
		phoneNumbers: string[];
	};
}

export enum StoreType {
	Hypermarket = 'Hypermarché Carrefour',
	Market = 'Carrefour Market',
	Express = 'Carrefour Express',
	Unknown = 'Carrefour',
}

export enum NotificationType {
	Sms = 'Sms',
	Email = 'Email',
	Push = 'Push',
}

/* ------------- Api Responses  ------------- */
export interface ApiCarrefourStore {
	id: string;
	name?: string;
	locationReferenceId: string;
	companyId: string;
	warehouseId: string;
	storageFeatures: boolean;
	notifications?: {
		emails: string[];
		phoneNumbers: string[];
	};
}

/* ------------- Action Payload Types  ------------- */
export interface CarrefourStoresSetActionType {
	stores: CarrefourStore[];
}

export interface SelectedStoreSetActionType {
	id: string;
}

export interface SelectedStoreChangeActionType {
	storeId: string;
}

export interface SelectedStoreSaveNotificationsActionType {
	type: NotificationType;
	data: string[];
}

export interface SelectedStoreSetStorageActionType {
	storageFeatures: boolean;
}

export interface StoreSetNotificationsActionType {
	id: string;
	emails: string[];
	phoneNumbers: string[];
}

export interface StoreSetStorageActionType {
	id: string;
	storageFeatures: boolean;
}
