import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { SettingsList } from 'src/components/list/settingsList/settingsList.component';
import { ListItem } from 'src/components/list/listItem/listItem.component';

import { BackofficePortalProps } from './backofficePortalProps';

import './backofficePortal.style.scss';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const BackofficePortalContainer: FC<BackofficePortalProps> = ({
	navToRefund,
	navToSendMessage,
}: BackofficePortalProps) => {
	//TODO Implement container
	const { t } = useTranslation();

	return (
		<div className="backoffice-container">
			<div className="backoffice__options">
				<SettingsList>
					{/*<ListItem key="messages" onClick={navToSendMessage}>*/}
					{/*	<span className="content__label">{t('backoffice.options.message')}</span>*/}
					{/*</ListItem>*/}
					<ListItem key="refund" onClick={navToRefund}>
						<span className="content__label">{t('backoffice.options.refund')}</span>
					</ListItem>
				</SettingsList>
			</div>
			<h3>messages coming soon...</h3>
		</div>
	);
};

export { BackofficePortalContainer };
