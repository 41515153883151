import { CacheStore } from './cache.type';

export const ACTION_INITIAL_STARTUP_DATA_FETCH = 'ACTION_INITIAL_STARTUP_DATA_FETCH';
export const ACTION_SELECTED_STORE_STARTUP_DATA_FETCH = 'ACTION_SELECTED_STORE_STARTUP_DATA_FETCH';

export const ACTION_LAST_SYNCED_INITIIAL_CACHE_SET = 'ACTION_LAST_SYNCED_INITIIAL_CACHE_SET';
export const ACTION_LAST_SYNCED_AUTHENTICATED_CACHE_SET = 'ACTION_LAST_SYNCED_AUTHENTICATED_CACHE_SET';

export const ACTION_CACHE_STORE_CLEAR = 'ACTION_CACHE_STORE_CLEAR';
export const ACTION_EXPIRED_CACHED_ITEMS_CLEAR = 'ACTION_EXPIRED_CACHED_ITEMS_CLEAR';

export const CACHE_INITIAL_STATE: CacheStore = {};
