import { InboundStore, ReturnDeliveries } from './inbound.type';

export const ACTION_INBOUND_DELIVERIES_FETCH = 'ACTION_INBOUND_DELIVERIES_FETCH';
export const ACTION_INBOUND_DELIVERIES_SET = 'ACTION_INBOUND_DELIVERIES_SET';

export const ACTION_INBOUND_DELIVERY_ACCEPT = 'ACTION_INBOUND_DELIVERY_ACCEPT';

export const ACTION_LAST_FETCHED_INBOUND_DELIVERIES_SET = 'ACTION_LAST_FETCHED_INBOUND_DELIVERIES_SET';

export const ACTION_INBOUND_DELIVERIES_CLEAR = 'ACTION_INBOUND_DELIVERIES_CLEAR';

export const ACTION_INBOUND_DELIVERIES_TO_RETURN_FETCH = 'ACTION_INBOUND_DELIVERIES_TO_RETURN_FETCH';
export const ACTION_INBOUND_DELIVERIES_TO_RETURN_SET = 'ACTION_INBOUND_DELIVERIES_TO_RETURN_SET';

export const RETURN_DELIVERIES_TOTE_INITIAL_STATE: ReturnDeliveries = {
	show: false,
	ordersToReturn: 0,
};

export const INBOUND_INITIAL_STATE: InboundStore = {
	deliveries: {},
	returnDeliveries: RETURN_DELIVERIES_TOTE_INITIAL_STATE,
};
