import { call, CallEffect, put, takeLatest } from 'redux-saga/effects';

import { actionStatusAdd } from 'src/state/store/actionStatus/actionStatus.action';
import { ActionStatusEnum } from 'src/state/store/actionStatus/actionStatus.type';
import { MooseApiRoutes } from 'src/config/api.config';
import { ExtendedAxiosResponse, fetchRequest, HttpMethod } from 'src/state/sagas/network/network.saga';
import { isOffline } from 'src/helpers/cache.helpers';
import { ACTION_API_VERSION_FETCH } from 'src/state/store/settings/settings.const';
import { actionApiVersionSet } from 'src/state/store/settings/settings.action';
import { ApiVersionResponse } from 'src/state/store/settings/settings.type';
import { authorize } from 'src/state/sagas/auth/authorization.saga';

function* fetchApiVersion(): Generator<CallEffect<any>, ExtendedAxiosResponse, ExtendedAxiosResponse> {
	return yield call(fetchRequest, { method: HttpMethod.Get, url: MooseApiRoutes.Version }, [authorize]);
}

function* apiVersionFetchWorker() {
	// Create action status running
	yield put(actionStatusAdd({ type: ACTION_API_VERSION_FETCH }, ActionStatusEnum.RUNNING));

	try {
		const { data }: ExtendedAxiosResponse<ApiVersionResponse> = yield call(fetchApiVersion);

		yield put(actionApiVersionSet(data.version));

		// Create action status success
		yield put(actionStatusAdd({ type: ACTION_API_VERSION_FETCH }, ActionStatusEnum.SUCCESS));
	} catch (e) {
		// General error
		yield put(actionStatusAdd({ type: ACTION_API_VERSION_FETCH }, ActionStatusEnum.ERROR, isOffline()));
	}
}

export function* apiVersionFetchSaga() {
	yield takeLatest(ACTION_API_VERSION_FETCH, apiVersionFetchWorker);
}
