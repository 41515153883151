import { Mapper } from 'src/state/mappers/mapper.type';
import { ApiOrderMultipleDate, Order } from 'src/state/store/order/order.type';

import { orderMapper } from './order.mapper';

class OrderMultiDayMapper extends Mapper<ApiOrderMultipleDate, Order[]> {
	map(data: ApiOrderMultipleDate): Order[] {
		return data.Orders.map((orders): Order => {
			return orderMapper.map(orders);
		});
	}
}

export const orderMultiDayMapper = new OrderMultiDayMapper();
