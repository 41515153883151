import { call, CallEffect, put, select, takeLatest } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';

import { ActionStatusEnum, ActionType } from 'src/state/store/actionStatus/actionStatus.type';
import { StorageFetchStorageSlotActionType, StorageSlot } from 'src/state/store/storage/storage.type';
import { actionStatusAdd } from 'src/state/store/actionStatus/actionStatus.action';
import { ACTION_STORAGE_STORAGE_SLOT_DETAILS_FETCH } from 'src/state/store/storage/storage.const';
import { selectSelectedStoreId } from 'src/state/store/carrefourStore/carrefourStore.selector';
import { fetchRequest, HttpMethod } from 'src/state/sagas/network/network.saga';
import { replaceColonPrefixed } from 'src/helpers/message.helpers';
import { MooseApiRoutes } from 'src/config/api.config';
import { authorize } from 'src/state/sagas/auth/authorization.saga';
import { storageSlotMapper } from 'src/state/mappers/storage/storageSlot.mapper';
import { actionStorageSlotDetailsSet } from 'src/state/store/storage/storage.action';

function* fetchStorageSlotDetails(
	storeId: string,
	storageSlotId: string,
): Generator<CallEffect, AxiosResponse, AxiosResponse> {
	return yield call(
		fetchRequest,
		{
			method: HttpMethod.Get,
			url: replaceColonPrefixed(MooseApiRoutes.StorageStorageSlotDetails, {
				storeUuid: storeId,
				storageSlotUuid: storageSlotId,
			}),
		},
		[authorize],
		storageSlotMapper,
	);
}

function* fetchStorageSlotDetailsWorker(action: ActionType<StorageFetchStorageSlotActionType>) {
	if (!action.payload) return;

	// Create action status running
	yield put(actionStatusAdd({ type: ACTION_STORAGE_STORAGE_SLOT_DETAILS_FETCH }, ActionStatusEnum.SUCCESS));

	try {
		// Get selected store id
		const selectedCarrefourStoreId: string = yield select(selectSelectedStoreId)!;
		// Get storage slot id
		const { storageSlotId } = action.payload;

		// Execute API call
		const { data }: AxiosResponse<StorageSlot> = yield call(
			fetchStorageSlotDetails,
			selectedCarrefourStoreId,
			storageSlotId,
		);
		// Save storage slot to store
		yield put(actionStorageSlotDetailsSet(data));

		// Create action status success
		yield put(actionStatusAdd({ type: ACTION_STORAGE_STORAGE_SLOT_DETAILS_FETCH }, ActionStatusEnum.SUCCESS));
	} catch (err) {
		// Create action status error
		yield put(actionStatusAdd({ type: ACTION_STORAGE_STORAGE_SLOT_DETAILS_FETCH }, ActionStatusEnum.ERROR));
	}
}

export function* storageSlotDetailsFetchSaga() {
	yield takeLatest(ACTION_STORAGE_STORAGE_SLOT_DETAILS_FETCH, fetchStorageSlotDetailsWorker);
}
