import { WithNavbarHeaderProps } from 'src/hoc/withNavbarHeader/withNavbarHeader.type';
import { Pally, StorageSlot } from 'src/state/store/storage/storage.type';

export interface AppRouterProps {
	isLoggedIn: boolean;
	numberOfIncoming: number;
	numberOfStorage: number;
	numberOfOutgoing: number;
}

export enum AppPages {
	Incoming = '/tabs/incoming',
	Storage = '/tabs/storage',
	Outgoing = '/tabs/outgoing',
	Settings = '/tabs/settings',
	Language = '/tabs/settings/language',
	NotificationSettings = '/tabs/settings/notifications',
	EmailSettings = '/tabs/settings/email',
	SmsSettings = '/tabs/settings/sms',
}

export interface OrderDetailPageProps extends WithNavbarHeaderProps {
	orderId?: string;
	currentDateKey?: string;
	externalOrderId?: string;
	currentOrderIndex?: number;
}

export interface OrdersOverviewProps {
	currentDateKey: string;
}

export interface StorageDetailPageProps extends WithNavbarHeaderProps {
	storageItem: StorageSlot;
}

export interface StoragePallyDetailPageProps extends WithNavbarHeaderProps {
	storageItem: Pally;
}

export interface StorageScannerPageProps extends WithNavbarHeaderProps {
	storageItem?: Pally | StorageSlot;
}
