import React, { FC, useEffect, useRef, useState } from 'react';

import { TIME_BEFORE_DELIVERY_TIMEOUT } from './timeBeforeNextDeliveryLabel.const';
import { getTimeBeforeNextDelivery } from './timeBeforeNextDeliveryLabel.helpers';
import { TimeBeforeNextDeliveryLabelProps } from './timeBeforeNextDeliveryLabel.type';

const TimeBeforeNextDeliveryLabel: FC<TimeBeforeNextDeliveryLabelProps> = ({
	openInboundDeliveries,
	appLanguage,
	className,
}: TimeBeforeNextDeliveryLabelProps) => {
	const intervalRef = useRef<ReturnType<typeof setInterval>>();

	const [timeBeforeNextDelivery, setTimeBeforeNextDelivery] = useState<string | undefined>();

	useEffect(() => {
		if (!openInboundDeliveries || !openInboundDeliveries.length) {
			stopTimer();

			return;
		}

		setTimeBeforeNextDelivery(getTimeBeforeNextDelivery(openInboundDeliveries, appLanguage));

		startTimer();

		return () => {
			stopTimer();
		};
	}, [openInboundDeliveries, appLanguage]);

	const startTimer = () => {
		stopTimer();

		intervalRef.current = setInterval(updateTimeBeforeNextDelivery, TIME_BEFORE_DELIVERY_TIMEOUT);
	};

	const stopTimer = () => {
		if (intervalRef.current) {
			clearInterval(intervalRef.current);
		}
	};

	const updateTimeBeforeNextDelivery = () => {
		if (new Date().getSeconds() === 0) {
			const timeBeforeNextDelivery = getTimeBeforeNextDelivery(openInboundDeliveries, appLanguage);

			setTimeBeforeNextDelivery(timeBeforeNextDelivery);

			if (!timeBeforeNextDelivery) {
				stopTimer();
			}
		}
	};

	return <span className={className}>{timeBeforeNextDelivery ? `± ${timeBeforeNextDelivery}` : '-'}</span>;
};

export { TimeBeforeNextDeliveryLabel };
