import { QuaggaDecodedCode } from './pwaScanner.type';

export const getQuaggaErrorsMedian = (errors: number[]) => {
	errors.sort((a: number, b: number) => a - b);
	const half = Math.floor(errors.length / 2);
	if (errors.length % 2 === 1) {
		return errors[half];
	}

	return (errors[half - 1] + errors[half]) / 2;
};

export const getMedianOfQuaggaCodeErrors = (decodedCodes: QuaggaDecodedCode[]) => {
	const errors: number[] = decodedCodes.filter((x) => x.error !== undefined).map((x) => x.error) as number[];

	return getQuaggaErrorsMedian(errors);
};
