import { Mapper } from 'src/state/mappers/mapper.type';

interface ApiCreatedItem {
	success: boolean;
	message: string;
	extraInfo: {
		id: string;
	};
}

class CreatedItemMapper extends Mapper<ApiCreatedItem, string> {
	map = (data: ApiCreatedItem): string => {
		if (!data?.extraInfo?.id) {
			return this.throwInvalid();
		}

		return data.extraInfo.id;
	};
}

export const createdItemMapper = new CreatedItemMapper();
