import React, { FC } from 'react';
import { useHistory } from 'react-router';
import { useParams } from 'react-router-dom';

import { OutgoingChangeTimeContainer } from 'src/containers/app/outgoing/outgoingChangeTime/outgoingChangeTime.container';
import { withNavbarHeader } from 'src/hoc/withNavbarHeader/withNavbarHeader.hoc';
import { OrderDetailPageProps } from 'src/navigation/app/appRouter.type';

const OutgoingChangeTimePageComponent: FC<OrderDetailPageProps> = ({ currentDateKey }: OrderDetailPageProps) => {
	const history = useHistory();
	const { orderId } = useParams<Record<string, string | undefined>>();

	const goBack = () => {
		history.goBack();
	};

	return <OutgoingChangeTimeContainer goBack={goBack} orderId={orderId} currentDateKey={currentDateKey} />;
};

const OutgoingChangeTimePage = withNavbarHeader<OrderDetailPageProps>(OutgoingChangeTimePageComponent);

export { OutgoingChangeTimePage };
