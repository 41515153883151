import React from 'react';
import { useHistory } from 'react-router-dom';

import { SettingsContainer } from 'src/containers/app/settings/settings.container';
import { AppPages } from 'src/navigation/app/appRouter.type';
import { RootPages } from 'src/navigation/router.type';

const SettingsOverviewPage = () => {
	const history = useHistory();

	const navToLanguage = () => {
		history.push(AppPages.Language);
	};

	const navToNotifications = () => {
		history.push(AppPages.NotificationSettings);
	};

	const navToStoreSelection = () => {
		history.push(RootPages.StoreSelection);
	};

	return (
		<SettingsContainer
			navToStoreSelection={navToStoreSelection}
			navToLanguage={navToLanguage}
			navToNotifications={navToNotifications}
		/>
	);
};

export { SettingsOverviewPage };
