import React, { ChangeEvent, CSSProperties, FC, useEffect, useMemo, useRef, useState } from 'react';
import { ListOnScrollProps } from 'react-window';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import PullToRefresh from 'react-simple-pull-to-refresh';

import { ListIndicator } from 'src/components/list/listIndicator/listIndicator.component';
import { IconNames } from 'src/components/icon/icon.type';
import { Colors } from 'src/assets';
import { FlatList } from 'src/components/list/flatList/flatList.component';
import { Order, OrderState } from 'src/state/store/order/order.type';
import { actionCustomerByOrderIdFetch } from 'src/state/store/order/order.action';
import { OrderListItem } from 'src/components/list/listItem/orderListItem/orderListItem.component';
import 'src/containers/app/outgoing/outgoingOverview/outgoingOverviewContainer.style.scss';
import { SearchBar } from 'src/components/searchBar/searchBar.component';

import { OrderOverviewContainerProps } from './orderOverview.type';
import {
	getIndexOfFirstOrderInViewport,
	getInitialScrollOffset,
	getScrollOffsteOfEarliestLateOrder,
} from './orderOverview.helpers';
import { ORDER_LIST_MARGIN, ORDER_ROW_HEIGHT, ORDER_ROW_ITEM_MARGIN } from './orderOverview.const';

export const OrderOverviewContainer: FC<OrderOverviewContainerProps> = ({
	currentDateKey,
	onNavigateToOrderActions,
	onNavigateToOrderDetails,
	ordersByDate,
	onRefresh,
}: OrderOverviewContainerProps) => {
	const listRef = useRef<any>(null);

	const { t } = useTranslation();

	// internal state to keep track if the late orders should be visible in the viewport
	const [lateOrdersVisible, setLateOrdersVisible] = useState<boolean>(false);
	// internal state that is used as visible data for our flatlist
	const [filteredOrders, setFilteredOrders] = useState<Order[]>([]);

	// used to display the late orders in the floating button
	const numberOfLateOrders = useMemo(
		() => ordersByDate.filter((order) => order.state === OrderState.CustomerLate).length,
		[ordersByDate],
	);

	// flag that checks if the store has late orders
	const hasLateOrders: boolean = numberOfLateOrders > 0;

	// initial scroll offset of the viewport
	const initialScrollOffset = getInitialScrollOffset(ordersByDate, hasLateOrders);
	// scroll offset that is used if we want to scroll to the earliest late order
	const earliestLateOrderScrollOffset = getScrollOffsteOfEarliestLateOrder(ordersByDate);
	// index of latest late order (used to check if we should hide or show latest order)
	const indexOfFirstOrderInViewport = getIndexOfFirstOrderInViewport(ordersByDate);

	const dispatch = useDispatch();

	useEffect(() => {
		setFilteredOrders(ordersByDate);
		scrollToInitialScrollOffset();
	}, [ordersByDate]);

	const scrollToEarliestLateOrder = () => {
		// set timeout is used to wait until everything is
		setTimeout(() => {
			listRef?.current?.scrollTo({
				left: 0,
				top: earliestLateOrderScrollOffset,
				behavior: 'smooth',
			});
		});
	};

	const scrollToInitialScrollOffset = () => {
		setTimeout(() => {
			listRef?.current?.scrollTo({
				left: 0,
				top: initialScrollOffset,
			});
		});
	};

	const navigateToOrderPage = (order: Order) => {
		if (order.state === OrderState.CancelReturn || order.state === OrderState.PickedUp) {
			onNavigateToOrderDetails(order.id, order.clientOrderId, currentDateKey);

			return;
		}

		const foundIndex = ordersByDate.findIndex(
			(o: Order) => o.id === order.id && o.clientOrderId === order.clientOrderId,
		);
		onNavigateToOrderActions(foundIndex < 0 ? 0 : foundIndex, currentDateKey, ordersByDate);
	};

	const showLateOrders = () => {
		setLateOrdersVisible(true);
		scrollToEarliestLateOrder();
	};

	const onScroll = ({ scrollOffset }: ListOnScrollProps) => {
		setLateOrdersVisible(scrollOffset < initialScrollOffset);
	};

	const onSearchStringChange = (e: ChangeEvent<HTMLInputElement>) => {
		const { target } = e;

		const searchString = target.value.startsWith('#') ? target.value.substring(1) : target.value.toLowerCase();

		if (!searchString || searchString.trim() === '') {
			onClearSearchString();

			return;
		}

		setLateOrdersVisible(true);
		scrollToEarliestLateOrder();
		setFilteredOrders(
			ordersByDate.filter(
				(order) =>
					order.clientOrderId.toLowerCase().includes(searchString) ||
					order.customer?.firstname.toLowerCase().includes(searchString) ||
					order.customer?.lastname.toLowerCase().includes(searchString),
			),
		);
	};

	const onClearSearchString = () => {
		setLateOrdersVisible(false);
		setFilteredOrders(ordersByDate);
		scrollToInitialScrollOffset();
	};

	const isItemLoaded = (index: number) => !!filteredOrders[index].customer;

	const loadMoreItems = (startIndex: number, stopIndex: number): Promise<void> => {
		const ordersWithoutCustomers: string[] = ordersByDate
			.slice(startIndex, stopIndex + 1)
			.filter((order: Order) => !order.customer)
			.map((order) => order.id);

		return new Promise((resolve) => {
			for (const orderId of ordersWithoutCustomers) {
				dispatch(actionCustomerByOrderIdFetch(orderId, currentDateKey));
			}

			resolve();
		});
	};

	const renderRow = (item: Order, index: number, style?: CSSProperties) => {
		if (!lateOrdersVisible && hasLateOrders && index === indexOfFirstOrderInViewport - 1) {
			return null;
		}

		return (
			<OrderListItem
				onClick={() => navigateToOrderPage(item)}
				state={item.state}
				firstName={item.customer?.firstname}
				lastName={item.customer?.lastname}
				time={new Date(item.timeslot.start)}
				barcode={item.clientOrderId}
				sequenceNumber={item.sequenceNumber}
				customer={item.customer}
				orderId={item.id}
				style={style}
			/>
		);
	};

	return (
		<>
			<div className="outgoing-search">
				<SearchBar
					placeholder={t('outgoing.search')}
					onClear={onClearSearchString}
					onChange={onSearchStringChange}
				/>
			</div>
			<PullToRefresh onRefresh={onRefresh} pullingContent={<div />}>
				<div className="outgoing-container">
					{hasLateOrders && !lateOrdersVisible ? (
						<ListIndicator
							message={`${numberOfLateOrders} ${t('outgoing.floatingLateOrderButton')}`}
							icon={IconNames.ArrowUp}
							iconPlacement="both"
							color={Colors.orange}
							onClick={showLateOrders}
							className="outgoing-container__indicator-late"
						/>
					) : undefined}
					<FlatList<Order>
						lazy={true}
						outerRef={listRef}
						initialScrollOffset={initialScrollOffset}
						data={filteredOrders}
						loadMoreItems={loadMoreItems}
						isItemLoaded={isItemLoaded}
						renderItem={renderRow}
						rowHeight={ORDER_ROW_HEIGHT}
						itemMargin={ORDER_ROW_ITEM_MARGIN}
						onScroll={onScroll}
						listMargin={{
							top: ORDER_LIST_MARGIN,
							bottom: ORDER_LIST_MARGIN,
							left: ORDER_LIST_MARGIN,
							right: ORDER_LIST_MARGIN,
						}}
					/>
				</div>
			</PullToRefresh>
		</>
	);
};
