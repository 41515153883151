import { CSSProperties } from 'react';

import { Colors } from 'src/assets';

import { OrderStatusLabelStatus } from './orderStatusLabel.type';

export const getBackgroundColorOfStatus = (status: OrderStatusLabelStatus): CSSProperties => {
	switch (status) {
		case OrderStatusLabelStatus.OPEN:
			return { backgroundColor: Colors.brightBlue };
		case OrderStatusLabelStatus.COMPLETED:
			return { backgroundColor: Colors.green };
		case OrderStatusLabelStatus.CLOSED:
			return { backgroundColor: Colors.silverGrey };
		default:
			return { backgroundColor: Colors.brightBlue };
	}
};
