import { put, takeLatest } from 'redux-saga/effects';

import { actionStatusAdd } from 'src/state/store/actionStatus/actionStatus.action';
import { ActionStatusEnum, ActionType } from 'src/state/store/actionStatus/actionStatus.type';
import { ACTION_SELECTED_CARREFOUR_STORE_CHANGE } from 'src/state/store/carrefourStore/carrefourStore.const';
import { actionSelectedStoreSet } from 'src/state/store/carrefourStore/carrefourStore.action';
import { SelectedStoreChangeActionType } from 'src/state/store/carrefourStore/carrefourStore.type';
import { isOffline } from 'src/helpers/cache.helpers';
import { actionInboundDeliveriesClear, actionInboundDeliveriesFetch } from 'src/state/store/inbound/inbound.action';
import { actionOutboundOrdersClear, actionOutboundOrderWithDetailsFetch } from 'src/state/store/order/order.action';

export function* selectedStoreChangeSaga() {
	yield takeLatest(ACTION_SELECTED_CARREFOUR_STORE_CHANGE, selectedStoreChangeWorker);
}

function* selectedStoreChangeWorker(action: ActionType<SelectedStoreChangeActionType>) {
	// Create action status running
	yield put(actionStatusAdd({ type: ACTION_SELECTED_CARREFOUR_STORE_CHANGE }, ActionStatusEnum.RUNNING, isOffline()));

	try {
		if (!action.payload) {
			return;
		}

		const { storeId } = action.payload;
		yield put(actionSelectedStoreSet(storeId));

		// clear all inbound deliveries and orders
		yield put(actionInboundDeliveriesClear());
		yield put(actionOutboundOrdersClear());

		// fetch all data again with the correct storeId
		yield put(actionInboundDeliveriesFetch());
		yield put(actionOutboundOrderWithDetailsFetch());

		yield put(
			actionStatusAdd({ type: ACTION_SELECTED_CARREFOUR_STORE_CHANGE }, ActionStatusEnum.SUCCESS, isOffline()),
		);
	} catch (e) {
		// General error
		yield put(
			actionStatusAdd({ type: ACTION_SELECTED_CARREFOUR_STORE_CHANGE }, ActionStatusEnum.ERROR, isOffline()),
		);
	}
}
