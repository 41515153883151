import React, { FC } from 'react';
import classnames from 'classnames';

import { BadgeProps } from './badge.type';
import './badge.style.scss';

const Badge: FC<BadgeProps> = ({ label, style, className, isActive }: BadgeProps) => {
	return (
		<div className={classnames('badge', className, { active: isActive })} style={style}>
			{label}
		</div>
	);
};

export { Badge };
