import React from 'react';
import ReactDOM from 'react-dom';
import { defineCustomElements } from '@ionic/pwa-elements/loader';

import {
	addColorVariablesToStyleSheet,
	addFontSizeVariablesToStyleSheet,
	addFontTypeVariablesToStyleSheet,
	addMetricsVariablesToStyleSheet,
} from 'src/helpers/style.helpers';

import App from './App';

import 'src/assets/fonts/fonts.scss';

// add CSS variables
addColorVariablesToStyleSheet();
addFontSizeVariablesToStyleSheet();
addFontTypeVariablesToStyleSheet();
addMetricsVariablesToStyleSheet();

ReactDOM.render(
	<React.StrictMode>
		<App />
	</React.StrictMode>,
	document.getElementById('root'),
);

defineCustomElements(window);
