import React from 'react';
import { useTranslation } from 'react-i18next';

import { Icon } from 'src/components/icon/icon.component';
import { IconNames, IconSizes } from 'src/components/icon/icon.type';
import { IncomingContainer } from 'src/containers/app/incoming/incoming.container';
import { selectLastFetchedInboundDeliveries } from 'src/state/store/inbound/inbound.selector';

const IncomingOverviewPage = () => {
	const { t } = useTranslation();

	return (
		<IncomingContainer
			title={t('incoming.title')}
			icon={<Icon iconName={IconNames.Incoming} size={IconSizes.Large} />}
			lastFetchedSelector={selectLastFetchedInboundDeliveries}
		/>
	);
};

export { IncomingOverviewPage };
