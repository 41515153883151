import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { getBrowserLanguage } from 'src/assets/translations/i18n';
import { actionAppLanguageSet } from 'src/state/store/settings/settings.action';
import { selectAppLanguagePrefix } from 'src/state/store/settings/settings.selector';
import { AppLanguage } from 'src/state/store/settings/settings.type';

const LanguageProvider: FC<{ children: React.ReactNode }> = ({ children }: { children: React.ReactNode }) => {
	const { i18n } = useTranslation();
	const appLanguage: AppLanguage | undefined = useSelector(selectAppLanguagePrefix);
	const dispatch = useDispatch();

	useEffect(() => {
		// set browser language as language
		if (!appLanguage) {
			const browserAppLanguage = getBrowserLanguage();
			dispatch(actionAppLanguageSet(browserAppLanguage));

			return;
		}

		i18n.changeLanguage(appLanguage || 'en');
	}, [appLanguage]);

	return <>{children}</>;
};
export { LanguageProvider };
