import { Mapper } from 'src/state/mappers/mapper.type';
import { ApiOrderState, OrderState } from 'src/state/store/order/order.type';

class OrderStateMapper extends Mapper<ApiOrderState, OrderState> {
	map(data: ApiOrderState | undefined): OrderState {
		switch (data) {
			case ApiOrderState.Expected:
				return OrderState.Expected;
			case ApiOrderState.AtStore:
				return OrderState.AtStore;
			case ApiOrderState.CustomerLate:
				return OrderState.CustomerLate;
			case ApiOrderState.PickedUp:
				return OrderState.PickedUp;
			case ApiOrderState.CancelReturn:
				return OrderState.CancelReturn;
			case ApiOrderState.CustomerDelayed:
				return OrderState.CustomerDelayed;
			case ApiOrderState.TimeChanged:
				return OrderState.TimeChanged;
			default:
				return OrderState.Unknown;
		}
	}

	mapToApi(state: OrderState): ApiOrderState {
		switch (state) {
			case OrderState.Expected:
				return ApiOrderState.Expected;
			case OrderState.AtStore:
				return ApiOrderState.AtStore;
			case OrderState.CustomerLate:
				return ApiOrderState.CustomerLate;
			case OrderState.PickedUp:
				return ApiOrderState.PickedUp;
			case OrderState.CancelReturn:
				return ApiOrderState.CancelReturn;
			case OrderState.CustomerDelayed:
				return ApiOrderState.CustomerDelayed;
			case OrderState.TimeChanged:
				return ApiOrderState.TimeChanged;
			default:
				return ApiOrderState.Unknown;
		}
	}
}

export const orderStateMapper = new OrderStateMapper();
