import { useAuthContext, useUserMetadata } from '@foodx/fx-react-utils';

import { isDev } from 'src/helpers/env.helpers';

const useIsLoggedIn = (): boolean => {
	if (process.env.REACT_APP_USE_AUTH === 'false' && isDev()) {
		return true;
	}

	// eslint-disable-next-line react-hooks/rules-of-hooks
	const { accessToken, idToken } = useAuthContext();
	// eslint-disable-next-line react-hooks/rules-of-hooks
	const { metaToken } = useUserMetadata();

	return !!accessToken && !!metaToken && !!idToken;
};

export { useIsLoggedIn };
