import { Mapper } from 'src/state/mappers/mapper.type';
import { ApiPally, Pally } from 'src/state/store/storage/storage.type';
import { orderMapper } from 'src/state/mappers/order/order.mapper';

class PallyMapper extends Mapper<ApiPally, Pally> {
	map = (data: ApiPally): Pally => {
		if (!data || !data.id || !data.code || !data.label) {
			return this.throwInvalid();
		}

		return {
			id: data.id,
			code: data.code,
			label: data.label,
			orderIds: data.orderIds,
			orders: data.orders?.map(orderMapper.map),
			storageSlotId: data.storageSlotId,
		};
	};
}

export const pallyMapper = new PallyMapper();
