import React, { CSSProperties, FC } from 'react';
import classnames from 'classnames';

import { TextAreaProps } from './textArea.type';
import { errorTextAreaStyle } from './textArea.const';
import './textArea.style.scss';

const TextArea: FC<TextAreaProps> = ({
	children,
	className,
	resizable = false,
	error,
	touched,
	...props
}: TextAreaProps) => {
	const errorStyles: CSSProperties = error && touched ? { ...errorTextAreaStyle } : {};

	return (
		<div className="text-area">
			<textarea style={errorStyles} className={classnames(className, { 'not-resizable': !resizable })} {...props}>
				{children}
			</textarea>
			{error && touched ? <span className="error">{error}</span> : null}
		</div>
	);
};

export { TextArea };
