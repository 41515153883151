import React, { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';

import { RestrictedRoute } from 'src/components/navigation/restrictedRoute/restrictedRoute.component';
import { StoreSelectionPage } from 'src/pages/storeSelection/storeSelection.page';
import { selectShouldShowStoreSelector } from 'src/state/store/carrefourStore/carrefourStore.selector';
import { AppPages } from 'src/navigation/app/appRouter.type';
import { AppRouter } from 'src/navigation/app/app.router';
import { PageNotFound } from 'src/components/pageNotFound/pageNotFound.component';
import { useIsLoggedIn } from 'src/hooks/useIsLoggedIn.hook';
import { selectUserNotAuthorizedReason } from 'src/state/store/user/user.selector';
import { AuthorizationErrorCodes } from 'src/state/store/user/user.type';
import { useLoader } from 'src/hooks/useLoader.hook';

import { RootPages } from './router.type';
import { BackofficeRouter } from './backoffice/backoffice.router';

const RootRouter: FC = () => {
	const isLoggedIn: boolean = useIsLoggedIn();
	const { onHideLoader } = useLoader();

	const shouldShowStoreSelector: boolean = useSelector(selectShouldShowStoreSelector);
	const userNotAuthorizedReason: AuthorizationErrorCodes | undefined = useSelector(selectUserNotAuthorizedReason);

	useEffect(() => {
		onHideLoader();
		localStorage.removeItem('last_date_orderOverview');
	}, []);

	useEffect(() => {
		if (!shouldShowStoreSelector && !userNotAuthorizedReason) {
			onHideLoader();
		}
	}, [shouldShowStoreSelector, userNotAuthorizedReason]);

	return (
		<BrowserRouter>
			<Switch>
				<RestrictedRoute
					canRoute={isLoggedIn && !shouldShowStoreSelector && !userNotAuthorizedReason}
					redirectRoute={RootPages.StoreSelection}
					exact
					path="/">
					<Redirect to={AppPages.Incoming} />
				</RestrictedRoute>
				<RestrictedRoute
					redirectRoute={RootPages.StoreSelection}
					canRoute={isLoggedIn && !shouldShowStoreSelector && !userNotAuthorizedReason}
					path={RootPages.App}>
					<AppRouter />
				</RestrictedRoute>
				<RestrictedRoute
					redirectRoute={RootPages.StoreSelection}
					canRoute={isLoggedIn}
					path={RootPages.Backoffice}>
					<BackofficeRouter />
				</RestrictedRoute>
				<Route exact path={RootPages.StoreSelection}>
					<StoreSelectionPage />
				</Route>
				<Route component={PageNotFound} />
			</Switch>
		</BrowserRouter>
	);
};

export default RootRouter;
