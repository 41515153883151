import { createSelector } from 'reselect';

import { Store } from 'src/state/store.type';

import { CarrefourStore } from './carrefourStore.type';

const selectCarrefourStoresRecord = ({ store }: Store): Record<number, CarrefourStore> => {
	return store.stores;
};

export const selectSelectedStoreId = ({ store }: Store): string | undefined => {
	return store.selectedStoreId;
};

export const selectCarrefourStores = createSelector<Store, Record<number, CarrefourStore>, CarrefourStore[]>(
	selectCarrefourStoresRecord,
	(storeRecord) => {
		return Object.values(storeRecord);
	},
);

export const selectSelectedStore = createSelector<
	Store,
	Record<string, CarrefourStore>,
	string | undefined,
	CarrefourStore | undefined
>([selectCarrefourStoresRecord, selectSelectedStoreId], (storeRecord, selectedStoreId) => {
	if (selectedStoreId) {
		return storeRecord[selectedStoreId];
	}

	return;
});

export const selectShouldShowStoreSelector = ({ store }: Store): boolean => {
	return !store?.selectedStoreId;
};

export const selectSelectedStoreEmails = (store: Store): string[] => {
	const selectedStore: CarrefourStore | undefined = selectSelectedStore(store);

	return selectedStore?.notifications?.emails || [];
};

export const selectSelectedStorePhoneNumbers = (store: Store): string[] => {
	const selectedStore: CarrefourStore | undefined = selectSelectedStore(store);

	return selectedStore?.notifications?.phoneNumbers || [];
};

export const selectSelectedStoreStorageFeatures = (store: Store): boolean => {
	const selectedStore: CarrefourStore | undefined = selectSelectedStore(store);

	return !!selectedStore?.storageFeatures;
};

export const selectLastFetchedStores = ({ store }: Store): number | undefined => {
	return store.lastFetched;
};
