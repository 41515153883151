import React, { FC } from 'react';
import { useHistory } from 'react-router';

import { OrderDetailPageProps } from 'src/navigation/app/appRouter.type';
import { withNavbarHeader } from 'src/hoc/withNavbarHeader/withNavbarHeader.hoc';
import { OrderRefundContainer } from 'src/containers/app/outgoing/orderRefund/orderRefund.container';

const RefundPageComponent: FC<OrderDetailPageProps> = ({ orderId, currentDateKey }: OrderDetailPageProps) => {
	const history = useHistory();
	const navigateBack = () => {
		history.goBack();
	};

	return <OrderRefundContainer orderId={orderId} currentDateKey={currentDateKey} onNavigateBack={navigateBack} />;
};

const RefundPage = withNavbarHeader<OrderDetailPageProps>(RefundPageComponent);

export { RefundPage };
