import { getMSALUserMetaData } from 'src/helpers/auth.helper';
import { Roles } from 'src/models/roles.model';

const useIsRole = (role: Roles): boolean => {
	const userMetaData = getMSALUserMetaData();
	if (!userMetaData) {
		return false;
	}

	const hasRole = !!userMetaData.roles?.find((i) => i === role.valueOf());

	if (role === Roles.ADMIN && !hasRole) {
		//TODO remove temporary solution
		const keyStoreBackoffice = process.env.REACT_APP_ID_STORE_BACKOFFICE;
		if (!keyStoreBackoffice) {
			return !!userMetaData.storeIds?.find((storeId) => storeId === 'BACK_OFFICE');
		}

		return !!userMetaData.storeIds?.find((storeId) => storeId === keyStoreBackoffice);
	}

	return hasRole;
};

export { useIsRole };
