import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Icon } from 'src/components/icon/icon.component';
import { IconNames, IconSizes } from 'src/components/icon/icon.type';
import { StorageStateProps } from 'src/components/storageState/storageState.type';

import './storageState.style.scss';

const StorageState: FC<StorageStateProps> = ({ storageSlots, pallies, orders }: StorageStateProps) => {
	const { t } = useTranslation();

	return (
		<div className="storage-state__container">
			<div className="storage-state__item">
				<Icon iconName={IconNames.Storages} size={IconSizes.Normal} />
				<div className="storage-state__item-quantity">
					<span className="storage-state__item-quantity-number">{storageSlots}</span>
					<p
						className="storage-state__item-quantity-category"
						dangerouslySetInnerHTML={{ __html: t('storageState.storageSlot', { count: storageSlots }) }}
					/>
				</div>
			</div>
			<div className="storage-state__item">
				<Icon iconName={IconNames.Pallies} size={IconSizes.Normal} />
				<div className="storage-state__item-quantity">
					<span className="storage-state__item-quantity-number">{pallies}</span>
					<p className="storage-state__item-quantity-category">
						{t('storageState.pally', { count: pallies })}
					</p>
				</div>
			</div>
			<div className="storage-state__item">
				<Icon iconName={IconNames.Groceries} size={IconSizes.Normal} />
				<div className="storage-state__item-quantity">
					<span className="storage-state__item-quantity-number">{orders}</span>
					<p className="storage-state__item-quantity-category">
						{t('storageState.order', { count: orders })}
					</p>
				</div>
			</div>
		</div>
	);
};

export default StorageState;
