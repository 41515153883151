import { createReducer } from 'reduxsauce';

import CacheManager from 'src/state/cache/cacheManager';
import { ActionType } from 'src/state/store/actionStatus/actionStatus.type';
import { ACTION_LOGOUT } from 'src/state/store/general/general.const';

import {
	ACTION_CACHE_STORE_CLEAR,
	ACTION_LAST_SYNCED_INITIIAL_CACHE_SET,
	ACTION_LAST_SYNCED_AUTHENTICATED_CACHE_SET,
	CACHE_INITIAL_STATE,
} from './cache.const';
import { CacheStore, LastSyncedCacheActionType } from './cache.type';

const cacheClear = (): CacheStore => {
	CacheManager.clear().then(() => {});

	return CACHE_INITIAL_STATE;
};

const lastSyncedInitialCacheSet = (state: CacheStore, action: ActionType<LastSyncedCacheActionType>): CacheStore => {
	if (!action.payload) {
		return state;
	}

	const { lastSynced } = action.payload;

	return {
		...state,
		lastSyncedInitialData: lastSynced,
	};
};
const lastSyncedAuthenticatedCacheSet = (
	state: CacheStore,
	action: ActionType<LastSyncedCacheActionType>,
): CacheStore => {
	if (!action.payload) {
		return state;
	}

	const { lastSynced } = action.payload;

	return {
		...state,
		lastSyncedAuthenticatedData: lastSynced,
	};
};

/* ------------- Hookup Reducers To Types ------------- */

export const cacheReducer = createReducer(CACHE_INITIAL_STATE, {
	[ACTION_LAST_SYNCED_INITIIAL_CACHE_SET]: lastSyncedInitialCacheSet,
	[ACTION_LAST_SYNCED_AUTHENTICATED_CACHE_SET]: lastSyncedAuthenticatedCacheSet,
	[ACTION_CACHE_STORE_CLEAR]: cacheClear,
	[ACTION_LOGOUT]: cacheClear,
});
