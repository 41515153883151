import React, { FC } from 'react';
import Select from 'react-select';

import './selectField.style.scss';
import fontsConst from 'src/assets/fonts/fonts.const';
import { Colors } from 'src/assets';

import { SelectFieldProps } from './selectField.type';

const SelectField: FC<SelectFieldProps> = ({ error, onChange, options, placeholder, ...props }: SelectFieldProps) => {
	const customStyle = {
		option: (provided: any, state: any) => ({
			...provided,
			fond: fontsConst.type.openSans,
			// eslint-disable-next-line no-nested-ternary
			backgroundColor: state.isSelected ? Colors.nightBlue : state.isFocused ? Colors.primary : null,
		}),
		container: (provided: any, state: any) => ({
			...provided,
			border: state.isSelected ? '1px dotted pink' : null,
		}),
	};

	return (
		<div className={'select-field'}>
			<Select
				placeholder={placeholder ? placeholder : 'Select...'}
				id={props.id}
				name={props.name}
				options={options}
				onChange={(val) => {
					if (val) {
						onChange(val.value);
					}
				}}
				styles={customStyle}
				theme={(theme) => ({
					...theme,
					colors: {
						...theme.colors,
						primary: Colors.green,
					},
				})}
			/>
			{error ? <span className="error">{error}</span> : null}
		</div>
	);
};

export { SelectField };
