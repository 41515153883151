import React, { FC } from 'react';
import { useHistory } from 'react-router';

import StorageScannerContainer from 'src/containers/app/storage/storageScanner/storageScanner.container';
import { withNavbarHeader } from 'src/hoc/withNavbarHeader/withNavbarHeader.hoc';
import { StorageScannerPageProps } from 'src/navigation/app/appRouter.type';
import { Pally } from 'src/state/store/storage/storage.type';

const StorageScannerPage: FC<StorageScannerPageProps> = ({ storageItem }: StorageScannerPageProps) => {
	const history = useHistory();

	const navigateBack = () => {
		history.goBack();
	};

	const storageSlot = storageItem && 'pallies' in storageItem ? storageItem : undefined;

	const pally = storageItem && !storageSlot ? (storageItem as Pally) : undefined;

	return <StorageScannerContainer onNavigateBack={navigateBack} storageSlot={storageSlot} pally={pally} />;
};

export default withNavbarHeader(StorageScannerPage);
