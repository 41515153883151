import { StorageStore } from 'src/state/store/storage/storage.type';

export const ACTION_STORAGE_OVERVIEW_FETCH = 'ACTION_STORAGE_OVERVIEW_FETCH';
export const ACTION_STORAGE_OVERVIEW_SET = 'ACTION_STORAGE_OVERVIEW_SET';

export const ACTION_STORAGE_STORAGE_SLOT_DETAILS_FETCH = 'ACTION_STORAGE_STORAGE_SLOT_DETAILS_FETCH';
export const ACTION_STORAGE_STORAGE_SLOT_DETAILS_SET = 'ACTION_STORAGE_STORAGE_SLOT_DETAILS_SET';
export const ACTION_STORAGE_STORAGE_SLOT_DETAILS_CLEAR = 'ACTION_STORAGE_STORAGE_SLOT_DETAILS_CLEAR';

export const ACTION_STORAGE_PALLY_DETAILS_FETCH = 'ACTION_STORAGE_PALLY_DETAILS_FETCH';
export const ACTION_STORAGE_PALLY_DETAILS_SET = 'ACTION_STORAGE_PALLY_DETAILS_SET';
export const ACTION_STORAGE_PALLY_DETAILS_CLEAR = 'ACTION_STORAGE_PALLY_DETAILS_CLEAR';

export const ACTION_STORAGE_LINK = 'ACTION_STORAGE_LINK_ADD';

export const ACTION_STORAGE_DELETE_STORAGE_SLOT = 'ACTION_STORAGE_DELETE_STORAGE_SLOT';
export const ACTION_STORAGE_DELETE_PALLY = 'ACTION_STORAGE_DELETE_PALLY';

export const ACTION_STORAGE_CREATE_STORAGE_SLOT = 'ACTION_STORAGE_CREATE_STORAGE_SLOT';
export const ACTION_STORAGE_CREATE_PALLY = 'ACTION_STORAGE_CREATE_PALLY';

export const ACTION_STORAGE_CLEAR = 'ACTION_STORAGE_CLEAR';

export const STORAGE_INITIAL_STATE: StorageStore = {
	storageSlots: {},
	pallies: {},
	storageSlot: undefined,
	pally: undefined,
};
