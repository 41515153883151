import { call, put, select, takeEvery } from 'redux-saga/effects';

import { MooseApiRoutes } from 'src/config/api.config';
import { replaceColonPrefixed } from 'src/helpers/message.helpers';
import { authorize } from 'src/state/sagas/auth/authorization.saga';
import { fetchRequest, HttpMethod } from 'src/state/sagas/network/network.saga';
import { actionStatusAdd } from 'src/state/store/actionStatus/actionStatus.action';
import { ActionStatusEnum, ActionType } from 'src/state/store/actionStatus/actionStatus.type';
import { selectSelectedStoreId } from 'src/state/store/carrefourStore/carrefourStore.selector';
import { ACTION_STORAGE_DELETE_PALLY } from 'src/state/store/storage/storage.const';
import { StorageDeletePallyActionType } from 'src/state/store/storage/storage.type';
import { actionStorageOverviewFetch } from 'src/state/store/storage/storage.action';

function* deletePally(storeId: string, pallyId: string): any {
	return yield call(
		fetchRequest,
		{
			method: HttpMethod.Delete,
			url: replaceColonPrefixed(MooseApiRoutes.StorageDeletePally, {
				storeUuid: storeId,
				pallyUuid: pallyId,
			}),
		},
		[authorize],
	);
}

function* deletePallyWorker(action: ActionType<StorageDeletePallyActionType>): any {
	yield put(actionStatusAdd({ type: ACTION_STORAGE_DELETE_PALLY }, ActionStatusEnum.RUNNING));

	try {
		const selectedCarrefourStore: string | undefined = yield select(selectSelectedStoreId);

		if (!selectedCarrefourStore || !action.payload) {
			return;
		}

		const { pallyId } = action.payload;

		yield call(deletePally, selectedCarrefourStore, pallyId);

		yield put(actionStorageOverviewFetch());

		yield put(actionStatusAdd({ type: ACTION_STORAGE_DELETE_PALLY }, ActionStatusEnum.SUCCESS));
	} catch (error) {
		yield put(actionStatusAdd({ type: ACTION_STORAGE_DELETE_PALLY }, ActionStatusEnum.ERROR));
	}
}

export function* deletePallySaga() {
	yield takeEvery(ACTION_STORAGE_DELETE_PALLY, deletePallyWorker);
}
