export const isDev = (): boolean => {
	const env: string | undefined = process.env.REACT_APP_ENVIRONMENT?.toLowerCase();

	return !process.env.NODE_ENV || process.env.NODE_ENV === 'development' || (env || '').includes('dev');
};

export const isTest = (): boolean => {
	const env: string | undefined = process.env.REACT_APP_ENVIRONMENT?.toLowerCase();

	return (env || '').includes('test');
};
