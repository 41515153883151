import { AxiosResponse } from 'axios';
import { call, CallEffect, put, takeLatest } from 'redux-saga/effects';

import { actionStatusAdd } from 'src/state/store/actionStatus/actionStatus.action';
import { ActionStatusEnum, ActionType } from 'src/state/store/actionStatus/actionStatus.type';
import { ACTION_ORDER_DETAIL_FETCH } from 'src/state/store/order/order.const';
import { fetchRequest, HttpMethod } from 'src/state/sagas/network/network.saga';
import { MooseApiRoutes } from 'src/config/api.config';
import { OrderDetail, OrderDetailFetchActionType } from 'src/state/store/order/order.type';
import { actionOrderDetailSet } from 'src/state/store/order/order.action';
import { replaceColonPrefixed } from 'src/helpers/message.helpers';
import { orderDetailMapper } from 'src/state/mappers/order/orderDetail.mapper';
import { isOffline } from 'src/helpers/cache.helpers';
import { authorize } from 'src/state/sagas/auth/authorization.saga';

function* fetchOrderItems(orderId: string, forceOpen?: boolean): Generator<CallEffect, AxiosResponse, AxiosResponse> {
	return yield call(
		fetchRequest,
		{
			method: HttpMethod.Get,
			url: `${replaceColonPrefixed(MooseApiRoutes.OutgoingOrderItems, { orderId })}${
				forceOpen ? '?force=true' : ''
			}`,
		},
		[authorize],
		orderDetailMapper,
	);
}

function* orderDetailFetchWorker(action: ActionType<OrderDetailFetchActionType>) {
	// Create action status running
	try {
		if (!action.payload) {
			return;
		}
		const { orderId, forceOpen } = action.payload;

		yield put(actionStatusAdd({ type: ACTION_ORDER_DETAIL_FETCH }, ActionStatusEnum.RUNNING));
		const { data }: AxiosResponse<OrderDetail> = yield call(fetchOrderItems, orderId, forceOpen);

		yield put(actionOrderDetailSet(data));

		// Create action status success
		yield put(actionStatusAdd({ type: ACTION_ORDER_DETAIL_FETCH }, ActionStatusEnum.SUCCESS));
	} catch (e) {
		// General error
		yield put(actionStatusAdd({ type: ACTION_ORDER_DETAIL_FETCH }, ActionStatusEnum.ERROR, isOffline()));
	}
}

export function* orderDetailFetchSaga() {
	yield takeLatest(ACTION_ORDER_DETAIL_FETCH, orderDetailFetchWorker);
}
