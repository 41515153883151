import React from 'react';

import { isOffline } from 'src/helpers/cache.helpers';

export const useIsOffline = (): boolean => {
	const [offline, setOffline] = React.useState(isOffline());

	// effects
	React.useEffect(() => {
		window.addEventListener('offline', offlineListener);
		window.addEventListener('online', onlineListener);

		return () => {
			window.removeEventListener('offline', offlineListener);
			window.removeEventListener('online', onlineListener);
		};
	}, []);

	const offlineListener = () => {
		setOffline(true);
	};

	const onlineListener = () => {
		setOffline(false);
	};

	return offline;
};
