import React, { CSSProperties } from 'react';
import classNames from 'classnames';

import { fullWidthButtonStyle } from './button.const';
import { ButtonProps } from './button.type';

import './button.style.scss';

/**
 * Wrapper component that will wrap his children with extra styling and functionality
 */
const Button = ({
	fullWidth = true,
	disabled = false,
	style = {},
	backgroundColor,
	color,
	className,
	children,
	onClick,
}: ButtonProps) => {
	const buttonStyles: CSSProperties = { ...(fullWidth && fullWidthButtonStyle), ...style, backgroundColor, color };

	return (
		<button
			className={classNames('button', { disabled }, className)}
			style={buttonStyles}
			disabled={disabled}
			onClick={onClick}>
			{children}
		</button>
	);
};

export { Button };
