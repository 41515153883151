import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import { AppLanguages } from 'src/state/store/settings/settings.const';
import { AppLanguage } from 'src/state/store/settings/settings.type';

import nl from './nl.json';
import fr from './fr.json';
import en from './en.json';

const AppTranslations = {
	en,
	fr,
	nl,
};

i18n
	// pass the i18n instance to react-i18next.
	.use(initReactI18next)
	// init i18next
	// for all options read: https://www.i18next.com/overview/configuration-options
	.init({
		fallbackLng: 'en',
		debug: true,
		resources: {
			en: {
				translation: en,
			},
			nl: {
				translation: nl,
			},
			fr: {
				translation: fr,
			},
		},

		interpolation: {
			escapeValue: false, // not needed for react as it escapes by default
		},
	});
/**
 * Helper fucntion that will fetch browser language and return if it is present in the App languages
 * The fallback language is English (en)
 */
const getBrowserLanguage = (): AppLanguage => {
	const browserAppLanguage: string = navigator.language;

	const languagePrefix = browserAppLanguage.slice(0, 2);

	if (Object.keys(AppLanguages).includes(languagePrefix)) {
		return languagePrefix as AppLanguage;
	}

	return 'en';
};

export { AppTranslations, getBrowserLanguage };
