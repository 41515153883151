import { ActionType } from 'src/state/store/actionStatus/actionStatus.type';
import {
	ACTION_OUTGOING_HELPDESK_REQUEST,
	ACTION_OUTGOING_REFUND_REQUEST,
} from 'src/state/store/outgoing/outgoing.const';
import {
	OutgoingHelpdeskRequestActionType,
	OutgoingRefundRequestActionType,
} from 'src/state/store/outgoing/outgoing.type';

/**
 * Action creator that will send a request to the helpdesk
 *
 * @param orderUuid     id of the order
 * @param formdata		formdata provided with the support ticket
 *
 * @returns action creator to trigger saga
 */
export const actionOutgoingHelpdeskRequest = (
	orderUuid: string,
	formdata: FormData,
): ActionType<OutgoingHelpdeskRequestActionType> => {
	return {
		type: ACTION_OUTGOING_HELPDESK_REQUEST,
		payload: {
			orderUuid,
			formdata,
		},
	};
};

/**
 * Action creator that will send a refund request
 *
 * @param orderUuid     id of the order
 * @param formdata		formdata provided with the support ticket
 *
 * @returns action creator to trigger saga
 */
export const actionOutgoingRefundRequest = (
	orderUuid: string,
	formdata: FormData,
): ActionType<OutgoingRefundRequestActionType> => {
	return {
		type: ACTION_OUTGOING_REFUND_REQUEST,
		payload: {
			orderUuid,
			formdata,
		},
	};
};
