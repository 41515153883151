import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { DraggableBottomSheet } from 'src/components/draggableBottomSheet/draggableBottomSheet.component';
import { Colors } from 'src/assets';
import { StorageItemKey } from 'src/state/store/storage/storage.type';
import { PrimaryButton } from 'src/components/button/primaryButton/primaryButton.component';

import { NotDeletableProps } from './notDeletable.type';
import './notDeletable.style.scss';

const NotDeletableBottomSheet: FC<NotDeletableProps> = ({
	storageItemType,
	onDismiss,
	...props
}: NotDeletableProps) => {
	const { t } = useTranslation();

	const title = (): string => {
		switch (storageItemType) {
			case StorageItemKey.STORAGE_SLOT:
				return t('notDeletableModal.storageSlotNotDeletable');
			case StorageItemKey.PALLY:
				return t('notDeletableModal.pallyNotDeletable');
			default:
				return '';
		}
	};

	const description = (): string => {
		switch (storageItemType) {
			case StorageItemKey.STORAGE_SLOT:
				return t('notDeletableModal.descriptionStorageSlot');
			case StorageItemKey.PALLY:
				return t('notDeletableModal.descriptionPally');
			default:
				return '';
		}
	};

	return (
		<DraggableBottomSheet onDismiss={onDismiss} {...props}>
			<div className="not-deletable">
				<h1>{title()}</h1>
				<p>{description()}</p>

				<div className="not-deletable__buttons">
					<PrimaryButton
						fullWidth={true}
						className="button"
						backgroundColor={Colors.lightBlue}
						color={Colors.brightBlue}
						onClick={onDismiss}>
						{t('general.goBack')}
					</PrimaryButton>
				</div>
			</div>
		</DraggableBottomSheet>
	);
};

export { NotDeletableBottomSheet };
