import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { DraggableBottomSheet } from 'src/components/draggableBottomSheet/draggableBottomSheet.component';
import { IconNames, IconSizes } from 'src/components/icon/icon.type';
import { Colors } from 'src/assets';
import { IconButton } from 'src/components/button/iconButton/iconButton.component';

import { ConfirmForceCloseProps } from './confirmForceClose.type';
import './confirmForceClose.style.scss';

const ConfirmForceCloseBottomSheet: FC<ConfirmForceCloseProps> = ({ onConfirm, ...props }: ConfirmForceCloseProps) => {
	const { t } = useTranslation();

	return (
		<DraggableBottomSheet {...props}>
			<div className="confirm-force-close">
				<h1>{t('confirmForceCloseModal.title')}</h1>
				<p>{t('confirmForceCloseModal.description')}</p>

				<IconButton
					fullWidth={true}
					className="button"
					backgroundColor={Colors.green}
					iconName={IconNames.Check}
					size={IconSizes.Small}
					color="white"
					onClick={onConfirm}>
					{t('general.confirm')}
				</IconButton>
			</div>
		</DraggableBottomSheet>
	);
};

export { ConfirmForceCloseBottomSheet };
