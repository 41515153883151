import { ReactNode } from 'react';
import styled from 'styled-components';

const Container = styled.div<{
	heightProp: number;
}>`
	height: ${({ heightProp }) => heightProp}px;
	max-height: ${({ heightProp }) => heightProp + 10}px;
	width: auto;
	position: relative;
	box-sizing: border-box;

	overflow: hidden;
`;

const Content = styled.div<{
	translateValue: number;
	transition: boolean;
	rtl: boolean;
	transitionDuration?: number;
	children?: ReactNode;
	disabled?: boolean;
}>`
	height: 100%;
	width: auto;
	position: relative;
	transform: translateX(${({ translateValue, rtl }) => (rtl ? 1 : 1) * (translateValue || 0)}px);
	${({ transition, transitionDuration }) => transition && `transition: transform ${transitionDuration}ms ease-out`}
	${({ disabled }) => disabled && 'pointer-events: none'}
`;

const Delete = styled.div<{
	deleteColor: string;
	deleteWidth: number;
	buttonMargin: number;
	rtl: boolean;
	transitionDuration: number;
}>`
	position: absolute;
	right: 0;
	left: 0;
	bottom: 0;
	top: 0;
	border-radius: 11px;
	background: ${({ deleteColor }) => deleteColor};
	font-weight: 400;
	display: inline-flex;
	justify-content: flex-start;
	align-items: center;

	button {
		width: ${({ deleteWidth }) => deleteWidth}px;
		transition: margin ${({ transitionDuration }) => transitionDuration}ms ease-in-out;
		${({ buttonMargin, rtl }) => `margin-${rtl ? 'right' : 'left'}: ${buttonMargin}px`};
		text-align: center;
		height: 100%;
		background: transparent;
		border: none;
		color: white;
		font-size: 1rem;
		cursor: pointer;
	}
`;

export { Container, Content, Delete };
