import localforage from 'localforage';

export default class CacheManager {
	static writeData = (key: string, data: any) => localforage.setItem(key, data);
	static readData = (key: string) => localforage.getItem(key);
	static removeData = (key: string) => localforage.removeItem(key);
	static clear = () => localforage.clear();
	static clearApiResponseCache = async () => {
		const storeData = await localforage.getItem('persist:root');
		localforage.clear();
		localforage.setItem('persist:root', storeData);
	};
}
