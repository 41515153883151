import React, { FC, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { OrderActionsContainer } from 'src/containers/app/outgoing/orderActions/orderActions.container';
import { replaceColonPrefixed } from 'src/helpers/message.helpers';
import { OutgoingPages } from 'src/navigation/app/outgoing/outgoingRouter.type';
import { selectOrdersByDateKey } from 'src/state/store/order/order.selector';
import { Store } from 'src/state/store.type';
import { OrderDisplay } from 'src/state/store/order/order.type';
import { formatDateKeyForStore } from 'src/helpers/date.helpers';

const OrderActionsPage: FC<{ currentOrderIndex: number; currentDateKey: string }> = ({
	currentOrderIndex,
	currentDateKey,
}: {
	currentOrderIndex: number;
	currentDateKey: string;
}) => {
	const history = useHistory();
	const { t } = useTranslation();
	const orders = useSelector((state: Store) => selectOrdersByDateKey(state, formatDateKeyForStore(currentDateKey)!));

	const [currentIndex, setCurrentIndex] = useState<number>(currentOrderIndex);
	const currentOrder = useMemo(() => orders[currentIndex], [currentIndex]);

	useEffect(() => {
		const orderId = localStorage.getItem(OrderDisplay.toId);
		const index = localStorage.getItem(OrderDisplay.lastIndex);
		if (index && !orderId) {
			const numberIndex = +index;
			setCurrentIndex(numberIndex);
		}
		if (orderId) {
			const numberIndex = orders.findIndex((order) => order.id === orderId);
			setCurrentIndex(numberIndex);
			localStorage.removeItem(OrderDisplay.toId);
		}
	}, []);

	const navigateToNextOrder = () => {
		setCurrentIndex((value) => (value >= orders.length - 1 ? orders.length - 1 : (value += 1)));
	};

	const navigateToPreviousOrder = () => {
		setCurrentIndex((value) => (value === 0 ? 0 : (value -= 1)));
	};

	const navigateToOrderScanner = () => {
		const { clientOrderId: externalOrderId, id: orderId } = currentOrder;
		localStorage.setItem(OrderDisplay.lastIndex, currentIndex.toString());
		history.push(OutgoingPages.Scanner, { orderId, externalOrderId, currentDateKey });
	};

	const navigateToChangeTimePage = () => {
		const { clientOrderId: externalOrderId, id: orderId } = currentOrder;
		localStorage.setItem(OrderDisplay.lastIndex, currentIndex.toString());
		history.push(
			replaceColonPrefixed(OutgoingPages.ChangeTime, { orderId: orderId, currentDateKey: currentDateKey }),
			{
				externalOrderId,
				currentDateKey,
			},
		);
	};

	const forceNavigateToOrderDetails = () => {
		const { clientOrderId: externalOrderId, id: orderId } = currentOrder;
		localStorage.setItem(OrderDisplay.lastIndex, currentIndex.toString());
		history.push(
			replaceColonPrefixed(OutgoingPages.OrderDetail, {
				orderId: orderId || '',
				currentDateKey: currentDateKey || '',
			}),
			{
				orderId,
				externalOrderId,
				forceOpen: true,
				currentDateKey,
			},
		);
	};

	return (
		<OrderActionsContainer
			onNavigateToNextOrder={navigateToNextOrder}
			onNavigateToPreviousOrder={navigateToPreviousOrder}
			onNavigateToScannerPage={navigateToOrderScanner}
			onNavigateToChangeTimePage={navigateToChangeTimePage}
			onForceNavigateToOrderDetail={forceNavigateToOrderDetails}
			dateKey={currentDateKey}
			orderId={currentOrder?.id}
			externalOrderId={currentOrder?.clientOrderId}
			isFirstIndex={currentIndex === 0}
			isLastIndex={currentIndex === orders.length - 1}
			title={t('orderActions.title', { orderId: currentOrder ? currentOrder.clientOrderId : '' })}
		/>
	);
};

export { OrderActionsPage };
