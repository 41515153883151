import React, { CSSProperties, FC, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { FlatList } from 'src/components/list/flatList/flatList.component';
import StorageListItem from 'src/components/list/listItem/storageListItem/storageListItem.component';
import StorageState from 'src/components/storageState/storageState.component';
import {
	STORAGE_LIST_MARGIN,
	STORAGE_ROW_HEIGHT,
	STORAGE_ROW_ITEM_MARGIN,
} from 'src/containers/app/storage/storageOverview/storageContainer.const';
import { withTabHeader } from 'src/hoc/withTabHeader/withTabHeader.hoc';
import { IconButton } from 'src/components/button/iconButton/iconButton.component';
import { IconNames } from 'src/components/icon/icon.type';
import { Colors } from 'src/assets';
import { Pally, StorageItemKey, StorageSlot } from 'src/state/store/storage/storage.type';
import {
	actionStorageDeletePally,
	actionStorageDeleteStorageSlot,
	actionStorageOverviewFetch,
} from 'src/state/store/storage/storage.action';
import { selectDistinctNumberOfOrdersInStorage, selectStorageOverview } from 'src/state/store/storage/storage.selector';
import { getStorageItemType } from 'src/helpers/storageItem.helpers';

import { StorageContainerProps } from './storageContainer.type';
import './storageContainer.style.scss';

const StorageContainerComponent: FC<StorageContainerProps> = ({
	onNavigateToStorageAssign,
	onNavigateToStorageDetail,
	onNavigateToPallyDetail,
}: StorageContainerProps) => {
	const { storageSlots, pallies } = useSelector(selectStorageOverview);
	const nrOfOrders = useSelector(selectDistinctNumberOfOrdersInStorage);
	const dispatch = useDispatch();

	const [filteredStorages, setFilteredStorages] = useState<Array<StorageSlot | Pally>>([]);

	const { t } = useTranslation();
	const listRef = useRef<any>(null);
	const containerRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		dispatch(actionStorageOverviewFetch());
	}, []);

	useEffect(() => {
		setFilteredStorages([...storageSlots, ...pallies]);
	}, [storageSlots, pallies]);

	const navigateToStorageAssignPage = () => {
		onNavigateToStorageAssign();
	};

	const navigateToDetailPage = (storageItem: StorageSlot | Pally) => {
		if ('pallies' in storageItem) {
			onNavigateToStorageDetail(storageItem);

			return;
		}
		onNavigateToPallyDetail(storageItem);
	};

	const handleDelete = (item: StorageSlot | Pally) => {
		switch (getStorageItemType(item)) {
			case StorageItemKey.STORAGE_SLOT:
				dispatch(actionStorageDeleteStorageSlot(item.id));
				break;
			case StorageItemKey.PALLY:
				dispatch(actionStorageDeletePally(item.id));
				break;
		}
	};

	const renderRow = (item: StorageSlot | Pally, index: number, style?: CSSProperties) => {
		return (
			<StorageListItem
				item={item}
				key={`${index}-${item.id}`}
				onClick={() => navigateToDetailPage(item)}
				onDelete={() => handleDelete(item)}
				style={style}
			/>
		);
	};

	return (
		<div className={'storage-container'}>
			<StorageState storageSlots={storageSlots.length} pallies={pallies.length} orders={nrOfOrders} />

			<div ref={containerRef} className={'list-wrapper'}>
				<FlatList<StorageSlot | Pally>
					outerRef={listRef}
					data={filteredStorages}
					renderItem={renderRow}
					rowHeight={STORAGE_ROW_HEIGHT}
					itemMargin={STORAGE_ROW_ITEM_MARGIN}
					listMargin={{
						top: STORAGE_LIST_MARGIN,
						bottom: STORAGE_LIST_MARGIN + STORAGE_ROW_HEIGHT,
						left: STORAGE_LIST_MARGIN,
						right: STORAGE_LIST_MARGIN,
					}}
				/>
			</div>
			<div className="storage__button-container">
				<IconButton
					fullWidth={true}
					backgroundColor={Colors.primary}
					size={15}
					className="button"
					iconName={IconNames.Enter}
					onClick={navigateToStorageAssignPage}>
					{t('storageOverview.button')}
				</IconButton>
			</div>
		</div>
	);
};

const StorageContainer = withTabHeader(StorageContainerComponent);

export { StorageContainer };
