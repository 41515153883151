export interface AcceptDeliveryContainerProps {
	deliveryId: string;

	onClose: () => void;
}

export enum AcceptDeliveryPages {
	DELIVERY_NOTES = 'orderNotes',
	EMPTY_TOTES = 'emptyTotes',
	RETURN_ORDER = 'returnOrder',
}
