import { ActionType } from 'src/state/store/actionStatus/actionStatus.type';

import { ACTION_API_VERSION_FETCH, ACTION_API_VERSION_SET, ACTION_APP_LANGUAGE_SET } from './settings.const';
import { ApiVersionSetActionType, AppLanguage, AppLanguageSetActionType } from './settings.type';

export const actionAppLanguageSet = (appLanguage: AppLanguage): ActionType<AppLanguageSetActionType> => {
	return {
		type: ACTION_APP_LANGUAGE_SET,
		payload: {
			appLanguage,
		},
	};
};

export const actionApiVersionFetch = (): ActionType => {
	return {
		type: ACTION_API_VERSION_FETCH,
	};
};

export const actionApiVersionSet = (apiVersion: string): ActionType<ApiVersionSetActionType> => {
	return {
		type: ACTION_API_VERSION_SET,
		payload: {
			apiVersion,
		},
	};
};
