import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { ListItem } from 'src/components/list/listItem/listItem.component';
import { SettingsList } from 'src/components/list/settingsList/settingsList.component';
// import { Switch } from 'src/components/switch/switch.component';

import { NotificationSettingsContainerProps } from './notificationSettingsContainer.type';
import './notificationSettingsContainer.style.scss';

const NotificationSettingsContainer: FC<NotificationSettingsContainerProps> = ({
	navToEmail,
	navToSms,
}: NotificationSettingsContainerProps) => {
	// const [allowPush, setAllowPush] = useState<boolean>(false);
	const { t } = useTranslation();

	return (
		<div className="notifications-settings-container">
			<SettingsList>
				{/* <ListItem key="push" hasChevron={false}>
					<span className="content__label">{t('notificationSettings.push')}</span>

					<Switch checked={allowPush} onChange={setAllowPush} />
				</ListItem> */}
				<ListItem key="sms" onClick={navToSms}>
					<span className="content__label">{t('smsSettings.title')}</span>
				</ListItem>
				<ListItem key="email" onClick={navToEmail}>
					<span className="content__label">{t('emailSettings.title')}</span>
				</ListItem>
			</SettingsList>
		</div>
	);
};

export { NotificationSettingsContainer };
