import React from 'react';

import { RefundExportContainer } from 'src/containers/backoffice/refundExport/refundExport.container';
import { withNavbarHeader } from 'src/hoc/withNavbarHeader/withNavbarHeader.hoc';

const RefundExportPageComponent = () => {
	return <RefundExportContainer />;
};

const RefundExportPage = withNavbarHeader(RefundExportPageComponent);

export { RefundExportPage };
