import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { selectAppLanguagePrefix } from 'src/state/store/settings/settings.selector';
import { ListItem } from 'src/components/list/listItem/listItem.component';
import { SettingsList } from 'src/components/list/settingsList/settingsList.component';
import { AppLanguages } from 'src/state/store/settings/settings.const';
import { AppLanguage } from 'src/state/store/settings/settings.type';
import { actionAppLanguageSet } from 'src/state/store/settings/settings.action';
import { Icon } from 'src/components/icon/icon.component';
import { IconNames } from 'src/components/icon/icon.type';
import { Colors } from 'src/assets';

import './languageContainer.style.scss';

const LanguageContainer: FC = () => {
	const language: string = useSelector(selectAppLanguagePrefix)!;
	const dispatch = useDispatch();

	const changeLanguage = (language: AppLanguage) => {
		dispatch(actionAppLanguageSet(language));
	};

	const languageItems = Object.keys(AppLanguages).map((key) => (
		<ListItem
			key={key}
			hasChevron={false}
			className={(key === language && 'list-item--check') || ''}
			onClick={() => changeLanguage(key as AppLanguage)}>
			<span>{AppLanguages[key as AppLanguage]}</span>
			{key === language ? <Icon iconName={IconNames.Check} size={18} color={Colors.brightBlue} /> : null}
		</ListItem>
	));

	return (
		<div className="language-container">
			<SettingsList>{languageItems}</SettingsList>
		</div>
	);
};

export { LanguageContainer };
