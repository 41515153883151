import { call, put, select, takeEvery } from 'redux-saga/effects';

import { MooseApiRoutes } from 'src/config/api.config';
import { replaceColonPrefixed } from 'src/helpers/message.helpers';
import { actionStatusAdd } from 'src/state/store/actionStatus/actionStatus.action';
import { ActionStatusEnum, ActionType } from 'src/state/store/actionStatus/actionStatus.type';
import { selectSelectedStoreId } from 'src/state/store/carrefourStore/carrefourStore.selector';
import { ACTION_STORAGE_DELETE_STORAGE_SLOT } from 'src/state/store/storage/storage.const';
import { StorageDeleteStorageSlotActionType } from 'src/state/store/storage/storage.type';
import { authorize } from 'src/state/sagas/auth/authorization.saga';
import { fetchRequest, HttpMethod } from 'src/state/sagas/network/network.saga';
import { actionStorageOverviewFetch } from 'src/state/store/storage/storage.action';

function* deleteStorageSlot(storeId: string, storageSlotId: string): any {
	return yield call(
		fetchRequest,
		{
			method: HttpMethod.Delete,
			url: replaceColonPrefixed(MooseApiRoutes.StorageDeleteStorageSlot, {
				storeUuid: storeId,
				storageSlotUuid: storageSlotId,
			}),
		},
		[authorize],
	);
}

function* deleteStorageSlotWorker(action: ActionType<StorageDeleteStorageSlotActionType>): any {
	yield put(actionStatusAdd({ type: ACTION_STORAGE_DELETE_STORAGE_SLOT }, ActionStatusEnum.RUNNING));

	try {
		const selectedCarrefourStore: string | undefined = yield select(selectSelectedStoreId);

		if (!action.payload || !selectedCarrefourStore) {
			return;
		}

		const { storageSlotId } = action.payload;

		yield call(deleteStorageSlot, selectedCarrefourStore, storageSlotId);

		yield put(actionStorageOverviewFetch());

		yield put(actionStatusAdd({ type: ACTION_STORAGE_DELETE_STORAGE_SLOT }, ActionStatusEnum.SUCCESS));
	} catch (error) {
		yield put(actionStatusAdd({ type: ACTION_STORAGE_DELETE_STORAGE_SLOT }, ActionStatusEnum.ERROR));
	}
}

export function* deleteStorageSlotSaga() {
	yield takeEvery(ACTION_STORAGE_DELETE_STORAGE_SLOT, deleteStorageSlotWorker);
}
