import { Store } from 'src/state/store.type';
import { ActionStatus, ActionType } from 'src/state/store/actionStatus/actionStatus.type';

export const selectActionStatus = ({ actionStatuses }: Store, action: ActionType): ActionStatus | undefined => {
	return actionStatuses[getActionHash(action)];
};

export const getActionHash = (action: ActionType, data?: any): string => {
	return `${action.type}${action.payload ? `_${JSON.stringify(action.payload)}` : ''}${
		data ? JSON.stringify(data) : ''
	}`;
};
