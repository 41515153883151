import { createReducer } from 'reduxsauce';

import {
	ActionStatus,
	ActionStatusAddActionType,
	ActionStatusesStore,
	ActionStatusRemoveActionType,
	ActionType,
} from 'src/state/store/actionStatus/actionStatus.type';
import { ACTION_LOGOUT } from 'src/state/store/general/general.const';

import {
	ACTION_CLEAR_ACTION_STATUS_STORE,
	ACTION_STATUS_ADD,
	ACTION_STATUS_INITIAL_STATE,
	ACTION_STATUS_REMOVE,
} from './actionStatus.const';

/* ------------- Reducers ------------- */

const actionStatusAdd = (
	state: ActionStatusesStore,
	action: ActionType<ActionStatusAddActionType>,
): ActionStatusesStore => {
	if (!action.payload) {
		return state;
	}
	const { actionStatus }: { actionStatus: ActionStatus } = action.payload;

	if (
		state[actionStatus.hash] &&
		state[actionStatus.hash].status === actionStatus.status &&
		state[actionStatus.hash].isOffline === actionStatus.isOffline
	) {
		// Nothing changed, so don't return a new object.
		return state;
	}

	return {
		...state,
		[actionStatus.hash]: actionStatus,
	};
};

const actionStatusRemove = (
	state: ActionStatusesStore,
	action: ActionType<ActionStatusRemoveActionType>,
): ActionStatusesStore => {
	if (!action.payload) {
		return state;
	}
	const { hash }: { hash: string } = action.payload;

	// Deconstruct state take object with key and place it in variable 'removed'.
	// eslint-disable-next-line no-unused-vars,@typescript-eslint/no-unused-vars
	const { [hash]: _, ...newState } = state;

	return newState;
};

const actionStatusClear = (): ActionStatusesStore => {
	return ACTION_STATUS_INITIAL_STATE;
};

/* ------------- Hookup Reducers To Types ------------- */

export const actionStatusReducer = createReducer(ACTION_STATUS_INITIAL_STATE, {
	[ACTION_STATUS_ADD]: actionStatusAdd,
	[ACTION_STATUS_REMOVE]: actionStatusRemove,
	[ACTION_CLEAR_ACTION_STATUS_STORE]: actionStatusClear,
	[ACTION_LOGOUT]: actionStatusClear,
});
