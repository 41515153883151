import React from 'react';
import classNames from 'classnames';

import { Button } from 'src/components/button/button.component';
import { ButtonProps } from 'src/components/button/button.type';

import './primaryButton.style.scss';

/**
 * Blue carrefour styled button
 */
const PrimaryButton = ({ children, className, ...props }: ButtonProps) => {
	return (
		<Button className={classNames('primary-button', className)} {...props}>
			<span className="primary-button__text">{children} </span>
		</Button>
	);
};

export { PrimaryButton };
