import React, { FC } from 'react';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';

import { PrimaryButton } from 'src/components/button/primaryButton/primaryButton.component';

import { PageNotFoundProps } from './pageNotFound.type';
import './pageNotFound.style.scss';

const PageNotFound: FC<PageNotFoundProps> = ({
	description,
	goBackCtaDescription,
	show404 = true,
}: PageNotFoundProps) => {
	const history = useHistory();
	const { t } = useTranslation();

	const navigateToRoot = () => {
		history.push('/');
	};

	if (!description) {
		description = t('pageNotFound.description');
	}

	if (!goBackCtaDescription) {
		goBackCtaDescription = t('pageNotFound.goBack');
	}

	return (
		<div className="page-not-found">
			{show404 && <span className="page-not-found__404">404</span>}
			<h1 className="page-not-found__title">{t('pageNotFound.title')}</h1>
			<p className="page-not-found__description">{description}</p>
			<PrimaryButton onClick={navigateToRoot}>{goBackCtaDescription}</PrimaryButton>
		</div>
	);
};

export { PageNotFound };
