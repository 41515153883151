export enum MSALStorageKeys {
	accessToken = 'msal.accesstoken',
	idToken = 'msal.idtoken',
	metaData = 'user-metadata',
	metaDataToken = 'user-metadata.token',
}

export interface UserMetaData {
	companyId: string | null;
	email: string | null;
	firstName: string | null;
	id: string | null;
	language: string | null;
	lastName: string | null;
	operatorId: string | null;
	partitionKey: string | null;
	roles: string[] | null;
	storeIds: string[] | null;
	userId: string | null;
	userName: string | null;
	warehouseIds: string[] | null;
}
