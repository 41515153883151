import React, { createContext, FC, ReactNode, useState } from 'react';

import { Modal } from 'src/components/modals/modal.component';

export interface ModalOptions {
	backdropDismissible?: boolean;
}

export interface ModalContextProps {
	onOpenModal: (modalContent: ReactNode, options?: ModalOptions) => void;
	onDismissModal: () => void;
}

export const ModalContext = createContext<ModalContextProps>({
	onOpenModal: () => null,
	onDismissModal: () => {},
});

/**
 * React context provider that will be used to show and hide modals
 * This context has 3 properties: isOpen - onOpenModal - onDismissModal
 *
 * We pass the modal content (react components) in the onOpenModal so that we can render dynamic content
 */
const ModalProvider: FC<{ children?: React.ReactNode }> = ({ children }: { children?: React.ReactNode }) => {
	const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
	const [modalContent, setModalContent] = useState<ReactNode>(null);
	const [modalOptions, setModalOptions] = useState<ModalOptions>();

	const onOpenModal = (modal: ReactNode, options?: ModalOptions) => {
		setModalContent(modal);
		setModalIsOpen(true);

		setModalOptions(options);
	};

	const onDismissModal = () => {
		setModalIsOpen(false);
	};

	const modalContextProps: ModalContextProps = {
		onOpenModal,
		onDismissModal,
	};

	return (
		<>
			<ModalContext.Provider value={modalContextProps}>{children}</ModalContext.Provider>
			<Modal
				onDismiss={onDismissModal}
				backdropDismissable={modalOptions?.backdropDismissible}
				isOpen={modalIsOpen}>
				{modalContent}
			</Modal>
		</>
	);
};

export { ModalProvider };
