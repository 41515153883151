import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

import { Icon } from 'src/components/icon/icon.component';
import { IconNames, IconSizes } from 'src/components/icon/icon.type';
import { StorageContainer } from 'src/containers/app/storage/storageOverview/storage.container';
import { replaceColonPrefixed } from 'src/helpers/message.helpers';
import { StoragePages } from 'src/navigation/app/storage/storageRouter.type';
import { Pally, StorageSlot } from 'src/state/store/storage/storage.type';

const StorageOverviewPage = () => {
	const { t } = useTranslation();

	const history = useHistory();

	const navigateToStorageAssign = () => {
		history.push(StoragePages.StorageScanner);
	};

	const navigateToStorageDetail = (storageItem: StorageSlot) => {
		history.push(replaceColonPrefixed(StoragePages.StorageDetail, { storageId: storageItem.id }), { storageItem });
	};

	const navigateToPallyDetail = (storageItem: Pally) => {
		history.push(replaceColonPrefixed(StoragePages.PallyDetail, { pallyId: storageItem.id }), { storageItem });
	};

	return (
		<StorageContainer
			title={t('tabs.storage')}
			icon={<Icon iconName={IconNames.Storage} size={IconSizes.Large} />}
			extendedHeader
			onNavigateToStorageAssign={navigateToStorageAssign}
			onNavigateToStorageDetail={navigateToStorageDetail}
			onNavigateToPallyDetail={navigateToPallyDetail}
		/>
	);
};

export { StorageOverviewPage };
