import { CSSProperties } from 'react';

const fullWidth: CSSProperties = {
	width: '100%',
};

const borderRadius: Record<string, CSSProperties> = {
	xsmall: {
		borderRadius: '5px',
	},
	small: {
		borderRadius: '10px',
	},
	regular: {
		borderRadius: '22px',
	},
	large: {
		borderRadius: '50px',
	},
	xlarge: {
		borderRadius: '80px',
	},
};

export default {
	fullWidth,
	borderRadius,
};
