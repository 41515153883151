import { createSelector } from 'reselect';
import { compareAsc } from 'date-fns';

import { Store } from 'src/state/store.type';

import { InboundDelivery, ReturnDeliveries } from './inbound.type';

const selectInboundsRecord = ({ inbounds }: Store): Record<number, InboundDelivery> => {
	return inbounds.deliveries;
};

export const selectInboundDeliveries = createSelector<Store, Record<number, InboundDelivery>, InboundDelivery[]>(
	selectInboundsRecord,
	(inboundsRecord) => {
		return Object.values(inboundsRecord);
	},
);

export const selectOpenInboundDeliveries = createSelector<Store, Record<number, InboundDelivery>, InboundDelivery[]>(
	selectInboundsRecord,
	(deliveries) => {
		return Object.values(deliveries)
			.filter((item) => !item.delivered)
			.sort((a: InboundDelivery, b: InboundDelivery) => compareAsc(a.datetime, b.datetime));
	},
);

export const selectAcceptedInboundDeliveries = createSelector<
	Store,
	Record<number, InboundDelivery>,
	InboundDelivery[]
>(selectInboundsRecord, (deliveries) => {
	return Object.values(deliveries)
		.filter((item) => item.delivered)
		.sort((a: InboundDelivery, b: InboundDelivery) => compareAsc(a.datetime, b.datetime));
});

export const selectInboundDelivery = ({ inbounds }: Store, deliveryId: string): InboundDelivery => {
	return inbounds.deliveries[deliveryId];
};

export const selectNumberOfOpenInboundDeliveries = createSelector<Store, Record<number, InboundDelivery>, number>(
	selectInboundsRecord,
	(inboundsRecord) => {
		return (Object.values(inboundsRecord) || []).filter((item) => !item.delivered).length;
	},
);

export const selectLastFetchedInboundDeliveries = ({ inbounds }: Store): number | undefined => {
	return inbounds.lastFetched;
};

export const selectReturnDeliveriesState = ({ inbounds }: Store): ReturnDeliveries => {
	return inbounds.returnDeliveries;
};
