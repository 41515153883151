import classNames from 'classnames';
import React, { FC, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Colors } from 'src/assets';
import { Icon } from 'src/components/icon/icon.component';
import { IconNames } from 'src/components/icon/icon.type';
import { ListItem } from 'src/components/list/listItem/listItem.component';
import { Pally, StorageItemKey, StorageSlot } from 'src/state/store/storage/storage.type';
import { Order } from 'src/state/store/order/order.type';
import {
	getStorageItemNestedCount,
	getStorageItemType,
	hasStorageItemNestedItems,
} from 'src/helpers/storageItem.helpers';
import { ConfirmDeleteBottomSheet } from 'src/components/draggableBottomSheet/confirmDelete/confirmDelete.component';
import { IconButton } from 'src/components/button/iconButton/iconButton.component';
import { STORAGE_ROW_HEIGHT } from 'src/containers/app/storage/storageOverview/storageContainer.const';
import { SwipeToDelete } from 'src/components/swipeToDelete/swipeToDelete.component';
import { NotDeletableBottomSheet } from 'src/components/draggableBottomSheet/notDeletable/notDeletable.component';

import { StorageListItemProps } from './storageListItem.type';
import './storageListItem.style.scss';

const StorageListItem: FC<StorageListItemProps> = ({
	item,
	onDelete,
	style,
	className,
	onClick,
	...props
}: StorageListItemProps) => {
	const { t } = useTranslation();
	const storageItemType = getStorageItemType(item);
	const nestedCount = getStorageItemNestedCount(item);

	const state = useRef({ x: 0 });

	const [confirmDeleteOpen, setConfirmDeleteOpen] = useState<boolean>(false);
	const [notDeletableOpen, setNotDeletableOpen] = useState<boolean>(false);

	const openConfirmDeletePopup = () => {
		setConfirmDeleteOpen(true);
	};

	const closeConfirmDeletePopup = () => {
		setConfirmDeleteOpen(false);
	};

	const openNotDeletablePopup = () => {
		setNotDeletableOpen(true);
	};

	const closeNotDeletablePopup = () => {
		setNotDeletableOpen(false);
	};

	const handleDelete = () => {
		if (hasStorageItemNestedItems(item)) {
			openNotDeletablePopup();

			return;
		}

		openConfirmDeletePopup();
	};

	const handleDeleteConfirm = () => {
		closeConfirmDeletePopup();
		onDelete();
	};

	const renderItems = () => {
		switch (storageItemType) {
			case StorageItemKey.STORAGE_SLOT:
				item = item as StorageSlot;

				return (
					<>
						<Icon iconName={IconNames.Storages} color={Colors.systemGrey} />
						<div className="storage-list-item__content">
							<p className="storage-list-item__content-title">
								{t('storageListItem.storageSlotTitle', { label: (item as StorageSlot).label })}
							</p>
							<p className="storage-list-item__content-info">
								{t('storageListItem.storageSlotDescription', nestedCount)}
							</p>
						</div>
					</>
				);
			case StorageItemKey.PALLY:
				return (
					<>
						<Icon iconName={IconNames.Pallies} color={Colors.darkOrange} />
						<div className="storage-list-item__content">
							<p className="storage-list-item__content-title">
								{t('storageListItem.pallyTitle', { label: (item as Pally).label })}
							</p>
							<p className="storage-list-item__content-info">
								{t('storageListItem.palliesDescription', nestedCount)}
							</p>
						</div>
					</>
				);
			case StorageItemKey.ORDER:
				return (
					<>
						<Icon iconName={IconNames.Groceries} color={Colors.systemGrey} />
						<div className="storage-list-item__content">
							<p className="storage-list-item__content-title--grey">
								{t('storageListItem.orderTitle', { orderId: (item as Order).foodxId })}
							</p>
							<p className="storage-list-item__content-info">
								{t('storageListItem.orderDescription', nestedCount)}
							</p>
						</div>
					</>
				);
		}
	};

	const handleClick = (e: any) => {
		const delta = Math.abs(e.screenX - state.current.x);

		if (delta > 10) {
			return;
		}

		if (onClick) {
			onClick();
		}
	};

	const handleMouseDown = (e: any) => {
		state.current.x = e.screenX;
	};

	return (
		<>
			<SwipeToDelete
				onDelete={handleDelete}
				deleteComponent={
					<IconButton
						className={'item-delete-button'}
						onClick={handleDelete}
						color={Colors.purewhite}
						iconName={IconNames.Trash}>
						{t('general.delete')}
					</IconButton>
				}
				style={{ ...style, borderRadius: 10 }}
				deleteText={t('general.delete')}
				height={STORAGE_ROW_HEIGHT}>
				<ListItem
					className={classNames('storage-list-item', className)}
					chevronColor={Colors.primary}
					hasChevron={!('foodxId' in item)}
					onMouseDown={handleMouseDown}
					onClick={handleClick}
					{...props}>
					{renderItems()}
				</ListItem>
			</SwipeToDelete>

			<ConfirmDeleteBottomSheet
				storageItemType={storageItemType}
				onConfirm={handleDeleteConfirm}
				open={confirmDeleteOpen}
				onDismiss={closeConfirmDeletePopup}
			/>

			<NotDeletableBottomSheet
				storageItemType={storageItemType}
				open={notDeletableOpen}
				onDismiss={closeNotDeletablePopup}
			/>
		</>
	);
};

export default StorageListItem;
