import { Mapper } from 'src/state/mappers/mapper.type';
import { ApiStorageSlot, StorageSlot } from 'src/state/store/storage/storage.type';
import { orderMapper } from 'src/state/mappers/order/order.mapper';

import { pallyMapper } from './pally.mapper';

class StorageSlotMapper extends Mapper<ApiStorageSlot, StorageSlot> {
	map = (data: ApiStorageSlot): StorageSlot => {
		if (!data || !data.id || !data.code || !data.label) {
			return this.throwInvalid();
		}

		return {
			id: data.id,
			code: data.code,
			label: data.label,
			pallies: data.pallies?.map(pallyMapper.map) || [],
			orderIds: data.orderIds,
			orders: data.orders?.map(orderMapper.map),
		};
	};
}

export const storageSlotMapper = new StorageSlotMapper();
