import React from 'react';

import { Colors } from 'src/assets';
import { cursorPosition } from 'src/helpers/cursor.helper';

import { SwipeToDeleteProps, SwipeToDeleteState } from './swipeToDelete.type';
import { Container, Content, Delete } from './swipeToDelete.styled';

class SwipeToDelete extends React.Component<SwipeToDeleteProps, SwipeToDeleteState> {
	state: SwipeToDeleteState = {
		touching: null,
		translate: 0,
	};
	container: any;
	startTouchPosition: any;
	initTranslate: any;
	containerWidth: any;

	componentDidMount() {
		// to get ref dimensions
		this.forceUpdate();
	}

	onMouseDown = (e: any) => {
		const { touching, translate } = this.state;
		const { disabled } = this.props;
		if (touching || disabled) {
			return;
		}
		this.startTouchPosition = cursorPosition(e);
		this.initTranslate = translate;
		this.setState({ touching: true }, () => {
			this.addEventListenerToMoveAndUp();
		});
	};

	addEventListenerToMoveAndUp = (remove = false) => {
		if (remove) {
			window.removeEventListener('mousemove', this.onMouseMove);
			window.removeEventListener('touchmove', this.onMouseMove);
			window.removeEventListener('mouseup', this.onMouseUp);
			window.removeEventListener('touchend', this.onMouseUp);
		} else {
			window.addEventListener('mousemove', this.onMouseMove);
			window.addEventListener('touchmove', this.onMouseMove);
			window.addEventListener('mouseup', this.onMouseUp);
			window.addEventListener('touchend', this.onMouseUp);
		}
	};

	onMouseMove = (e: any) => {
		const { rtl } = this.props;
		const { touching } = this.state;

		if (!touching) {
			return cursorPosition(e);
		}
		if (
			(!rtl && cursorPosition(e) > this.startTouchPosition - this.initTranslate) ||
			(rtl && cursorPosition(e) < this.startTouchPosition - this.initTranslate)
		) {
			this.setState({ translate: 0 });

			return;
		}
		this.setState({ translate: cursorPosition(e) - this.startTouchPosition + this.initTranslate });
	};

	onMouseUp = () => {
		this.startTouchPosition = null;
		const { deleteWidth, rtl } = this.props;
		const { translate } = this.state;

		const newState = {
			touching: false,
			translate,
		};
		const acceptableMove = -(deleteWidth || 75) * 0.7;
		const showDelete = (rtl ? -1 : 1) * translate < acceptableMove;
		const notShowDelete = (rtl ? -1 : 1) * translate >= acceptableMove;
		if (notShowDelete) newState.translate = 0;
		if (showDelete) newState.translate = (rtl ? 1 : -1) * (deleteWidth || 75);
		this.setState(newState);

		this.addEventListenerToMoveAndUp(true);
	};

	onDeleteClick = () => {
		const { transitionDuration, onDelete } = this.props;
		window.setTimeout(() => {
			onDelete();
		}, transitionDuration);
	};

	componentWillUnmount() {
		this.addEventListenerToMoveAndUp(true);
	}

	render() {
		const { translate, touching } = this.state;
		const {
			deleteWidth,
			transitionDuration,
			deleteText,
			deleteComponent,
			deleteColor,
			height,
			rtl,
			children,
			style,
		} = this.props;
		const cssParams = { deleteWidth, transitionDuration, deleteColor, heightProp: height, rtl };

		return (
			<Container
				id="delete-container"
				style={style}
				{...cssParams}
				ref={(c) => {
					if (c) {
						this.container = c;
						this.containerWidth = c.getBoundingClientRect().width;
					}
				}}>
				<Delete
					deleteColor={deleteColor || Colors.red}
					deleteWidth={deleteWidth || 75}
					rtl={false}
					transitionDuration={250}
					id="delete"
					buttonMargin={this.containerWidth - (deleteWidth || 75)}>
					{deleteComponent ? (
						deleteComponent
					) : (
						<button id="delete-button" onClick={this.onDeleteClick}>
							{deleteText}
						</button>
					)}
				</Delete>
				<Content
					{...cssParams}
					rtl={false}
					disabled={!touching}
					id="delete-content"
					onMouseDown={this.onMouseDown}
					onTouchStart={this.onMouseDown}
					translateValue={translate}
					transition={!touching}>
					{children}
				</Content>
			</Container>
		);
	}
}

export { SwipeToDelete };
