import { Colors, Fonts, Metrics } from 'src/assets';

/**
 * Helper method to add js variables to css stylesheet
 *
 * @param styleString css string that will be added
 */
const addStyle = (styleString: string) => {
	const style = document.createElement('style');
	style.textContent = styleString;
	document.head.append(style);
};

/**
 * Helper method that will add all Color constants defined in `src/assets/colors/colors.const` to the CSS stylesheet
 *
 * See {@link Colors}
 */
export const addColorVariablesToStyleSheet = (): void => {
	const colorVariables: string[] = [];

	for (const key in Colors) {
		colorVariables.push(`--color-${key}: ${Colors[key as keyof typeof Colors]}`);
	}

	const cssString: string = `
    :root {
        ${colorVariables.join('; \n')}
    }`;

	addStyle(cssString);
};

/**
 * Helper method that will add all Font sizes constants defined in `src/assets/fonts/fonts.const` to the CSS stylesheet
 *
 * See {@link Fonts}
 */
export const addFontSizeVariablesToStyleSheet = (): void => {
	const fontSizeVariables: string[] = [];

	for (const key in Fonts.size) {
		fontSizeVariables.push(`--font-size-${key}: ${Fonts.size[key as keyof typeof Fonts.size]}`);
	}

	const fontSizeVariablesString: string = `
    :root {
        ${fontSizeVariables.join('; \n')}
    }`;

	addStyle(fontSizeVariablesString);
};

/**
 * Helper method that will add all Font types constants defined in `src/assets/fonts/fonts.const` to the CSS stylesheet
 *
 * See {@link Fonts}
 */
export const addFontTypeVariablesToStyleSheet = (): void => {
	const fontTypeVariables: string[] = [];

	for (const type in Fonts.type) {
		for (const font in Fonts.type[type as keyof typeof Fonts.type]) {
			fontTypeVariables.push(`--font-type-${type}-${font}: ${(Fonts.type as any)[type][font]}`);
		}
	}

	const fontTypeVariablesString: string = `
    :root {
        ${fontTypeVariables.join('; \n')}
    }`;

	addStyle(fontTypeVariablesString);
};

/**
 * Helper method that will add all Font types constants defined in `src/assets/fonts/fonts.const` to the CSS stylesheet
 *
 * See {@link Fonts}
 */
export const addMetricsVariablesToStyleSheet = (): void => {
	const metricsVariables: string[] = [];

	for (const metric in Metrics) {
		metricsVariables.push(`--metric-${metric}: ${Metrics[metric as keyof typeof Metrics]}`);
	}

	const fontTypeVariablesString: string = `
    :root {
        ${metricsVariables.join('; \n')}
    }`;

	addStyle(fontTypeVariablesString);
};

export { addStyle };
