import React, { createContext, FC, ReactNode, useState } from 'react';

import { IconNames } from 'src/components/icon/icon.type';
import { FullPageLoader } from 'src/components/fullPageLoader/fullPageLoader.component';

export interface LoaderOptions {
	iconName?: IconNames;
}

export interface LoaderContextProps {
	onShowLoader: (options?: LoaderOptions) => void;
	onHideLoader: () => void;
}

export const LoaderContext = createContext<LoaderContextProps>({
	onShowLoader: () => null,
	onHideLoader: () => {},
});

/**
 * React context provider that will be used to show and hide a ull page loader.
 *
 * @param children
 */
const LoaderProvider: FC<{ children?: ReactNode }> = ({ children }: { children?: ReactNode }) => {
	const [loaderVisible, setLoaderVisible] = useState<boolean>(false);
	const [loaderOptions, setLoaderOptions] = useState<LoaderOptions>();

	const onShowLoader = (options?: LoaderOptions) => {
		setLoaderVisible(true);
		setLoaderOptions(options);
	};

	const onHideLoader = () => {
		setLoaderVisible(false);
	};

	const loaderContextProps: LoaderContextProps = {
		onShowLoader,
		onHideLoader,
	};

	return (
		<>
			<LoaderContext.Provider value={loaderContextProps}>{children}</LoaderContext.Provider>
			<FullPageLoader loading={loaderVisible} icon={loaderOptions?.iconName} />
		</>
	);
};

export { LoaderProvider };
