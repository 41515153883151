import { enUS, fr, nlBE } from 'date-fns/locale';
import { format } from 'date-fns';
import i18n from 'i18next';

import { DateFormat } from 'src/state/store/order/order.type';

export const getDateLocale = () => {
	switch (i18n.language) {
		case 'nl':
			return nlBE;

		case 'fr':
			return fr;

		case 'en':
			return enUS;
	}
};

export const formatLocale = (date: Date, dateFormat: string) => {
	return format(date, dateFormat, { locale: getDateLocale() });
};

export const formatToday = (): string => {
	return format(new Date(), 'yyyy-MM-dd');
};

export const formatDateKeyForStore = (dateString?: string) => {
	const regx = new RegExp(DateFormat.dateAndTimezoneRegx);
	// Allow the date to be used without timezone. Timezone is only used in the view not for the store
	const regxDate = new RegExp(DateFormat.dateRegx);

	if (!dateString) {
		return undefined;
	}
	if (!(regx.test(dateString) || regxDate.test(dateString))) {
		throw new Error(`Date ${dateString} does not contain the correct format : ${DateFormat.dateAndTimezone}`);
	}

	return dateString.split(' ')[0];
};
