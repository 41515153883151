import { closestTo, format, isAfter, getHours, sub, getMinutes } from 'date-fns';
import { nlBE } from 'date-fns/locale';

import { getAbbreviationOfHour } from 'src/helpers/time.helpers';
import { InboundDelivery } from 'src/state/store/inbound/inbound.type';
import { AppLanguage } from 'src/state/store/settings/settings.type';

export const getTimeBeforeNextDelivery = (
	openDeliveries: InboundDelivery[],
	language: AppLanguage = 'en',
): string | undefined => {
	const now = Date.now();
	const mappedDeliveries: number[] = openDeliveries.map((delivery) => delivery.datetime);
	const nonLateDeliveries = mappedDeliveries.filter((time) => isAfter(time, now));

	if (!nonLateDeliveries || !nonLateDeliveries.length) {
		return;
	}

	const closestDate = closestTo(now, nonLateDeliveries);

	if (!closestDate) {
		return;
	}

	const hours = getHours(now);
	const minutes = getMinutes(now);

	const differenceBetweenNowAndClosestDate = sub(closestDate, { hours, minutes });

	return format(differenceBetweenNowAndClosestDate, `HH'${getAbbreviationOfHour(language)}'mm`, { locale: nlBE });
};
