import React, { FC, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Order, OrderState } from 'src/state/store/order/order.type';
import { selectOrderById } from 'src/state/store/order/order.selector';
import { Store } from 'src/state/store.type';
import { Icon } from 'src/components/icon/icon.component';
import { IconNames } from 'src/components/icon/icon.type';
import { getHourAndMinuteString } from 'src/helpers/time.helpers';
import { formatDateKeyForStore } from 'src/helpers/date.helpers';
import { SecondaryButton } from 'src/components/button/secondaryButton/secondaryButton.component';
import { PrimaryButton } from 'src/components/button/primaryButton/primaryButton.component';
import { OutgoingChangeStatusBottomSheet } from 'src/components/draggableBottomSheet/outgoingChangeStatus/outgoingChangeStatus.component';
import { HRLLabel } from 'src/components/hrlLabel/hrlLabel.component';
import { actionOutgoingOrderUpdate } from 'src/state/store/order/order.action';
import { selectSelectedStoreStorageFeatures } from 'src/state/store/carrefourStore/carrefourStore.selector';
import { useIsOffline } from 'src/hooks/useIsOffline.hook';
import OfflineWarningModal from 'src/components/modals/offlineWarning/offlineWarning.component';
import './orderActionsContainer.style.scss';
import { StorageItemKey } from 'src/state/store/storage/storage.type';
import { StorageItemListBottomSheet } from 'src/components/draggableBottomSheet/storageItemList/storageItemList.component';
import { IconButton } from 'src/components/button/iconButton/iconButton.component';
import { withNavbarHeader } from 'src/hoc/withNavbarHeader/withNavbarHeader.hoc';
import { OrderDetailPageProps } from 'src/navigation/app/appRouter.type';

import { OrderActionsContainerProps } from './orderActionsContainer.type';

const OrderActionsContainerComponent: FC<OrderActionsContainerProps> = ({
	dateKey,
	orderId,
	isFirstIndex,
	isLastIndex,
	onNavigateToScannerPage,
	onNavigateToChangeTimePage,
	onForceNavigateToOrderDetail,
	onNavigateToNextOrder,
	onNavigateToPreviousOrder,
}: OrderActionsContainerProps) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();

	const order: Order | undefined = useSelector((state: Store) =>
		selectOrderById(state, orderId, formatDateKeyForStore(dateKey)),
	);
	const enableStorage = useSelector(selectSelectedStoreStorageFeatures);

	const [openDetails, setOpenDetails] = useState<boolean>(false);
	const isOffline = useIsOffline();
	const [showOfflineWarning, setShowOfflineWarning] = useState<boolean>(false);
	const [showStorageSlots, setShowStorageSlots] = useState<boolean>(false);
	const [showPallies, setShowPallies] = useState<boolean>(false);

	const storageSlotsAmount = order?.storageSlots?.length || 0;
	const palliesAmount = order?.pallies?.length || 0;
	const hasStorageItems = storageSlotsAmount > 0 || palliesAmount > 0;

	const onChangeTime = () => {
		setOpenDetails(false);

		setTimeout(onNavigateToChangeTimePage, 450);
	};

	const onCancel = () => {
		setOpenDetails(false);

		if (order && dateKey) {
			dispatch(actionOutgoingOrderUpdate(order.id, OrderState.CancelReturn, dateKey));
		}
	};

	const onChangeStatusClick = () => {
		if (isOffline) {
			setShowOfflineWarning(true);

			return;
		}

		setOpenDetails(true);
	};

	const closeOfflineWarning = () => {
		setShowOfflineWarning(false);
	};

	const openStorageSlots = () => {
		setShowStorageSlots(true);
	};

	const openPallies = () => {
		setShowPallies(true);
	};

	const closeStorageItemListPopup = () => {
		setShowStorageSlots(false);
		setShowPallies(false);
	};

	return (
		<>
			<div className="order-actions">
				<div className="order-actions__header">
					<div className="order-navigation__header">
						<IconButton
							iconName={IconNames.ChevronLeft}
							onClick={onNavigateToPreviousOrder}
							disabled={isFirstIndex}
						/>
						<div className="header__details">
							<div className="details__name">
								{order?.customer?.firstname} {order?.customer?.lastname}
								{order?.customer?.phoneNumber ? ` | ${order?.customer?.phoneNumber}` : ''}
							</div>
							<div className="details__info">
								<div className="info__item">
									<Icon className="item__icon" iconName={IconNames.Clock} />
									<h1>
										{order?.timeslot.start ? getHourAndMinuteString(order.timeslot.start) : '-'}
									</h1>
								</div>
								<div className="info__item">
									<Icon className="item__icon" iconName={IconNames.Groceries} />
									<h1>
										{order?.totes?.length || '-'} {t('orderActions.totes')}
									</h1>
								</div>
							</div>
						</div>
						<IconButton
							iconName={IconNames.Chevron}
							onClick={onNavigateToNextOrder}
							disabled={isLastIndex}
						/>
					</div>
					{enableStorage && hasStorageItems ? (
						<div className="header__location">
							{storageSlotsAmount > 0 ? (
								<div className="location__item" onClick={openStorageSlots}>
									<Icon className="item__icon" iconName={IconNames.Grid} />{' '}
									<h1>
										{t('orderActions.storage', {
											count: storageSlotsAmount,
											label: order?.storageSlots[0],
										})}
									</h1>
								</div>
							) : undefined}

							{storageSlotsAmount > 0 && palliesAmount > 0 ? (
								<div className="location__delimiter" />
							) : undefined}

							{palliesAmount > 0 ? (
								<div className="location__item" onClick={openPallies}>
									<Icon className="item__icon" iconName={IconNames.Pally} />{' '}
									<h1>
										{t('orderActions.pally', { count: palliesAmount, label: order?.pallies[0] })}
									</h1>
								</div>
							) : undefined}
						</div>
					) : null}
				</div>

				<div className="order-actions__hrl-container">
					<HRLLabel
						clientOrderId={order?.clientOrderId || 'unknown'}
						numberOfTotes={order?.totes?.length || 0}
						sequenceNumber={order?.sequenceNumber || 0}
						alternateClientOrderReference={order?.alternateClientOrderReference || null}
					/>
				</div>

				<div className="order-actions__button-container">
					<SecondaryButton onClick={onNavigateToScannerPage}>
						<Icon className="item__icon" iconName={IconNames.Scan} />
						{t('orderActions.scan')}
					</SecondaryButton>
					<PrimaryButton onClick={onChangeStatusClick}>
						<Icon className="item__icon" size={15} iconName={IconNames.Edit} />
						{t('orderActions.changeStatus')}
					</PrimaryButton>
					<PrimaryButton onClick={onForceNavigateToOrderDetail}>
						<Icon className="item__icon" size={20} iconName={IconNames.Eye} />
						{t('orderActions.forceOpen')}
					</PrimaryButton>
				</div>
			</div>

			<OutgoingChangeStatusBottomSheet
				order={order!}
				open={openDetails}
				enableStorage={enableStorage}
				onDismiss={() => setOpenDetails(false)}
				onCancel={onCancel}
				onChangeTime={onChangeTime}
			/>

			<OfflineWarningModal open={showOfflineWarning} onDismiss={closeOfflineWarning} />

			<StorageItemListBottomSheet
				itemType={StorageItemKey.STORAGE_SLOT}
				labels={order?.storageSlots || []}
				open={showStorageSlots}
				onDismiss={closeStorageItemListPopup}
			/>
			<StorageItemListBottomSheet
				itemType={StorageItemKey.PALLY}
				labels={order?.pallies || []}
				open={showPallies}
				onDismiss={closeStorageItemListPopup}
			/>
		</>
	);
};

const OrderActionsContainer = withNavbarHeader<OrderDetailPageProps & OrderActionsContainerProps>(
	OrderActionsContainerComponent,
);

export { OrderActionsContainer };
