import { call, CallEffect, put, select, takeLatest } from '@redux-saga/core/effects';
import { AxiosResponse } from 'axios';

import { MooseApiRoutes } from 'src/config/api.config';
import { replaceColonPrefixed } from 'src/helpers/message.helpers';
import { fetchRequest } from 'src/state/sagas/network/network.saga';
import { actionStatusAdd } from 'src/state/store/actionStatus/actionStatus.action';
import { ActionStatusEnum } from 'src/state/store/actionStatus/actionStatus.type';
import { selectSelectedStoreId } from 'src/state/store/carrefourStore/carrefourStore.selector';
import { actionInboundDeliveriesToReturnSet } from 'src/state/store/inbound/inbound.action';
import { ACTION_INBOUND_DELIVERIES_TO_RETURN_FETCH } from 'src/state/store/inbound/inbound.const';
import { authorize } from 'src/state/sagas/auth/authorization.saga';

export function* inboundDeliveriesToReturnSaga() {
	yield takeLatest(ACTION_INBOUND_DELIVERIES_TO_RETURN_FETCH, inboundDeliveriesToReturnWorker);
}

function* inboundDeliveriesToReturnWorker() {
	yield put(actionStatusAdd({ type: ACTION_INBOUND_DELIVERIES_TO_RETURN_FETCH }, ActionStatusEnum.RUNNING));
	try {
		const selectedStoreId: string | undefined = yield select(selectSelectedStoreId);

		if (!selectedStoreId) {
			return;
		}

		const {
			data: { ordersToReturn },
		}: AxiosResponse<{ ordersToReturn: number }> = yield call(fetchInboundDeliveriesToReturn, selectedStoreId);

		yield put(actionInboundDeliveriesToReturnSet(ordersToReturn));

		yield put(actionStatusAdd({ type: ACTION_INBOUND_DELIVERIES_TO_RETURN_FETCH }, ActionStatusEnum.SUCCESS));
	} catch (error) {
		yield put(actionStatusAdd({ type: ACTION_INBOUND_DELIVERIES_TO_RETURN_FETCH }, ActionStatusEnum.ERROR));
	}
}

function* fetchInboundDeliveriesToReturn(storeId: string): Generator<CallEffect<any>, AxiosResponse, any> {
	return yield call(
		fetchRequest,
		{
			url: replaceColonPrefixed(MooseApiRoutes.InboundToReturn, { storeId }),
		},
		[authorize],
	);
}
