import React, { FC } from 'react';

import { TabBarProps } from './tabBar.type';
import { Tab } from './tab/tab.component';
import './tabBar.style.scss';

const TabBar: FC<TabBarProps> = ({ tabs }: TabBarProps) => {
	return (
		<nav className="tabBar" role="navigation">
			{tabs.map((tab, index) => (!tab?.disabled ? <Tab key={index.toString()} {...tab} /> : null))}
		</nav>
	);
};

export { TabBar };
