import React, { FC } from 'react';

import { formatTrans } from 'src/assets/translations/formatWithTranslation';
import { IconNames } from 'src/components/icon/icon.type';
import { IconButton } from 'src/components/button/iconButton/iconButton.component';

import { OrderNavigationProps } from './orderNavigation.type';
import './orderNavigation.style.scss';

const OrderNavigation: FC<OrderNavigationProps> = ({
	dateToDisplay,
	onNavigateToNextDay,
	onNavigateToPreviousDay,
	disableNavigationNextDay,
	disableNavigationPreviousDay,
}: OrderNavigationProps) => {
	return (
		<div className="order-navigation__container">
			<IconButton
				iconName={IconNames.ChevronLeft}
				onClick={onNavigateToPreviousDay}
				disabled={disableNavigationPreviousDay}
			/>
			<div className="display__title">
				<h1>{formatTrans(new Date(dateToDisplay), 'EEEE dd MMM')}</h1>
			</div>
			<IconButton
				iconName={IconNames.Chevron}
				onClick={onNavigateToNextDay}
				disabled={disableNavigationNextDay}
			/>
		</div>
	);
};

export default OrderNavigation;
