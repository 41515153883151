import { fork } from 'redux-saga/effects';

import { incomingSagas } from './inbound';
import { storeSagas } from './store';
import { outgoingSagas } from './outgoing';
import { cacheSagas } from './cache';
import { storageSagas } from './storage';
import { settingsSagas } from './settings';

export default function* root() {
	yield fork(cacheSagas);
	yield fork(incomingSagas);
	yield fork(storeSagas);
	yield fork(outgoingSagas);
	yield fork(storageSagas);
	yield fork(settingsSagas);
}
