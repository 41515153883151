import React, { ReactElement, Ref } from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList, ListChildComponentProps, ListOnItemsRenderedProps, VariableSizeList } from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';

import { FlatListProps } from './flatList.type';

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
function FlatList<I = Record<string, any>>({
	data,
	rowHeight,
	itemMargin,
	listMargin,
	className,
	style,
	outerRef,
	initialScrollOffset,
	lazy = false,
	threshold,
	isItemLoaded,
	loadMoreItems,
	onScroll,
	renderItem,
}: FlatListProps<I>): ReactElement | null {
	const renderedItem = ({ index, style }: ListChildComponentProps) => {
		const itemStyles = {
			marginTop: index === 0 ? listMargin?.top || 0 : (itemMargin || 0) / 2,
			marginBottom: index === data.length - 1 ? listMargin?.bottom || 0 : (itemMargin || 0) / 2,
			marginLeft: listMargin?.left || 0,
			marginRight: listMargin?.right || 0,
		};

		const rowChild = renderItem(data[index], index, itemStyles);

		if (!rowChild) {
			return null;
		}

		return <div style={style}>{rowChild}</div>;
	};

	let itemSize: number | ((index: number) => void);
	if (typeof rowHeight === 'number' && !listMargin?.top && !listMargin?.bottom) {
		itemSize = rowHeight + (itemMargin || 0);
	} else {
		if (typeof rowHeight === 'number') {
			itemSize = (index) => {
				if (index === 0) return rowHeight + (itemMargin || 0) / 2 + (listMargin?.top || 0);

				if (index === data.length - 1) return rowHeight + (itemMargin || 0) / 2 + (listMargin?.bottom || 0);

				return rowHeight + (itemMargin || 0);
			};
		} else {
			itemSize = (index) => {
				if (index === 0) return rowHeight(index) + (itemMargin || 0) + (listMargin?.top || 0);

				if (index === data.length - 1) return rowHeight(index) + (itemMargin || 0) + (listMargin?.bottom || 0);

				return rowHeight(index) + (itemMargin || 0);
			};
		}
	}

	const renderList = (
		width: number,
		height: number,
		ref?: Ref<any>,
		onItemsRendered?: (props: ListOnItemsRenderedProps) => any,
	) => {
		return typeof itemSize === 'number' ? (
			<FixedSizeList
				ref={ref}
				width={width}
				initialScrollOffset={initialScrollOffset}
				itemSize={itemSize}
				height={height}
				outerRef={outerRef}
				onItemsRendered={onItemsRendered}
				itemCount={data.length}
				onScroll={onScroll}>
				{renderedItem}
			</FixedSizeList>
		) : (
			<VariableSizeList
				ref={ref}
				itemSize={itemSize as (index: number) => number}
				initialScrollOffset={initialScrollOffset}
				height={height}
				itemCount={data.length}
				outerRef={outerRef}
				width={width}
				onItemsRendered={onItemsRendered}
				onScroll={onScroll}>
				{renderedItem}
			</VariableSizeList>
		);
	};

	return (
		<AutoSizer className={className} style={style}>
			{({ height, width }) => {
				return lazy && !!isItemLoaded && !!loadMoreItems ? (
					<InfiniteLoader
						isItemLoaded={isItemLoaded}
						itemCount={data.length}
						loadMoreItems={loadMoreItems}
						threshold={threshold}>
						{({ onItemsRendered, ref }) => renderList(width, height, ref, onItemsRendered)}
					</InfiniteLoader>
				) : (
					renderList(width, height)
				);
			}}
		</AutoSizer>
	);
}

export { FlatList };
