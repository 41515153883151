import { createReducer } from 'reduxsauce';

import { LastFetchedSetActionType } from 'src/state/store/cache/cache.type';
import { ActionType } from 'src/state/store/actionStatus/actionStatus.type';
import { ACTION_LOGOUT } from 'src/state/store/general/general.const';
import { ACTION_OUTBOUND_ORDERS_CLEAR } from 'src/state/store/order/order.const';

import {
	ACTION_INBOUND_DELIVERIES_SET,
	ACTION_INBOUND_DELIVERIES_TO_RETURN_SET,
	ACTION_LAST_FETCHED_INBOUND_DELIVERIES_SET,
	INBOUND_INITIAL_STATE,
} from './inbound.const';
import {
	InboundDeliveriesSetActionType,
	InboundDeliveriesToReturnSetActionType,
	InboundStore,
	InboundDelivery,
	ReturnDeliveries,
} from './inbound.type';

const inboundsSet = (state: InboundStore, action: ActionType<InboundDeliveriesSetActionType>): InboundStore => {
	if (!action.payload) {
		return state;
	}

	const { inboundDeliveries } = action.payload;

	const inboundsRecords: Record<string, InboundDelivery> = inboundDeliveries.reduce(
		(accumaltor: Record<string, InboundDelivery>, currentStore: InboundDelivery) => {
			accumaltor[currentStore.id] = currentStore;

			return accumaltor;
		},
		{},
	);

	return { ...state, deliveries: { ...inboundsRecords } };
};

const inboundLastFetchedSet = (state: InboundStore, action: ActionType<LastFetchedSetActionType>): InboundStore => {
	if (!action.payload) {
		return state;
	}

	const { lastFetched } = action.payload;

	return {
		...state,
		lastFetched,
	};
};

const inboundShowDeliveriesToReturnToteSet = (
	state: InboundStore,
	action: ActionType<InboundDeliveriesToReturnSetActionType>,
): InboundStore => {
	if (!action.payload) {
		return state;
	}

	const { ordersToReturn } = action.payload;

	const returnDeliveries: ReturnDeliveries = {
		show: ordersToReturn > 0,
		ordersToReturn,
	};

	return { ...state, returnDeliveries };
};

const clear = (): InboundStore => {
	return INBOUND_INITIAL_STATE;
};

/* ------------- Hookup Reducers To Types ------------- */

export const inboundReducer = createReducer(INBOUND_INITIAL_STATE, {
	[ACTION_INBOUND_DELIVERIES_SET]: inboundsSet,
	[ACTION_LAST_FETCHED_INBOUND_DELIVERIES_SET]: inboundLastFetchedSet,
	[ACTION_OUTBOUND_ORDERS_CLEAR]: clear,
	[ACTION_LOGOUT]: clear,
	[ACTION_INBOUND_DELIVERIES_TO_RETURN_SET]: inboundShowDeliveriesToReturnToteSet,
});
