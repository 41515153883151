import { CachedStore } from 'src/state/store/cache/cache.type';

export interface OrderStore extends CachedStore {
	orders: Record<string, Record<string, Order>>;
}

export enum OrderState {
	Expected = 'expected',
	AtStore = 'atStore',
	CustomerLate = 'customerLate',
	PickedUp = 'pickedUp',
	CancelReturn = 'cancelReturn',
	CustomerDelayed = 'customerDelayed',
	TimeChanged = 'timeChanged',
	Unknown = 'unknown',
}

export enum OrderDisplay {
	toId = 'last-order-index-replace-to-order-id',
	lastIndex = 'last-order-index',
}

export enum DateFormat {
	dateAndTimezone = 'yyyy-MM-dd OOOO',
	dateAndTimezoneRegx = '([0-9]+(-[0-9]+)+)\\s[a-zA-Z]+[+--]+\\d\\d:\\d\\d',
	dateRegx = '([0-9]+(-[0-9]+)+)',
	date = 'yyyy-MM-dd',
}

export interface Customer {
	clientOrderId?: string;
	firstname: string;
	lastname: string;
	phoneNumber?: string;
}

export interface Order {
	id: string;
	clientOrderId: string;
	foodxId: string;
	alternateClientOrderReference?: string;
	sequenceNumber?: number;
	timeslot: Interval;
	customer?: Customer;
	state: OrderState;
	totes: Array<{ barcode: string }>;
	storageSlots: string[];
	pallies: string[];
	items: OrderItem[];
}

export interface OrderDetail {
	id: string;
	clientOrderId: string;
	timeslot: Interval;
	state: OrderState;
	totes: Array<{ barcode: string }>;
	items: OrderItem[];
}

export interface OrderItem {
	id: string;
	foodxId: string;
	name: string;
	quantityOrdered: number;
	quantityShipped: number;
	quantityShorted: number;
	quantitySubstituted: number;
	substitutions: Substitution[];
}

export interface Substitution {
	id: string;
	foodxId: string;
	name: string;
	quantity: number;
}

export interface Tote {
	lp: string;
}

export interface Interval {
	start: Date | number;
	end: Date | number;
}

export interface Timeslot {
	id: string;
	start: Date;
	end: Date;
	disabled: boolean;
}

/* ------------- API Response Types ------------- */

export enum ApiOrderState {
	Expected = 'expected',
	AtStore = 'at_store',
	CustomerLate = 'customer_late',
	PickedUp = 'picked_up',
	CancelReturn = 'cancel_return',
	CustomerDelayed = 'customer_delayed',
	TimeChanged = 'time_changed',
	Unknown = 'unknown',
}

export interface ApiCustomer {
	firstname: string;
	lastname: string;
	phoneNumber: string;
}

export interface ApiOrderMultipleDate {
	datetime: Date;
	Orders: ApiOrder[];
}

export interface ApiOrder {
	id: string;
	clientOrderId: string;
	foodxId: string;
	alternateClientOrderReference?: string;
	sequenceNumber: number;
	datetime: string; // date string
	customer?: ApiCustomer;
	state: ApiOrderState;
	totes?: Tote[];
	storage?: {
		storageSlots: Array<{ id: string; label: string }>;
		pallies: Array<{ id: string; label: string }>;
	};
	items?: OrderItem[];
}

export interface ApiOrderWithItems {
	id: string;
	clientOrderId: string;
	datetime: string;
	items: OrderItem[];
	state: string;
	totes: Tote[];
}

/* ------------- Action Payload Types  ------------- */
export interface OrdersSetActionType {
	orders: Order[];
	hasDetails?: boolean;
}

export interface OrderUpdateActionType {
	orderId: string;
	state: OrderState;
	timeslot?: Interval;
	currentDateKey: string;
}

export interface OrderDetailFetchActionType {
	orderId: string;
	forceOpen?: boolean;
}

export interface OrderDetailSetActionType {
	orderDetail: OrderDetail;
}

export interface OrderCustomerFetchActionType {
	orderId: string;
	dateKey: string;
}

export interface OrderCustomerSetActionType {
	orderId: string;
	customer: Customer;
	dateKey: string;
}

export interface OrdersFetchActionType {
	includeCustomers?: boolean;
	includeItems?: boolean;
	includePreviousDays?: number;
	date?: Date;
}
