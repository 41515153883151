import React, { ReactElement, useMemo } from 'react';

import { ListItemType } from 'src/components/list/list.type';

import { SectionListProps } from './sectionList.type';

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
function SectionList<I = Record<string, any>>({
	sections,
	renderItem,
	renderSectionHeader,
	keyExtractor,
	className,
	style,
}: SectionListProps<I>): ReactElement | null {
	const flattenedData: Array<(I | { section: string }) & { type: ListItemType; index: number }> = useMemo(() => {
		let data: Array<(I | { section: string }) & { type: ListItemType; index: number }> = [];

		Object.keys(sections).forEach((section: string, sectionIndex: number) => {
			const sectionItem = {
				section,
				index: sectionIndex,
				type: ListItemType.Section,
			};

			const dataItems = sections[section].map((item, itemIndex) => ({
				...item,
				index: itemIndex,
				type: ListItemType.Item,
			}));

			data = [...data, sectionItem, ...dataItems];
		});

		return data;
	}, [sections]);

	const renderedItem = (item: (I | { section: string }) & { type: ListItemType; index: number }, index: number) => {
		return (
			<div key={keyExtractor(item, index)} style={style}>
				{item.type === ListItemType.Section
					? renderSectionHeader((item as { section: string }).section)
					: renderItem(item as I, item.index)}
			</div>
		);
	};

	return (
		<div className={className} style={style}>
			{flattenedData.map(renderedItem)}
		</div>
	);
}

export { SectionList };
