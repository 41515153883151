import {
	actionCarrefourStoreClear,
	actionCarrefourStoresFetch,
} from 'src/state/store/carrefourStore/carrefourStore.action';
import { actionInboundDeliveriesClear, actionInboundDeliveriesFetch } from 'src/state/store/inbound/inbound.action';
import { actionOutboundOrdersClear, actionOutboundOrderWithDetailsFetch } from 'src/state/store/order/order.action';
import { selectInboundDeliveries, selectLastFetchedInboundDeliveries } from 'src/state/store/inbound/inbound.selector';
import { selectCarrefourStores, selectLastFetchedStores } from 'src/state/store/carrefourStore/carrefourStore.selector';
import { selectLastFetchedOrders, selectOutboundOrders } from 'src/state/store/order/order.selector';
import { RequestToCacheRecord } from 'src/state/store/cache/cache.type';
import { selectLastFetchedStorage, selectStorageOverview } from 'src/state/store/storage/storage.selector';
import { actionStorageClear, actionStorageOverviewFetch } from 'src/state/store/storage/storage.action';

import { MooseApiRoutes } from './api.config';

export const requestsToCache: RequestToCacheRecord = {
	[MooseApiRoutes.Stores]: {
		url: MooseApiRoutes.Stores,
		fetchAction: actionCarrefourStoresFetch,
		lastFetchedSelector: selectLastFetchedStores,
		cachedDataSelector: selectCarrefourStores,
		clearAction: actionCarrefourStoreClear,
	},
	[MooseApiRoutes.Inbound]: {
		url: MooseApiRoutes.Inbound,
		fetchAction: actionInboundDeliveriesFetch,
		clearAction: actionInboundDeliveriesClear,
		cachedDataSelector: selectInboundDeliveries,
		lastFetchedSelector: selectLastFetchedInboundDeliveries,
		needsSelectedStore: true,
	},
	[MooseApiRoutes.OutboundOrders]: {
		url: MooseApiRoutes.Inbound,
		fetchAction: actionOutboundOrderWithDetailsFetch,
		clearAction: actionOutboundOrdersClear,
		cachedDataSelector: selectOutboundOrders,
		lastFetchedSelector: selectLastFetchedOrders,
		needsSelectedStore: true,
	},
	[MooseApiRoutes.Storage]: {
		url: MooseApiRoutes.Storage,
		// fetchAction: actionStorageOverviewWithDetailsFetch, TODO: Storage caching
		fetchAction: actionStorageOverviewFetch,
		clearAction: actionStorageClear,
		cachedDataSelector: selectStorageOverview,
		lastFetchedSelector: selectLastFetchedStorage,
		needsSelectedStore: true,
	},
};
