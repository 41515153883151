import React, { FC } from 'react';
import './refundExportContainer.style.scss';
import { useSelector } from 'react-redux';
import { add, startOfYear } from 'date-fns';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';

import { IconButton } from 'src/components/button/iconButton/iconButton.component';
import { IconNames, IconSizes } from 'src/components/icon/icon.type';
import { Colors } from 'src/assets';
import { httpRequest } from 'src/helpers/download.helper';
import { getMSALUserMetaDataToken } from 'src/helpers/auth.helper';
import { CarrefourStore } from 'src/state/store/carrefourStore/carrefourStore.type';
import { selectCarrefourStores } from 'src/state/store/carrefourStore/carrefourStore.selector';
import { SelectField } from 'src/components/select/selectField';
import { SelectOptions } from 'src/components/select/selectField.type';
import { Icon } from 'src/components/icon/icon.component';
import { APP_REFUND_EXPORT_FIRST_YEAR } from 'src/config/refund.config';
import { formatTrans } from 'src/assets/translations/formatWithTranslation';

const RefundExportContainer: FC = () => {
	const metaToken: string | null | undefined = getMSALUserMetaDataToken();

	const { t } = useTranslation();

	const stores: CarrefourStore[] = useSelector(selectCarrefourStores);
	const storeOptions: SelectOptions[] = stores.map((store) => {
		return { value: store.locationReferenceId, label: '#' + store.locationReferenceId };
	});

	const yearOptions: SelectOptions[] = [];
	const monthOptions: SelectOptions[] = [];
	const monthsThisYear: number[] = [];
	const today = new Date();
	const firstDayOfYear = startOfYear(today);

	for (let i = 0; i < 12; i++) {
		if (i < 5) {
			const year = firstDayOfYear.getFullYear() - i;
			if (year >= APP_REFUND_EXPORT_FIRST_YEAR) yearOptions.push({ value: year, label: year.toString() });
		}
		monthOptions.push({ value: i + 1, label: formatTrans(add(firstDayOfYear, { months: i }), 'LLLL') });
		if (i <= today.getMonth()) {
			monthsThisYear.push(i + 1);
		}
	}

	const formSchema = Yup.object().shape({
		year: Yup.number().required(t('refund.form.validation.mandatory')),
		month: Yup.number().when('year', {
			is: today.getFullYear(),
			then: Yup.number()
				.required(t('refund.form.validation.mandatory'))
				.oneOf(monthsThisYear, t('refund.form.validation.dateFuture')),
			otherwise: Yup.number().required(t('refund.form.validation.mandatory')).max(12).min(1),
		}),
		store: Yup.string().required(t('refund.form.validation.mandatory')),
	});

	const form = useFormik<{
		year: number;
		month: number;
		store: string;
	}>({
		initialValues: {
			year: firstDayOfYear.getFullYear(),
			month: today.getMonth() + 1,
			store: '',
		},
		enableReinitialize: true,
		validationSchema: formSchema,
		onSubmit: (values) => {
			downloadCSV(values.store, values.year, values.month);
		},
	});

	const setField = (data: any, field: string) => {
		if (data) {
			form.setFieldValue(field, data);
		}
	};

	const downloadCSV = (store: string, year: number, month: number) => {
		try {
			if (metaToken) {
				httpRequest(
					process.env.REACT_APP_BASE_URL! +
						(process.env.REACT_APP_API_PREFIX ?? '') +
						`/refund/${store}?year=${year}&month=${month}`,
					'get',
					metaToken,
					'',
				)
					.then((response) => {
						return response.blob();
					})
					.then((blob) => {
						const bl = new Blob([blob], { type: 'text/csv;charset=utf-8;' });
						const url = window.URL.createObjectURL(bl);
						const link = document.createElement('a');
						link.href = url;
						link.setAttribute('download', `${store}_refund_${year}_${month}.csv`);

						link.click();
						if (link.parentNode) {
							link.parentNode.removeChild(link);
						}
					});
			}
		} catch (err) {
			console.log(err);
		}
	};

	return (
		<div className={'refundExport-container'}>
			<Icon iconName={IconNames.Download} size={IconSizes.Huge} className={'icon'} color={Colors.brightBlue} />
			<div className={'refund__info'}>
				<h2>{t('refund.description')}</h2>
			</div>
			<form className={'form'} onSubmit={form.handleSubmit}>
				<SelectField
					onChange={(val: string | undefined | null) => {
						if (val) {
							setField(val, 'store');
						}
					}}
					placeholder={t('refund.form.options.store')}
					options={storeOptions}
					error={form.errors.store}
				/>
				<SelectField
					onChange={(val: string | undefined | null) => {
						if (val) {
							setField(+val, 'year');
						}
					}}
					placeholder={t('refund.form.options.year')}
					options={yearOptions}
					error={form.errors.year}
				/>
				<SelectField
					onChange={(val: string | undefined | null) => {
						if (val) {
							setField(+val, 'month');
						}
					}}
					placeholder={t('refund.form.options.month')}
					options={monthOptions}
					error={form.errors.month}
				/>
				<IconButton
					type="submit"
					iconName={IconNames.Download}
					fullWidth={true}
					className={'button'}
					backgroundColor={Colors.green}
					disabled={!form.isValid}
					size={IconSizes.Normal}
					color="white">
					{t('refund.download')}
				</IconButton>
			</form>
		</div>
	);
};

export { RefundExportContainer };
