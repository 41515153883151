import React, { FC } from 'react';
import { useHistory } from 'react-router';

import StorageDetailContainer from 'src/containers/app/storage/storageDetail/storageDetail.container';
import { replaceColonPrefixed } from 'src/helpers/message.helpers';
import { withNavbarHeader } from 'src/hoc/withNavbarHeader/withNavbarHeader.hoc';
import { StorageDetailPageProps } from 'src/navigation/app/appRouter.type';
import { StoragePages } from 'src/navigation/app/storage/storageRouter.type';
import { Pally, StorageSlot } from 'src/state/store/storage/storage.type';

const StorageDetailPage: FC<StorageDetailPageProps> = ({ storageItem }: StorageDetailPageProps) => {
	const history = useHistory();

	const navigateToStorageScanner = (storageItem: StorageSlot) => {
		history.push(StoragePages.StorageScanner, { storageItem });
	};

	const navigateToPallyDetailPage = (storageItem: Pally) => {
		history.push(replaceColonPrefixed(StoragePages.PallyDetail, { pallyId: storageItem.id }), { storageItem });
	};

	return (
		<StorageDetailContainer
			storageItem={storageItem}
			onNavigateToPallyDetail={navigateToPallyDetailPage}
			onNavigateToStorageScanner={navigateToStorageScanner}
		/>
	);
};

export default withNavbarHeader(StorageDetailPage);
