import React, { CSSProperties, FC } from 'react';
import classNames from 'classnames';

import { DefaultStyles, Shadows } from 'src/assets';
import { ShadowSizes } from 'src/assets/style/style.type';

import { CardProps } from './card.type';
import './card.scss';

const Card: FC<CardProps> = ({
	fullWidth = false,
	shadow = ShadowSizes.Small,
	style,
	children,
	className,
	...props
}: CardProps) => {
	const cardStyles: CSSProperties = {
		...(fullWidth && DefaultStyles.fullWidth),
		...(shadow !== ShadowSizes.None && Shadows[shadow]()),
		...style,
	};

	return (
		// eslint-disable-next-line react/jsx-props-no-spreading
		<div className={classNames('card', className)} style={cardStyles} {...props}>
			{children}
		</div>
	);
};

export { Card };
