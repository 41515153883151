import React, { CSSProperties, FC, ReactNode } from 'react';
import classNames from 'classnames';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';

import { Colors } from 'src/assets';
import { Icon } from 'src/components/icon/icon.component';
import { IconNames } from 'src/components/icon/icon.type';
import { OrderState } from 'src/state/store/order/order.type';
import { formatTrans } from 'src/assets/translations/formatWithTranslation';

import { OrderStateSquareProps } from './orderStateSquare.type';
import './orderStateSquare.style.scss';

const OrderStateSquare: FC<OrderStateSquareProps> = ({
	state,
	time,
	className,
	style,
	displayDay,
	...props
}: OrderStateSquareProps) => {
	const { t } = useTranslation();

	let squareStyles: CSSProperties = { backgroundColor: Colors.brightBlue, ...style };
	let squareContent: ReactNode;

	const hourElement = (
		<p className="square__hour">{displayDay ? formatTrans(time, 'dd MMM') : format(time, 'H:mm')}</p>
	);
	const iconSize = 22;

	switch (state) {
		case OrderState.PickedUp:
			squareStyles.backgroundColor = Colors.green;
			squareContent = (
				<>
					<Icon
						iconName={IconNames.Check}
						color={Colors.purewhite}
						className="square__icon"
						size={iconSize}
					/>
					<p className="square__info">{t('outgoing.state.pickedUp')}</p>
				</>
			);
			break;
		case OrderState.CancelReturn:
			squareStyles.backgroundColor = Colors.silverGrey;
			squareContent = (
				<>
					<Icon iconName={IconNames.Cross} className="square__icon" size={iconSize} />
					<p className="square__info">{t('outgoing.state.canceled')}</p>
				</>
			);
			break;
		case OrderState.CustomerLate:
			squareStyles.backgroundColor = Colors.orange;
			squareContent = (
				<>
					{hourElement}
					<p className="square__info">{t('outgoing.state.late')}</p>
				</>
			);
			break;
		case OrderState.TimeChanged:
			squareContent = (
				<>
					{hourElement}
					<p className="square__info">{t('outgoing.state.delayed')}</p>
				</>
			);
			break;
		default:
			squareContent = hourElement;
	}

	return (
		// eslint-disable-next-line react/jsx-props-no-spreading
		<div className={classNames('square', className)} style={squareStyles} {...props}>
			{squareContent}
		</div>
	);
};

export { OrderStateSquare };
