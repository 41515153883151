import add from 'date-fns/add';

import { ApiOrderState, ApiOrderWithItems, OrderDetail } from 'src/state/store/order/order.type';
import { Mapper } from 'src/state/mappers/mapper.type';

import { orderStateMapper } from './orderState.mapper';

class OrderDetailMapper extends Mapper<ApiOrderWithItems, OrderDetail> {
	map(data: ApiOrderWithItems): OrderDetail {
		if (!data.id || !data.datetime) {
			return this.throwInvalid();
		}

		// eslint-disable-next-line no-undef
		const timeslotDuration: Duration = { hours: 1 };

		const orderDate = Date.parse(data.datetime);

		return {
			id: data.id,
			clientOrderId: data.clientOrderId,
			timeslot: {
				start: orderDate,
				end: add(orderDate, timeslotDuration).getTime(),
			},
			state: orderStateMapper.map(data.state as ApiOrderState),
			totes: data.totes?.map((tote) => ({ barcode: tote.lp })),
			items: data.items || [],
		};
	}
}

export const orderDetailMapper = new OrderDetailMapper();
