import React, { FC, PropsWithChildren, useEffect, useRef } from 'react';

import { UpdateAppModal } from 'src/components/modals/updateApp/updateApp.component';
import { useModal } from 'src/hooks/useModal.hook';
import * as serviceWorkerRegistration from 'src/serviceWorkerRegistration';

const UpdateAppContainer: FC = ({ children }: PropsWithChildren<any>) => {
	const waitingWorker = useRef<ServiceWorker | null>(null);

	const { onOpenModal, onDismissModal } = useModal();

	useEffect(() => {
		if (process.env.NODE_ENV === 'production') {
			serviceWorkerRegistration.register({
				onUpdate: onSWUpdate,
				onWaiting: onSWWaiting,
			});
		}

		return () => {
			onDismissModal();
		};
	}, []);

	const openModal = () => {
		onOpenModal(<UpdateAppModal onUpdate={reloadPage} />, { backdropDismissible: false });
	};

	const reloadPage = () => {
		if (waitingWorker) {
			waitingWorker.current?.postMessage({ type: 'SKIP_WAITING' });

			setTimeout(() => window.location.reload(), 500);
		}
	};

	const onSWUpdate = (registration: ServiceWorkerRegistration) => {
		waitingWorker.current = registration.waiting;
		openModal();
	};

	const onSWWaiting = (registration: ServiceWorkerRegistration) => {
		waitingWorker.current = registration.waiting;
		openModal();
	};

	return <>{children}</>;
};

export { UpdateAppContainer };
