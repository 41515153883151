import { AxiosResponse } from 'axios';
import { call, CallEffect, put, takeLatest } from 'redux-saga/effects';

import { actionStatusAdd } from 'src/state/store/actionStatus/actionStatus.action';
import { ActionStatusEnum, ActionType } from 'src/state/store/actionStatus/actionStatus.type';
import { ACTION_OUTGOING_ORDER_UPDATE } from 'src/state/store/order/order.const';
import { fetchRequest, HttpMethod } from 'src/state/sagas/network/network.saga';
import { MooseApiRoutes } from 'src/config/api.config';
import { actionOutboundOrdersFetch, actionOutgoingOrderUpdateSet } from 'src/state/store/order/order.action';
import { replaceColonPrefixed } from 'src/helpers/message.helpers';
import { Interval, OrderState, OrderUpdateActionType } from 'src/state/store/order/order.type';
import { orderStateMapper } from 'src/state/mappers/order/orderState.mapper';
import { authorize } from 'src/state/sagas/auth/authorization.saga';
import { isOffline } from 'src/helpers/cache.helpers';

function* updateOutgoingOrder(
	orderId: string,
	state: OrderState,
	timeslot?: Interval,
): Generator<CallEffect, AxiosResponse, AxiosResponse> {
	const data: any = {
		newState: orderStateMapper.mapToApi(state),
	};

	if (timeslot) {
		data.newPickupTime = new Date(timeslot.start).toISOString();
	}

	return yield call(
		fetchRequest,
		{
			method: HttpMethod.Put,
			url: replaceColonPrefixed(MooseApiRoutes.OutgoingOrderUpdate, { orderId }),
			data,
		},
		[authorize],
	);
}

function* outgoingOrderUpdateWorker(action: ActionType<OrderUpdateActionType>) {
	try {
		if (!action.payload) {
			yield put(actionStatusAdd({ type: ACTION_OUTGOING_ORDER_UPDATE }, ActionStatusEnum.ERROR));

			return;
		}

		// Create action status running
		yield put(actionStatusAdd({ type: ACTION_OUTGOING_ORDER_UPDATE }, ActionStatusEnum.RUNNING, isOffline()));

		const { orderId, state, currentDateKey, timeslot } = action.payload;

		yield call(updateOutgoingOrder, orderId, state, timeslot);

		yield put(
			actionOutgoingOrderUpdateSet(
				orderId,
				state,
				currentDateKey,
				timeslot && {
					start: Date.parse(new Date(timeslot.start).toISOString()),
					end: Date.parse(new Date(timeslot.end).toISOString()),
				},
			),
		);

		yield put(actionOutboundOrdersFetch());

		// Create action status success
		yield put(actionStatusAdd({ type: ACTION_OUTGOING_ORDER_UPDATE }, ActionStatusEnum.SUCCESS));
	} catch (e) {
		// General error
		yield put(actionStatusAdd({ type: ACTION_OUTGOING_ORDER_UPDATE }, ActionStatusEnum.ERROR, isOffline()));
	}
}

export function* outgoingUpdateSaga() {
	yield takeLatest(ACTION_OUTGOING_ORDER_UPDATE, outgoingOrderUpdateWorker);
}
