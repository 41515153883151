import React, { FC } from 'react';
import { useHistory } from 'react-router';

import { OrderDetailPageProps } from 'src/navigation/app/appRouter.type';
import { withNavbarHeader } from 'src/hoc/withNavbarHeader/withNavbarHeader.hoc';
import { OrderHelpdeskContainer } from 'src/containers/app/outgoing/orderHelpdesk/orderHelpdesk.container';

const HelpdeskPageComponent: FC<OrderDetailPageProps> = ({ orderId, currentDateKey }: OrderDetailPageProps) => {
	const history = useHistory();

	const navigateBack = () => {
		history.goBack();
	};

	return <OrderHelpdeskContainer orderId={orderId} currentDateKey={currentDateKey} onNavigateBack={navigateBack} />;
};

const HelpdeskPage = withNavbarHeader<OrderDetailPageProps>(HelpdeskPageComponent);

export { HelpdeskPage };
