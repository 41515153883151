import React, { ChangeEvent, FC, useState } from 'react';
import classNames from 'classnames';

import { IconButton } from 'src/components/button/iconButton/iconButton.component';
import { IconNames } from 'src/components/icon/icon.type';
import { Icon } from 'src/components/icon/icon.component';
import { Colors } from 'src/assets';

import { SearchBarProps } from './searchBar.type';
import './searchBar.style.scss';

const SearchBar: FC<SearchBarProps> = ({ placeholder, className, onClear, ...props }: SearchBarProps) => {
	const [isActive, setIsActive] = useState<boolean>(false);
	const [value, setValue] = useState<string>('');

	const inputClassNames = classNames('search-bar__input');

	const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
		const { target } = event;

		setIsActive(target.value !== '');
		setValue(target.value);

		if (props.onChange) {
			props.onChange(event);
		}
	};

	const clearInput = () => {
		if (onClear) {
			setValue('');
			setIsActive(false);
			onClear();
		}
	};

	return (
		// eslint-disable-next-line react/jsx-props-no-spreading
		<div className={classNames('search-bar', className)} {...props}>
			<Icon className="search-bar__icon" iconName={IconNames.Search} size={18} color={Colors.brightBlue} />
			<input value={value} onChange={handleChange} className={inputClassNames} placeholder={placeholder} />
			{isActive ? (
				<IconButton
					className="search-bar__close"
					iconName={IconNames.Cross}
					size={15}
					color={Colors.brightBlue}
					onClick={clearInput}
				/>
			) : undefined}
		</div>
	);
};

export { SearchBar };
