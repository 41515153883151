const type = {
	openSans: {
		light: 'OpenSans-Light',
		regular: 'OpenSans-Regular',
		italic: 'OpenSans-Italic',
		semiBold: 'OpenSans-SemiBold',
		bold: 'OpenSans-Bold',
	},
	ubuntu: {
		light: 'Ubuntu-Light',
		regular: 'Ubuntu-Regular',
		italic: 'Ubuntu-Italic',
		medium: 'Ubuntu-Medium',
		bold: 'Ubuntu-Bold',
	},
};

const size = {
	xxsmall: '.5rem',
	xsmall: '.625rem',
	small: '0.75rem',
	normal: '.875rem',
	large: '1rem',
	xlarge: '1.125rem',
	xxlarge: '1.25rem',
	xxxlarge: '1.5rem',
};

const Fonts = {
	type,
	size,
};

export default Fonts;
