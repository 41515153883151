import React, { FC } from 'react';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';

import { BottomSheet } from 'src/components/bottomSheet/bottomSheet.component';
import { SecondaryButton } from 'src/components/button/secondaryButton/secondaryButton.component';
import { Icon } from 'src/components/icon/icon.component';
import { IconNames, IconSizes } from 'src/components/icon/icon.type';
import { OrderIconLabel } from 'src/components/label/orderIconLabel/orderIconLabel.component';
import { OrderStatusLabel } from 'src/components/label/orderStatusLabel/orderStatusLabel.component';
import { TextArea } from 'src/components/textArea/textArea.component';
import { getHourAndMinuteString } from 'src/helpers/time.helpers';

import { DeliveryNotesModalProps } from './deliveryNotes.type';
import './deliveryNotes.style.scss';

const DeliveryNotesModal: FC<DeliveryNotesModalProps> = ({ delivery, onConfirmPressed }: DeliveryNotesModalProps) => {
	const { t } = useTranslation();

	const { values, handleChange, handleSubmit } = useFormik({
		initialValues: {
			comment: '',
		},
		onSubmit: (values) => {
			onConfirmPressed(values.comment);
		},
	});

	return (
		<BottomSheet key={'delivery-notes'}>
			<div className="delivery-notes">
				<Icon className="delivery-notes__icon" iconName={IconNames.OrderDelivered} size={IconSizes.Huge} />
				<h1>{t('deliveryNotesModal.title')}</h1>

				<div className="delivery-notes__delivery-details">
					<div className="delivery-details__detail">
						<OrderStatusLabel label={getHourAndMinuteString(delivery.datetime)} />
					</div>
					<div className="delivery-details__detail">
						<OrderIconLabel
							numberOfItems={delivery.numberOfTotes}
							iconName={IconNames.Box}
							unit={t('incoming.boxes')}
						/>
					</div>
					<div className="delivery-details__detail">
						<OrderIconLabel
							numberOfItems={delivery.numberOfOrders}
							iconName={IconNames.Person}
							unit={t('incoming.orders')}
						/>
					</div>
				</div>

				<form className="delivery-notes__form" onSubmit={handleSubmit}>
					<div className="form__textfield">
						<TextArea
							rows={5}
							id="comment"
							name="comment"
							onChange={handleChange}
							value={values.comment}
							placeholder={t('deliveryNotesModal.notesPlaceholder')}></TextArea>
					</div>
					<SecondaryButton className="form__button" type="submit">
						<Icon className="button__button-icon" iconName={IconNames.Check} size={IconSizes.Small} />
						{t('deliveryNotesModal.submit')}
					</SecondaryButton>
				</form>
			</div>
		</BottomSheet>
	);
};

export { DeliveryNotesModal };
