const Colors = {
	transparent: 'transparent',

	purewhite: '#FFFFFF',
	black: '#000000',

	primary: '#0970E6',

	pageBackground: '#F7F7F7',

	red: '#D83832',

	green: '#4CCD8A',
	softGreen: '#C9F0D8',
	systemGreen: '#34C759',

	text: '#575757',

	nav: '#94A1B4',

	systemGrey: '#E5E5E5',
	veryLightGrey: '#F5F5F5',
	lightGrey: '#E6E6E6',
	midGrey: '#929292',
	silverGrey: '#BCBCBC',
	darkGrey: '#575757',

	lightBlue: '#E6F4FF',
	blue: '#0970E6',
	nightBlue: '#0E3368',
	brightBlue: '#0970E6',

	warmYellow: '#FEE2A2',
	orange: '#FFA917',
	darkOrange: '#D87832',
};

export default Colors;
