import React, { FC, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { dateIsPassedMidnight } from 'src/helpers/time.helpers';
import { useActionStatus } from 'src/hooks/useActionStatus.hook';
import { useIsLoggedIn } from 'src/hooks/useIsLoggedIn.hook';
import { ActionStatusEnum } from 'src/state/store/actionStatus/actionStatus.type';
import {
	actionExpiredCachedItemsClear,
	actionInitialStartupDataFetch,
	actionSelectedStoreStartupDataFetch,
} from 'src/state/store/cache/cache.action';
import { selectLastAuthenticatedCacheSynced, selectLastInitialCacheSynced } from 'src/state/store/cache/cache.selector';
import { selectSelectedStoreId } from 'src/state/store/carrefourStore/carrefourStore.selector';
import { actionLogout } from 'src/state/store/general/general.action';
import { actionApiVersionFetch } from 'src/state/store/settings/settings.action';

const CacheProvider: FC<{ children: React.ReactNode }> = ({ children }: { children: React.ReactNode }) => {
	const lastSyncedInitialCache: number | undefined = useSelector(selectLastInitialCacheSynced);
	const lastSyncedAuthenticatedCache: number | undefined = useSelector(selectLastAuthenticatedCacheSynced);
	const isLoggedIn: boolean = useIsLoggedIn();
	const selectedStoreId = useSelector(selectSelectedStoreId);

	const hasFetchedInitialCache = useRef<boolean>(false);
	const hasFetchedAuthenticatedCache = useRef<boolean>(false);

	const clearCacheActionStatus = useActionStatus(actionExpiredCachedItemsClear());

	const dispatch = useDispatch();

	useEffect(() => {
		if (!isLoggedIn) {
			dispatch(actionLogout());
		}
	}, [isLoggedIn]);

	// Clears all expired items out of store and indexeddb
	useEffect(() => {
		dispatch(actionExpiredCachedItemsClear());
		dispatch(actionApiVersionFetch());
	}, []);

	// Fetch all cache on startup that don't need authentication
	useEffect(() => {
		if (
			!clearCacheActionStatus ||
			clearCacheActionStatus.status !== ActionStatusEnum.SUCCESS ||
			hasFetchedInitialCache.current
		) {
			return;
		}

		if (!lastSyncedInitialCache || dateIsPassedMidnight(lastSyncedInitialCache)) {
			dispatch(actionInitialStartupDataFetch());
			hasFetchedInitialCache.current = true;
		}
	}, [clearCacheActionStatus, lastSyncedInitialCache, hasFetchedInitialCache]);

	// Fetch all api data on startup when user selects store
	useEffect(() => {
		if (
			!clearCacheActionStatus ||
			clearCacheActionStatus.status !== ActionStatusEnum.SUCCESS ||
			hasFetchedAuthenticatedCache.current
		) {
			return;
		}

		if (
			isLoggedIn &&
			selectedStoreId &&
			(!lastSyncedAuthenticatedCache || dateIsPassedMidnight(lastSyncedAuthenticatedCache))
		) {
			dispatch(actionSelectedStoreStartupDataFetch());
			hasFetchedAuthenticatedCache.current = true;
		}
	}, [
		isLoggedIn,
		selectedStoreId,
		lastSyncedAuthenticatedCache,
		clearCacheActionStatus,
		hasFetchedAuthenticatedCache,
	]);

	return <>{children}</>;
};
export { CacheProvider };
