import React, { ComponentType } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { format } from 'date-fns';

import { TabHeader } from 'src/components/navigation/header/tabHeader/tabHeader.component';
import { CarrefourStore } from 'src/state/store/carrefourStore/carrefourStore.type';
import { selectSelectedStore } from 'src/state/store/carrefourStore/carrefourStore.selector';

import { WithTabHeaderProps } from './withTabHeader.type';
import './withTabHeader.style.scss';

const withTabHeader =
	<P extends WithTabHeaderProps>(Component: ComponentType<P>): React.FC<P> =>
	// eslint-disable-next-line react/display-name
	({ title, icon, extendedHeader = false, children, lastFetchedSelector, ...props }: WithTabHeaderProps) => {
		const { t } = useTranslation();
		const selectedStore: CarrefourStore | undefined = useSelector(selectSelectedStore);

		const lastFetched: number | undefined = lastFetchedSelector && useSelector(lastFetchedSelector);

		return (
			<div className="with-tab-content">
				<TabHeader
					className="with-tab-content__header"
					title={title}
					storeName={`${selectedStore ? `#${selectedStore.locationReferenceId}` : ''}`}
					lastSynced={
						lastFetched
							? t('general.lastSynced', {
									date: format(lastFetched, 'yyyy-MM-dd HH:mm'),
							  })
							: undefined
					}
					style={extendedHeader ? undefined : { zIndex: 3 }}
					icon={icon}>
					{children}
				</TabHeader>
				<div className="with-tab-content__content">
					<Component {...(props as P)} />
				</div>
			</div>
		);
	};

export { withTabHeader };
