import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { call } from 'redux-saga/effects';

import { Mapper } from 'src/state/mappers/mapper.type';

export enum HttpMethod {
	Get = 'GET',
	Post = 'POST',
	Put = 'PUT',
	Delete = 'DELETE',
	Head = 'HEAD',
	Options = 'OPTIONS',
	Patch = 'PATCH',
	Purge = 'PURGE',
	Link = 'LINK',
	Unlink = 'UNLINK',
}

export type TransformedNetworkHelper = (request: ExtendedAxiosRequestConfig) => IterableIterator<any>;
export type NetworkHelper = (request: ExtendedAxiosRequestConfig, next: TransformedNetworkHelper) => any;

const transformHelper = (helper: NetworkHelper, nextHelper: TransformedNetworkHelper): TransformedNetworkHelper => {
	return (request: ExtendedAxiosRequestConfig) => helper(request, nextHelper);
};

function* axiosHelper(
	request: ExtendedAxiosRequestConfig,
): Generator<Promise<AxiosResponse>, AxiosResponse | AxiosError, AxiosResponse | AxiosError> {
	return yield axios(request).catch((e: any) => {
		return Promise.reject(e);
	});
}

export interface ExtendedAxiosRequestConfig extends AxiosRequestConfig {
	extra?: any;
}

export interface ExtendedAxiosResponse<T = any> extends AxiosResponse<T> {
	isCached?: boolean;
}

/**
 * Fetch the request while executing an optional helper chain.
 * Automatically adds the correct base url.
 *
 * @param request An axios request.
 * @param helpers (optional) A single helper function or an array of helper functions.
 * @param mapper (optional) A mapper class to immediately map the response into the correct format
 * @returns returns an axios response.
 */
export function* fetchRequest(request: ExtendedAxiosRequestConfig, helpers: NetworkHelper[] = [], mapper?: Mapper) {
	// Add base url when needed
	if (request.url && !request.url.startsWith('http')) {
		request.url = `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_API_PREFIX}${request.url}`;
	}

	// Chain the helpers together with Axios as the end
	let transformedHelper = axiosHelper as TransformedNetworkHelper;

	for (let i = helpers.length - 1; i >= 0; i -= 1) {
		transformedHelper = transformHelper(helpers[i], transformedHelper);
	}

	// Execute the chain
	let response: ExtendedAxiosResponse = yield call(transformedHelper, request);

	if (mapper && !response.isCached) {
		response = {
			...response,
			data: Array.isArray(response?.data) ? response.data.map((d) => mapper.map(d)) : mapper.map(response?.data),
		};
	}

	return response;
}
