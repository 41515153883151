import { fork } from '@redux-saga/core/effects';

import { storageSlotDetailsFetchSaga } from './storageSlot/storageSlotsDetails.saga';
import { pallyDetailsFetchSaga } from './pally/pallyDetails.saga';
import { createStorageSlotSaga } from './storageSlot/storageSlotCreate.saga';
import { deletePallySaga } from './pally/pallyDelete.saga';
import { deleteStorageSlotSaga } from './storageSlot/storageSlotDelete.saga';
import { storageLinkSaga } from './storageLink.saga';
import { storageOverviewFetchSaga } from './storageOverviewFetch.saga';
import { createPallySaga } from './pally/pallyCreate.saga';

export function* storageSagas() {
	yield fork(storageOverviewFetchSaga);
	yield fork(storageSlotDetailsFetchSaga);
	yield fork(pallyDetailsFetchSaga);
	yield fork(storageLinkSaga);
	yield fork(deleteStorageSlotSaga);
	yield fork(deletePallySaga);
	yield fork(createStorageSlotSaga);
	yield fork(createPallySaga);
}
