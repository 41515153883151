import React, { FC } from 'react';
import classNames from 'classnames';

import { AppVersionLabelProps } from './appVersionLabel.type';
import './appVersionLabel.style.scss';

const AppVersionLabel: FC<AppVersionLabelProps> = ({ version, style, className }: AppVersionLabelProps) => {
	return (
		<div style={style} className={classNames('app-version-label', className)}>
			{version}
		</div>
	);
};

export { AppVersionLabel };
