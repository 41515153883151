import { ActionType } from 'src/state/store/actionStatus/actionStatus.type';

import {
	ACTION_INITIAL_STARTUP_DATA_FETCH,
	ACTION_SELECTED_STORE_STARTUP_DATA_FETCH,
	ACTION_CACHE_STORE_CLEAR,
	ACTION_EXPIRED_CACHED_ITEMS_CLEAR,
	ACTION_LAST_SYNCED_AUTHENTICATED_CACHE_SET,
	ACTION_LAST_SYNCED_INITIIAL_CACHE_SET,
} from './cache.const';
import { LastSyncedCacheActionType } from './cache.type';

/**
 * Action creator responsible to fetch all data that doesn't require authentication
 * e.g. Carrefour stores
 */
export const actionInitialStartupDataFetch = (): ActionType => {
	return { type: ACTION_INITIAL_STARTUP_DATA_FETCH };
};

/**
 * Action creator responsible to fetch all data that require a selected store
 * e.g. Inbound, outbound
 */
export const actionSelectedStoreStartupDataFetch = (): ActionType => {
	return { type: ACTION_SELECTED_STORE_STARTUP_DATA_FETCH };
};

export const actionExpiredCachedItemsClear = () => {
	return { type: ACTION_EXPIRED_CACHED_ITEMS_CLEAR };
};

export const actionCacheClear = (): ActionType => {
	return {
		type: ACTION_CACHE_STORE_CLEAR,
	};
};

/**
 * Action creator that updates the last synced time of initial data (e.g. store, employees...)
 *
 * @param lastSynced number that represents the last synced time
 */
export const actionLastSyncedInitialCacheSet = (lastSynced: number): ActionType<LastSyncedCacheActionType> => {
	return {
		type: ACTION_LAST_SYNCED_INITIIAL_CACHE_SET,
		payload: {
			lastSynced,
		},
	};
};

/**
 * Action creator that updates the last synced time of authenticated data (e.g. orders, deliveries...)
 *
 * @param lastSynced number that represents the last synced time
 */
export const actionLastSyncedAuthenticatedCacheSet = (lastSynced: number): ActionType<LastSyncedCacheActionType> => {
	return {
		type: ACTION_LAST_SYNCED_AUTHENTICATED_CACHE_SET,
		payload: {
			lastSynced,
		},
	};
};
