import { ActionType } from 'src/state/store/actionStatus/actionStatus.type';
import { LastFetchedSetActionType } from 'src/state/store/cache/cache.type';

import {
	ACTION_CARREFOUR_STORES_CLEAR,
	ACTION_CARREFOUR_STORES_FETCH,
	ACTION_CARREFOUR_STORES_SET,
	ACTION_CARREFOUR_STORE_CLEAR,
	ACTION_LAST_FETCHED_STORES_SET,
	ACTION_SELECTED_CARREFOUR_STORE_CHANGE,
	ACTION_SELECTED_CARREFOUR_STORE_REMOVE,
	ACTION_SELECTED_CARREFOUR_STORE_SAVE_NOTIFICATIONS,
	ACTION_SELECTED_CARREFOUR_STORE_SET,
	ACTION_SELECTED_CARREFOUR_STORE_SET_STORAGE,
	ACTION_STORE_SET_NOTIFICATIONS,
	ACTION_STORE_SET_STORAGE,
} from './carrefourStore.const';
import {
	CarrefourStore,
	CarrefourStoresSetActionType,
	NotificationType,
	SelectedStoreChangeActionType,
	SelectedStoreSaveNotificationsActionType,
	SelectedStoreSetActionType,
	SelectedStoreSetStorageActionType,
	StoreSetNotificationsActionType,
	StoreSetStorageActionType,
} from './carrefourStore.type';

export const actionCarrefourStoresFetch = (): ActionType => {
	return {
		type: ACTION_CARREFOUR_STORES_FETCH,
	};
};

export const actionCarrefourStoresSet = (stores: CarrefourStore[]): ActionType<CarrefourStoresSetActionType> => {
	return {
		type: ACTION_CARREFOUR_STORES_SET,
		payload: {
			stores,
		},
	};
};

export const actionSelectedStoreChange = (storeId: string): ActionType<SelectedStoreChangeActionType> => {
	return {
		type: ACTION_SELECTED_CARREFOUR_STORE_CHANGE,
		payload: {
			storeId,
		},
	};
};

export const actionSelectedStoreSet = (id: string): ActionType<SelectedStoreSetActionType> => {
	return {
		type: ACTION_SELECTED_CARREFOUR_STORE_SET,
		payload: {
			id,
		},
	};
};

export const actionSelectedStoreRemove = (): ActionType => {
	return {
		type: ACTION_SELECTED_CARREFOUR_STORE_REMOVE,
	};
};

export const actionCarrefourStoresClear = (): ActionType => {
	return {
		type: ACTION_CARREFOUR_STORE_CLEAR,
	};
};

export const actionCarrefourStoreSetNotifications = (
	type: NotificationType,
	data: string[],
): ActionType<SelectedStoreSaveNotificationsActionType> => {
	return {
		type: ACTION_SELECTED_CARREFOUR_STORE_SAVE_NOTIFICATIONS,
		payload: {
			type,
			data,
		},
	};
};

export const actionCarrefourStoreSaveStorage = (
	storageFeatures: boolean,
): ActionType<SelectedStoreSetStorageActionType> => {
	return {
		type: ACTION_SELECTED_CARREFOUR_STORE_SET_STORAGE,
		payload: {
			storageFeatures,
		},
	};
};

export const actionStoreSetNotifications = (
	id: string,
	emails: string[],
	phoneNumbers: string[],
): ActionType<StoreSetNotificationsActionType> => {
	return {
		type: ACTION_STORE_SET_NOTIFICATIONS,
		payload: {
			id,
			emails,
			phoneNumbers,
		},
	};
};

export const actionStoreSetStorage = (id: string, storageFeatures: boolean): ActionType<StoreSetStorageActionType> => {
	return {
		type: ACTION_STORE_SET_STORAGE,
		payload: {
			id,
			storageFeatures,
		},
	};
};

export const actionCarrefourStoreClear = (): ActionType => {
	return {
		type: ACTION_CARREFOUR_STORES_CLEAR,
	};
};

export const actionCarrefourStoresLastFetchedSet = (lastFetched: number): ActionType<LastFetchedSetActionType> => {
	return {
		type: ACTION_LAST_FETCHED_STORES_SET,
		payload: {
			lastFetched,
		},
	};
};
