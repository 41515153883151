import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useAuthContext } from '@foodx/fx-react-utils';

import { Header } from 'src/components/navigation/header/header.component';
import { selectApiVersion, selectAppLanguageFullName } from 'src/state/store/settings/settings.selector';
import { actionLogout } from 'src/state/store/general/general.action';
import { ListItem } from 'src/components/list/listItem/listItem.component';
import { SettingsList } from 'src/components/list/settingsList/settingsList.component';
import { Switch } from 'src/components/switch/switch.component';
import { selectSelectedStoreStorageFeatures } from 'src/state/store/carrefourStore/carrefourStore.selector';
import { actionCarrefourStoreSaveStorage } from 'src/state/store/carrefourStore/carrefourStore.action';
import { AppVersionLabel } from 'src/components/label/appVersionLabel/appVersionLabel.component';
import { APP_VERSION } from 'src/config/app.config';

import './settingsContainer.style.scss';
import { SettingsContainerProps } from './settingsContainer.type';

const SettingsContainer: FC<SettingsContainerProps> = ({
	navToLanguage,
	navToNotifications,
	navToStoreSelection,
}: SettingsContainerProps) => {
	const language: string | undefined = useSelector(selectAppLanguageFullName);
	const apiVersion: string | undefined = useSelector(selectApiVersion);
	const enableStorage = useSelector(selectSelectedStoreStorageFeatures);

	const { t } = useTranslation();

	const { logout } = useAuthContext();

	const dispatch = useDispatch();

	const setEnableStorage = (enableStorage: boolean) => {
		dispatch(actionCarrefourStoreSaveStorage(enableStorage));
	};

	const logUserOut = async () => {
		dispatch(actionLogout());
		await logout({ authority: `https://login.microsoftonline.com/${process.env.REACT_APP_AUTH_TENANT}` });
	};

	const openPrivacyPolicy = () => {
		window.open('https://www.foodxtech.com/privacy-policy/', '_blank');
	};

	return (
		<>
			<Header title={t('tabs.settings')} className="settings-header" />

			<div className="settings-container">
				<SettingsList>
					<ListItem key="storage" hasChevron={false}>
						<span className="content__label">{t('settings.storage')}</span>

						<Switch checked={enableStorage} onChange={setEnableStorage} />
					</ListItem>
					<ListItem key="notifications" onClick={navToNotifications}>
						<span className="content__label">{t('settings.notifications')}</span>
					</ListItem>
					<ListItem key="language" onClick={navToLanguage}>
						<span className="content__label">{t('settings.language')}</span>
						<span className="content__value">{language}</span>
					</ListItem>
					<ListItem key="privacy" onClick={openPrivacyPolicy}>
						<span className="content__label">{t('settings.privacyPolicy')}</span>
					</ListItem>
					<ListItem key="change-store" onClick={navToStoreSelection}>
						<span className="content__label">{t('settings.changeStore')}</span>
					</ListItem>
					<ListItem key="logout" hasChevron={false} onClick={logUserOut}>
						<span className="content__label content__label--red">{t('settings.logout')}</span>
					</ListItem>
				</SettingsList>
			</div>

			<AppVersionLabel
				version={`v${APP_VERSION} ${apiVersion ? ' | API v' + apiVersion : ''}`}
				className="settings-app-version"
			/>
		</>
	);
};

export { SettingsContainer };
