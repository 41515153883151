import { fork } from 'redux-saga/effects';

import { orderDetailFetchSaga } from './orderDetailFetch.saga';
import { outboundOrdersFetchSaga } from './outboundOrdersFetch.saga';
import { outgoingUpdateSaga } from './outgoingUpdate.saga';
import { outgoingHelpdeskRequestSaga } from './outgoingHelpdeskRequest.saga';
import { orderCustomerFetchSaga } from './orderCustomerFetch.saga';
import { outgoingRefundRequestSaga } from './outgoingRefundRequest.saga';

export function* outgoingSagas() {
	yield fork(outboundOrdersFetchSaga);
	yield fork(outgoingUpdateSaga);
	yield fork(orderDetailFetchSaga);
	yield fork(outgoingHelpdeskRequestSaga);
	yield fork(outgoingRefundRequestSaga);
	yield fork(orderCustomerFetchSaga);
}
