import {
	BarcodeReaderEventHandlerCallback,
	BarcodeReaderResult,
	BufferResult,
	HoneywellBarcodeSDKReader,
	SetCompleteResult,
} from './honeywellSDK.type';

// Use static functions to work with Promises: https://betterprogramming.pub/how-to-write-an-async-class-constructor-in-typescript-javascript-7d7e8325c35e
export class HoneywellBarcodeReader {
	private barcodeReader: HoneywellBarcodeSDKReader | null = null;
	isPresent: boolean = false;

	constructor(barcodeReader: HoneywellBarcodeSDKReader | null) {
		this.barcodeReader = barcodeReader;
		this.isPresent = !!barcodeReader;
	}

	private hasSuccessfulStatus(status: number): boolean {
		return status === 0;
	}

	/**
	 * Activates/Deactivates scanner
	 *
	 * @param on boolean that indicates if scanner should be activated/deactivated
	 */
	activate(on: boolean): Promise<void> {
		return new Promise((resolve, reject) => {
			if (!this.barcodeReader) {
				reject();
			}

			this.barcodeReader!.activate(on, (result: BarcodeReaderResult) => {
				if (this.hasSuccessfulStatus(result.status)) {
					resolve();
				}

				reject(result.message);
			});
		});
	}

	/**
	 * Registers the specified event handler for handeling scanner events (type 'barcodedataready')
	 *
	 * @param eventHandler callback function to be called when event occurs
	 * @param eventCapturingMode True for capturing phase but false for bubbling pahse (Honeywell ignores this param)
	 */
	addEventListener(eventHandler: BarcodeReaderEventHandlerCallback, eventCapturingMode: boolean = false): void {
		if (this.barcodeReader) {
			this.barcodeReader.addEventListener('barcodedataready', eventHandler, eventCapturingMode);
		}
	}

	/**
	 * Unregisters the specified event handler which was previously registered by addEventListener
	 *
	 * @param eventHandler callback function that is used in de addEventListener
	 */
	removeEventListener(eventHandler: BarcodeReaderEventHandlerCallback) {
		if (this.barcodeReader) {
			this.barcodeReader.removeEventListener('barcodedataready', eventHandler);
		}
	}

	setBuffered(
		family: string,
		key: string,
		option: string,
		onSetComplete: string | ((result: SetCompleteResult) => void),
	) {
		if (this.barcodeReader) {
			this.barcodeReader.setBuffered(family, key, option, onSetComplete);
		}
	}

	commitBuffer(onCommitComplete: (result: BufferResult) => void) {
		if (this.barcodeReader) {
			this.barcodeReader.commitBuffer(onCommitComplete);
		}
	}

	close(onActionComplete: (result: BarcodeReaderResult) => void): void {
		if (this.barcodeReader) {
			this.barcodeReader.close(onActionComplete);
		}
	}
}
