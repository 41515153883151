import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';

import { StoreSelectionContainer } from 'src/containers/storeSelection/storeSelection.container';
import { withBoxedContainer } from 'src/hoc/withBoxedContainer/withBoxedContainer.hoc';

const StoreSelectionPageComponent: FC = () => {
	const history = useHistory();

	const navigateToApp = () => {
		history.push('/');
	};

	const navigateToBackoffice = () => {
		history.push('/backoffice');
	};

	return <StoreSelectionContainer onNavigateToApp={navigateToApp} onNavigateToBackoffice={navigateToBackoffice} />;
};

const StoreSelectionPage = withBoxedContainer(StoreSelectionPageComponent);

export { StoreSelectionPage };
