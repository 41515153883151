import { isBefore } from 'date-fns';

import { Order, OrderState } from 'src/state/store/order/order.type';

import { ORDER_ROW_SCROLL_OFFSET, SHOW_LATE_ORDER_BUTTON_OFFSET } from './orderOverview.const';

/**
 * Helper method that will loop over all orders and find index of the earliest late order
 * This helper is used to calculate the index of the earliest late order scroll offset
 *
 * @param orders
 * @returns the index of the earliest late order
 */
export const getIndexOfEarliestLateOrder = (orders: Order[]): number => {
	const indexOfEarlistLateOrder: number = orders?.findIndex((order) => order.state === OrderState.CustomerLate);

	return indexOfEarlistLateOrder >= 0 ? indexOfEarlistLateOrder : 0;
};

/**
 * Helper method that will loop over all orders and find index of the latest late order
 * This helper is used to check whether we should hide or show a row item
 *
 * @param orders
 * @returns the index of the earliest late order
 */
export const getIndexOLatestLateOrder = (orders: Order[]): number => {
	const indexOfLatestOrder: number = orders?.map((order) => order.state).lastIndexOf(OrderState.CustomerLate);

	return indexOfLatestOrder >= 0 ? indexOfLatestOrder : 0;
};

/**
 * Helper method hat will caluclate the scrollOffset of the earliest late order
 * This helper is used to scroll to the first late order in the list (after clicking on the floating button)
 *
 * @param orders
 * @returns scroll offset that represents the earliest order
 */
export const getScrollOffsteOfEarliestLateOrder = (orders: Order[]): number => {
	const indexOfEarlistLateOrder: number = orders?.findIndex((order) => order.state === OrderState.CustomerLate);

	if (indexOfEarlistLateOrder <= 0) {
		return 0;
	}

	return ORDER_ROW_SCROLL_OFFSET * indexOfEarlistLateOrder;
};

/**
 * Helper method hat will caluclate the scrollOffset of the latest late order
 * This helper is used to hide and show floating button
 *
 * @param orders
 * @returns scroll offset that represents the latest order
 */
export const getScrollOffsteOfLatestLateOrder = (orders: Order[]): number => {
	const indexOfLatestLateOrder: number = getIndexOLatestLateOrder(orders);

	if (indexOfLatestLateOrder <= 0) {
		return 0;
	}

	return ORDER_ROW_SCROLL_OFFSET * indexOfLatestLateOrder;
};

/**
 * Helper method that will be used in the dynamic viewport
 * It will return the index of the first order in the dynamic viewport
 *
 * @param orders
 * @returns index of first order in viewport
 */
export const getIndexOfFirstOrderInViewport = (orders: Order[]): number => {
	const now = Date.now();
	const ordersInViewport: Order[] = orders?.filter((order) => isBefore(now, order.timeslot.end)) || [];

	const indexOfFirstOrderInViewport = orders?.findIndex((order) => order.id === ordersInViewport[0]?.id);

	return indexOfFirstOrderInViewport >= 0 ? indexOfFirstOrderInViewport : 0;
};

/**
 * Helper method that will calculate the initial scroll offset of the viewport
 *
 * @param orders
 * @param hasLateOrders
 * @returns scroll offset where the list should start
 */
export const getInitialScrollOffset = (orders: Order[], hasLateOrders: boolean) => {
	const indexOfFirstOrderInViewport = getIndexOfFirstOrderInViewport(orders);

	if (indexOfFirstOrderInViewport < 0) {
		return 0;
	}

	return hasLateOrders
		? ORDER_ROW_SCROLL_OFFSET * indexOfFirstOrderInViewport - SHOW_LATE_ORDER_BUTTON_OFFSET
		: ORDER_ROW_SCROLL_OFFSET * indexOfFirstOrderInViewport;
};
