import { isAfter, setHours, startOfHour } from 'date-fns';

import { Timeslot } from 'src/state/store/order/order.type';

export const generateTimeSlots = (startHour = 8, endHour = 21): Timeslot[] => {
	const timeslots: number[] = [...Array(endHour - startHour).keys()];

	const currentDate = startOfHour(new Date());

	return timeslots.map((idx) => {
		const end = setHours(currentDate, startHour + idx + 1);

		return {
			id: `${startHour + idx}-${startHour + idx + 1}`,
			start: setHours(currentDate, startHour + idx),
			end,
			disabled: isAfter(new Date(), end),
		};
	});
};

export const generateNextTimeSlot = (): Timeslot => {
	const currentDate = startOfHour(new Date());
	const start = new Date(currentDate.setHours(new Date().getHours() + 1));
	const end = new Date(currentDate.setHours(new Date().getHours() + 2));

	return {
		id: `${start.getHours()}-${end.getHours()}`,
		start: start,
		end: end,
		disabled: false,
	};
};
