import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { Scanner } from 'src/components/scanner/scanner.component';
import { Button } from 'src/components/button/button.component';
import { Colors } from 'src/assets';
import { selectOrderById } from 'src/state/store/order/order.selector';
import { Order } from 'src/state/store/order/order.type';
import { Store } from 'src/state/store.type';
import { isDev, isTest } from 'src/helpers/env.helpers';
import { PageNotFound } from 'src/components/pageNotFound/pageNotFound.component';
import { ScannerType } from 'src/components/scanner/scanner.type';
import { formatDateKeyForStore } from 'src/helpers/date.helpers';

import { OrderScannerContainerProps, OrderScanStatus } from './orderScannerContainer.type';
import './orderScannerContainer.style.scss';

const OrderScannerContainer: FC<OrderScannerContainerProps> = ({
	currentDateKey,
	orderId,
	onNavigateBack,
	onNavigateToOrderItems,
}: OrderScannerContainerProps) => {
	const { t } = useTranslation();

	const order: Order | undefined = useSelector((state: Store) =>
		selectOrderById(state, orderId, formatDateKeyForStore(currentDateKey)),
	);

	const [scanStatus, setScanStatus] = useState<string>(OrderScanStatus.INITIAL);
	const [scanIsActive, setScanIsActive] = useState<boolean>(true);

	if (!orderId) {
		return <PageNotFound description={t('orderScanner.orderIdNotSelected')} show404={false} />;
	}

	if (!currentDateKey) {
		return <PageNotFound description={t('orderScanner.dateUnknown')} show404={false} />;
	}

	const onInitedScanner = () => {
		setScanStatus(OrderScanStatus.INITIAL);
	};

	const onSuccess = (data: string | Record<string, unknown>) => {
		// check if foodX order id matches with data
		if (order?.alternateClientOrderReference === data) {
			setScanStatus(OrderScanStatus.SUCCESS);

			setTimeout(() => {
				onNavigateToOrderItems();
			}, 1000);
		}
	};

	const onFailed = (e: string | Record<string, unknown> | undefined, scannerType: ScannerType) => {
		let errorMessage: string | undefined;

		if (typeof e === 'string') {
			errorMessage = e;
		} else if (e && scannerType === ScannerType.HONEYWELL && (isDev() || isTest())) {
			errorMessage = e.message as string;
		}

		setScanStatus(
			`${t(
				scannerType === ScannerType.HONEYWELL ? OrderScanStatus.FAILED_HONEYWELL_SDK : OrderScanStatus.FAILED,
			)} ${errorMessage ? '\n' : ''} ${errorMessage || ''}`,
		);
	};

	const cancelScan = () => {
		setScanIsActive(false);
		onNavigateBack();
	};

	return (
		<div className="order-scanner">
			<p className="order-scanner__message" dangerouslySetInnerHTML={{ __html: t(scanStatus) }} />

			<div className="order-scanner__scan-container">
				<Scanner onInited={onInitedScanner} onSuccess={onSuccess} onFailed={onFailed} />
			</div>

			<div className="order-scanner__button-container">
				{scanIsActive ? (
					<Button backgroundColor={Colors.red} onClick={cancelScan}>
						{t('general.cancel')}
					</Button>
				) : null}
			</div>
		</div>
	);
};

export { OrderScannerContainer };
