import React, { CSSProperties, FC, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Colors } from 'src/assets';
import { Pally, ScannedStorageItem, StorageItemKey, StorageSlot } from 'src/state/store/storage/storage.type';
import { FlatList } from 'src/components/list/flatList/flatList.component';
import { Order } from 'src/state/store/order/order.type';
import { IconButton } from 'src/components/button/iconButton/iconButton.component';
import { IconNames } from 'src/components/icon/icon.type';
import StorageListItem from 'src/components/list/listItem/storageListItem/storageListItem.component';
import { selectPallyDetails, selectStorageSlotDetails } from 'src/state/store/storage/storage.selector';
import {
	actionPallyDetailsFetch,
	actionStorageDeletePally,
	actionStorageLink,
	actionStorageSlotDetailsFetch,
} from 'src/state/store/storage/storage.action';
import { getStorageItemType } from 'src/helpers/storageItem.helpers';
import { useActionStatus } from 'src/hooks/useActionStatus.hook';
import { ACTION_STORAGE_DELETE_PALLY, ACTION_STORAGE_LINK } from 'src/state/store/storage/storage.const';
import { ActionStatusEnum } from 'src/state/store/actionStatus/actionStatus.type';

import { StorageDetailContainerProps } from './storageDetailContainer.type';
import { STORAGE_LIST_MARGIN, STORAGE_ROW_HEIGHT, STORAGE_ROW_ITEM_MARGIN } from './storageDetailContainer.const';
import './storageDetailContainer.style.scss';

const StorageDetailContainer: FC<StorageDetailContainerProps> = ({
	storageItem,
	onNavigateToStorageScanner,
	onNavigateToPallyDetail,
}: StorageDetailContainerProps) => {
	const { t } = useTranslation();
	const listRef = useRef<any>(null);
	const containerRef = useRef<HTMLDivElement>(null);
	const dispatch = useDispatch();

	const deletePallyStatus = useActionStatus({ type: ACTION_STORAGE_DELETE_PALLY });
	const unlinkStatus = useActionStatus({ type: ACTION_STORAGE_LINK });

	const [filteredData, setFilteredData] = useState<Array<StorageSlot | Pally | Order>>([]);

	const storageSlotDetails = useSelector(selectStorageSlotDetails);
	const pallyDetails = useSelector(selectPallyDetails);

	const storageItemType: 'storageSlot' | 'pally' = 'pallies' in storageItem ? 'storageSlot' : 'pally';

	const fetchDetails = () => {
		if (storageItemType === 'storageSlot') {
			dispatch(actionStorageSlotDetailsFetch(storageItem.id));
		} else if (storageItemType === 'pally') {
			dispatch(actionPallyDetailsFetch(storageItem.id));
		}
	};

	useEffect(() => {
		fetchDetails();
	}, []);

	useEffect(() => {
		if (deletePallyStatus?.status === ActionStatusEnum.SUCCESS) {
			fetchDetails();
		}
	}, [deletePallyStatus]);

	useEffect(() => {
		if (unlinkStatus?.status === ActionStatusEnum.SUCCESS) {
			fetchDetails();
		}
	}, [unlinkStatus]);

	useEffect(() => {
		let storageList: Array<StorageSlot | Pally | Order> = [];

		if (storageItemType === 'storageSlot') {
			if (storageSlotDetails?.pallies) {
				storageList.push(...storageSlotDetails.pallies);
			}

			if (storageSlotDetails?.orders) {
				storageList.push(...storageSlotDetails.orders);
			}
		} else if (storageItemType === 'pally') {
			if (pallyDetails?.orders) {
				storageList.push(...pallyDetails.orders);
			}
		}

		setFilteredData(storageList);
	}, [storageItem, storageItemType, storageSlotDetails, pallyDetails]);

	const navigateToStorageScanner = (storageItem: StorageSlot) => {
		onNavigateToStorageScanner(storageItem);
	};

	const navigateToPallyDetailPage = (storageItem: Pally | Order) => {
		if ('foodxId' in storageItem) {
			return;
		}

		onNavigateToPallyDetail(storageItem);
	};

	const handleDelete = (item: Pally | Order) => {
		const scannedItems: ScannedStorageItem[] = [];

		switch (getStorageItemType(item)) {
			case StorageItemKey.PALLY:
				dispatch(actionStorageDeletePally(item.id));
				break;
			case StorageItemKey.ORDER:
				scannedItems.push({ type: StorageItemKey.ORDER, data: item.id });
				scannedItems.push({ type: StorageItemKey.STORAGE_SLOT, data: storageItem.code });
				dispatch(actionStorageLink(false, scannedItems));
				break;
		}
	};

	const renderRow = (item: Pally | Order | StorageSlot, index: number, style?: CSSProperties) => {
		return (
			<StorageListItem
				item={item}
				key={`${index}-${item.id}`}
				onClick={() => navigateToPallyDetailPage(item)}
				onDelete={() => handleDelete(item)}
				style={style}
			/>
		);
	};

	return (
		<div className="storage-container">
			<div ref={containerRef} className={'list-wrapper'}>
				<FlatList<Pally | Order | StorageSlot>
					outerRef={listRef}
					data={filteredData}
					renderItem={renderRow}
					rowHeight={STORAGE_ROW_HEIGHT}
					itemMargin={STORAGE_ROW_ITEM_MARGIN}
					listMargin={{
						top: STORAGE_LIST_MARGIN,
						bottom: STORAGE_LIST_MARGIN + STORAGE_ROW_HEIGHT,
						left: STORAGE_LIST_MARGIN,
						right: STORAGE_LIST_MARGIN,
					}}
				/>
			</div>
			<div className="storage__button-container">
				<IconButton
					fullWidth={true}
					backgroundColor={Colors.primary}
					size={15}
					className="button"
					iconName={IconNames.Enter}
					onClick={() => navigateToStorageScanner(storageItem)}>
					{t('storageDetailPage.button', { storage: storageItem.label })}
				</IconButton>
			</div>
		</div>
	);
};

export default StorageDetailContainer;
