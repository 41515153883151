import React, { FC } from 'react';

import { ICON_SIZES, ICONS } from './icon.const';
import { IconProps, IconSizes } from './icon.type';

const Icon: FC<IconProps> = ({ iconName, color, stroke, style, className, size = IconSizes.Normal }: IconProps) => {
	const IconComponent = ICONS[iconName];
	const iconSize = Object.values(IconSizes).includes(size as IconSizes) ? ICON_SIZES[size as IconSizes] : size;

	return (
		<IconComponent
			color={color}
			width={iconSize}
			height={iconSize}
			stroke={stroke}
			style={style}
			className={className}
			preserveAspectRatio="xMidYMid meet"
		/>
	);
};

export { Icon };
