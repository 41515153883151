import React, { FC } from 'react';
import { useHistory } from 'react-router';

import { OrderScannerContainer } from 'src/containers/app/outgoing/orderScanner/orderScanner.container';
import { replaceColonPrefixed } from 'src/helpers/message.helpers';
import { withNavbarHeader } from 'src/hoc/withNavbarHeader/withNavbarHeader.hoc';
import { OrderDetailPageProps } from 'src/navigation/app/appRouter.type';
import { OutgoingPages } from 'src/navigation/app/outgoing/outgoingRouter.type';

const OrderScannerPageComponent: FC<OrderDetailPageProps> = ({
	orderId,
	externalOrderId,
	currentDateKey,
}: OrderDetailPageProps) => {
	const history = useHistory();

	const navigateToOrderItems = () => {
		history.push(
			replaceColonPrefixed(OutgoingPages.OrderDetail, {
				orderId: orderId || '',
				currentDateKey: currentDateKey || '',
			}),
			{
				orderId,
				externalOrderId,
				currentDateKey,
			},
		);
	};

	const navigateBack = () => {
		history.goBack();
	};
	console.log('page date', currentDateKey);

	return (
		<OrderScannerContainer
			currentDateKey={currentDateKey}
			orderId={orderId}
			onNavigateToOrderItems={navigateToOrderItems}
			onNavigateBack={navigateBack}
		/>
	);
};

const OrderScannerPage = withNavbarHeader<OrderDetailPageProps>(OrderScannerPageComponent);

export { OrderScannerPage };
