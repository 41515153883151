import React, { FC } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import classNames from 'classnames';

import { TabProps } from './tab.type';
import './tab.scss';

const Tab: FC<TabProps> = ({ route, icon, disabled = false, label }: TabProps) => {
	const location = useLocation();

	const link = disabled ? (
		<a href={undefined} className="nav-link tab__link tab__link--disabled">
			<div className="link__icon">{icon}</div>
			<div className="link__label">{label}</div>
		</a>
	) : (
		<NavLink to={route} className="tab__link" activeClassName="active">
			<div className="link__icon">{icon}</div>
			<div className="link__label">{label}</div>
		</NavLink>
	);

	return (
		<div className={classNames('tab', { isActive: location.pathname?.includes(route) })}>
			{location.pathname?.includes(route) ? <div className="tab__indicator" /> : null}
			{link}
		</div>
	);
};

export { Tab };
