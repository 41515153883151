import React, { FC, useEffect, useRef } from 'react';

import { ScannerSkeleton } from 'src/components/scanner/scannerSkeleton/scannerSkeleton.component';
import { Icon } from 'src/components/icon/icon.component';
import { IconNames } from 'src/components/icon/icon.type';
import { HoneywellFactory } from 'src/models/honeywellScanner/honeywellFactory.model';
import { HoneywellBarcodeReader } from 'src/models/honeywellScanner/honeywellBarcodeReader.model';
import { BarcodeReaderResult, BufferResult } from 'src/models/honeywellScanner/honeywellSDK.type';
import { ScannerType } from 'src/components/scanner/scanner.type';

import { HoneywellScannerProps } from './honeywellScanner.type';
import './honeywellScanner.style.scss';

const HoneywellScanner: FC<HoneywellScannerProps> = ({
	onSuccess,
	onFailed,
	onHoneywellSDKInited,
}: HoneywellScannerProps) => {
	const scanner = useRef<HoneywellBarcodeReader | null>(HoneywellFactory.barcodeScanner);

	const honeywellSDKError: BarcodeReaderResult | undefined = HoneywellFactory.errorWhileCreating;

	useEffect(() => {
		if (honeywellSDKError) {
			onFailed(honeywellSDKError as Record<string, any>, ScannerType.HONEYWELL);
		}

		openBarcodeReader();

		return () => {
			scanner.current?.removeEventListener(onDataScanned);
		};
	}, []);

	const openBarcodeReader = () => {
		if (scanner.current) {
			scanner.current.setBuffered('Symbology', 'Code128', 'EnableCode128', 'true');
			scanner.current.setBuffered('Symbology', 'QRCode', 'Enable', 'true');

			scanner.current.commitBuffer(onCommitBuffer);

			// Add an event handler for the barcodedataready event
			scanner.current.addEventListener(onDataScanned);

			onHoneywellSDKInited();
		}
	};

	const onDataScanned = (data: string, type: string) => {
		if (type && typeof type === 'string' && type.toLowerCase().includes('qr')) {
			try {
				const dataObject = JSON.parse(data);
				onSuccess(dataObject);
			} catch (e) {
				onSuccess(data);
			}

			return;
		}

		onSuccess(data);
	};

	const onCommitBuffer = (bufferResult: BufferResult) => {
		console.log('bufferResult', bufferResult);
	};

	return (
		<ScannerSkeleton className={'honeywell-scanner'}>
			<Icon iconName={IconNames.Scan} size={'70vw'} />
		</ScannerSkeleton>
	);
};

export { HoneywellScanner };
