import { CSSProperties } from 'react';

const small = (color = 'rgba(0, 0, 0, 0.03)', extraStyles?: CSSProperties): CSSProperties => {
	const style: CSSProperties = {
		boxShadow: `0 5px 10px ${color}`,
		...extraStyles,
	};

	return style;
};

const normal = (color = 'rgba(0,0,0,0.75)', extraStyles?: CSSProperties): CSSProperties => {
	const style: CSSProperties = {
		boxShadow: `1px 5px 20px 2px ${color}`,
		...extraStyles,
	};

	return style;
};

const large = (color = 'rgba(0,0,0,0.75)', extraStyles?: CSSProperties): CSSProperties => {
	const style: CSSProperties = {
		boxShadow: `1px 2px 11px 6px  ${color}`,
		...extraStyles,
	};

	return style;
};

const Shadows = {
	small,
	normal,
	large,
};

export default Shadows;
