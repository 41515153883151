import { createSelector } from 'reselect';
import { compareAsc, format, isAfter, sub } from 'date-fns';

import { Store } from 'src/state/store.type';
import { APP_ORDERS_IN_PAST } from 'src/config/order.config';

import { Order, OrderState } from './order.type';

/**
 * Helper method that will automatically set an order to late if the end timeslot is passed
 *
 * @param orders array of orders
 * @returns array of mapped orders
 */
export const mapLateOrdersWithCurrentTime = (orders: Order[]): Order[] => {
	const now = Date.now();

	return orders
		.sort((a: Order, b: Order) => compareAsc(a.timeslot.start, b.timeslot.start))
		.reduce((acc: Order[], order: Order) => {
			const isLateOrder: boolean = isAfter(now, order.timeslot.end);

			// if the order is expected and is late -> we set the state on expected
			if (order.state === OrderState.Expected && isLateOrder) {
				order.state = OrderState.CustomerLate;
			}
			if (order.state === OrderState.TimeChanged && isLateOrder) {
				order.state = OrderState.CustomerLate;
			}
			acc.push(order);

			return acc;
		}, []);
};

export const selectOutboundOrdersRecord = ({ order: orderStore }: Store): Record<string, Record<string, Order>> => {
	return orderStore.orders;
};

export const selectOrderById = (
	{ order: orderStore }: Store,
	orderId: string | undefined,
	dateKey: string | undefined,
): Order | undefined => {
	if (!orderId || !dateKey) {
		return;
	}

	return orderStore.orders[dateKey][orderId];
};

export const selectOrdersByDateKey = ({ order: orderStore }: Store, dateKey: string): Order[] => {
	if (!orderStore.orders[dateKey]) {
		return [];
	}

	return sortOrdersByTimeslot(Object.values(orderStore.orders[dateKey]));
};

const sortOrdersByTimeslot = (orders: Order[]): Order[] => {
	return orders.sort((o1: Order, o2: Order) => {
		return compareAsc(o1.timeslot.start, o2.timeslot.start);
	});
};

export const selectOutboundDatesRecord = createSelector<Store, Record<string, Record<string, Order>>, string[]>(
	selectOutboundOrdersRecord,
	(ordersRecord) => {
		return Object.keys(ordersRecord);
	},
);

export const selectOutboundOrders = createSelector<Store, Record<string, Record<string, Order>>, Record<string, Order>>(
	selectOutboundOrdersRecord,
	(ordersRecord) => {
		return ordersRecord[format(new Date(), 'yyyy-MM-dd')];
	},
);

export const selectOutboundLateOrdersPreviousDays = createSelector<
	Store,
	Record<string, Record<string, Order>>,
	Order[]
>(selectOutboundOrdersRecord, (ordersRecord) => {
	const lateOrders: Order[] = [];
	APP_ORDERS_IN_PAST.forEach((i) => {
		const orders = ordersRecord[format(sub(new Date(), { days: i }), 'yyyy-MM-dd')];
		if (orders) {
			Object.values(orders).forEach((order) => {
				if (order.state !== OrderState.PickedUp) {
					lateOrders.push(order);
				}
			});
		}
	});

	return lateOrders;
});

/**
 * Memoized selector that will automatically set an expected order to late if the end timestamp is reached
 */
// export const selectLateOutboundOrders = createSelector<Store, Record<string, Order>, Order[]>(
// 	selectOutboundOrdersRecord,
// 	(ordersRecord) => {
// 		const orders = Object.values(ordersRecord) || [];
//
// 		return mapLateOrdersWithCurrentTime(orders);
// 	},
// );

/**
 * Memoized selector that counts the expected orders
 */
export const selectNumberExpectedOutboundOrders = ({ order: orderStore }: Store, dateKey: string): number => {
	const ordersRecord = orderStore.orders[dateKey];
	if (!ordersRecord) {
		return 0;
	}

	const orders = Object.values(ordersRecord);
	const filteredOrders: Order[] = mapLateOrdersWithCurrentTime(orders).filter(
		(lateOrder) => lateOrder.state !== OrderState.CancelReturn && lateOrder.state !== OrderState.PickedUp,
	);

	return filteredOrders?.length || 0;
};

// export const selectOrderIds = createSelector<Store, Record<string, Order>, string[]>(
// 	selectOutboundOrdersRecord,
// 	(ordersRecord) => {
// 		return Object.keys(ordersRecord);
// 	},
// );

export const selectAlternateClientOrderReferences = createSelector<
	Store,
	Record<string, Record<string, Order>>,
	Record<string, string> | undefined
>(selectOutboundOrdersRecord, (ordersRecordDate) => {
	const alternateClientOrderReferencesRecord: Record<string, string> = {};

	const ordersRecord = Object.values(ordersRecordDate);
	if (!ordersRecord) {
		return;
	}
	let orderKeys: string[] = [];
	ordersRecord.forEach((record) => {
		Object.keys(record).forEach((rec) => orderKeys.push(rec));
	});

	orderKeys.forEach((orderId) => {
		const alternateClientOrderReference = ordersRecord.find((order) => order[orderId] !== null);
		if (!alternateClientOrderReference || !alternateClientOrderReference[orderId]) {
			return;
		}
		if (alternateClientOrderReference) {
			alternateClientOrderReferencesRecord[
				alternateClientOrderReference[orderId].alternateClientOrderReference!
			] = orderId;
		}
	});

	return alternateClientOrderReferencesRecord;
});

export const selectLastFetchedOrders = ({ order }: Store): number | undefined => {
	return order.lastFetched;
};

export const selectLastFetchedOrdersPreviousDays = ({ order }: Store): number | undefined => {
	return order.lastFetchedPreviousDays;
};

// export const selectOrdersInStorageCount = createSelector<Store, Record<string, Order>, number>(
// 	selectOutboundOrdersRecord,
// 	(ordersRecord) =>
// 		Object.values(ordersRecord)
// 			.map((o) => o.storageSlots.length + o.pallies.length)
// 			.reduce((acc, val) => acc + val, 0),
// );
