import { CachedStore } from 'src/state/store/cache/cache.type';
import { ApiOrder, Order } from 'src/state/store/order/order.type';

export interface StorageStore extends CachedStore {
	storageSlots: Record<string, StorageSlot>;
	pallies: Record<string, Pally>;
	storageSlot?: StorageSlot;
	pally?: Pally;
}

export interface StorageSlot {
	id: string;
	code: number;
	label: string;
	pallies: Pally[];
	orderIds?: string[];
	orders?: Order[];
}

export interface Pally {
	id: string;
	code: number;
	label: string;
	storageSlotId?: string;
	orderIds?: string[];
	orders?: Order[];
}

export enum LinkMethod {
	MOVE = 'move',
	EXTEND = 'extend',
}

export enum StorageItemKey {
	PALLY = 'pally',
	ORDER = 'order',
	STORAGE_SLOT = 'storageSlot',
}

export interface ScannedStorageItem {
	type: StorageItemKey;
	data: string | number;
}

export interface ApiStorageLinkBody {
	link: boolean;
	method?: LinkMethod;
	orderId?: string;
	storageSlotId?: string;
	pallyId?: string;
}

export interface ApiStorageSlot {
	id: string;
	code: number;
	label: string;
	pallies: ApiPally[];
	orderIds?: string[];
	orders?: ApiOrder[];
}

export interface ApiPally {
	id: string;
	code: number;
	label: string;
	orderIds?: string[];
	orders?: ApiOrder[];
	storageSlotId?: string;
}

export interface ApiStorageOverview {
	storageSlots: ApiStorageSlot[];
	pallies: ApiPally[];
}

/* ------------- Action Payload Types  ------------- */
export type StorageOverviewFetchActionType = {
	// TODO
};

export interface StorageOverviewSetActionType {
	storageSlots: StorageSlot[];
	pallies: Pally[];
}

export interface StorageFetchStorageSlotActionType {
	storageSlotId: string;
}

export interface StorageSetStorageSlotActionType {
	storageSlot: StorageSlot;
}

export interface StorageFetchPallyActionType {
	pallyId: string;
}

export interface StorageSetPallyActionType {
	pally: Pally;
}

export interface StorageDeleteStorageSlotActionType {
	storageSlotId: string;
}

export interface StorageDeletePallyActionType {
	pallyId: string;
}

export interface StorageLinkAddActionType {
	link: boolean;
	scannedItems: ScannedStorageItem[];
	method?: LinkMethod;
}

export interface StorageCreateStorageSlotActionType {
	label?: string;
	code: number;
	pallyIds?: string[];
	orderIds?: string[];
}

export interface StorageCreatePallyActionType {
	label?: string;
	code: number;
	storageSlotId?: string;
	orderIds?: string[];
}
