import React, { FC } from 'react';
import { Redirect, Route } from 'react-router-dom';

import { RestrictedRouteProps } from './restrictedRoute.type';

/**
 * A wrapper for <Route> that redirects to another screen if condition 'canRoute' is not valid
 */
const RestrictedRoute: FC<RestrictedRouteProps> = ({
	canRoute = false,
	redirectRoute,
	children,
}: RestrictedRouteProps) => {
	return (
		<Route
			render={({ location }) =>
				canRoute ? (
					children
				) : (
					<Redirect
						to={{
							pathname: redirectRoute,
							state: { from: location },
						}}
					/>
				)
			}
		/>
	);
};

export { RestrictedRoute };
