import { call, put, select, takeLatest } from 'redux-saga/effects';

import { getListOfCachedRequestConfig } from 'src/helpers/cache.helpers';
import { dateIsPassedMidnight } from 'src/helpers/time.helpers';
import { actionStatusAdd } from 'src/state/store/actionStatus/actionStatus.action';
import { ActionStatusEnum } from 'src/state/store/actionStatus/actionStatus.type';
import { ACTION_EXPIRED_CACHED_ITEMS_CLEAR } from 'src/state/store/cache/cache.const';

export function* expiredCachedItemsClearSaga() {
	yield takeLatest(ACTION_EXPIRED_CACHED_ITEMS_CLEAR, expiredCachedItemsClearWorker);
}

function* expiredCachedItemsClearWorker() {
	// Create action status running
	yield put(actionStatusAdd({ type: ACTION_EXPIRED_CACHED_ITEMS_CLEAR }, ActionStatusEnum.RUNNING));

	try {
		// clear all expired store data
		yield call(clearExpiredStoreData);

		// Create action status success
		yield put(actionStatusAdd({ type: ACTION_EXPIRED_CACHED_ITEMS_CLEAR }, ActionStatusEnum.SUCCESS));
	} catch (e) {
		// General error
		yield put(actionStatusAdd({ type: ACTION_EXPIRED_CACHED_ITEMS_CLEAR }, ActionStatusEnum.ERROR));
	}
}

/**
 * Deletes all store data
 */
function* clearExpiredStoreData() {
	const requestConfigCacheList = getListOfCachedRequestConfig();

	for (const item of requestConfigCacheList) {
		const lastFetched: number | undefined = yield select(item.lastFetchedSelector);
		if (lastFetched && dateIsPassedMidnight(lastFetched)) {
			yield put(item.clearAction());
		}
	}
}
