import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { BottomSheet } from 'src/components/bottomSheet/bottomSheet.component';
import { IconNames, IconSizes } from 'src/components/icon/icon.type';
import { Icon } from 'src/components/icon/icon.component';
import './returnOrders.style.scss';
import { IconButton } from 'src/components/button/iconButton/iconButton.component';
import { Colors } from 'src/assets';

import { ReturnOrdersModalProps } from './returnOrders.type';

const ReturnOrdersModal: FC<ReturnOrdersModalProps> = ({
	onNextPressed,
	nrOfOrdersToReturn,
}: ReturnOrdersModalProps) => {
	const { t } = useTranslation();

	return (
		<BottomSheet key="return-orders">
			<div className="return-orders">
				<Icon className={'return-orders__icon'} iconName={IconNames.Box} size={IconSizes.Huge} />
				<p className="return-orders__description">
					{nrOfOrdersToReturn > 1
						? t('returnOrdersModal.descriptionMultiple', { nrOfOrdersToReturn })
						: t('returnOrdersModal.description')}
				</p>
				<IconButton
					className="button"
					fullWidth={true}
					backgroundColor={Colors.green}
					iconName={IconNames.Check}
					size={IconSizes.Small}
					color="white"
					onClick={onNextPressed}>
					{t('returnOrdersModal.submit')}
				</IconButton>
			</div>
		</BottomSheet>
	);
};

export default ReturnOrdersModal;
