import React, { FC } from 'react';
import classNames from 'classnames';
import { useHistory } from 'react-router-dom';

import { Header } from 'src/components/navigation/header/header.component';
import { IconNames, IconSizes } from 'src/components/icon/icon.type';
import { Icon } from 'src/components/icon/icon.component';
import { Colors } from 'src/assets';

import { NavbarHeaderProps } from './navbarHeader.type';
import './navbarHeader.style.scss';

const NavbarHeader: FC<NavbarHeaderProps> = ({
	title,
	icon,
	subtitle,
	children,
	className,
	style,
}: NavbarHeaderProps) => {
	const history = useHistory();

	return (
		<Header className={classNames('navbar-header', className)} style={style}>
			<div className="navbar-header__content">
				<div className="navbar-header__content__back-button" onClick={history.goBack}>
					<Icon iconName={IconNames.ChevronLeft} color={Colors.brightBlue} size={IconSizes.Small} />
				</div>
				<div className="navbar-header__content__title-wrapper">
					{icon && (
						<Icon
							className="navbar-header__content__icon"
							iconName={icon}
							color={Colors.nightBlue}
							size={IconSizes.Small}
						/>
					)}
					<div className="navbar-header__content__text-wrapper">
						<h1 className="navbar-header__content__title">{title}</h1>
						{subtitle && <h2 className="navbar-header__content__subtitle">{subtitle}</h2>}
					</div>
				</div>
				<div className="navbar-header__content__right"> </div>
			</div>
			<div className="navbar-header__children">{children}</div>
		</Header>
	);
};

export { NavbarHeader };
