import { call, CallEffect, put, select, takeLatest } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';

import { actionStatusAdd } from 'src/state/store/actionStatus/actionStatus.action';
import { ActionStatusEnum, ActionType } from 'src/state/store/actionStatus/actionStatus.type';
import { ACTION_SELECTED_CARREFOUR_STORE_SET_STORAGE } from 'src/state/store/carrefourStore/carrefourStore.const';
import { MooseApiRoutes } from 'src/config/api.config';
import { fetchRequest, HttpMethod } from 'src/state/sagas/network/network.saga';
import { replaceColonPrefixed } from 'src/helpers/message.helpers';
import { selectSelectedStore } from 'src/state/store/carrefourStore/carrefourStore.selector';
import { actionStoreSetStorage } from 'src/state/store/carrefourStore/carrefourStore.action';
import { CarrefourStore, SelectedStoreSetStorageActionType } from 'src/state/store/carrefourStore/carrefourStore.type';
import { authorize } from 'src/state/sagas/auth/authorization.saga';
import { isOffline } from 'src/helpers/cache.helpers';

export function* storeSetStorageSaga() {
	yield takeLatest(ACTION_SELECTED_CARREFOUR_STORE_SET_STORAGE, setStorageWorker);
}

function* setStorageWorker(action: ActionType<SelectedStoreSetStorageActionType>) {
	// Create action status running
	yield put(
		actionStatusAdd({ type: ACTION_SELECTED_CARREFOUR_STORE_SET_STORAGE }, ActionStatusEnum.RUNNING, isOffline()),
	);

	try {
		const selectedStore: CarrefourStore | undefined = yield select(selectSelectedStore);

		if (!selectedStore || !action?.payload) {
			yield put(actionStatusAdd({ type: ACTION_SELECTED_CARREFOUR_STORE_SET_STORAGE }, ActionStatusEnum.ERROR));

			return;
		}

		const { storageFeatures } = action.payload;

		yield put(actionStoreSetStorage(selectedStore.id, storageFeatures));

		yield call(storeSetStorage, selectedStore.id, storageFeatures);

		// Create action status success
		yield put(actionStatusAdd({ type: ACTION_SELECTED_CARREFOUR_STORE_SET_STORAGE }, ActionStatusEnum.SUCCESS));
	} catch (e) {
		// General error
		yield put(
			actionStatusAdd({ type: ACTION_SELECTED_CARREFOUR_STORE_SET_STORAGE }, ActionStatusEnum.ERROR, isOffline()),
		);
	}
}

function* storeSetStorage(
	storeId: string,
	storageFeatures: boolean = false,
): Generator<CallEffect, AxiosResponse, AxiosResponse> {
	return yield call(
		fetchRequest,
		{
			method: HttpMethod.Patch,
			url: replaceColonPrefixed(MooseApiRoutes.UpdateNotifications, { storeId }),
			data: {
				storageFeatures,
			},
		},
		[authorize],
	);
}
