import { C4StoreStateStore } from './carrefourStore.type';

export const ACTION_CARREFOUR_STORES_FETCH = 'ACTION_CARREFOUR_STORES_FETCH';
export const ACTION_CARREFOUR_STORES_SET = 'ACTION_CARREFOUR_STORES_SET';

export const ACTION_CARREFOUR_STORES_CLEAR = 'ACTION_CARREFOUR_STORES_CLEAR';

export const ACTION_SELECTED_CARREFOUR_STORE_SET = 'ACTION_SELECTED_CARREFOUR_STORE_SET';
export const ACTION_SELECTED_CARREFOUR_STORE_REMOVE = 'ACTION_SELECTED_CARREFOUR_STORE_REMOVE';
export const ACTION_SELECTED_CARREFOUR_STORE_CHANGE = 'ACTION_SELECTED_CARREFOUR_STORE_CHANGE';

export const ACTION_SELECTED_CARREFOUR_STORE_SAVE_NOTIFICATIONS = 'ACTION_SELECTED_CARREFOUR_STORE_SAVE_NOTIFICATIONS';
export const ACTION_STORE_SET_NOTIFICATIONS = 'ACTION_STORE_SET_NOTIFICATIONS';
export const ACTION_SELECTED_CARREFOUR_STORE_SET_STORAGE = 'ACTION_SELECTED_CARREFOUR_STORE_SET_STORAGE';
export const ACTION_STORE_SET_STORAGE = 'ACTION_STORE_SET_STORAGE';

export const ACTION_CARREFOUR_STORE_CLEAR = 'ACTION_CARREFOUR_STORE_CLEAR';

export const ACTION_LAST_FETCHED_STORES_SET = 'ACTION_LAST_FETCHED_STORES_SET';

export const CARREFOUR_STORE_INITIAL_STATE: C4StoreStateStore = {
	stores: {},
};
