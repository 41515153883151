import { createReducer } from 'reduxsauce';

import { ActionType } from 'src/state/store/actionStatus/actionStatus.type';
import { ACTION_LOGOUT } from 'src/state/store/general/general.const';
import {
	ACTION_STORAGE_CLEAR,
	ACTION_STORAGE_OVERVIEW_SET,
	ACTION_STORAGE_PALLY_DETAILS_CLEAR,
	ACTION_STORAGE_PALLY_DETAILS_SET,
	ACTION_STORAGE_STORAGE_SLOT_DETAILS_CLEAR,
	ACTION_STORAGE_STORAGE_SLOT_DETAILS_SET,
	STORAGE_INITIAL_STATE,
} from 'src/state/store/storage/storage.const';
import {
	StorageOverviewSetActionType,
	StorageSetPallyActionType,
	StorageSetStorageSlotActionType,
	StorageStore,
} from 'src/state/store/storage/storage.type';
import { arrayToMapRecord } from 'src/helpers/record.helpers';

const storageOverviewSet = (state: StorageStore, action: ActionType<StorageOverviewSetActionType>): StorageStore => {
	if (!action.payload) {
		return state;
	}

	const { storageSlots, pallies } = action.payload;

	return {
		...state,
		storageSlots: arrayToMapRecord(storageSlots),
		pallies: arrayToMapRecord(pallies),
	};
};

const storageSlotDetailsSet = (
	state: StorageStore,
	action: ActionType<StorageSetStorageSlotActionType>,
): StorageStore => {
	if (!action.payload) return state;

	const { storageSlot } = action.payload;

	return {
		...state,
		storageSlot,
	};
};

const storageSlotDetailsClear = (state: StorageStore): StorageStore => {
	return {
		...state,
		storageSlot: undefined,
	};
};

const pallyDetailsSet = (state: StorageStore, action: ActionType<StorageSetPallyActionType>): StorageStore => {
	if (!action.payload) return state;

	const { pally } = action.payload;

	return {
		...state,
		pally,
	};
};

const pallyDetailsClear = (state: StorageStore): StorageStore => {
	return {
		...state,
		pally: undefined,
	};
};

const clear = (): StorageStore => {
	return STORAGE_INITIAL_STATE;
};

/* ------------- Hookup Reducers To Types ------------- */
export const storageReducer = createReducer(STORAGE_INITIAL_STATE, {
	[ACTION_STORAGE_OVERVIEW_SET]: storageOverviewSet,
	[ACTION_STORAGE_STORAGE_SLOT_DETAILS_SET]: storageSlotDetailsSet,
	[ACTION_STORAGE_STORAGE_SLOT_DETAILS_CLEAR]: storageSlotDetailsClear,
	[ACTION_STORAGE_PALLY_DETAILS_SET]: pallyDetailsSet,
	[ACTION_STORAGE_PALLY_DETAILS_CLEAR]: pallyDetailsClear,
	[ACTION_STORAGE_CLEAR]: clear,
	[ACTION_LOGOUT]: clear,
});
